.rev-btn {
    background-color: #0accbe;
    color: white;
    margin: 10px 0;
    cursor: pointer;
    font-size: 1rem;
    box-shadow: 0 0 1rem 0 rgba(99, 123, 150, 0.329);
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    border-radius: 35px;
    transition: all .15s ease-in-out;
}

.rev-btn:hover {
    background-color: white;
    border: 1px solid #0accbe;
    color: #0accbe;
}

._3LWZlK {
    line-height: normal;
    display: inline-block;
    color: #fff;
    padding: 2px 4px 2px 6px;
    border-radius: 3px;
    font-weight: 500;
    font-size: 12px;
    vertical-align: middle;
    background-color: #388e3c;
    
}
._2wzgFH ._1BLPMq
{
margin-top: 3px;
}

._1wB99o
{
height: 10px;
margin: -3px 0 0 2px;
}

.review_row
{
    display: flex;
    flex-flow: row wrap;
    margin-top: 20px;
    margin-left: auto;
}
._2wzgFH .t-ZTKy
{
    line-height: 1.4;
    font-size: 14px;
    color:#212121;
    margin: 12px 0;
    overflow: hidden;
}
._2wzgFH ._1H-bmy
{
    height: 0;
    width: 0;
    margin: 0;
    padding: 0;
    visibility: collapse;
    display: none;
}
._2wzgFH ._2mcZGG
{
    font-size: 12px;
    color: #878787;
    padding: 0 6px 0 0; 
    /* margin-top: 4px; */
}

@media (max-width: 400px) {
    .rev-btn {
        font-size: 0.6rem;
        padding: 0.2rem 0.3rem;
    }
    .xtension4s {
        font-size: 2.5rem;
    }
    ._5499 {
        width: 100% !important;
    }
}
