.more-services {
    padding-top: 20px;
  }
  .more-services .card {
    border: 0;
    padding: 160px 20px 20px 20px;
    position: relative;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .more-services .card-body {
    z-index: 10;
    background: rgba(255, 255, 255, 0.9);
    padding: 15px 30px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    transition: ease-in-out 0.4s;
    border-radius: 5px;
  }
  .more-services .card-title {
    font-weight: 700;
    text-align: center;
    margin-bottom: 15px;
  }
  .more-services .card-title a {
    color: #222222;
  }
  .more-services .card-text {
    color: #5e5e5e;
  }
  .more-services .read-more a {
    color: #777777;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    transition: 0.4s;
  }
  .more-services .read-more a:hover {
    text-decoration: underline;
  }
  .more-services .card:hover .card-body {
    background: #4E83F1;
  }
  .more-services .card:hover .read-more a, .more-services .card:hover .card-title, .more-services .card:hover .card-title a, .more-services .card:hover .card-text {
    color: #fff;
  }