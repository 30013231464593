.cart_wrapper .cart_details {
  /*background: linear-gradient(45deg, #4e83f1, );*/
  background: white; /* Changed: linear-gradient(0deg, #5c8cf1 0%, #4a80f0 100%)*/
  padding: 30px 40px;
  width: 330px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 330px;
  flex: 0 0 330px;
  -webkit-box-shadow: -8px 0px 32px rgba(0, 0, 0, 0.36);
  box-shadow: -8px 0px 32px rgba(0, 0, 0, 0.36);
  position: relative;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .cart_wrapper .cart_details {
    width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}

@media (max-width: 767px) {
  .cart_wrapper .cart_details {
    width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    padding: 30px;
  }
}

.cart_wrapper .cart_details .cart_title {
  font-size: 22px;
  color: black; /*Changed: white*/
  font-weight: 400;
  margin-bottom: 60px;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .cart_wrapper .cart_details .cart_title {
    margin-bottom: 40px;
  }
}

.cart_wrapper .cart_details .form_row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .cart_wrapper .cart_details .form_row {
    margin-bottom: 40px;
  }
}

.cart_wrapper .cart_details .form_row .form_group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0px;
  margin-bottom: 35px;
  padding: 0px 10px;
  width: 100%;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .cart_wrapper .cart_details .form_row .form_group:nth-child(2) {
    width: 50%;
  }
  .cart_wrapper .cart_details .form_row .form_group:nth-child(3) {
    width: 30% !important;
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 30% !important;
    flex: 0 0 30% !important;
  }
  .cart_wrapper .cart_details .form_row .form_group:nth-child(4) {
    width: 20% !important;
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 20% !important;
    flex: 0 0 20% !important;
  }
}

.cart_wrapper .cart_details .form_row .form_group.w_75 {
  width: 65%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 65%;
  flex: 0 0 65%;
}

.cart_wrapper .cart_details .form_row .form_group.w_25 {
  width: 35%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 35%;
  flex: 0 0 35%;
}

.cart_wrapper .cart_details .form_row .form_group .input_label {
  color: #666; /*Changed: #0acbbe*/
  font-weight: bold;
  font-size: 16px;
  width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  letter-spacing: 0.5px;
}

.cart_wrapper .cart_details .form_row .form_group .input {
  width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  background-color: transparent;
  border: none;
  outline: none;
  color: #eee;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  border-bottom: solid 2px #999;
  height: 32px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.cart_wrapper
  .cart_details
  .form_row
  .form_group
  .input::-webkit-input-placeholder {
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.1);
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.cart_wrapper .cart_details .form_row .form_group .input:-ms-input-placeholder {
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.1);
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.cart_wrapper
  .cart_details
  .form_row
  .form_group
  .input::-ms-input-placeholder {
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.1);
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.cart_wrapper .cart_details .form_row .form_group .input::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.1);
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.cart_wrapper
  .cart_details
  .form_row
  .form_group
  .input:focus::-webkit-input-placeholder {
  opacity: 0;
}

.cart_wrapper
  .cart_details
  .form_row
  .form_group
  .input:focus:-ms-input-placeholder {
  opacity: 0;
}

.cart_wrapper
  .cart_details
  .form_row
  .form_group
  .input:focus::-ms-input-placeholder {
  opacity: 0;
}

.cart_wrapper .cart_details .form_row .form_group .input:focus::placeholder {
  opacity: 0;
}

.cart_wrapper .cart_details .form_row .form_group.cart_type {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .cart_wrapper .cart_details .form_row .form_group.cart_type {
    -webkit-box-pack: left;
    -ms-flex-pack: left;
    justify-content: left;
  }
}

.cart_wrapper .cart_details .form_row .form_group.cart_type .type {
  width: calc(100% / 3 - 15px);
  padding: 0px;
  margin-top: 5px;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .cart_wrapper .cart_details .form_row .form_group.cart_type .type {
    width: 75px;
  }
}

.cart_wrapper .cart_details .form_row .form_group.cart_type .type:hover svg {
  fill: #bbb;
}

.cart_wrapper .cart_details .form_row .form_group.cart_type .type svg {
  width: 42px;
  fill: #7f7a76;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.cart_wrapper .cart_details .form_row .form_group.cart_type .type.paypal svg {
  width: 62px;
  margin-top: -10px;
}

.cart_wrapper .cart_details .form_row .form_group.cart_type input {
  display: none;
}

.cart_wrapper
  .cart_details
  .form_row
  .form_group.cart_type
  input#master:checked
  ~ .master
  svg {
  fill: #f4f4f4;
}

.cart_wrapper
  .cart_details
  .form_row
  .form_group.cart_type
  input#visa:checked
  ~ .visa
  svg {
  fill: #f4f4f4;
}

.cart_wrapper
  .cart_details
  .form_row
  .form_group.cart_type
  input#paypal:checked
  ~ .paypal
  svg {
  fill: #f4f4f4;
}

.cart_wrapper .cart_details .form_row .btn-checkout {
  width: calc(100%);
  height: 65px;
  margin: 0px -40px;
  background-color: #0accbe;
  border: none;
  color: white;
  font-size: 20px;
  font-weight: 400;
  position: absolute;
  margin-top: 70px;
  bottom: 0px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

@media (max-width: 767px) {
  .cart_wrapper .cart_details .form_row .btn-checkout {
    position: static;
    margin: auto;
  }
}

.cart_wrapper .cart_details .form_row .btn-checkout:hover {
  background-color: rgba(10, 204, 190, 0.8);
}

.cart_wrapper .cart_details .form_row .btn-checkout:active {
  -webkit-box-shadow: inset 0 0 28px 0px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 28px 0px rgba(0, 0, 0, 0.3);
}

.wrap {
  width: 600px;
}
.coupon {
  display: inline-block;
  overflow: hidden;
  border-radius: 10px;
}
.coupon-left {
  float: left;
  width: 150px;
  height: 150px;
  position: relative;
}
.coupon-left::before {
  content: "";
  position: absolute;
  top: -210px;
  display: block;
  right: -210px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 200px solid #252525;
  clip: rect(auto, auto, 285px, auto);
}
.coupon-left::after {
  content: "";
  position: absolute;
  bottom: -210px;
  display: block;
  right: -210px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 200px solid #252525;
  clip: rect(135px, auto, auto, auto);
}
.coupon-con {
  float: left;
  width: 350px;
  height: 150px;
  position: relative;
}
.coupon-con::before {
  content: "";
  position: absolute;
  top: -410px;
  display: block;
  left: -410px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 400px solid #fff;
  clip: rect(0, auto, 485px, 410px);
}
.coupon-con::after {
  content: "";
  position: absolute;
  bottom: -410px;
  display: block;
  left: -410px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 400px solid #fff;
  clip: rect(335px, auto, auto, 410px);
}
