.ie-panel {
    display: none;
    background: #212121;
    padding: 10px 0;
    box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, .3);
    clear: both;
    text-align: center;
    position: relative;
    z-index: 1;
}

.goog-te-banner-frame.skiptranslate {
    display: none !important;
}

.goog-logo-link {
    display: none !important;
}

html.ie-10 .ie-panel,
html.lt-ie-10 .ie-panel {
    display: block;
}

.vertical {
    border-left: 2px solid white;
    height: 100%;
    position: absolute;
    left: -40%;
}

@media only screen and (min-device-width: 773px) and (max-device-width: 1399px) {
    .myclass {
        margin-bottom: 0;
    }
}

@media only screen and (min-width : 1400px) {
    .myclass {
        margin-bottom: 150px;
    }
}

#handle-view {
    margin-top: 80%;
    padding: 0 10px;
}

@media only screen and (min-width: 1200px) {
    #icon-view {
        display: none;
    }

    #handle-view {
        display: none;
    }

    #cart-size {
        display: none;
    }

    #icon-view-home {
        display: none;
    }

    #icon-view-more {
        display: none;
    }

}

@media only screen and (max-width: 1199px) {
    #cart-size-1 {
        display: none;
    }

    #home-tab {
        margin-right: 30px;
    }

    #more-tab {
        margin-right: 50px;
    }

    #icon-view-home {
        margin-left: 30px;
        margin-right: 20px;

    }

    #icon-view-more {
        margin-left: 30px;
        margin-right: 20px;
    }
}

#handle-view {
    font-size: 28px;
}

#cart-size {
    font-size: 28px;
}

#cart-size-1 {
    font-size: 35px;
}

.make-navbar {
    margin-right: 10%;
}

.ml-2,
.mx-2 {
    /* margin-left: 1.5rem !important; */
}

.feature video {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    z-index: 0;
}

.add-xs {

    border-radius: 50%;
    background-color: #0accbe;
    color: white;
    font-size: 40%;
    padding: .6px;
    bottom: 60%;
    left: 50%;
    line-height: 20px;
    min-width: 20px;
    text-align: center;
    position: absolute;
    background: #0accbe;
    font-weight: bold;



}

.lottie-container {
    overflow: hidden;
    width: 570px;
    height: 370px;
    /*Change as needed*/

}

.lottie-container2 {
    overflow: hidden;
    width: 570px;
    height: 370px;
    /*Change as needed*/

}


@media only screen and (min-device-width: 992px) and (max-device-width: 1200px) {
    .lottie-container {
        overflow: hidden;
        width: 360px;
        height: 370px;
        /*Change as needed*/
        margin-left: -20px;

    }

    .col-md-82 {
        flex: 0 0 66.66667%;
        max-width: 45.66667%;
        padding-right: 5px;
        padding-left: 55px;
        margin-left: -1px;
        margin-right: 90px;
        margin-top: -29px;

    }

    .lottie-container2 {
        overflow: hidden;
        width: 360px;
        height: 370px;
        margin-left: 76px;
        margin-top: -2pc;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 992px) {
    .lottie-container {
        overflow: hidden;
        width: 340px;
        height: 360px;
        /*Change as needed*/
        margin-left: 10px;

    }


    .lottie-container2 {

        overflow: hidden;
        width: 340px;
        height: 360px;
        margin-top: -5pc;
        margin-left: -20px;
    }
}

.col-md-82 {
    flex: 0 0 66.66667%;
    max-width: 45.66667%;
    padding-right: 5px;
    padding-left: 5px;
    margin-left: -1px;
    margin-right: 90px;
    margin-top: -29px;
}

@media only screen and (min-device-width: 576px) and (max-device-width: 768px) {
    .lottie-container {
        overflow: hidden;
        width: 340px;
        height: 360px;
        margin-left: 10px;
    }

    .lottie-container2 {
        overflow: hidden;
        width: 340px;
        height: 360px;
        margin-left: -95px;
    }

    .col-md-82 {
        flex: 0 0 66.66667%;
        max-width: 52.66667%;
        padding-right: 5px;
        padding-left: 5px;
        margin-right: auto;
        margin-left: -30px;
    }
}

@media only screen and (min-device-width: 500px) and (max-device-width: 576px) {
    .lottie-container {
        overflow: hidden;
        width: 294px;
        height: 347px;
        /*Change as needed*/
        margin-left: 110px;
    }

    .lottie-container2 {
        overflow: hidden;
        width: 294px;
        height: 347px;
        margin-left: 110px;
    }

    .col-md-82 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
        padding-right: 5px;
        padding-left: 5px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media only screen and (min-device-width: 400px) and (max-device-width: 500px) {
    .lottie-container {
        overflow: hidden;
        width: 294px;
        height: 347px;
        /*Change as needed*/
        margin-left: 70px;
    }

    .lottie-container2 {
        overflow: hidden;
        width: 294px;
        height: 347px;
        margin-left: 70px;
    }

    .col-md-82 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
        padding-right: 5px;
        padding-left: 5px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 400px) {
    .lottie-container {
        overflow: hidden;
        width: 294px;
        height: 347px;
        /*Change as needed*/
        margin-left: 50px;
    }

    .lottie-container2 {
        overflow: hidden;
        width: 294px;
        height: 347px;
        margin-left: 50px;
    }

    .col-md-82 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
        padding-right: 5px;
        padding-left: 5px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media only screen and (max-device-width: 320px) {
    .lottie-container {
        overflow: hidden;
        width: 250px;
        height: 320px;
        margin-left: 40px;
    }

    .lottie-container2 {
        overflow: hidden;
        width: 250px;
        height: 320px;
        margin-left: 40px;
    }

    .col-md-82 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
        padding-right: 5px;
        padding-left: 5px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (width: 1024px) {
    .lottie-container {
        overflow: hidden;
        width: 360px;
        height: 370px;
        /*Change as needed*/
        margin-left: -20px;
    }

    .col-md-82 {
        flex: 0 0 66.66667%;
        max-width: 45.66667%;
        padding-right: 5px;
        padding-left: 55px;
        margin-left: -1px;
        margin-right: 90px;
        margin-top: -29px;

    }

    .lottie-container2 {
        overflow: hidden;
        width: 360px;
        height: 370px;
        margin-left: 76px;
        margin-top: -2pc;
    }
}

@media screen and (width: 280px) {
    .lottie-container {
        overflow: hidden;
        width: 220px;
        height: 310px;
        margin-left: 35px;

    }

    .lottie-container2 {
        overflow: hidden;
        width: 220px;
        height: 300px;
        margin-left: 35px;
    }

    .col-md-82 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
        padding-right: 5px;
        padding-left: 5px;
        margin-left: auto;
        margin-right: auto;
    }
}