.item_qty {
  padding-top: 20px;
}
.tr_item input {
  width: 50px;
  height: 40px;
  margin-top: 10px;
  font-size: 16px;
}
.item_qty input {
  -webkit-appearance: none;
  border: none;
  text-align: center;
  margin-top: 3px;
  margin-bottom: 3px;
  width: 32px;
  font-size: 16px;
  margin-left: 20px;
  color: #43484d;
  font-weight: 300;
}

.plus-btn {
  width: 30px;
  height: 30px;
  /* background: none; */
  /* border-radius: 6px; */
  /* margin-left: 21px; */
  border: none;
  cursor: pointer;
}
.minus-btn {
  width: 30px;
  height: 30px;
  /* background: none; */
  /* border-radius: 6px; */
  /* margin-left: 20px; */
  border: none;
  cursor: pointer;
}
.minus-btn img {
  margin-bottom: 3px;
}
.plus-btn img {
  margin-bottom: 2px;
}
