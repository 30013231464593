@charset "UTF-8";

.footer-anchor {
  color: #e3ecef;
}
/* @font-face {
  font-family: Avenir;
  src: url("/public/fonts/AvenirLTStd-Book.otf");
} */


/*
* Trunk version 2.0.1
*/
/*
* Contexts
*/
.color-nav{
  background:linear-gradient(0deg, #5c8cf1 0%, #4a80f0 100%);
}
.nav-font{
  font-size:1rem;
  color: white;
  margin-left: 10px;
}
.nav-menu{
  align: center;
}
body
{
  font-family: Avenir;
}

#nav-dropdown{
  color: blue;
}
.loader{
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url('//upload.wikimedia.org/wikipedia/commons/thumb/e/e5/Phi_fenomeni.gif/50px-Phi_fenomeni.gif') 
              50% 50% no-repeat rgb(249,249,249);
}

.sticky-nav {
  position: sticky;
  top: 0;
}

.context-dark,
.bg-gray-900,
.bg-accent,
.context-dark h1,
.bg-gray-900 h1,
.bg-accent h1,
.context-dark h2,
.bg-gray-900 h2,
.bg-accent h2,
.context-dark h3,
.bg-gray-900 h3,
.bg-accent h3,
.context-dark h4,
.bg-gray-900 h4,
.bg-accent h4,
.context-dark h5,
.bg-gray-900 h5,
.bg-accent h5,
.context-dark h6,
.bg-gray-900 h6,
.bg-accent h6,
.context-dark .heading,
.bg-gray-900 .heading,
.bg-accent .heading {
  color: #ffffff;
}

a:focus,
button:focus {
  outline: none !important;
}

button::-moz-focus-inner {
  border: 0;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.cart-menu {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
/* Show the dropdown menu on hover */
.dropdown:hover .cart-menu {
  display: block;
}

.cart-list {
  display: flexbox;
  width: 100%;
  padding: 30px 25px 50px;
  background: linear-gradient(0deg, #4a80f0 0%, #5c8cf1 100%);
}

*:focus {
  outline: none;
}

blockquote {
  padding: 0;
  margin: 0;
}

input,
button,
select,
textarea {
  outline: none;
}

label {
  margin-bottom: 0;
}

p {
  margin: 0;
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul li,
ol li {
  display: block;
}

dl {
  margin: 0;
}

dt,
dd {
  line-height: inherit;
}

dt {
  font-weight: inherit;
}

dd {
  margin-bottom: 0;
}

cite {
  font-style: normal;
}

form {
  margin-bottom: 0;
}

blockquote {
  padding-left: 0;
  border-left: 0;
}

address {
  margin-top: 0;
  margin-bottom: 0;
}

figure {
  margin-bottom: 0;
}

html p a:hover {
  text-decoration: none;
}

/*
* Typography
*/

body {
  font-size: 14px;
  letter-spacing: 0.05em;
  background-color: #ffffff;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: subpixel-antialiased;
}

@media (max-width: 767.98px) {
  body {
    line-height: 1.6;
  }
}

@media (min-width: 768px) {
  body {
    font-size: 15px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.heading {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Samsung;
  font-weight: 400;
  color: #262f3c;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
.heading a {
  color: inherit;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
.heading a:hover {
  color: #0accbe;
}

h1,
.heading-1 {
  font-size: 34px;
  font-weight: 800;
  line-height: 1.5;
  letter-spacing: 0.025em;
}

@media (min-width: 768px) {
  h1,
  .heading-1 {
    font-size: 44px;
  }
}

@media (min-width: 1200px) {
  h1,
  .heading-1 {
    font-size: 65px;
    line-height: 1.07692;
  }
}

h2,
.heading-2 {
  font-size: 28px;
  line-height: 1.35;
  letter-spacing: 0;
}

@media (min-width: 768px) {
  h2,
  .heading-2 {
    font-size: 40px;
  }
}

@media (min-width: 1200px) {
  h2,
  .heading-2 {
    font-size: 48px;
    line-height: 1.12727;
  }
}



@media (min-width: 1600px) {
  h2,
  .heading-2 {
    font-size: 55px;
  }
}


h3,
.heading-3 {
  font-size: 22px;
  line-height: 1.35;
  letter-spacing: 0;
}

@media (min-width: 576px) {
  h3,
  .heading-3 {
    font-size: 24px;
  }
}

@media (min-width: 768px) {
  h3,
  .heading-3 {
    font-size: 30px;
  }
}

@media (min-width: 1200px) {
  h3,
  .heading-3 {
    font-size: 39px;
    line-height: 1.23077;
  }
}

h4,
.heading-4 {
  font-size: 18px;
  line-height: 1.35;
  letter-spacing: 0;
}

@media (min-width: 992px) {
  h4,
  .heading-4 {
    font-size: 19px;
  }
}

@media (min-width: 1200px) {
  h4,
  .heading-4 {
    font-size: 22px;
    line-height: 1.36364;
  }
}

@media (min-width: 1200px) {
  h4.h4-bigger,
  .heading-4.h4-bigger {
    font-size: 26px;
  }
}

h5,
.heading-5 {
  font-size: 16px;
  line-height: 1.35;
  letter-spacing: 0.025em;
}

@media (min-width: 992px) {
  h5,
  .heading-5 {
    font-size: 16px;
    line-height: 1.625;
  }
}

h6,
.heading-6 {
  font-size: 14px;
  line-height: 1.35;
  letter-spacing: 0.025em;
}

@media (min-width: 576px) {
  h6,
  .heading-6 {
    font-size: 15px;
    line-height: 1.6;
  }
}

.heading-7 {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.35;
  color: #8491a5;
  text-transform: uppercase;
  letter-spacing: 0.34em;
}

.heading-alternate-1 {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
}

@media (min-width: 576px) {
  .heading-alternate-1 {
    font-size: 20px;
  }
}

@media (min-width: 768px) {
  .heading-alternate-1 {
    font-size: 24px;
  }
}

@media (min-width: 992px) {
  .heading-alternate-1 {
    font-size: 29px;
  }
}

.text-extra-large {
  font-family: "Samsung";
  font-size: 88px;
  line-height: 1;
  font-weight: 100;
  letter-spacing: 0.08em;
  color: #262f3c;
}

@media (min-width: 576px) {
  .text-extra-large {
    font-size: 70px;
  }
}

@media (min-width: 576px) {
  .text-extra-large {
    font-size: 155px;
  }
}

@media (min-width: 1600px) {
  .text-extra-large {
    font-size: 250px;
  }
}

.heading-decoration-1 {
  position: relative;
  padding-left: 30px;
}

.heading-decoration-1::before {
  content: "";
  position: absolute;
  top: 0.66em;
  left: 0;
  height: 1px;
  width: 20px;
  background: #d7d7d7;
}

.heading-decoration-1 span {
  display: inline-block;
  line-height: 1.1;
}

@media (min-width: 576px) {
  .heading-decoration-1 {
    padding-left: 40px;
  }
  .heading-decoration-1::before {
    top: 0.8em;
    width: 24px;
  }
}

@media (min-width: 1200px) {
  .heading-decoration-1 {
    padding-left: 85px;
  }
  .heading-decoration-1::before {
    top: 0.65em;
    width: 60px;
  }
}

.heading-decoration-2 {
  position: relative;
  padding-left: 30px;
  line-height: 1.05;
}

.heading-decoration-2::before {
  content: "";
  position: absolute;
  top: 0.7em;
  left: 0;
  height: 3px;
  width: 20px;
  background: #0accbe;
}

.heading-decoration-2 span {
  display: inline;
  margin-right: 0.25em;
  line-height: 1.1;
}

@media (min-width: 576px) {
  .heading-decoration-2 {
    padding-left: 60px;
  }
  .heading-decoration-2::before {
    top: 0.8em;
    height: 4px;
    width: 35px;
  }
}

@media (min-width: 1200px) {
  .heading-decoration-2::before {
    top: 0.66em;
  }
}

.heading-decoration-3 {
  position: relative;
  padding-left: 30px;
  line-height: 1.05;
}

.heading-decoration-3 span {
  display: inline;
  margin-right: 0.25em;
  line-height: 1.1;
}

@media (min-width: 576px) {
  .heading-decoration-3 {
    padding-left: 60px;
  }
  .heading-decoration-3::before {
    top: 0.8em;
    height: 4px;
    width: 35px;
  }
}

.heading-mod-1 {
  margin-top: -0.35em;
}

.heading-mod-1 span {
  display: inline;
  line-height: 1.2;
}

.heading-mod-1 br {
  display: flex;
  height: 0;
}

small,
.small {
  display: block;
  font-size: 13px;
  line-height: 1.38462;
}

mark,
.mark {
  padding: 3px 5px;
  color: #ffffff;
  background: #0accbe;
}

.big {
  font-size: 15px;
  line-height: 1.4;
  font-weight: 400;
  letter-spacing: 0.025em;
}

@media (min-width: 992px) {
  .big {
    font-size: 17px;
    line-height: 1.5;
  }
}

.normal {
  font-size: 14px;
  line-height: 1.4;
  font-weight: 400;
  letter-spacing: 0.025em;
}

@media (min-width: 992px) {
  .normal {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.07em;
  }
}

.normal-2 {
  font-size: 14px;
  line-height: 1.4;
  font-weight: 400;
  letter-spacing: 0.025em;
}

@media (min-width: 992px) {
  .normal-2 {
    font-size: 16px;
    line-height: 2.5em;
    letter-spacing: 0.07em;
  }
}

.lead {
  font-size: 24px;
  line-height: 34px;
  font-weight: 300;
}

code {
  padding: 10px 5px;
  border-radius: 0.2rem;
  font-size: 90%;
  color: #3f4856;
  background: #f9f8f8;
}

p [data-toggle="tooltip"] {
  padding-left: 0.25em;
  padding-right: 0.25em;
  color: #0accbe;
}

p [style*="max-width"] {
  display: inline-block;
}

::selection {
  background: rgba(38, 47, 60, 0.5);
  color: #ffffff;
}

::-moz-selection {
  background: rgba(38, 47, 60, 0.5);
  color: #ffffff;
}

.text-1 {
  color: #262f3c;
  letter-spacing: 0;
}

.text-1 > * {
  margin-right: 0.2em;
}

/*
* Brand
*/
.brand {
  display: inline-block;
}

.brand .brand-logo-light {
  display: none;
}

.brand .brand-logo-dark {
  display: block;
}

/*
*
* Badge Custom
*/
.badge {
  font-weight: 700;
  color: #0accbe;
  background: #d6f3f5;
  text-transform: uppercase;
  letter-spacing: 0.075em;
}

.context-dark .badge,
.bg-gray-900 .badge,
.bg-accent .badge {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.2);
}

.badge-1 {
  padding: 10px 12px;
  font-weight: 600;
  letter-spacing: 0.025em;
  text-transform: none;
  border-radius: 0;
}

.badge-sm {
  padding: 5px 10px;
  font-size: 12px;
  letter-spacing: 0.025em;
}

.badge.badge-secondary {
  color: #ffffff;
  background: #5f67df;
}

.badge.badge-color-1 {
  color: #0accbe;
  background: #d6f3f5;
}

/*
* Links
*/
a {
  transition: all 0.3s ease-in-out;
}
.cart-icon:hover .add-xs{

  background-color: white;
  color:#0accbe;
  border:#0accbe 1px solid;
}
a,
a:focus,
a:active,
a:hover {
  text-decoration: none;
}

a,
a:focus,
a:active {
  color: inherit;
}

a:hover {
  color: #0accbe;
}

a[href*="tel"],
a[href*="mailto"] {
  white-space: nowrap;
}

.link-hover {
  color: #0accbe;
}

.link-press {
  color: #09b4a7;
}

.privacy-link {
  display: inline-block;
}

* + .privacy-link {
  margin-top: 25px;
}

.link-default {
  letter-spacing: 0.01em;
  color: #262f3c;
}

.link-default:hover {
  color: #0accbe;
}

.link-social-1 {
  position: relative;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  text-align: center;
  color: #ffffff;
  background: #8491a5;
  font-size: 16px;
  line-height: 2em;
}

.link-social-1,
.link-social-1:active,
.link-social-1:focus,
.link-social-1:hover {
  color: #ffffff;
}

.link-social-1::after {
  content: "";
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  top: 0;
  left: 0;
  padding: 2px;
  z-index: -1;
  background: #0accbe;
  opacity: 1;
  transform: scale(1);
  border: 1px solid rgba(255, 255, 255, 0.4);
}

.link-social-1[class*="twitter"] {
  background: transparent;
}

.link-social-1[class*="twitter"]::after {
  background: transparent;
}

.link-social-1[class*="facebook"] {
  background: transparent;
}

.link-social-1[class*="facebook"]::after {
  background: transparent;
}

.link-social-1[class*="instagram"] {
  background: transparent;
}

.link-social-1[class*="instagram"]::after {
  background: transparent;
}

.link-social-1[class*="linkedin"] {
  background: #3078bb;
}

.link-social-1[class*="linkedin"]::after {
  background: #3078bb;
}

.link-social-1:hover::after {
  transition: transform 0.2s, opacity 0.3s;
  transform: scale(1.3);
  opacity: 0;
}

.link-social-2 {
  color: white;
}

.link-social-2:hover {
  color: #0accbe;
}

.link-social-2[class*="twitter"]:hover {
  color: #0accbe;
}

.link-social-2[class*="facebook"]:hover {
  color: #0accbe;
}

.link-social-2[class*="instagram"]:hover {
  color: #0accbe;
}
.link-social-2[class*="cart"]:hover {
  color: #0accbe;
}

.link-social-3 {
  position: relative;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  text-align: center;
  color: #ffffff;
  background: #8491a5;
  font-size: 16px;
  line-height: 2em;
}

.link-social-3,
.link-social-3:active,
.link-social-3:focus,
.link-social-3:hover {
  color: #ffffff;
}

.link-social-3::after {
  content: "";
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  top: 0;
  left: 0;
  padding: 2px;
  z-index: -1;
  background: #0accbe;
  opacity: 1;
  transform: scale(1);
  border: 1px solid #ffffff;
}

.link-social-3[class*="twitter"] {
  background: #63c9eb;
}

.link-social-3[class*="twitter"]::after {
  background: #63c9eb;
}

.link-social-3[class*="facebook"] {
  background: #4970bf;
}

.link-social-3[class*="facebook"]::after {
  background: #4970bf;
}

.link-social-3[class*="instagram"] {
  background: #ff6f80;
}

.link-social-3[class*="instagram"]::after {
  background: #ff6f80;
}

.link-social-3[class*="linkedin"] {
  background: #3078bb;
}

.link-social-3[class*="linkedin"]::after {
  background: #3078bb;
}

.link-social-3:hover::after {
  transition: transform 0.2s, opacity 0.3s;
  transform: scale(1.3);
  opacity: 0;
}

.link-underline {
  display: inline-block;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
  text-align: center;
  letter-spacing: 0.01em;
}

.link-underline::before,
.link-underline::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: #262f3c;
}

.link-underline::before {
  transition: 0.22s 0.22s ease-in;
  transform: translateX(-110%);
}

.link-underline::after {
  left: 0;
  transition: 0.22s ease-in;
}

.link-underline,
.link-underline:active,
.link-underline:focus,
.link-underline:hover {
  color: #262f3c;
}

.link-underline:hover::after {
  right: 0;
  left: auto;
  width: 0;
}

.link-underline:hover::before {
  transform: translateX(0);
}

.link-icon {
  position: relative;
  display: inline-block;
  padding: 3px;
  white-space: nowrap;
  letter-spacing: 0;
  transition: 0.22s ease-in;
}

.link-icon > * {
  display: inline-block;
  vertical-align: middle;
}

.link-icon .icon {
  position: relative;
  top: 1px;
  transition: transform 0.22s ease-in;
}

.link-icon > * + * {
  margin-left: 5px;
}

.link-icon,
.link-icon:active,
.link-icon:focus,
.link-icon:hover {
  color: #262f3c;
}

.link-icon:hover {
  opacity: 0.5;
}

.link-icon-left .icon + span {
  transition: transform 0.22s ease-in;
}

.link-icon-left:hover .icon {
  transform: translateX(-8px);
}

.link-icon-left:hover .icon + span {
  transform: translateX(-6px);
}

.link-image-1 {
  display: inline-block;
  opacity: 0.3;
  transition: 0.25s ease-in-out;
}

.link-image-1:hover {
  opacity: 0.8;
}

.link-modern {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  color: #262f3c;
  letter-spacing: 0.075em;
}

.link-modern .icon {
  order: 10;
  margin-left: 7px;
  color: #0accbe;
}

.link-modern:hover {
  color: #5f67df;
}

.icon.link-waypoint-icon {
  font-size: 26px;
}

* + .link-waypoint-icon {
  margin-top: 15px;
}

.context-dark .link-waypoint-icon,
.bg-gray-900 .link-waypoint-icon,
.bg-accent .link-waypoint-icon {
  color: rgba(255, 255, 255, 0.6);
}

.context-dark .link-waypoint-icon:hover,
.bg-gray-900 .link-waypoint-icon:hover,
.bg-accent .link-waypoint-icon:hover {
  color: #ffffff;
}

@media (min-width: 768px) {
  * + .link-waypoint-icon {
    margin-top: 25px;
  }
}

/*
* Blocks
*/
.block-1 {
  max-width: 400px;
}

@media (min-width: 992px) {
  .block-2 {
    max-width: 220px;
  }
}

.block-3 {
  display: inline-block;
  max-width: 600px;
}

* + .block-3 {
  margin-top: 15px;
}

@media (min-width: 768px) {
  * + .block-3 {
    margin-top: 35px;
  }
}

.block-4 {
  max-width: 554px;
}

@media (min-width: 576px) {
  .block-4 {
    padding-right: 30px;
  }
}

@media (min-width: 768px) {
  .block-4 {
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  .block-4 {
    padding-right: 30px;
  }
}

@media (min-width: 992px) {
  .block-5 {
    padding-left: 30px;
  }
}

@media (min-width: 1200px) {
  .block-5 {
    padding-left: 70px;
  }
}

.block-6 {
  position: relative;
  z-index: 1;
  padding: 45px 15px;
  max-width: 430px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1200px) {
  .block-6 {
    max-width: 600px;
    padding: 90px 0 120px 100px;
    margin-right: 0;
  }
  .block-6 .form-lg {
    margin-right: 30px;
  }
}

@media (min-width: 1600px) {
  .block-6 {
    padding: 120px 0 140px 110px;
  }
}

* + .block-6 {
  margin-top: 30px;
}

@media (min-width: 992px) {
  .block-7 {
    max-width: 280px;
  }
}

@media (min-width: 992px) {
  .block-8 {
    max-width: 420px;
  }
}

.block-9 {
  max-width: 210px;
}

.block-10 {
  max-width: 540px;
}

.block-11 {
  max-width: 520px;
}

.block-ratio {
  display: flex;
}

@media (max-width: 575.98px) {
  .block-ratio {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}

.block-ratio-content,
.block-ratio-dummy {
  width: 100%;
  flex-shrink: 0;
}

.block-ratio-dummy {
  visibility: hidden;
  opacity: 0;
  order: 10;
}

.block-ratio-dummy::before {
  content: "";
  display: block;
}

.block-ratio-content {
  display: flex;
}

.block-ratio-content > * {
  min-width: 100%;
}

.block-ratio-1 .block-ratio-dummy::before {
  padding-bottom: 67.56757%;
}

.block-ratio-2 .block-ratio-dummy::before {
  padding-bottom: 116.21622%;
}

.block-ratio-3 .block-ratio-dummy::before {
  padding-bottom: 55.84416%;
}

/*
* Boxes
*/
.box-bordered {
  padding-left: 30px;
  border-left: 1px solid #ebebeb;
}

.box-bordered h5,
.box-bordered .heading-5 {
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

* + .box-bordered {
  margin-top: 35px;
}

@media (min-width: 768px) {
  * + .box-bordered {
    margin-top: 50px;
  }
}

.box-shadow-1 {
  /* max-width: 630px; */
  margin-left: auto;
  margin-right: auto;
  padding: 35px 20px 40px;
  border-radius: 6px;
  box-shadow: 1px 6px 32px 0 rgba(75, 81, 91, 0.11);
}

.box-shadow-1-main {
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
}

* + .box-shadow-1-main {
  margin-top: 20px;
}

@media (min-width: 360px) {
  .box-shadow-1 {
    padding: 35px 25px 40px;
  }
  .box-shadow-1-main {
    padding: 0 10px;
  }
}

@media (min-width: 768px) {
  .box-shadow-1 {
    padding: 60px 30px 80px;
  }
  .box-shadow-1 .brand + p {
    margin-top: 35px;
  }
  * + .box-shadow-1-main {
    margin-top: 35px;
  }
}

.box-custom-1 {
  padding: 20px;
  text-align: center;
  background: #f9f8f8;
}

.box-custom-1 > * {
  max-width: 200px;
}

.box-custom-1 h2,
.box-custom-1 .heading-2 {
  font-weight: 300;
}

.block-ratio .box-custom-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bg-gray-100 .box-custom-1 {
  background: #ffffff;
}

.box-custom-2 {
  position: relative;
  z-index: 1;
  padding: 30px 20px;
}

div.box-custom-2 {
  background-color: transparent;
}

.box-custom-2-bg,
.box-custom-2-bg::after,
.box-custom-2-bg-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}

.box-custom-2-bg {
  border-radius: 0 0 22px 22px;
  overflow: hidden;
}

.box-custom-2-bg::after {
  content: "";
  opacity: 0.68;
  border-radius: inherit;
  background: linear-gradient(227deg, #00a4c4 0%, #00c7b9 100%);
}

.box-custom-2-bg-inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.box-custom-2-bg-image {
  background-size: cover;
  background-position: 70% center;
}

.box-custom-2-inner {
  position: relative;
  z-index: 2;
  max-width: 420px;
}

@media (min-width: 768px) {
  .box-custom-2 {
    padding: 80px 50px 100px;
  }
}

@media (min-width: 1200px) {
  .box-custom-2 {
    padding: 20px 25px 50px 60px;
  }
  .box-custom-2-bg {
    top: -48%;
    right: -52%;
    bottom: -40%;
    margin-right: 0;
    transform: rotate(-12deg);
    border-radius: 8%;
    border: 1px solid transparent;
  }
  .box-custom-2-bg::after {
    top: -9%;
    right: -10%;
    bottom: -10%;
    left: -10%;
  }
  .box-custom-2-bg-inner {
    top: -10%;
    right: -5%;
    bottom: -5%;
    left: -12%;
    transform: rotate(12deg);
    border: 1px solid transparent;
  }
}

@media (min-width: 1740px) {
  .box-custom-2 {
    padding: 0 25px 100px 110px;
  }
  .box-custom-2-bg {
    left: -4%;
    transform: rotate(-18.5deg);
  }
  .box-custom-2-bg-inner {
    transform: rotate(18.5deg);
  }
  .box-custom-2-inner {
    max-width: 450px;
  }
}

.box-custom-3 {
  padding: 30px 0;
  border-radius: 7px;
  background: #ffffff;
  margin-top: 170px;
  box-shadow: -1px 7px 51px 0px rgba(75, 81, 91, 0.18);
}

.section-box-custom-3-outer {
  position: relative;
  z-index: 10;
  padding-bottom: 50px;
  margin-bottom: -80px;
}

.section-box-custom-3.parallax-container {
  overflow: visible;
}

.section-box-custom-3 .material-parallax {
  overflow: hidden;
  bottom: 30px;
}

.section-box-custom-3 .box-custom-3-container {
  margin-bottom: -30px;
}

.section-box-custom-3-outer + .section {
  padding-top: 110px;
}

@media (min-width: 768px) {
  .box-custom-3 {
    padding: 45px 0;
  }
  .section-box-custom-3-outer {
    padding-bottom: 100px;
    margin-bottom: -150px;
  }
  .section-box-custom-3 .material-parallax {
    bottom: 50px;
  }
  .section-box-custom-3-outer + .section {
    padding-top: 120px;
  }
}

@media (min-width: 1200px) {
  .box-custom-3 {
    padding: 90px 0;
  }
  .section-box-custom-3-outer {
    padding-bottom: 95px;
    margin-bottom: -190px;
  }
  .section-box-custom-3 .material-parallax {
    bottom: 95px;
  }
  .section-box-custom-3-outer + .section {
    padding-top: 200px;
  }
}

.box-custom-4 {
  position: relative;
  -webkit-border-radius: 0px 0px 50px 50px;
  -moz-border-radius: 0px 0px 50px 50px;
}

@media (min-width: 768px) {
  .box-custom-4 {
    border-radius: 0px 0px 0px 120px;
    -webkit-border-radius: 0px 0px 0px 120px;
    -moz-border-radius: 0px 0px 0px 120px;
  }
}

.box-custom-4-aside {
  position: relative;
  text-align: center;
}

div.box-custom-4-aside {
  background-color: transparent;
}

.box-custom-4-aside-decoration,
.box-custom-4-aside-decoration-bg,
.box-custom-4-icon-outer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.box-custom-4-icon-outer {
  overflow: hidden;
}

.icon.box-custom-4-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  color: rgba(255, 255, 255, 0.08);
  font-size: 500px;
}

.box-custom-4-main {
  position: relative;
  padding: 30px 15px 50px;
}

.box-custom-4-main-decoration {
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  right: 0;
  width: 19.8%;
  height: 73%;
  max-height: 555px;
  max-width: 285px;
  min-width: 250px;
  pointer-events: none;
}

.box-custom-4-main-decoration::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  /* background: url(../images/mobile-app-decoration-1.png); */
  background-size: cover;
  width: 100%;
  opacity: 0.5;
  padding-bottom: 195.07042%;
}

.box-custom-4-aside .button-play {
  margin-top: 0;
}

@media (max-width: 767.98px) {
  .box-custom-4-aside {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 45px 30px;
  }
  .box-custom-4-aside .button-play {
    flex-shrink: 0;
  }
  .box-custom-4-aside .iphone-frame-4-outer {
    margin-right: -100px;
    margin-left: -18px;
    transform: translate3d(25px, 15px, 0);
    width: 100%;
  }
  .box-custom-4-aside * + .iphone-frame-4-group {
    margin-top: 15px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .box-custom-4-aside {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 576px) {
  .box-custom-4 {
    display: flex;
    flex-direction: row-reverse;
  }
  .box-custom-4-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    padding-left: 35px;
  }
  .box-custom-4-aside {
    position: relative;
    z-index: 1;
    text-align: center;
  }
  .box-custom-4-aside * + .iphone-frame-4-group {
    margin-top: 10px;
  }
  .icon.box-custom-4-icon {
    font-size: 600px;
  }
}

@media (min-width: 768px) {
  .box-custom-4-aside {
    padding: 30px 0;
  }
}

@media (min-width: 1600px) {
  .box-custom-4-main {
    /* padding-left: 11%; */
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .box-custom-4-aside .iphone-frame-4-group {
    margin-right: -100px;
    margin-left: -30px;
    transform: translate3d(15px, 15px, 0);
  }
}

@media (min-width: 992px) {
  .box-custom-4 {
    height: 620px;
  }
  .box-custom-4-aside {
    width: calc(190px + 15.1vw);
    flex-shrink: 0;
    padding-left: 45px;
  }
  .box-custom-4-aside-content {
    display: flex;
    align-items: center;
  }
  .box-custom-4-aside-content > * {
    flex-shrink: 0;
  }
  .box-custom-4-aside-content .iphone-frame-4-group {
    margin-top: 0;
  }
  .icon.box-custom-4-icon {
    font-size: 740px;
  }
}

@media (min-width: 1200px) {
  .box-custom-4-aside .iphone-frame-4-group {
    transform: translate3d(45px, 15px, 0);
  }
}

@media (min-width: 1400px) {
  .box-custom-4-aside .iphone-frame-4-group {
    transform: translate3d(70px, 15px, 0);
  }
  .icon.box-custom-4-icon {
    font-size: 800px;
  }
}

@media (min-width: 1600px) {
  .box-custom-4-aside {
    padding: 10px 0 5px 75px;
  }
  .box-custom-4-main-decoration::before {
    opacity: 1;
  }
  .box-custom-4-aside-content {
    max-width: 480px;
    margin-left: auto;
  }
}

@media (min-width: 1920px) {
  .box-custom-4 {
    height: 620px;
  }
  .box-custom-4-aside {
    justify-content: flex-end;
    width: calc(58vw - 480px);
  }
  .box-custom-4-aside .iphone-frame-4-group {
    transform: translate3d(160px, 15px, 0);
  }
}

div.box-custom-5-main {
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(to bottom, #4dcde4 0%, #086ca6 100%);
  padding: 0 20px;
}

.box-custom-5-aside {
  padding: 30px 15px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.box-custom-5-main-header {
  padding: 30px 0 20px;
}

.box-custom-5-main-inner {
  position: relative;
  z-index: 1;
  max-width: 450px;
  margin: auto;
}

.box-custom-5-main-text {
  position: relative;
  left: -1vw;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -6.5vw;
  font-family: "Samsung";
  font-weight: 900;
  font-size: 110px;
  line-height: 1;
  color: rgba(255, 255, 255, 0.09);
  letter-spacing: 0.025em;
}

* + .box-custom-5-main-text {
  margin-top: -20px;
}

@media (min-width: 576px) {
  .box-custom-5-aside {
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .box-custom-5 {
    display: flex;
  }
  .box-custom-5 > * {
    flex-shrink: 0;
  }
  .box-custom-5-main {
    display: flex;
    flex-direction: column;
    width: 60%;
    border-radius: 0 0 50px 0;
  }
  .box-custom-5-aside {
    align-self: center;
    width: 40%;
  }
  .box-custom-5-main-inner {
    width: 100%;
  }
  .box-custom-5-main-body {
    flex-grow: 1;
  }
  .box-custom-5-main-text {
    font-size: 200px;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 1200px) {
  .box-custom-5-main {
    width: 54%;
    border-radius: 0 0 90px 0;
  }
  .box-custom-5-aside {
    width: calc(46% + 56px);
    margin-left: -56px;
  }
  .box-custom-5-main-header {
    padding: 60px 0;
  }
  .box-custom-5-main-text {
    font-size: 21.2201875vw;
  }
  * + .box-custom-5-main-text {
    margin-top: -7vw;
  }
}

@media (min-width: 1200px) {
  .box-custom-5-main-inner {
    margin-left: calc(50vw - 600px);
  }
}

.box-modern {
  padding: 25px 15px;
  border-radius: 7px;
  box-shadow: -1px 9px 18px 0px rgba(75, 81, 91, 0.1);
  background: #ffffff;
  text-align: center;
}

.box-modern-title {
  font-family: Samsung;
  font-size: 16px;
  line-height: 1.3;
  color: #262f3c;
}

.box-modern-media {
  position: relative;
  padding: 20px 0;
  font-size: 60px;
  line-height: 1;
  min-height: 1.3em;
  width: 1.9em;
  margin-left: auto;
  margin-right: auto;
}

.box-modern-media :after {
  content: "";
  position: absolute;
  width: 90px;
  height: 80px;
  top: 0;
  left: 0;
  /* background: url(../images/triangle.png) no-repeat; */
  -webkit-animation: mymove linear 3s infinite;
  /* Safari 4.0 - 8.0 */
  animation: mymove linear 4s infinite;
}

@media (min-width: 1200px) {
  @-webkit-keyframes mymove {
    0% {
      top: 0px;
      left: 0;
    }
    5% {
      top: 1px;
      left: 1px;
    }
    10% {
      top: 2px;
      left: 2px;
    }
    15% {
      top: 3px;
      left: 3px;
    }
    20% {
      top: 4px;
      left: 4px;
    }
    25% {
      top: 5px;
      left: 5px;
    }
    30% {
      top: 6px;
      left: 6px;
    }
    35% {
      top: 7px;
      left: 7px;
    }
    40% {
      top: 8px;
      left: 8px;
    }
    45% {
      top: 9px;
      left: 9px;
    }
    50% {
      top: 10px;
      left: 10px;
    }
    55% {
      top: 9px;
      left: 9px;
    }
    60% {
      top: 8px;
      left: 8px;
    }
    65% {
      top: 7px;
      left: 7px;
    }
    70% {
      top: 6px;
      left: 6px;
    }
    75% {
      top: 5px;
      left: 5px;
    }
    80% {
      top: 4px;
      left: 4px;
    }
    85% {
      top: 3px;
      left: 3px;
    }
    90% {
      top: 2px;
      left: 2px;
    }
    95% {
      top: 1px;
      left: 1px;
    }
    100% {
      top: 0px;
      left: 0px;
    }
  }
  @keyframes mymove {
    0% {
      top: 0px;
      left: 0;
    }
    5% {
      top: 1px;
      left: 1px;
    }
    10% {
      top: 2px;
      left: 2px;
    }
    15% {
      top: 3px;
      left: 3px;
    }
    20% {
      top: 4px;
      left: 4px;
    }
    25% {
      top: 5px;
      left: 5px;
    }
    30% {
      top: 6px;
      left: 6px;
    }
    35% {
      top: 7px;
      left: 7px;
    }
    40% {
      top: 8px;
      left: 8px;
    }
    45% {
      top: 9px;
      left: 9px;
    }
    50% {
      top: 10px;
      left: 10px;
    }
    55% {
      top: 9px;
      left: 9px;
    }
    60% {
      top: 8px;
      left: 8px;
    }
    65% {
      top: 7px;
      left: 7px;
    }
    70% {
      top: 6px;
      left: 6px;
    }
    75% {
      top: 5px;
      left: 5px;
    }
    80% {
      top: 4px;
      left: 4px;
    }
    85% {
      top: 3px;
      left: 3px;
    }
    90% {
      top: 2px;
      left: 2px;
    }
    95% {
      top: 1px;
      left: 1px;
    }
    100% {
      top: 0px;
      left: 0px;
    }
  }
}

.box-modern-circle-1,
.box-modern-circle-2 {
  position: absolute;
  border-radius: 50%;
}

.box-modern-circle-1 {
  left: 0;
  bottom: 0;
  width: 1.23em;
  height: 1.23em;
  background: #e2fcfe;
}

.box-modern-circle-2 {
  top: 0;
  right: 0;
  width: 0.54em;
  height: 0.54em;
  background: #f9f9ef;
}

.box-modern_alternate .box-modern-circle-1 {
  top: 0;
  bottom: auto;
}

.box-modern_alternate .box-modern-circle-2 {
  top: auto;
  bottom: 0;
}

.box-modern_alternate-1 .box-modern-circle-1 {
  right: 0;
  left: auto;
}

.box-modern_alternate-1 .box-modern-circle-2 {
  right: auto;
  left: auto;
}

.box-modern-icon {
  position: relative;
  z-index: 2;
  right: -5%;
  font-size: inherit;
  color: #0accbe;
  opacity: 0.7;
}

.box-modern-sm .box-modern-media {
  width: 1.7em;
}

.box-modern-sm .box-modern-icon {
  opacity: 1;
}

@supports (-webkit-background-clip: text) {
  .box-modern-icon {
    background: linear-gradient(30deg, #13c3dd, #62e3ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

* + .box-modern-text {
  margin-top: 12px;
}

@media (max-width: 575.98px) {
  .box-modern {
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 768px) {
  .box-modern {
    padding: 45px 24px;
  }
  .box-modern-title {
    font-size: 22px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .box-modern {
    padding-left: 9px;
    padding-right: 9px;
  }
}

@media (min-width: 1200px) {
  .box-modern {
    padding-top: 55px;
    padding-bottom: 60px;
  }
  .box-modern-media {
    font-size: 85px;
  }
  .box-modern-title {
    font-size: 24px;
  }
  .box-modern-text {
    font-size: 16px;
    line-height: 1.52941;
  }
  * + .box-modern-text {
    margin-top: 24px;
  }
  .box-modern-sm .box-modern-media {
    font-size: 64px;
  }
  .box-modern-sm .box-modern-title {
    font-size: 22px;
  }
  .box-modern-sm .box-modern-text {
    font-size: 15px;
  }
}

@media (min-width: 1600px) {
  .box-modern-title {
    font-size: 26px;
    letter-spacing: 0.01em;
  }
  .box-modern-text {
    font-size: 17px;
  }
}

.box-light {
  position: relative;
  padding: 30px 20px 20px;
  background: #ffffff;
  text-align: left;
}

.box-light::before,
.box-light::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 5px;
  transition: 0.44s;
}

.box-light::before {
  width: 80%;
  background: #ebebeb;
}

.box-light::after {
  width: 0;
  background: #ffea5c;
}

.box-light:hover::after {
  width: 80%;
  left: 20%;
  right: auto;
}

.box-light-counter {
  font-family: "Samsung";
  font-size: 20px;
  font-weight: 300;
  line-height: 1.2;
  color: #262f3c;
}

* + .box-light-title {
  margin-top: 14px;
}

* + .box-light-main {
  margin-top: 17px;
}

* + .box-light-footer {
  margin-top: 25px;
}

@media (min-width: 992px) {
  html:not(.tablet):not(.mobile) .box-light:hover .box-light-counter {
    color: #262f3c;
  }
  html:not(.tablet):not(.mobile) .box-light:hover .box-light-title {
    color: #0accbe;
  }
  html:not(.tablet):not(.mobile) .box-light:hover .box-light-footer {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }
  html:not(.tablet):not(.mobile) .box-light-counter {
    color: #8491a5;
    transition: 0.22s;
  }
  html:not(.tablet):not(.mobile) .box-light-footer {
    position: relative;
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0, 5px, 0);
    transition: 0.22s;
  }
}

@media (min-width: 768px) {
  .box-light-counter {
    font-size: 30px;
  }
  * + .box-light-title {
    margin-top: 20px;
  }
  * + .box-light-main {
    margin-top: 16px;
  }
  * + .box-light-footer {
    margin-top: 20px;
  }
}

@media (min-width: 992px) {
  .box-light-title {
    font-size: 26px;
  }
  * + .box-light-title {
    margin-top: 45px;
  }
  * + .box-light-main {
    margin-top: 28px;
  }
  * + .box-light-footer {
    margin-top: 35px;
  }
}

.box-classic {
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  cursor: default;
}

.icon.box-classic-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  margin-left: auto;
  margin-right: auto;
  color: #ffffff;
  background: #869098;
  border-radius: 50%;
  font-size: 30px;
}

.icon.box-classic-icon::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  pointer-events: none;
  box-shadow: 1.219px 9.925px 7px 0px rgba(75, 81, 91, 0.13);
}

* + .box-classic-main {
  margin-top: 10px;
}

@media (min-width: 576px) {
  .icon.box-classic-icon {
    font-size: 43px;
  }
}

@media (min-width: 768px) {
  * + .box-classic-main {
    margin-top: 18px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .box-classic {
    display: flex;
    text-align: left;
    max-width: 100%;
  }
  .box-classic > * + * {
    margin-top: 0;
    margin-left: 25px;
  }
  .icon.box-classic-icon {
    flex-shrink: 0;
  }
  .box-classic-main {
    flex-grow: 1;
  }
}

@media (min-width: 992px) {
  .box-classic {
    padding: 35px 20px;
  }
  html:not(.tablet):not(.mobile) .box-classic {
    position: relative;
  }
  html:not(.tablet):not(.mobile) .box-classic > * {
    position: relative;
    z-index: 1;
  }
  html:not(.tablet):not(.mobile) .box-classic::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 9px;
    background: #ffffff;
    box-shadow: 0 0 76px 0 rgba(75, 81, 91, 0.23);
    opacity: 0;
    visibility: hidden;
    transform: scale3d(0.9, 0.9, 0.9);
    transition: 0.1s;
  }
  html:not(.tablet):not(.mobile) .box-classic:hover::before {
    opacity: 1;
    visibility: visible;
    transform: scale3d(1, 1, 1);
    transition: 0.25s;
  }
  html:not(.tablet):not(.mobile)
    .box-classic:hover
    .icon.box-classic-icon::after {
    opacity: 0;
    visibility: hidden;
  }
  html:not(.tablet):not(.mobile) .icon.box-classic-icon::after {
    transition: 0.25s;
  }
}

/*
* Element groups
*/
html body .group {
  /* margin-bottom: -10px; */
  /* margin-left: -20px; */
}

html body .group:empty {
  margin-bottom: 0;
  margin-left: 0;
}

html body .group > * {
  display: inline-block;
  margin-top: 0;
  /* margin-bottom: 10px; */
  /* margin-left: 20px; */
}

html body .group-xs {
  margin-bottom: -10px;
  margin-left: -12px;
}

html body .group-xs:empty {
  margin-bottom: 0;
  margin-left: 0;
}

html body .group-xs > * {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 10px;
  margin-left: 12px;
}

html body .group-xl {
  margin-bottom: -20px;
  margin-left: -30px;
}

html body .group-xl:empty {
  margin-bottom: 0;
  margin-left: 0;
}

html body .group-xl > * {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 20px;
  margin-left: 30px;
}

@media (min-width: 992px) {
  html body .group-xl {
    margin-bottom: -20px;
    margin-left: -45px;
  }
  html body .group-xl > * {
    margin-bottom: 20px;
    margin-left: 45px;
  }
}

html body .group-buttons-responsive {
  margin-bottom: -10px;
  margin-left: -10px;
}

html body .group-buttons-responsive:empty {
  margin-bottom: 0;
  margin-left: 0;
}

html body .group-buttons-responsive > * {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 10px;
  margin-left: 10px;
}

@media (max-width: 575.98px) {
  html body .group-buttons-responsive .button {
    font-size: 13px;
    padding: 10px 20px;
  }
}

@media (min-width: 768px) {
  html body .group-buttons-responsive {
    margin-left: -20px;
  }
  html body .group-buttons-responsive > * {
    margin-left: 20px;
  }
}

.group-1 {
  margin-bottom: -15px;
  margin-left: -15px;
}

.group-1:empty {
  margin-bottom: 0;
  margin-left: 0;
}

.group-1 > * {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 15px;
  margin-left: 15px;
}

.group-1 .button {
  margin-top: 0;
  min-width: 200px;
}

.group-middle {
  display: inline-table;
}

.group-middle > * {
  vertical-align: middle;
}

.group-custom {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 100%;
  margin-bottom: -10px;
  margin-left: -15px;
}

.group-custom:empty {
  margin-bottom: 0;
  margin-left: 0;
}

.group-custom > * {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 10px;
  margin-left: 15px;
}

.group-custom > * {
  flex: 0 1 100%;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 576px) {
  .group-custom {
    flex-wrap: nowrap;
  }
  .group-custom > * {
    flex: 1 1 auto;
  }
}

* + .group {
  /* margin-top: 30px; */
}

* + .group-xs {
  margin-top: 15px;
}

* + .group-xl {
  margin-top: 30px;
}

* + .group-1 {
  margin-top: 30px;
}

* + .group-custom {
  margin-top: 25px;
}

* + .group-buttons-responsive {
  margin-top: 25px;
}

@media (min-width: 768px) {
  * + .group-buttons-responsive {
    margin-top: 33px;
  }
}

@media (min-width: 768px) {
  * + .group {
    /* margin-top: 33px; */
  }
  * + .group-xs {
    margin-top: 33px;
  }
}

/*
* Responsive units
*/
.unit {
  display: flex;
  flex: 0 1 100%;
  margin-bottom: -30px;
  margin-left: -20px;
}

.unit > * {
  margin-bottom: 30px;
  margin-left: 20px;
}

.unit:empty {
  margin-bottom: 0;
  margin-left: 0;
}

.unit-body {
  flex: 0 1 auto;
}

.unit-left,
.unit-right {
  flex: 0 0 auto;
  max-width: 100%;
}

.unit-spacing-xs {
  margin-bottom: -15px;
  margin-left: -7px;
}

.unit-spacing-xs > * {
  margin-bottom: 15px;
  margin-left: 7px;
}

@media (min-width: 1200px) {
  .unit-spacing-1 {
    margin-bottom: -15px;
    margin-left: -25px;
  }
  .unit-spacing-1 > * {
    margin-bottom: 15px;
    margin-left: 25px;
  }
}

.unit-spacing-2 {
  margin-bottom: -10px;
  margin-left: -20px;
}

.unit-spacing-2 > * {
  margin-bottom: 10px;
  margin-left: 20px;
}

@media (min-width: 992px) {
  .unit-spacing-2 {
    margin-bottom: -15px;
    margin-left: -40px;
  }
  .unit-spacing-2 > * {
    margin-bottom: 15px;
    margin-left: 40px;
  }
}

.unit-spacing-3 {
  margin-bottom: -10px;
  margin-left: -15px;
}

.unit-spacing-3 > * {
  margin-bottom: 10px;
  margin-left: 15px;
}

.unit .unit-left.icon-sm {
  position: relative;
  top: 5px;
}

/*
* Lists
*/
/*
* Vertical Lists
*/
.list > li + li {
  margin-top: 10px;
}

.list-xs > li + li {
  margin-top: 5px;
}

.list-sm > li + li {
  margin-top: 12px;
}

.list-md > li + li {
  margin-top: 18px;
}

.list-lg > li + li {
  margin-top: 25px;
}

.list-xl > li + li {
  margin-top: 30px;
}

@media (min-width: 768px) {
  .list-xl > li + li {
    margin-top: 60px;
  }
}

/*
* Inline Lists
*/
.list-inline > li {
  display: inline-block;
}

html .list-inline-xs {
  transform: translate3d(0, -5px, 0);
  margin-bottom: -5px;
  margin-left: -3px;
  margin-right: -3px;
}

html .list-inline-xs > * {
  margin-top: 5px;
  padding-left: 3px;
  padding-right: 3px;
}

html .list-inline-md {
  transform: translate3d(0, -8px, 0);
  margin-bottom: -8px;
  margin-left: -10px;
  margin-right: -10px;
}

html .list-inline-md > * {
  margin-top: 8px;
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 992px) {
  html .list-inline-md {
    margin-left: -15px;
    margin-right: -15px;
  }
  html .list-inline-md > * {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/*
* List terms
*/
.list-terms dt + dd {
  margin-top: 5px;
}

.list-terms dd + dt {
  margin-top: 25px;
}

* + .list-terms {
  margin-top: 25px;
}

/*
* Marked list
*/
.list-marked {
  font-family: "Samsung";
  font-weight: 300;
  text-align: left;
  color: #262f3c;
}

.list-marked > li {
  text-indent: -25px;
  padding-left: 25px;
}

.list-marked > li::before {
  content: "";
  position: relative;
  left: 0;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 17px;
  border-radius: 50%;
  background: #d7d7d7;
}

.list-marked > li + li {
  margin-top: 10px;
}

.list-marked_secondary {
  font-family: "Samsung";
  font-weight: 400;
  color: #2c343b;
}

.list-marked_secondary > li::before {
  width: 6px;
  height: 6px;
  background: #0accbe;
}

@media (min-width: 992px) {
  .list-marked_secondary {
    font-size: 16px;
  }
}

@media (min-width: 1200px) {
  .list-marked_secondary {
    font-size: 17px;
    line-height: 1.52941;
  }
}

.context-dark .list-marked_secondary,
.bg-gray-900 .list-marked_secondary,
.bg-accent .list-marked_secondary {
  color: #ffffff;
}

.context-dark .list-marked_secondary > li::before,
.bg-gray-900 .list-marked_secondary > li::before,
.bg-accent .list-marked_secondary > li::before {
  background: #ffea5c;
}

* + .list-marked {
  margin-top: 15px;
}

/*
* Ordered List
*/
.list-ordered {
  font-family: "Samsung";
  font-weight: 300;
  counter-reset: li;
  text-align: left;
  color: #262f3c;
}

.list-ordered > li {
  position: relative;
  padding-left: 25px;
}

.list-ordered > li:before {
  content: counter(li, decimal) ".";
  counter-increment: li;
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 15px;
  color: #8491a5;
}

.list-ordered > li + li {
  margin-top: 10px;
}

* + .list-ordered {
  margin-top: 15px;
}

.list-palette {
  margin-bottom: -8px;
  margin-left: -8px;
  font-size: 0;
  line-height: 0;
}

.list-palette:empty {
  margin-bottom: 0;
  margin-left: 0;
}

.list-palette > * {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 8px;
  margin-left: 8px;
}

.list-palette > li {
  width: 45px;
  height: 45px;
  background: #f9f8f8;
}

.list-palette > li:nth-child(1) {
  background: #0accbe;
}

.list-palette > li:nth-child(2) {
  background: #5f67df;
}

.list-palette > li:nth-child(3) {
  background: #262f3c;
}

.list-palette > li:nth-child(4) {
  background: #f9f8f8;
}

.list-palette > li:nth-child(5) {
  background: #777;
}

* + .list-palette {
  margin-top: 20px;
}

@media (min-width: 1200px) {
  .list-palette {
    max-width: 200px;
  }
  .list-palette > li {
    width: 53px;
    height: 53px;
  }
}

.list-style-1 {
  color: #262f3c;
  letter-spacing: 0;
}

.list-style-1 .unit {
  margin-bottom: -8px;
  margin-left: -20px;
}

.list-style-1 .unit > * {
  margin-bottom: 8px;
  margin-left: 20px;
}

.list-style-1 > li + li {
  margin-top: 14px;
}

* + .list-style-1 {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .list-style-1 > li + li {
    margin-top: 17px;
  }
}

@media (min-width: 992px) {
  .list-style-1 {
    font-size: 17px;
    line-height: 1.52941;
  }
}

.list-style-2 {
  counter-reset: li;
  font-family: "Samsung";
  line-height: 1.2;
  font-weight: 400;
  color: #262f3c;
}

.list-style-2 > li {
  position: relative;
  padding-left: 25px;
}

.list-style-2 > li::before {
  position: absolute;
  left: 0;
  content: counter(li) ".";
  counter-increment: li;
}

.list-style-2 > li + li {
  margin-top: 9px;
}

.list-style-2_2-cols {
  columns: 2;
  column-gap: 30px;
  break-inside: avoid;
}

* + .list-style-2 {
  margin-top: 15px;
}

@media (min-width: 768px) {
  .list-style-2 {
    font-size: 17px;
  }
  * + .list-style-2 {
    margin-top: 30px;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .list-style-2_2-cols-responsive {
    columns: 2;
    column-gap: 30px;
    break-inside: avoid;
  }
}

.list-objects-inline {
  margin-bottom: -4px;
  margin-left: -10px;
  letter-spacing: 0;
}

.list-objects-inline:empty {
  margin-bottom: 0;
  margin-left: 0;
}

.list-objects-inline > * {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 4px;
  margin-left: 10px;
}

.list-objects-inline > li {
  vertical-align: middle;
}

.list-objects-inline > li > * {
  display: inline-block;
  vertical-align: middle;
}

.list-objects-inline > li > * + * {
  margin-left: 5px;
}

.list-objects-inline .icon {
  font-size: 15px;
}

@media (min-width: 576px) {
  .list-objects-inline .list-objects-inline {
    margin-left: -22px;
  }
  .list-objects-inline .list-objects-inline > * {
    margin-left: 22px;
  }
  .list-objects-inline .icon {
    font-size: 19px;
  }
}

.list-blocks-outer {
  position: relative;
  overflow: hidden;
}

.list-blocks {
  counter-reset: list-blocks-item;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: -1px;
  text-align: center;
}

.list-blocks-item {
  width: 100%;
  padding: 35px 20px;
  margin: -1px 0 0 0;
  border-bottom: 1px solid #ebebeb;
}

.list-blocks-item-inner {
  max-width: 225px;
  margin-left: auto;
  margin-right: auto;
}

.list-blocks-counter {
  font-family: "Samsung";
  font-size: 20px;
  line-height: 1;
  font-weight: 300;
}

.list-blocks-counter::before {
  content: counter(list-blocks-item, decimal-leading-zero);
  counter-increment: list-blocks-item;
}

.list-blocks-title {
  font-family: "Samsung";
  font-size: 18px;
  line-height: 1.35;
  color: #262f3c;
}

* + .list-blocks-title {
  margin-top: 10px;
}

@media (min-width: 576px) {
  .list-blocks {
    justify-content: flex-end;
    text-align: left;
  }
  .list-blocks-item {
    position: relative;
    width: calc(50% + 1px);
    padding: 45px 25px;
    margin: -1px 0 0 -1px;
    border: 1px solid #ebebeb;
  }
  .list-blocks-counter {
    font-size: 24px;
  }
  .list-blocks-title {
    font-size: 22px;
  }
  * + .list-blocks-title {
    margin-top: 15px;
  }
}

@media (max-width: 991.98px) {
  .list-blocks-item_caption {
    order: -1;
  }
}

@media (min-width: 992px) {
  .list-blocks-item {
    width: calc(33.3333% + 1px);
  }
}

@media (min-width: 1200px) {
  .list-blocks-item {
    padding: 55px 20px;
  }
  .list-blocks-item * + p {
    margin-top: 25px;
  }
  .list-blocks-counter {
    font-size: 30px;
  }
  .list-blocks-title {
    font-size: 26px;
  }
  * + p.list-blocks-title {
    margin-top: 27px;
  }
}

.list-localization .label-text {
  padding: 3px 5px;
  font-size: 12px;
  line-height: 1.2;
  font-weight: 700;
  background-color: transparent;
  color: #ffffff;
  transition: 0.22s ease-in-out;
  cursor: pointer;
}

.list-localization .label-text:hover {
  color: #0accbe;
}

.list-localization input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  -webkit-appearance: none;
}

.list-localization input:checked ~ .label-text {
  color: #ffffff;
  background: #0accbe;
}

.list-nav-classic {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-family: "Samsung";
  line-height: 1.2;
  color: #262f3c;
  letter-spacing: 0;
}

.list-nav-classic li {
  display: flex;
  padding-right: 25px;
}

.list-nav-classic a {
  position: relative;
  display: flex;
  align-items: center;
  padding: 22px 0 24px;
}

.list-nav-classic a::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  bottom: 0;
  background: #0accbe;
  pointer-events: none;
  width: 0;
  height: 2px;
  max-width: 58px;
  transition: 0.55s;
}

.list-nav-classic a.active,
.list-nav-classic a:hover {
  color: #0accbe;
}

.list-nav-classic a.active::after {
  width: 80%;
}

@media (min-width: 1200px) {
  .list-nav-classic {
    font-size: 22px;
  }
  .list-nav-classic a {
    padding: 20px 0;
  }
  .list-nav-classic a::after {
    height: 4px;
  }
}

/*
* Images
*/
img {
  display: inline-block;
  /* max-width: 100%; */
  height: auto;
}

.img-responsive {
  width: 100%;
}

/*
* Icons
*/
.icon {
  display: inline-block;
  font-size: 16px;
  line-height: 1;
}

.icon::before {
  position: relative;
  display: inline-block;
  font-weight: 400;
  font-style: normal;
  speak: none;
  text-transform: none;
}

.icon-circle {
  border-radius: 50%;
}

.icon-sm {
  font-size: 18px;
}

.icon-md {
  font-size: 20px;
}

.icon-lg {
  font-size: 30px;
}

.icon-xl {
  font-size: 40px;
}

.icon-style-1 {
  display: inline-block;
  width: 2em;
  height: 2em;
  font-size: 28px;
  text-align: center;
  line-height: 2em;
  border-radius: 50%;
  background: #ffffff;
  color: #0accbe;
  box-shadow: -7.25px 3.381px 18px 0px rgba(75, 81, 91, 0.08);
}
.section-md .container:hover .icon-style-1 {
  background: #0accbe;
  /*border: 2px solid #f03c02;*/
  color: #fff;
}

@media (min-width: 992px) {
  .icon-style-1 {
    font-size: 34px;
  }
}

/*
* Tables custom
*/
.table-custom {
  width: 100%;
  max-width: 100%;
  text-align: left;
  background: #ffffff;
  border-collapse: collapse;
  letter-spacing: 0.015em;
}

.table-custom th,
.table-custom td {
  color: #8491a5;
  background: #ffffff;
}

.table-custom th {
  padding: 37px 24px;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.35;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  background: #f9f8f8;
  border-bottom: 1px solid #ebebeb;
  white-space: nowrap;
}

@media (max-width: 991.98px) {
  .table-custom th {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.table-custom td {
  padding: 15px 24px;
}

.table-custom tbody tr:first-child td {
  border-top: 0;
}

.table-custom tr td {
  border-bottom: 1px solid #ebebeb;
}

.table-custom tfoot td {
  font-weight: 600;
  color: #262f3c;
}

.table-rounded {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  border-radius: 10px;
  border: 1px solid #ebebeb;
}

.table-rounded .table-custom {
  max-width: unset;
  margin-bottom: -1px;
}

* + .table-custom-responsive {
  margin-top: 30px;
}

@media (min-width: 768px) {
  * + .table-custom-responsive {
    margin-top: 40px;
  }
}

.table-custom.table-custom-primary thead th {
  color: #ffffff;
  background: #0accbe;
  border: 0;
}

.table-custom.table-custom-primary tbody tr:hover td {
  background: #f9f8f8;
}

.table-custom.table-custom-secondary thead th {
  color: #ffffff;
  background: #5f67df;
  border: 0;
}

.table-custom.table-custom-secondary tbody tr:hover td {
  background: #f9f8f8;
}

.table-custom.table-custom-bordered tr td:first-child {
  border-left: 0;
}

.table-custom.table-custom-bordered tr td:last-child {
  border-right: 0;
}

.table-custom.table-custom-bordered td {
  border: 1px solid #ebebeb;
}

.table-custom.table-custom-bordered tbody > tr:first-of-type > td {
  border-top: 0;
}

.table-custom.table-custom-striped {
  border-bottom: 1px solid #ebebeb;
}

.table-custom.table-custom-striped tbody tr:nth-of-type(odd) td {
  background: transparent;
}

.table-custom.table-custom-striped tbody tr:nth-of-type(even) td {
  background: #f9f8f8;
}

.table-custom.table-custom-striped tbody td {
  border: 0;
}

.table-custom.table-custom-striped tfoot td:not(:first-child) {
  border-left: 0;
}

/*
* Dividers
*/

hr {
  margin-top: 0;
  margin-bottom: 0;
  border-top: 1px solid #ebebeb;
}

.divider {
  font-size: 0;
  line-height: 0;
}

.divider::after {
  content: "";
  display: inline-block;
  width: 60px;
  height: 2px;
  background-color: #0accbe;
}

/*
* Buttons
*/
.button {
  position: relative;
  overflow: hidden;
  display: inline-block;
  padding: 16px 35px;
  font-size: 14.5px;
  line-height: 1.4;
  border: 2px solid;
  border-radius: 35px;
  font-family: "Samsung";
  font-weight: 300;
  letter-spacing: 0.01em;
  text-transform: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  user-select: none;
  transition: 250ms all ease-in-out;
}

@media (max-width: 575.98px) {
  .button {
    padding: 10px 15px;
  }
}

.button-block {
  display: block;
  width: 100%;
}

.button-default,
.button-default:focus {
  color: #8491a5;
  background-color: #2c343b;
  border-color: #2c343b;
}

.button-default:hover,
.button-default:active,
.button-default:focus {
  color: #ffffff;
  background-color: #0accbe;
  border-color: #0accbe;
}

.button-default.button-ujarak::before {
  background: #0accbe;
}

.button-primary-title,
.button-primary-title:focus {
  color: #262f3c;
  background-color: #ffffff;
  border-color: #ffffff;
}

.button-primary-title:hover,
.button-primary-title:active,
.button-primary-title:focus {
  color: #ffffff;
  background-color: #5f67df;
  border-color: #5f67df;
}

.button-primary-title.button-ujarak::before {
  background: #5f67df;
}

.button-primary,
.button-primary:focus {
  color: #ffffff;
  background-color: #0accbe;
  border-color: #0accbe;
}

.button-primary:hover,
.button-primary:active,
.button-primary:focus {
  color: #ffffff;
  background-color: #5f67df;
  border-color: #5f67df;
}

.button-primary.button-ujarak::before {
  background: #5f67df;
}

.button-gray-700,
.button-gray-700:focus {
  color: #ffffff;
  background-color: #3f4856;
  border-color: #3f4856;
}

.button-gray-700:hover,
.button-gray-700:active,
.button-gray-700:focus {
  color: #ffffff;
  background-color: #0accbe;
  border-color: #0accbe;
}

.button-gray-700.button-ujarak::before {
  background: #0accbe;
}

.button-secondary,
.button-secondary:focus {
  color: #ffffff;
  background-color: #0accbe;
  border-color: #0accbe;
}

.button-secondary:hover,
.button-secondary:active,
.button-secondary:focus {
  color: #262f3c;
  background-color: #ffffff;
  border-color: #262f3c;
}

.button-secondary.button-ujarak::before {
  background: #ffffff;
}

.button-color-8,
.button-color-8:focus {
  color: #ffffff;
  background-color: #ffffff;
  border-color: #ffffff;
}

.button-color-8:hover,
.button-color-8:active,
.button-color-8:focus {
  color: #ffffff;
  background-color: #0accbe;
  border-color: #0accbe;
}

.button-color-8.button-ujarak::before {
  background: #0accbe;
}

.button-primary-outline,
.button-primary-outline:focus {
  color: #0accbe;
  background-color: transparent;
  border-color: #0accbe;
}

.button-primary-outline:hover,
.button-primary-outline:active,
.button-primary-outline:focus {
  color: #ffffff;
  background-color: #0accbe;
  border-color: #0accbe;
}

.button-primary-outline.button-ujarak::before {
  background: #0accbe;
}

.button-default-outline,
.button-default-outline:focus {
  color: #e3ecff;
  background-color: transparent;
  border-color: #ebebeb;
}

.button-default-outline:hover,
.button-default-outline:active,
.button-default-outline:focus {
  color: #ffffff;
  background-color: #4080f0;
  border-color: #4080f0;
}

.button-default-outline.button-ujarak::before {
  background: #5f67df;
}

.button-ghost {
  border: 0;
  background-color: transparent;
}

.button-ghost:hover {
  color: #ffffff;
  background: #0accbe;
}

.button-facebook,
.button-facebook:focus {
  color: #ffffff;
  background-color: #4970bf;
  border-color: #4970bf;
}

.button-facebook:hover,
.button-facebook:active,
.button-facebook:focus {
  color: #ffffff;
  background-color: #3e64b0;
  border-color: #3e64b0;
}

.button-facebook.button-ujarak::before {
  background: #3e64b0;
}

.button-twitter,
.button-twitter:focus {
  color: #ffffff;
  background-color: #63c9eb;
  border-color: #63c9eb;
}

.button-twitter:hover,
.button-twitter:active,
.button-twitter:focus {
  color: #ffffff;
  background-color: #4cc1e8;
  border-color: #4cc1e8;
}

.button-twitter.button-ujarak::before {
  background: #4cc1e8;
}

.button-google,
.button-google:focus {
  color: #ffffff;
  background-color: #e2411e;
  border-color: #e2411e;
}

.button-google:hover,
.button-google:active,
.button-google:focus {
  color: #ffffff;
  background-color: #cc3a1a;
  border-color: #cc3a1a;
}

.button-google.button-ujarak::before {
  background: #cc3a1a;
}

.button-shadow {
  box-shadow: 1px 6px 8px 0 rgba(75, 81, 91, 0.11);
}

.button-shadow:focus,
.button-shadow:active {
  box-shadow: none;
}

.button-ujarak {
  position: relative;
  z-index: 0;
  transition: background 0.4s, border-color 0.4s, color 0.4s;
}

.button-ujarak::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0accbe;
  z-index: -1;
  opacity: 0;
  transform: scale3d(0.7, 1, 1);
  transition: transform 0.42s, opacity 0.42s;
  border-radius: inherit;
}

.button-ujarak,
.button-ujarak::before {
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.button-ujarak:hover {
  transition: background 0.4s 0.4s, border-color 0.4s 0s, color 0.2s 0s;
}

.button-ujarak:hover::before {
  opacity: 1;
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
}

/**
* Button effects
*/
.button-winona {
  position: relative;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  vertical-align: middle;
  text-align: center;
  transform: translate3d(0, 0, 0);
  white-space: nowrap;
}

.button-winona .content-original,
.button-winona .content-dubbed {
  vertical-align: middle;
  transition: transform 0.4s, clip-path 0.4s;
}

.button-winona .content-original {
  display: block;
  clip-path: polygon(0 100%, -35% 0, 0 0, 0 100%);
}

.button-winona .content-dubbed {
  position: absolute;
  top: 0;
  margin: auto;
  filter: blur(0px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 100%;
  padding: inherit;
  clip-path: polygon(100% 100%, 100% 0, -35% 0, 0 100%);
  white-space: nowrap;
}

.button-winona:hover .content-original {
  animation: showum 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.2s;
}

.button-winona:hover .content-dubbed {
  animation: hideum 0.3s;
  animation-fill-mode: forwards;
}

html body .button-winona {
  display: inline-flex;
}

@keyframes showum {
  0% {
    clip-path: polygon(0 100%, -35% 0, 0 0, 0 100%);
  }
  100% {
    clip-path: polygon(135% 100%, 100% 0, 0 0, 0 100%);
  }
}

@keyframes hideum {
  0% {
    clip-path: polygon(100% 100%, 100% 0, -35% 0, 0 100%);
  }
  100% {
    clip-path: polygon(100% 100%, 100% 0, 100% 0, 135% 100%);
  }
}

.button-sm {
  letter-spacing: 0.025em;
  padding: 11px 25px;
  font-size: 14px;
  line-height: 1.5;
}

.button-lg {
  padding: 18px 45px;
  font-size: 16px;
  line-height: 1.5;
}

.button-circle {
  border-radius: 30px;
}

.button-round {
  border-radius: 5px;
}

.button-square {
  border-radius: 0;
}

html .button.button-icon,
html .button.button-icon > [class*="content"] {
  display: inline-flex;
}

.button.button-icon,
.button.button-icon > [class*="content"] {
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.button.button-icon .icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: inherit;
  font-size: 1.26667 em;
  line-height: 1em;
}

.button.button-icon-left .icon {
  padding-right: 11px;
}

.button.button-icon-right,
.button.button-icon-right > [class*="content"] {
  flex-direction: row-reverse;
}

.button.button-icon-right .icon {
  padding-left: 11px;
}

.btn-primary {
  border-radius: 3px;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transition: 0.33s;
}
.btn-primary-1 {
  border-radius: 3px;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transition: 0.33s;
}
.btn-primary-1,
.btn-primary-1:active,
.btn-primary-1:focus {
  color: #ffffff;
  background: #0accbe;
  border-color: #0accbe;
}
@media (min-width: 992px) {
  .button-load {
    font-size: 60px;
  }
}
.btn-primary,
.btn-primary:active,
.btn-primary:focus {
  color: #ffffff;
  background: #0accbe;
  border-color: #0accbe;
}

.btn-primary:hover {
  color: #ffffff;
  background: #000000;
  border-color: #000000;
}

/**
* Other
*/
.button-load-icon {
  position: relative;
  z-index: 2;
  background: none;
  border: none;
  display: inline-block;
  padding: 0;
  outline: none;
  outline-offset: 0;
  cursor: pointer;
  -webkit-appearance: none;
  color: rgba(132, 145, 165, 0.5);
  font-size: 36px;
  line-height: 1;
  transition: 0.25s;
  cursor: pointer;
}

.button-load-icon::-moz-focus-inner {
  border: none;
  padding: 0;
}

.button-load-icon:hover {
  color: #0accbe;
}

.button-load-icon.button-load-in-process {
  transition: border-color 0.3s, background 0.3s;
  animation: 0.33s linear infinite-rotate infinite;
}

.button-load-icon.button-load-loaded {
  display: none;
}

html .page .button.button-load::before {
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  font-size: 1.5em;
  opacity: 0;
  visibility: hidden;
  transform-origin: 50% 50%;
  transform-style: preserve-3d;
}

html .page .button.button-load.button-load-in-process {
  color: transparent;
}

html .page .button.button-load.button-load-in-process::before {
  opacity: 1;
  visibility: visible;
  animation: 0.33s linear infinite-rotate-pseudo infinite;
}

html .page .button.button-load.button-load-loaded {
  display: none;
}

@-webkit-keyframes infinite-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes infinite-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes infinite-rotate-pseudo {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

@keyframes infinite-rotate-pseudo {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

@media (min-width: 992px) {
  .button-load {
    font-size: 60px;
  }
}

html .page [data-load-trigger] {
  display: none;
}

.button-play {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 86px;
  height: 86px;
  border-radius: 6px;
  border: 1px solid #ebebeb;
}

.button-play,
.button-play:active,
.button-play:focus,
.button-play:hover {
  color: #0accbe;
}

.button-play::before {
  font-size: 20px;
}

.button-play:hover .button-play-shape {
  stroke-dasharray: 600;
  stroke-dashoffset: 0;
}

.button-play-sm {
  width: 60px;
  height: 60px;
}

.button-play-shape {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  fill: none;
  stroke: #0accbe;
  stroke-width: 1px;
  stroke-dasharray: 340;
  stroke-dashoffset: 340;
  transition: 1.3s;
}

* + .button-play {
  margin-top: 30px;
}

.context-dark .button-play,
.bg-gray-900 .button-play,
.bg-accent .button-play {
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.context-dark .button-play,
.bg-gray-900 .button-play,
.bg-accent .button-play,
.context-dark .button-play:active,
.bg-gray-900 .button-play:active,
.bg-accent .button-play:active,
.context-dark .button-play:focus,
.bg-gray-900 .button-play:focus,
.bg-accent .button-play:focus,
.context-dark .button-play:hover,
.bg-gray-900 .button-play:hover,
.bg-accent .button-play:hover {
  color: #ffffff;
}

.context-dark .button-play-shape,
.bg-gray-900 .button-play-shape,
.bg-accent .button-play-shape {
  stroke: #ffffff;
}

@media (min-width: 1200px) {
  * + .button-play {
    margin-top: 50px;
  }
}

/*
* Form styles
*/
.rd-form {
  position: relative;
  text-align: left;
}

* + .rd-form {
  margin-top: 20px;
}

input:-webkit-autofill ~ label,
input:-webkit-autofill ~ .form-validation {
  color: #000000 !important;
}

.form-wrap {
  position: relative;
}

.form-wrap .link-underline {
  margin-left: 10px;
}

.form-wrap.has-error .form-input {
  border-color: #ec4242;
}

.form-wrap.has-focus .form-input {
  border-color: #0accbe;
}

.form-wrap + * {
  margin-top: 20px;
}

.form-input {
  display: block;
  width: 100%;
  min-height: 54px;
  padding: 14px 26px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #8491a5;
  background-color: #ffffff;
  background-image: none;
  border-radius: 35px;
  -webkit-appearance: none;
  transition: 0.3s ease-in-out;
  border: 1px solid #ebebeb;
}

.form-input:focus {
  outline: 0;
}

textarea.form-input {
  height: 185px;
  min-height: 54px;
  max-height: 315px;
  resize: vertical;
}

.form-label,
.form-label-outside {
  margin-bottom: 0;
  color: #8491a5;
  font-weight: 400;
}

.form-label {
  position: absolute;
  top: 27px;
  left: 0;
  right: 0;
  padding-left: 26px;
  padding-right: 26px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  pointer-events: none;
  text-align: left;
  z-index: 9;
  transition: 0.25s;
  will-change: transform;
  transform: translateY(-50%);
}

.form-label.focus {
  opacity: 0;
}

.form-label.auto-fill {
  color: #8491a5;
}

.form-label-outside {
  width: 100%;
  margin-bottom: 4px;
  pointer-events: none;
}

@media (min-width: 768px) {
  .form-label-outside {
    position: static;
  }
  .form-label-outside,
  .form-label-outside.focus,
  .form-label-outside.auto-fill {
    transform: none;
  }
}

[data-x-mode="true"] .form-label {
  pointer-events: auto;
}

.form-validation {
  position: absolute;
  right: 25px;
  top: 0;
  z-index: 11;
  margin-top: 2px;
  font-size: 9px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0;
  color: #ec4242;
  transition: 0.3s;
}

.form-validation-left .form-validation {
  top: 100%;
  right: auto;
  left: 0;
}

#form-output-global {
  position: fixed;
  bottom: 30px;
  left: 15px;
  z-index: 2000;
  visibility: hidden;
  transform: translate3d(-500px, 0, 0);
  transition: 0.3s all ease;
}

#form-output-global.active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

@media (min-width: 576px) {
  #form-output-global {
    left: 30px;
  }
}

.form-output {
  position: absolute;
  top: 100%;
  left: 0;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.2;
  margin-top: 2px;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
}

.form-output.active {
  opacity: 1;
  visibility: visible;
}

.form-output.error {
  color: #ec4242;
}

.form-output.success {
  color: #77d216;
}

.radio .radio-custom,
.radio-inline .radio-custom,
.checkbox .checkbox-custom,
.checkbox-inline .checkbox-custom {
  opacity: 0;
}

.radio .radio-custom,
.radio .radio-custom-dummy,
.radio-inline .radio-custom,
.radio-inline .radio-custom-dummy,
.checkbox .checkbox-custom,
.checkbox .checkbox-custom-dummy,
.checkbox-inline .checkbox-custom,
.checkbox-inline .checkbox-custom-dummy {
  position: absolute;
  left: 0;
  width: 14px;
  height: 14px;
  outline: none;
  cursor: pointer;
}

.radio .radio-custom-dummy,
.radio-inline .radio-custom-dummy,
.checkbox .checkbox-custom-dummy,
.checkbox-inline .checkbox-custom-dummy {
  pointer-events: none;
  background: #ffffff;
}

.radio .radio-custom-dummy::after,
.radio-inline .radio-custom-dummy::after,
.checkbox .checkbox-custom-dummy::after,
.checkbox-inline .checkbox-custom-dummy::after {
  position: absolute;
  opacity: 0;
  transition: 0.22s;
}

.radio .radio-custom:focus,
.radio-inline .radio-custom:focus,
.checkbox .checkbox-custom:focus,
.checkbox-inline .checkbox-custom:focus {
  outline: none;
}

.radio input,
.radio-inline input,
.checkbox input,
.checkbox-inline input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.radio-custom:checked + .radio-custom-dummy:after,
.checkbox-custom:checked + .checkbox-custom-dummy:after {
  opacity: 1;
}

.radio,
.radio-inline {
  padding-left: 28px;
}

.radio .radio-custom-dummy,
.radio-inline .radio-custom-dummy {
  top: 1px;
  left: 0;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #8491a5;
}

.radio .radio-custom-dummy::after,
.radio-inline .radio-custom-dummy::after {
  content: "";
  top: 3px;
  right: 3px;
  bottom: 3px;
  left: 3px;
  background: #2c343b;
  border-radius: inherit;
}

.checkbox,
.checkbox-inline {
  padding-left: 28px;
}

.checkbox .checkbox-custom-dummy,
.checkbox-inline .checkbox-custom-dummy {
  left: 0;
  width: 18px;
  height: 18px;
  margin: 0;
  border: 1px solid #8491a5;
}

.checkbox .checkbox-custom-dummy::after,
.checkbox-inline .checkbox-custom-dummy::after {
  content: "\f222";
  font-family: "Material Design Icons";
  position: absolute;
  top: -1px;
  left: -2px;
  font-size: 20px;
  line-height: 18px;
  color: #d7d7d7;
}

.toggle-custom {
  padding-left: 60px;
  -webkit-appearance: none;
}

.toggle-custom:checked ~ .checkbox-custom-dummy::after {
  background: #0accbe;
  transform: translate(20px, -50%);
}

.toggle-custom ~ .checkbox-custom-dummy {
  position: relative;
  display: inline-block;
  margin-top: -1px;
  width: 44px;
  height: 20px;
  background: #ffffff;
  cursor: pointer;
}

.toggle-custom ~ .checkbox-custom-dummy::after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 16px;
  height: 16px;
  left: 0;
  top: 50%;
  background: #8491a5;
  transform: translate(4px, -50%);
  opacity: 1;
  transition: 0.22s;
}

.form-sm .form-input,
.form-sm .button {
  padding-top: 9px;
  padding-bottom: 9px;
  min-height: 40px;
}

.form-sm .form-validation {
  top: -16px;
}

.form-sm .form-label {
  top: 20px;
}

.form-sm * + .button {
  margin-top: 10px;
}

@media (min-width: 992px) {
  .form-lg .form-input,
  .form-lg .form-label,
  .form-lg
    .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    font-size: 16px;
  }
  .form-lg .form-input,
  .form-lg
    .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    padding: 23px 40px;
  }
  .form-lg
    .select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
    right: 35px;
  }
  .form-lg .form-input,
  .form-lg .form-button,
  .form-lg .button,
  .form-lg
    .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    min-height: 70px;
  }
  .form-lg .form-label {
    top: 35px;
  }
  .form-lg .button {
    padding: 18px 45px;
    font-size: 16px;
    line-height: 1.5;
  }
}

.form-inline {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  text-align: center;
  margin-bottom: -8px;
  margin-left: -20px;
}

.form-inline:empty {
  margin-bottom: 0;
  margin-left: 0;
}

.form-inline > * {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 8px;
  margin-left: 20px;
}

.form-inline > * {
  margin-top: 0;
}

.form-inline .form-wrap {
  flex: 30 0;
  min-width: 220px;
}

.form-inline .form-label {
  display: block;
}

.form-inline .form-button {
  flex-grow: 1;
  min-height: 54px;
}

.form-inline .form-button .button {
  width: 100%;
  min-height: inherit;
}

.form-shadow .form-input,
.form-shadow .button {
  box-shadow: 2.424px 4.373px 10px 0px rgba(100, 129, 173, 0.25);
}

.form-shadow .form-input {
  border-color: #ffffff;
}

.form-inline-1 {
  position: relative;
  padding-bottom: 13px;
  transition: 0.3s all ease-in-out;
}

.form-inline-1 .form-input,
.form-inline-1 .form-label {
  padding-right: 70px;
}

.form-inline-1 .form-input {
  border: 0;
  box-shadow: 0 10px 18px 0 rgba(0, 0, 0, 0.1);
}

.form-inline-1 .form-validation {
  right: 70px;
}

.form-inline-1 .form-button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 54px;
  line-height: 54px;
  border-radius: 50%;
  padding: 5px;
  margin: 0;
}

.form-inline-1 .form-button .icon {
  position: absolute;
  font-size: 27px;
  transition: 0.3s all ease-in-out;
}

.ie-10 .form-inline-1 .form-button .icon,
.ie-11 .form-inline-1 .form-button .icon {
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.form-inline-1 .form-button-icon-default::before {
  transform: rotate(0deg) scale(1);
  opacity: 1;
  visibility: visible;
}

.form-inline-1 .form-button-icon-error::before,
.form-inline-1 .form-button-icon-success::before {
  transform: rotate(-45deg) scale(0.23);
  opacity: 0;
  visibility: hidden;
}

.form-inline-1 .form-output {
  position: absolute;
  bottom: 1px;
  left: 0;
  height: 16px;
  font-size: 11px;
  padding-left: 26px;
  -webkit-clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
  clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
  transition: 0.3s all ease-in-out;
}

.form-inline-1 .form-output.active {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.form-inline-1 .form-output.success {
  color: #77d216;
}

.form-inline-1 .form-output.error {
  color: #ec4242;
}

.form-inline-1.form-in-process {
  background: rebeccapurple !important;
}

.form-inline-1.success .form-button-icon-default::before,
.form-inline-1.error .form-button-icon-default::before {
  transform: rotate(-45deg) scale(0.23);
  opacity: 0;
  visibility: hidden;
}

.form-inline-1.success .form-button {
  background: #77d216;
  border-color: #77d216;
}

.form-inline-1.success .form-button-icon-success::before {
  transform: rotate(0deg) scale(1);
  opacity: 1;
  visibility: visible;
}

.form-inline-1.error .form-button {
  background: #ec4242;
  border-color: #ec4242;
}

.form-inline-1.error .form-button-icon-error::before {
  transform: rotate(0deg) scale(1);
  opacity: 1;
  visibility: visible;
}

@media (min-width: 992px) {
  .form-inline-1.form-lg .form-button {
    min-width: 70px;
  }
  .form-inline-1.form-lg .form-label,
  .form-inline-1.form-lg .form-input {
    padding-right: 80px;
  }
}

* + .form-lg.form-inline-1 {
  margin-top: 30px;
}

@media (min-width: 1200px) {
  * + .form-lg.form-inline-1 {
    margin-top: 45px;
  }
}

.toggle-modern {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -15px;
}

.toggle-modern > * {
  margin-left: 15px;
}

.toggle-modern.active .toggle-modern-text_default {
  color: #262f3c;
}

.toggle-modern.active .toggle-modern-text_alternate {
  color: #0accbe;
}

.toggle-modern-text {
  font-weight: 400;
  letter-spacing: 0.025em;
  color: #262f3c;
  pointer-events: none;
  transition: 0.22s ease-in-out;
}

.toggle-modern-text_default {
  color: #0accbe;
}

.toggle-modern-switch {
  position: relative;
  height: 32px;
  width: 80px;
  border: 0;
  border-radius: 30px;
  background: #0accbe;
  cursor: pointer;
}

.toggle-modern-switch.active .toggle-modern-switch-element {
  left: 52px;
}

.toggle-modern-switch-element {
  position: absolute;
  left: 6px;
  top: 5px;
  z-index: 1;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 1px 5.955px 8px 0 rgba(75, 81, 91, 0.11);
  transition: 0.22s ease-in-out;
}

.context-dark .toggle-modern-switch,
.bg-gray-900 .toggle-modern-switch,
.bg-accent .toggle-modern-switch {
  background: rgba(255, 255, 255, 0.2);
}

.context-dark .toggle-modern-text_default,
.bg-gray-900 .toggle-modern-text_default,
.bg-accent .toggle-modern-text_default {
  color: #ffffff;
}

.context-dark .toggle-modern-text_alternate,
.bg-gray-900 .toggle-modern-text_alternate,
.bg-accent .toggle-modern-text_alternate {
  color: rgba(255, 255, 255, 0.7);
}

.context-dark .toggle-modern.active .toggle-modern-text_default,
.bg-gray-900 .toggle-modern.active .toggle-modern-text_default,
.bg-accent .toggle-modern.active .toggle-modern-text_default {
  color: rgba(255, 255, 255, 0.7);
}

.context-dark .toggle-modern.active .toggle-modern-text_alternate,
.bg-gray-900 .toggle-modern.active .toggle-modern-text_alternate,
.bg-accent .toggle-modern.active .toggle-modern-text_alternate {
  color: #ffffff;
}

* + .toggle-modern {
  margin-top: 20px;
}

@media (min-width: 576px) {
  .toggle-modern-switch {
    height: 36px;
    width: 85px;
  }
  .toggle-modern-switch.active .toggle-modern-switch-element {
    left: 54px;
  }
  .toggle-modern-switch-element {
    width: 26px;
    height: 26px;
  }
}

@media (min-width: 768px) {
  .toggle-modern-text {
    font-size: 17px;
    line-height: 1.52941;
  }
  * + .toggle-modern {
    margin-top: 35px;
  }
}

/*
* Posts
*/
.post-vacancy {
  position: relative;
  display: block;
  width: 100%;
  padding: 35px 25px 35px;
  border-radius: 6px;
  background: #ffffff;
  box-shadow: 1px 2px 5px 0 rgba(68, 73, 83, 0.12);
  backface-visibility: hidden;
}

.post-vacancy,
.post-vacancy:active,
.post-vacancy:focus,
.post-vacancy:hover {
  color: #8491a5;
}

.post-vacancy:hover {
  box-shadow: 1px 2px 15px 0px rgba(68, 73, 83, 0.25);
}

.post-vacancy-title {
  font-family: "Samsung";
  font-weight: 400;
  font-size: 18px;
  line-height: 1.3;
  letter-spacing: 0;
  color: #262f3c;
}

* + .post-vacancy-meta {
  margin-top: 14px;
}

@media (min-width: 768px) {
  .post-vacancy {
    padding: 35px 9% 45px;
  }
  .post-vacancy-title {
    font-size: 20px;
  }
}

@media (min-width: 1200px) {
  .post-vacancy-title {
    font-size: 26px;
  }
  * + .post-vacancy-meta {
    margin-top: 5px;
  }
}

.post-info {
  padding: 25px 15px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0 0 24px 0 rgba(75, 81, 91, 0.12);
  text-align: left;
}

.post-info-header {
  display: flex;
  align-items: flex-start;
}

.post-info-header > * + * {
  margin-left: 10px;
}

.post-info-icon {
  font-size: 20px;
  line-height: 1.4;
  color: #0accbe;
}

* + .post-info-title {
  margin-top: 0;
}

* + .post-info-main {
  margin-top: 14px;
}

@media (max-width: 767.98px) {
  .post-info {
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .post-info {
    padding: 40px 30px;
  }
}

@media (min-width: 768px) {
  .post-info {
    padding: 30px 20px;
  }
}

@media (min-width: 1200px) {
  .post-info {
    padding: 45px 15px 25px 40px;
  }
  .post-info-header {
    max-width: 97%;
  }
  * + .post-info-main {
    margin-top: 20px;
  }
}

.post-lina {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.post-lina-icon {
  font-size: 32px;
  line-height: 1;
  color: #0accbe;
}

.post-lina-title {
  font-family: "Samsung";
  font-weight: 400;
  color: #262f3c;
  font-size: 18px;
  line-height: 1.3;
  letter-spacing: 0.025em;
}

* + .post-lina-title {
  margin-top: 10px;
}

* + .post-lina-text {
  margin-top: 12px;
}

@media (min-width: 768px) {
  .post-lina-icon {
    font-size: 60px;
  }
  .post-lina-title {
    font-size: 22px;
  }
  .post-lina-text {
    font-size: 17px;
    line-height: 1.52941;
  }
  * + .post-lina-title {
    margin-top: 16px;
  }
}

@media (min-width: 992px) {
  .post-lina-icon {
    font-size: 68px;
  }
  .post-lina-title {
    font-size: 26px;
  }
  * + .post-lina-title {
    margin-top: 24px;
  }
  * + .post-lina-text {
    margin-top: 20px;
  }
}

/*
* Quotes
*/
.quote-light {
  position: relative;
  display: flex;
  padding: 20px 0;
  text-align: left;
}

.quote-light::before,
.quote-light::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  background: radial-gradient(circle, #d6dfeb 0%, rgba(214, 223, 235, 0) 100%);
}

.quote-light::before {
  top: 0;
}

.quote-light::after {
  bottom: 0;
}

.quote-light > * + * {
  margin-left: 12px;
}

.quote-light.quote-light_borderless {
  padding-top: 0;
  padding-bottom: 0;
}

.quote-light.quote-light_borderless::before,
.quote-light.quote-light_borderless::after {
  display: none;
}

.quote-light_1 {
  max-width: 640px;
}

@media (min-width: 992px) {
  .quote-light_1 {
    margin-right: 35px;
  }
}

.quote-light-mark {
  flex-shrink: 0;
  font-size: 30px;
  line-height: 1;
  color: #0accbe;
}

.quote-light-text {
  flex-grow: 1;
  font-size: 15px;
  line-height: 1.45;
  letter-spacing: 0.025em;
}

* + .quote-light {
  margin-top: 30px;
}

@media (min-width: 576px) {
  .quote-light-text {
    font-size: 18px;
    font-weight: 300;
  }
}

@media (min-width: 768px) {
  .quote-light {
    padding: 25px 0;
  }
  .quote-light > * + * {
    margin-left: 15px;
  }
  .quote-light-mark {
    font-size: 30px;
  }
  * + .quote-light {
    margin-top: 44px;
  }
}

@media (min-width: 992px) {
  .quote-light {
    padding: 33px 0;
  }
  .quote-light > * + * {
    margin-left: 20px;
  }
  .quote-light-mark {
    font-size: 34px;
  }
  .quote-light-text {
    font-size: 19px;
  }
}

@media (min-width: 1200px) {
  .quote-light-mark {
    font-size: 44px;
  }
  .quote-light-text {
    font-size: 22px;
    line-height: 1.27273;
  }
}

.quote-modern {
  position: relative;
  background: #ffffff;
  border-radius: 9px;
  box-shadow: 0 2px 16px 0 rgba(75, 81, 91, 0.14);
  margin-bottom: 33px;
  text-align: left;
}

.quote-modern::before,
.quote-modern::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}

.quote-modern::before {
  bottom: -24px;
  left: 61px;
  border-width: 24px 34px 0 0;
  border-color: #ebebeb transparent transparent transparent;
}

.quote-modern::after {
  bottom: -22px;
  left: 62px;
  border-width: 22px 32px 0 0;
  border-color: #ffffff transparent transparent transparent;
}

@supports (filter: drop-shadow(0 0 0 #000)) {
  .quote-modern {
    box-shadow: none;
    filter: drop-shadow(0 2px 8px rgba(75, 81, 91, 0.14));
  }
  .quote-modern::before {
    display: none;
  }
}

.quote-modern-header,
.quote-modern-main {
  padding: 22px 20px;
}

.quote-modern-info {
  display: flex;
  align-items: center;
  margin-left: -18px;
}

.quote-modern-info > * {
  margin-left: 18px;
}

.quote-modern-info-main {
  flex-grow: 1;
}

.quote-modern-avatar {
  flex-shrink: 0;
  border-radius: 50%;
  max-width: 55px;
}

.quote-modern-cite {
  display: block;
  font-size: 16px;
  line-height: 1.3;
  color: #262f3c;
}

.quote-modern-position {
  line-height: 1.3;
  color: #0accbe;
}

.quote-modern-header + .quote-modern-main {
  border-top: 1px solid #ebebeb;
}

.quote-modern-text {
  font-weight: 300;
  letter-spacing: 0.0025em;
}

.quote-modern-text a {
  color: #0accbe;
}

.quote-modern-text a:hover {
  color: #5f67df;
}

.quote-modern-meta {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -10px;
}

.quote-modern-meta > * {
  margin-left: 10px;
}

.quote-modern-link.icon {
  display: block;
  font-size: 24px;
  line-height: 1;
  opacity: 0.38;
}

.quote-modern-link.icon[class*="facebook"] {
  color: #6885cf;
}

.quote-modern-link.icon[class*="facebook"]:hover {
  color: #5f67df;
}

.quote-modern-time {
  display: block;
  font-weight: 300;
}

* + .quote-modern-position {
  margin-top: 3px;
}

* + .quote-modern-meta {
  margin-top: 15px;
}

@media (max-width: 767.98px) {
  .quote-modern {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 768px) {
  .quote-modern-header,
  .quote-modern-main {
    padding-left: 25px;
    padding-right: 20px;
  }
  .quote-modern-header {
    padding-top: 30px;
    padding-bottom: 20px;
  }
  .quote-modern-avatar {
    max-width: 75px;
  }
  .quote-modern-cite {
    font-size: 17px;
  }
  .quote-modern-text {
    font-size: 17px;
    line-height: 1.52941;
  }
  .quote-modern-meta {
    margin-left: -5px;
  }
  .quote-modern-meta > * {
    margin-left: 5px;
  }
  .quote-modern-link.icon {
    position: relative;
    font-size: 40px;
    left: -6px;
  }
  .quote-modern-time {
    font-size: 1.52941;
  }
  * + .quote-modern-position {
    margin-top: 6px;
  }
  * + .quote-modern-meta {
    margin-top: 22px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .quote-modern-avatar {
    max-width: 60px;
  }
  .quote-modern-text {
    font-size: 16px;
    line-height: 1.5;
  }
  .quote-modern-link.icon {
    font-size: 34px;
  }
  * + .quote-modern-position {
    margin-top: 2px;
  }
}

@media (min-width: 1200px) {
  .quote-modern-header,
  .quote-modern-main {
    padding-left: 45px;
    padding-right: 30px;
  }
}

.quote-classic {
  text-align: center;
}

.quote-classic-avatar {
  width: 100%;
  max-width: 80px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  box-shadow: 3px 10px 19px 2px rgba(75, 81, 91, 0.26);
}

.quote-classic-text {
  color: #8491a5;
}

.quote-classic-text a {
  color: #5f67df;
}

.quote-classic-text a:hover {
  color: #0accbe;
}

.quote-classic-cite {
  display: block;
  font-family: "Samsung";
  font-weight: 400;
  font-size: 18px;
  line-height: 1.35;
  letter-spacing: 0;
  color: #262f3c;
}

.quote-classic-position {
  line-height: 1.3;
  color: #0accbe;
}

* + .quote-classic-text {
  margin-top: 20px;
}

* + .quote-classic-meta {
  margin-top: 20px;
}

* + .quote-classic-position {
  margin-top: 5px;
}

@media (min-width: 768px) {
  .quote-classic-avatar {
    max-width: 100px;
  }
}

@media (min-width: 992px) {
  .quote-classic-text {
    font-size: 17px;
    line-height: 1.52941;
  }
  .quote-classic-cite {
    font-size: 22px;
    line-height: 1.36364;
  }
  * + .quote-classic-text {
    margin-top: 26px;
  }
  * + .quote-classic-meta {
    margin-top: 26px;
  }
}

@media (min-width: 1200px) {
  * + .quote-classic-text {
    margin-top: 36px;
  }
  * + .quote-classic-meta {
    margin-top: 36px;
  }
}

.quote-minimal {
  padding: 20px;
  border-radius: 9px;
  background: #ffffff;
  text-align: left;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.04);
}

.quote-minimal-text {
  font-weight: 300;
  letter-spacing: 0.025em;
  color: #8491a5;
}

.quote-minimal-text a,
.quote-minimal-text a:active,
.quote-minimal-text a:focus {
  color: #5f67df;
}

.quote-minimal-text a:hover {
  color: #0accbe;
}

.quote-minimal-meta {
  display: flex;
  align-items: center;
}

.quote-minimal-meta > * + * {
  margin-left: 15px;
}

.quote-minimal-avatar {
  display: block;
  max-width: 65px;
  border-radius: 50%;
}

.quote-minimal-cite {
  line-height: 1.2;
}

html .quote-minimal-cite {
  color: #262f3c;
}

.quote-minimal-position {
  line-height: 1.2;
  color: #0accbe;
}

* + .quote-minimal-meta {
  margin-top: 15px;
}

* + .quote-minimal-position {
  margin-top: 4px;
}

@media (min-width: 576px) {
  * + .quote-minimal-meta {
    margin-top: 22px;
  }
}

@media (min-width: 768px) {
  .quote-minimal-meta > * + * {
    margin-left: 20px;
  }
}

@media (min-width: 1200px) {
  .quote-minimal-text {
    font-size: 17px;
    line-height: 1.52941;
  }
}

/*
* Thumbnails
*/
.image-responsive {
  width: 100%;
}

.thumbnail-classy {
  position: relative;
  overflow: hidden;
  display: flex;
  border-radius: 8px;
  box-shadow: 0 12px 20px 0 rgba(75, 81, 91, 0.22);
  background-position: center center;
  background-size: cover;
}

.thumbnail-classy::before {
  content: "";
  display: block;
  width: 100%;
  padding-bottom: 73.68421%;
}

.thumbnail-classy-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.thumbnail-classy-image::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  pointer-events: none;
  background-image: linear-gradient(to top, #1c3758 0%, #647181 100%);
}

.thumbnail-classy-caption {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-shrink: 0;
}

.thumbnail-classy-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 86px;
  height: 86px;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.thumbnail-classy-button,
.thumbnail-classy-button:active,
.thumbnail-classy-button:focus,
.thumbnail-classy-button:hover {
  color: #ffffff;
}

.thumbnail-classy-button::before {
  font-size: 20px;
}

.thumbnail-classy-button:hover .thumbnail-classy-button-shape {
  stroke-dasharray: 600;
  stroke-dashoffset: 0;
}

.thumbnail-classy-button-shape {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  fill: none;
  stroke: #ffffff;
  stroke-width: 1px;
  stroke-dasharray: 340;
  stroke-dashoffset: 340;
  transition: 1.3s;
}

@media (min-width: 768px) {
  .thumbnail-classy {
    box-shadow: 0 25px 54px 0 rgba(75, 81, 91, 0.33);
  }
}

html:not(.tablet):not(.mobile) .thumbnail-classy-image {
  transform: scale3d(1.15, 1.15, 1.15);
  transition: 1s;
}

html:not(.tablet):not(.mobile) .thumbnail-classy:hover .thumbnail-classy-image {
  transform: scale3d(1, 1, 1);
  transition: 1.3s;
}

.thumbnail-preview {
  display: block;
  text-align: center;
}

.thumbnail-preview:hover .thumbnail-preview-media {
  box-shadow: 0 0 10px 2px rgba(75, 81, 91, 0.12);
}

.thumbnail-preview:hover .thumbnail-preview-title {
  color: #0accbe;
}

.thumbnail-preview-media {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 0 4px 0 rgba(75, 81, 91, 0.08);
  background: #ffffff;
  transition: 0.33s;
}

.thumbnail-preview-header {
  width: 100%;
}

.thumbnail-preview-image {
  position: relative;
  padding-bottom: 79.22535%;
  background-position: center top;
  background-size: cover;
}

.thumbnail-preview-title {
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
  text-align: center;
  letter-spacing: 0.01em;
  transition: 0.22s;
}

.thumbnail-preview-title,
.thumbnail-preview-title:active,
.thumbnail-preview-title:focus,
.thumbnail-preview-title:hover {
  color: #262f3c;
}

* + .thumbnail-preview-title {
  margin-top: 10px;
}

@media (min-width: 1200px) {
  * + .thumbnail-preview-title {
    margin-top: 22px;
  }
}

.thumbnail-modern {
  position: relative;
  display: block;
}

.thumbnail-modern:hover .thumbnail-modern-media {
  box-shadow: 0 2px 15px 2px rgba(75, 81, 91, 0.2);
}

.thumbnail-modern:hover .thumbnail-modern-title {
  color: #0accbe;
}

.thumbnail-modern-sm .thumbnail-modern-media {
  padding-bottom: 59.45946%;
}

.thumbnail-modern-media,
.thumbnail-modern-title {
  transition: 0.3s;
}

.thumbnail-modern-media {
  position: relative;
  overflow: hidden;
  padding-bottom: 118.91892%;
  background-position: center top;
  background-size: cover;
  border-radius: 6px;
  box-shadow: 0 2px 9px 0 rgba(75, 81, 91, 0.11);
  transform: scale3d(1, 1, 1);
}

* + .thumbnail-modern-title {
  margin-top: 10px;
}

@media (min-width: 992px) {
  * + .thumbnail-modern-title {
    margin-top: 20px;
  }
}

/*
* Breadcrumbs
*/
.breadcrumbs-custom {
  display: flex;
  flex-direction: column;
  text-align: center;
}

@media (min-width: 1600px) {
  .breadcrumbs-custom {
    margin-left: 5.25%;
  }
}

.breadcrumbs-custom .big {
  color: rgba(255, 255, 255, 0.8);
}

.breadcrumbs-custom-main {
  position: relative;
  padding: 40px 0 20px;
  border-radius: 0px 0px 0px 70px;
}

@media (min-width: 576px) {
  .breadcrumbs-custom-main {
    border-radius: 0px 0px 0px 120px;
  }
}

.breadcrumbs-custom-main-2 {
  position: relative;
  padding: 40px 0 20px;
  border-radius: 0px 0px 0px 70px;
}

@media (min-width: 576px) {
  .breadcrumbs-custom-main-2 {
    border-radius: 0px 0px 0px 120px;
  }
}

.breadcrumbs-custom-aside {
  background: transparent;
  padding: 0 10px;
}

.breadcrumbs-custom-path {
  position: relative;
  top: -12px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 12px 10px 12px;
  background: transparent;
  border-radius: 4px;
  transform: translate3d(0, -3px, 0);
  margin-bottom: -3px;
  margin-left: -17px;
  margin-right: -17px;
}

@media (min-width: 1600px) {
  .breadcrumbs-custom-path {
    margin-left: -75px;
  }
}

.breadcrumbs-custom-path > * {
  margin-top: 3px;
  padding-left: 17px;
  padding-right: 17px;
}

.breadcrumbs-custom-path li {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 15px;
  line-height: 1.7;
}

.breadcrumbs-custom-path li::after {
  content: "/";
  position: absolute;
  top: 52%;
  right: -5px;
  display: inline-block;
  font-size: inherit;
  font-style: normal;
  transform: translate3d(0, -50%, 0);
}

.breadcrumbs-custom-path li:last-child:after {
  display: none;
}

.breadcrumbs-custom-path a:hover,
.breadcrumbs-custom-path li.active {
  color: #8491a5;
}

.breadcrumbs-custom-path li.active {
  top: 2px;
}

.breadcrumbs-custom-path a {
  display: inline;
  vertical-align: middle;
}

.breadcrumbs-custom-path a,
.breadcrumbs-custom-path a:active,
.breadcrumbs-custom-path a:focus {
  color: #ffffff;
}

.breadcrumbs-custom-path a:hover {
  color: #0accbe;
}

.breadcrumbs-custom-title {
  font-weight: 600;
  letter-spacing: 0;
  margin-bottom: 60px;
}

@media (min-width: 576px) {
  .breadcrumbs-custom-title {
    margin-bottom: 70px;
  }
}

@media (min-width: 1600px) {
  .breadcrumbs-custom-title {
    margin-left: -75px;
    margin-bottom: 145px;
  }
}

.breadcrumbs-custom-title-2 {
  font-weight: 600;
  letter-spacing: 0;
  margin-bottom: 36px;
}

@media (min-width: 1600px) {
  .breadcrumbs-custom-title-2 {
    margin-left: -75px;
  }
}

.breadcrumbs-custom-text-2 {
  margin-bottom: 60px;
}

@media (min-width: 768px) {
  .breadcrumbs-custom-text-2 {
    margin-bottom: 82px;
  }
}

@media (min-width: 992px) {
  .breadcrumbs-custom-text-2 {
    margin-bottom: 70px;
  }
}

@media (min-width: 1600px) {
  .breadcrumbs-custom-text-2 {
    margin-bottom: 63px;
  }
}

@media (min-width: 768px) {
  .breadcrumbs-custom-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 60px 0 20px;
    min-height: 20.3125vw;
  }
}

@media (min-width: 1200px) {
  .breadcrumbs-custom-main {
    padding-top: 124px;
    padding-bottom: 20px;
  }
  .breadcrumbs-custom-main * + .big {
    margin-top: 30px;
  }
  .breadcrumbs-custom-main .link-waypoint-icon {
    position: relative;
    margin-top: 0;
    bottom: -33px;
  }
  .breadcrumbs-custom-path {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (min-width: 2050px) {
  .breadcrumbs-custom-main {
    min-height: 390px;
  }
}

@media (min-width: 768px) {
  .breadcrumbs-custom-main-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 60px 0 20px;
    min-height: 20.3125vw;
  }
}

@media (min-width: 1200px) {
  .breadcrumbs-custom-main-2 {
    padding-top: 120px;
    padding-bottom: 20px;
  }
  .breadcrumbs-custom-main-2 * + .big {
    margin-top: 30px;
  }
  .breadcrumbs-custom-main-2 .link-waypoint-icon {
    position: relative;
    margin-top: 0;
    bottom: -33px;
  }
  .breadcrumbs-custom-path {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (min-width: 2050px) {
  .breadcrumbs-custom-main-2 {
    min-height: 390px;
  }
}

/*
* Pagination custom
*/
.pagination {
  font-size: 0;
  line-height: 0;
  margin-bottom: -10px;
  margin-left: -10px;
}

.pagination:empty {
  margin-bottom: 0;
  margin-left: 0;
}

.pagination > * {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 10px;
  margin-left: 10px;
}

.page-item {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-weight: 700;
}

.page-link {
  display: inline-block;
  min-width: 48px;
  padding: 10px 10px;
  border: 2px solid #ebebeb;
  font-size: 14px;
  line-height: 1.71429;
  background-color: #ffffff;
  color: #777;
  transition: all 0.3s ease-in-out;
}

.page-link:hover,
.page-link:focus,
.page-link:active {
  color: #ffffff;
  background-color: #0accbe;
  border-color: #0accbe;
}

.page-item.active > .page-link,
.page-item.active > .page-link:hover,
.page-item.active > .page-link:focus,
.page-item.active > .page-link:active {
  color: #ffffff;
  background-color: #0accbe;
  border-color: #0accbe;
}

.page-item.disabled > .page-link,
.page-item.disabled > .page-link:hover,
.page-item.disabled > .page-link:focus,
.page-item.disabled > .page-link:active {
  color: #8491a5;
  background-color: #ebebeb;
  border-color: #ebebeb;
}

.page-item-control .icon::before {
  font-family: "FontAwesome";
  font-size: 11px;
  line-height: 24px;
}

.page-item-control:first-child .icon::before {
  content: "\f053";
  margin-left: -1px;
}

.page-item-control:last-child .icon::before {
  content: "\f054";
  margin-right: -1px;
}

/*
* Snackbars
*/
.snackbars {
  padding: 9px 16px;
  margin-left: auto;
  margin-right: auto;
  color: #ffffff;
  text-align: left;
  background-color: #262f3c;
  border-radius: 0;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  font-size: 14px;
}

.snackbars .icon-xxs {
  font-size: 18px;
}

.snackbars p span:last-child {
  padding-left: 14px;
}

.snackbars-left {
  display: inline-block;
  margin-bottom: 0;
}

.snackbars-right {
  display: inline-block;
  float: right;
  text-transform: uppercase;
}

.snackbars-right:hover {
  text-decoration: underline;
}

@media (min-width: 576px) {
  .snackbars {
    max-width: 540px;
    padding: 12px 15px;
    font-size: 15px;
  }
}

/*
* Footers
*/
.footer-classic {
  padding-top: 60px;
}

.footer-classic hr {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

@media (min-width: 768px) {
  .footer-classic {
    padding-top: 100px;
  }
}

.footer-classic .text {
  letter-spacing: 0;
}

.footer-classic.footer-classic_boxed,
.footer-classic.footer-classic_boxed .footer-classic-aside {
  border-top: 0;
}

.breadcrumbs-custom + .footer-classic,
.bg-gray-100 + .footer-classic {
  border-top: 0;
}

.footer-classic-main {
  padding: 40px 0;
}

.footer-classic-list {
  color: #262f3c;
}

.footer-classic-title {
  font-weight: 600;
}

.footer-classic-aside {
  padding: 20px 0;
  text-align: center;
  border-top: 1px solid #ebebeb;
}

.footer-classic-aside .rights {
  color: #8491a5;
}

.footer-classic .footer-classic-title + * {
  margin-top: 15px;
}

@media (max-width: 575.98px) {
  .footer-classic-list {
    margin-bottom: -10px;
    margin-left: -15px;
  }
  .footer-classic-list:empty {
    margin-bottom: 0;
    margin-left: 0;
  }
  .footer-classic-list > * {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 10px;
    margin-left: 15px;
  }
  .footer-classic-list > li {
    display: inline-block;
    vertical-align: middle;
  }
  .footer-classic-list > li + li {
    margin-top: 0;
  }
}

@media (min-width: 576px) {
  .footer-classic-list > li + li {
    margin-top: 12px;
  }
  .footer-classic-list_2-cols {
    columns: 2;
    break-inside: avoid;
    column-gap: 30px;
  }
  .footer-classic .footer-classic-title + * {
    margin-top: 25px;
  }
}

@media (min-width: 768px) {
  .footer-classic-main {
    padding: 70px 0 60px;
  }
  .footer-classic-aside {
    padding: 35px 0;
  }
}

@media (min-width: 1200px) {
  .footer-classic .footer-classic-title + * {
    margin-top: 38px;
  }
}

.footer-light {
  padding: 40px 0 30px;
  background: #ffffff;
}

.footer-light-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-light-main > * + * {
  margin-top: 22px;
}

.footer-light-list > li {
  display: inline-block;
  vertical-align: middle;
  margin-right: 30px;
}

.footer-light-list > li:last-child {
  margin-right: 0;
}

.footer-light-list > li + li {
  margin-top: 0;
}

@media (min-width: 768px) {
  .footer-light {
    padding: 45px 0;
  }
  .footer-light-main {
    flex-direction: row;
    justify-content: space-between;
  }
  .footer-light-main > * {
    padding: 0 15px;
    flex-grow: 1;
    text-align: center;
  }
  .footer-light-main > *:first-child {
    padding-left: 0;
    text-align: left;
  }
  .footer-light-main > *:last-child {
    padding-right: 0;
    text-align: right;
  }
  .footer-light-main > * + * {
    margin-top: 0;
  }
  .footer-light-list > li {
    margin-right: 45px;
  }
}

@media (min-width: 1200px) {
  .footer-light-main > * {
    width: 33.33333%;
  }
}

.block-footer-classic {
  background: linear-gradient(0deg, #4a80f0 0%, #5c8cf1 100%);
  border-top-right-radius: 116px;
  border-top-right-radius: 70px;
}

@media (min-width: 1600px) {
  .block-footer-classic {
    margin-right: 5.25%;
  }
  .block-footer-classic .block-footer-classic-wrap {
    margin-right: -5.25%;
  }
}

/*
*
* Profiles
*/
.profile-modern {
  position: relative;
  overflow: hidden;
  display: block;
  max-width: 265px;
  margin: 0 auto;
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.15);
  background: #ffffff;
  text-align: left;
  border-radius: 16px;
}

.profile-modern .profile-modern-main-title {
  font-style: italic;
}

.profile-modern-figure {
  position: relative;
  flex-grow: 1;
  z-index: 2;
  padding: 6px;
}

.profile-modern-image {
  width: 100%;
  transform-origin: 50% 0;
  transform: scale3d(1, 1, 1) rotate(0.1deg);
}

.profile-modern-main {
  position: relative;
  z-index: 1;
  color: #9b9b9b;
  padding: 20px;
  text-align: center;
}

.profile-modern-main-item {
  width: 100%;
  flex-shrink: 0;
  background: #ffffff;
}

.profile-modern-main-item * + .list-inline {
  margin-top: 12px;
}

html:not(.tablet):not(.mobile) .profile-modern:hover .profile-modern-toggle {
  transform: rotate(180deg);
}

html:not(.tablet):not(.mobile)
  .profile-modern:hover
  .profile-modern-main-item_primary::before {
  opacity: 1;
  visibility: visible;
}

html:not(.tablet):not(.mobile)
  .profile-modern:hover
  .profile-modern-main-item_secondary {
  top: 0;
  right: 0;
  transform: translate3d(-100%, 0, 0);
  cursor: default;
}

html:not(.tablet):not(.mobile) .profile-modern-main {
  display: flex;
  padding: 0;
  text-align: left;
}

html:not(.tablet):not(.mobile) .profile-modern-main-item_primary {
  padding: 24px 20px 45px 70px;
  transform: scale3d(0.97, 0.97, 0.97);
}

html:not(.tablet):not(.mobile) .profile-modern-main-item_primary::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background: rgba(0, 0, 0, 0.05);
  opacity: 0;
  visibility: hidden;
  transition: 0.45s;
}

html:not(.tablet):not(.mobile) .profile-modern-main-item_secondary {
  position: relative;
  z-index: 2;
  top: -52px;
  right: -52px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px 55px 25px 47px;
  text-align: center;
  cursor: pointer;
  transform: translate3d(-200%, 100%, 0);
  transition: 0.45s;
  color: #ffffff;
  background: #0accbe;
}

html:not(.tablet):not(.mobile)
  .profile-modern-main-item_secondary
  .profile-modern-main-title {
  color: #ffffff;
}

html:not(.tablet):not(.mobile)
  .profile-modern-main-item_secondary
  a.icon:hover {
  color: #ffea5c;
}

html:not(.tablet):not(.mobile) .profile-modern-main-title {
  display: block;
}

html:not(.tablet):not(.mobile) .profile-modern-toggle {
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  color: #ffffff;
  font-size: 18px;
  transition: 0.22s;
}

.profile-modern-main-title {
  display: none;
}

.profile-modern-name {
  font-weight: 400;
}

.profile-modern-toggle {
  display: none;
}

* + .profile-modern-position {
  margin-top: 3px;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .profile-modern {
    max-width: 320px;
  }
}

/*
*
* Layouts
*/
.layout-bordered-1-item {
  position: relative;
  padding: 30px 15px;
}

.layout-bordered-1-item::before {
  content: "";
  position: absolute;
  background: radial-gradient(circle, #d6dfeb 0%, rgba(214, 223, 235, 0) 100%);
}

@media (max-width: 575.98px) {
  .layout-bordered-1-item:first-child {
    padding-top: 0;
  }
  .layout-bordered-1-item:first-child::before {
    display: none;
  }
  .layout-bordered-1-item:last-child {
    padding-bottom: 0;
  }
  .layout-bordered-1-item::before {
    width: 80%;
    height: 1px;
    top: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }
}

@media (min-width: 576px) {
  .layout-bordered-1 {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .layout-bordered-1-item {
    width: 33.33333%;
    padding-top: 0;
    padding-bottom: 0;
  }
  .layout-bordered-1-item::before {
    width: 1px;
    height: 80%;
    right: 0;
    top: 50%;
    transform: translate3d(0, -50%, 0);
  }
  .layout-bordered-1-item:nth-child(3n)::before {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .layout-bordered-1-item .counter-classic-title {
    font-size: 16px;
  }
}

.layout-2 {
  padding: 30px 15px;
  margin-left: auto;
  margin-right: auto;
}

.layout-2-inner {
  display: inline-table;
  margin-bottom: -15px;
  margin-left: -15px;
  text-align: center;
}

.layout-2-inner:empty {
  margin-bottom: 0;
  margin-left: 0;
}

.layout-2-inner > * {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 15px;
  margin-left: 15px;
}

.layout-2-inner > * {
  display: inline-block;
  vertical-align: middle;
}

.layout-2-group {
  display: inline-table;
  margin-bottom: -15px;
  margin-left: -30px;
}

.layout-2-group:empty {
  margin-bottom: 0;
  margin-left: 0;
}

.layout-2-group > * {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 15px;
  margin-left: 30px;
}

.layout-2-group > * {
  display: inline-block;
  vertical-align: middle;
}

.layout-2-group > *,
.layout-2-group .button {
  margin-top: 0;
}

@media (min-width: 768px) {
  .layout-2-inner {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 30px;
  }
}

@media (min-width: 1600px) {
  .layout-2 {
    max-width: 1800px;
  }
  .layout-2-inner {
    padding: 0;
  }
}

.layout-3 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: -25px;
}

.layout-3 > * {
  margin-bottom: 25px;
}

.layout-3-item {
  padding: 0 30px;
}

.layout-3-item .link-image-1 {
  max-width: 70px;
}

* + .layout-3 {
  margin-top: 30px;
}

@media (min-width: 768px) {
  .layout-3 {
    justify-content: space-between;
  }
  * + .layout-3 {
    margin-top: 45px;
  }
}

@media (min-width: 992px) {
  .layout-3-item {
    padding: 0 30px;
  }
  .layout-3-item .link-image-1 {
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  * + .layout-3 {
    margin-top: 55px;
  }
}

.layout-4 {
  position: relative;
}

.layout-4-item-left {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;
}

* + .layout-4-item-left {
  margin-top: 40px;
}

@media (min-width: 1200px) {
  .layout-4 {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0;
  }
  .layout-4-item-left {
    width: 67%;
    margin-left: -12.5%;
    max-width: 100%;
  }
  .layout-4-item-right {
    width: 46%;
  }
  * + .layout-4-item-left {
    margin-top: 0;
  }
}

@media (min-width: 1600px) {
  .layout-4 {
    padding: 0;
  }
  .layout-4-item-left {
    width: 60%;
    padding: 40px 0;
    margin-left: -11.3%;
  }
  .layout-4-item-right {
    width: 46%;
  }
}

@media (min-width: 1940px) {
  .layout-4-item-left {
    padding-left: 12%;
  }
}

.layout-5 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -10px;
  margin-left: -20px;
}

.layout-5:empty {
  margin-bottom: 0;
  margin-left: 0;
}

.layout-5 > * {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 10px;
  margin-left: 20px;
}

.container.layout-5-outer + .container {
  margin-top: 30px;
}

@media (min-width: 768px) {
  .container.layout-5-outer + .container {
    margin-top: 40px;
  }
}

@media (min-width: 1200px) {
  .container.layout-5-outer + .container {
    margin-top: 55px;
  }
}

/*
*
* Timeline
*/
.timeline-classic {
  position: relative;
  overflow: hidden;
  text-align: left;
  padding: 25px 0 25px 20px;
}

.timeline-classic-item {
  position: relative;
  padding-left: 29px;
}

.timeline-classic-item::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate3d(0, -50%, 0);
  height: 300%;
  border-left: 1px solid #ebebeb;
}

.timeline-classic-item:not(:last-child) .timeline-classic-item-inner {
  padding-bottom: 30px;
  border-bottom: 1px solid #ebebeb;
}

.timeline-classic-item-decoration {
  position: absolute;
  left: 0;
  top: 20px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.timeline-classic-item-decoration::before,
.timeline-classic-item-decoration::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  background: #5c61db;
}

.timeline-classic-item-decoration::before {
  width: 14px;
  height: 14px;
}

.timeline-classic-item-decoration::after {
  width: 26px;
  height: 26px;
  opacity: 0.3;
}

.ie-10 .timeline-classic-item-decoration::before,
.ie-10 .timeline-classic-item-decoration::after,
.ie-11 .timeline-classic-item-decoration::before,
.ie-11 .timeline-classic-item-decoration::after {
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.timeline-classic-title {
  font-family: "Samsung";
  font-size: 16px;
  line-height: 1.4;
  color: #262f3c;
}

.timeline-classic-title span {
  margin-right: 0.25em;
}

.timeline-classic-text {
  color: #262f3c;
}

.timeline-classic-item-decoration + * {
  margin-top: 0;
}

* + .timeline-classic-text {
  margin-top: 10px;
}

.timeline-classic-item + .timeline-classic-item {
  margin-top: 30px;
}

@media (min-width: 576px) {
  .timeline-classic {
    padding: 35px 0 50px 20px;
  }
  .timeline-classic-item {
    padding-left: 50px;
  }
  .timeline-classic-title {
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  * + .timeline-classic-text {
    margin-top: 18px;
  }
}

@media (min-width: 992px) {
  .timeline-classic_1 {
    padding-left: 40px;
  }
}

@media (min-width: 1200px) {
  .timeline-classic {
    padding: 100px 0;
  }
  .timeline-classic_1 {
    padding-left: 85px;
  }
  .timeline-classic-item {
    padding-left: 85px;
  }
  .timeline-classic-item:not(:last-child) .timeline-classic-item-inner {
    padding-bottom: 44px;
  }
  .timeline-classic-item-decoration {
    top: 50px;
  }
  .timeline-classic-item-decoration::before {
    width: 18px;
    height: 18px;
  }
  .timeline-classic-item-decoration::after {
    width: 34px;
    height: 34px;
  }
  .timeline-classic-title {
    font-size: 26px;
    line-height: 1.3;
  }
  .timeline-classic-text {
    font-size: 17px;
    line-height: 1.52941;
  }
  .timeline-classic-item + .timeline-classic-item .timeline-classic-item-inner {
    margin-top: 44px;
  }
}

/*
*
* Pricing
*/
@keyframes object-hide {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.1;
  }
  80%,
  100% {
    opacity: 0;
  }
}

@keyframes object-show {
  0% {
    opacity: 0;
    transform: scale3d(0.9, 0.9, 0.9);
  }
  33% {
    opacity: 0.05;
  }
  75% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes opacity-loop {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.pt-classic {
  position: relative;
  padding: 40px 15px;
  border-radius: 7px;
  box-shadow: 0 0 29px 0 rgba(75, 81, 91, 0.17);
  background: #ffffff;
}

.pt-classic::before,
.pt-classic::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 9px;
  background: #0accbe;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  pointer-events: none;
}

.pt-classic::after {
  z-index: 2;
  opacity: 0;
  transform: scale(-1, 1);
}

.pt-classic > * {
  max-width: 260px;
  margin-left: auto;
  margin-right: auto;
}

.pt-classic:hover::after {
  animation: 1s 0.1s opacity-loop infinite alternate both ease-in-out;
}

.pt-classic_style-2::before,
.pt-classic_style-2:after {
  background: #0accbe;
}

.pt-classic_style-3::before,
.pt-classic_style-3:after {
  background: #0accbe;
}

.pt-classic_preferred {
  z-index: 2;
}

.pt-classic-header {
  max-width: 100%;
}

.pt-classic-header .pt-classic-title {
  position: relative;
  padding: 0 30px;
}

.pt-classic-header .pt-classic-icon {
  position: absolute;
  right: 0;
  top: 15%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.15s ease-in-out, transform 0.6s;
}

.pt-classic-header .pt-classic-price-outer {
  padding: 0 75px;
  transition: 0.22s;
}

.pt-classic-header .badge {
  position: absolute;
  top: 10%;
  margin: 0;
  right: -15px;
  max-width: 100px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.15s ease-in-out;
}

@supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
  .pt-classic-header .badge {
    opacity: 1;
    visibility: visible;
    -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  }
}

.pt-classic-group.active .pt-classic-icon {
  transform: translate(0, 0);
  opacity: 1;
  visibility: visible;
  transition: 0.22s 0.22s ease-in-out;
}

.pt-classic-group.active .pt-classic-icon .icon:nth-child(2) {
  transform: scale(1.85, 1.85);
  opacity: 0;
  visibility: hidden;
  transition: 0.22s 0.22s ease-in-out;
}

.pt-classic-group.active .pt-classic-header .pt-classic-price-outer {
  padding-left: 40px;
}

.pt-classic-group.active .badge {
  opacity: 1;
  visibility: visible;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  transition: 0.22s ease-in-out;
}

.pt-classic-title {
  font-weight: 600;
  color: #0accbe;
}

.pt-classic-icon {
  position: relative;
  width: 30px;
  height: 30px;
  transform: translate(0, 5px);
}

.pt-classic-icon .icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-size: 30px;
}

.pt-classic-icon .icon:nth-child(2) {
  opacity: 1;
  visibility: visible;
  transform: scale(1, 1);
}

.pt-classic-price {
  position: relative;
  white-space: nowrap;
  font-family: "Samsung";
  font-size: 45px;
  line-height: 1;
  font-weight: 100;
  color: #262f3c;
  letter-spacing: -0.1em;
}

.pt-classic-price > * {
  letter-spacing: 0.025em;
}

.pt-classic-price-currency,
.pt-classic-price-value {
  display: inline-block;
}

.pt-classic-price-outer {
  position: relative;
}

.pt-classic-price-outer > *:first-child {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.pt-classic-price-outer [aria-hidden="false"] {
  animation: 0.22s ease-in object-show forwards;
}

.pt-classic-price-outer [aria-hidden="true"] {
  animation: 0.22s ease-in object-hide forwards;
}

.pt-classic-price-currency {
  position: relative;
  top: -0.2em;
  font-size: 0.4em;
}

.pt-classic-divider {
  height: 1px;
  width: 100%;
  background: radial-gradient(circle, #d6dfeb 0%, rgba(214, 223, 235, 0) 100%);
}

.pt-classic-list {
  padding: 0 10px;
  font-weight: 400;
  text-align: left;
}

.pt-classic-list li {
  position: relative;
  padding-left: 30px;
  display: block;
  color: #262f3c;
  line-height: 1.3;
}

.pt-classic-list li::before {
  content: "\eab9";
  position: absolute;
  left: 0;
  font-family: "Linearicons";
  color: #0accbe;
  font-size: 1.2em;
  line-height: inherit;
}

.pt-classic-list li[aria-disabled="true"] {
  color: #8491a5;
}

.pt-classic-list li[aria-disabled="true"]::before {
  color: #8491a5;
}

.pt-classic-list li + li {
  margin-top: 14px;
}

.pt-classic-note {
  color: #262f3c;
  opacity: 0.3;
}

* + .pt-classic-group {
  margin-top: 25px;
}

* + .pt-classic-price-outer {
  margin-top: 15px;
}

* + .pt-classic-divider {
  margin-top: 20px;
}

* + .pt-classic-list {
  margin-top: 20px;
}

* + .pt-classic-footer {
  margin-top: 30px;
}

@media (max-width: 767.98px) {
  .pt-classic {
    max-width: 360px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 576px) {
  .pt-classic-list {
    padding: 0 15px;
  }
}

@media (min-width: 768px) {
  .pt-classic {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .pt-classic-price {
    font-size: 50px;
  }
  .pt-classic-list li + li {
    margin-top: 17px;
  }
  * + .pt-classic-group {
    margin-top: 30px;
  }
  * + .pt-classic-price-outer {
    margin-top: 25px;
  }
  * + .pt-classic-divider {
    margin-top: 30px;
  }
  * + .pt-classic-list {
    margin-top: 30px;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .pt-classic-group .row {
    margin-right: 0;
    margin-left: 0;
  }
  .pt-classic-group .row > [class*="col"] {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1200px) {
  .pt-classic {
    padding: 53px 25px;
  }
  .pt-classic-header .pt-classic-price-outer .badge {
    right: -25px;
  }
  .pt-classic_preferred {
    padding-top: 75px;
    padding-bottom: 30px;
  }
  .pt-classic-price {
    font-size: 75px;
  }
  * + .pt-classic-group {
    margin-top: 45px;
  }
  * + .pt-classic-list {
    margin-top: 35px;
  }
  * + .pt-classic-footer {
    margin-top: 42px;
  }
  * + .pt-classic-note {
    margin-top: 25px;
  }
}

.pt-creative-group {
  display: flex;
  justify-content: space-around;
  padding: 25px 10px 30px;
  background: #ffffff;
  border-radius: 7px;
  box-shadow: 0 0 27px 0 rgba(75, 81, 91, 0.15);
}

.pt-creative {
  width: 200px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.pt-creative .pt-creative-features li::after {
  background: radial-gradient(circle, #d6dfeb 0%, rgba(214, 223, 235, 0) 100%);
}

.pt-creative-main {
  flex-grow: 1;
  flex-shrink: 0;
  width: calc(100% - 155px);
}

.pt-creative-main .owl-carousel {
  display: flex;
}

.pt-creative-main .owl-stage-outer {
  margin: -10px;
  padding: 10px;
}

.pt-creative-main .owl-stage {
  display: flex;
  align-items: flex-end;
}

.pt-creative-main .owl-item {
  visibility: hidden;
}

.pt-creative-main .owl-item.active {
  visibility: visible;
}

.pt-creative-main .owl-prev,
.pt-creative-main .owl-next {
  top: 16.5%;
  background-color: transparent;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
}

.pt-creative-main .owl-prev.disabled,
.pt-creative-main .owl-next.disabled {
  opacity: 0.2;
  pointer-events: none;
  filter: grayscale(100%);
}

.pt-creative-main .owl-prev {
  transform: translate(-35%, 0);
}

.pt-creative-main .owl-next {
  transform: translate(35%, 0);
}

@media (max-width: 575.98px) {
  .pt-creative-main .owl-prev,
  .pt-creative-main .owl-next {
    box-shadow: none;
  }
  .pt-creative-main .button {
    padding: 10px 12px;
  }
}

.pt-creative-aside {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 155px;
}

.pt-creative-aside-title {
  font-weight: 100;
  color: #8491a5;
}

.pt-creative-title {
  font-weight: 600;
}

.pt-creative-price-outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 80px;
  color: #262f3c;
}

.pt-creative-price,
.pt-creative-price-caption {
  position: relative;
}

.pt-creative-price > *:first-child,
.pt-creative-price-caption > *:first-child {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.pt-creative-price [aria-hidden="false"],
.pt-creative-price-caption [aria-hidden="false"] {
  animation: 0.22s ease-in object-show forwards;
}

.pt-creative-price [aria-hidden="true"],
.pt-creative-price-caption [aria-hidden="true"] {
  animation: 0.22s ease-in object-hide forwards;
}

.pt-creative-price-currency,
.pt-creative-price-small {
  position: relative;
  top: 0.22em;
  font-size: 0.5641em;
}

.pt-creative-price-inner {
  white-space: nowrap;
  font-size: 30px;
  line-height: 1.2;
  letter-spacing: -0.16em;
}

.pt-creative-price-inner > * {
  display: inline-block;
  vertical-align: top;
  letter-spacing: 0.025em;
}

.pt-creative-price-text {
  font-size: 26px;
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #262f3c;
}

.pt-creative-price-value {
  font-weight: 700;
}

.pt-creative-divider {
  height: 1px;
  width: 100%;
  background: radial-gradient(circle, #d6dfeb 0%, rgba(214, 223, 235, 0) 100%);
}

.pt-creative-text {
  line-height: 1.2;
}

.pt-creative-features {
  color: #262f3c;
}

.pt-creative-features li {
  position: relative;
  display: block;
  padding: 10px 0;
  line-height: 22px;
}

.pt-creative-features li::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background: #ebebeb;
}

.pt-creative-features .icon {
  font-size: 18px;
  color: #0accbe;
}

.pt-creative-features li[aria-disabled="true"] {
  color: #8491a5;
}

.pt-creative-footer {
  position: relative;
  display: flex;
  align-items: center;
}

.pt-creative-footer::after {
  content: "";
  display: block;
  min-height: 60px;
  padding-bottom: 30%;
  visibility: hidden;
  pointer-events: none;
}

.pt-creative-footer::after,
.pt-creative-footer .pt-creative-footer-inner {
  width: 100%;
  flex-shrink: 0;
}

* + .pt-creative-group {
  margin-top: 30px;
}

* + .pt-creative-divider {
  margin-top: 10px;
}

* + .pt-creative-features {
  margin-top: 10px;
}

* + .pt-creative-footer {
  margin-top: 25px;
}

@media (max-width: 399px) {
  .pt-creative-group {
    margin-left: -13px;
    margin-right: -13px;
  }
}

@media (min-width: 400px) {
  .pt-creative-group {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (max-width: 575.98px) {
  .pt-creative-features {
    font-size: 12px;
  }
  .pt-creative-features .icon {
    font-size: 16px;
  }
}

@media (min-width: 576px) {
  .pt-creative-aside {
    width: 200px;
  }
  .pt-creative-main {
    max-width: calc(100% - 200px);
  }
}

@media (min-width: 768px) {
  .pt-creative-group {
    padding: 60px 20px 60px 30px;
  }
  .pt-creative-price-inner {
    font-size: 39px;
  }
  .pt-creative-price-caption {
    font-size: 17px;
    line-height: 1.52941;
  }
  .pt-creative-features li {
    padding: 13px 0 14px;
  }
  * + .pt-creative-price-outer {
    margin-top: 10px;
  }
  * + .pt-creative-text {
    margin-top: 19px;
  }
  * + .pt-creative-features {
    margin-top: 20px;
  }
  * + .pt-creative-footer {
    margin-top: 30px;
  }
  .pt-creative-aside-title + .pt-creative-features {
    margin-top: 45px;
  }
}

@media (min-width: 1200px) {
  * + .pt-creative-group {
    margin-top: 45px;
  }
  .pt-creative-aside-title + .pt-creative-features {
    margin-top: 65px;
  }
}

.pt-modern {
  position: relative;
  overflow: hidden;
  border-radius: 7px;
  box-shadow: 0 0 29px 0 rgba(75, 81, 91, 0.17);
  background: #ffffff;
  text-align: left;
}

.pt-modern .nav-tabs {
  padding: 30px 15px;
  font-size: 15px;
  line-height: 1.5;
}

.pt-modern .nav-item {
  padding: 5px;
  cursor: unset;
}

.pt-modern .nav-link {
  position: relative;
  display: block;
  padding-left: 30px;
  font-family: "Samsung";
  font-weight: 600;
  font-size: 17px;
  line-height: 1.3;
  color: #262f3c;
  cursor: pointer;
}

.pt-modern .nav-link > * {
  display: inline-block;
  margin-right: 12px;
  vertical-align: middle;
}

.pt-modern .nav-link,
.pt-modern .nav-item-indicator {
  transition: 0.22s ease-in-out;
}

.pt-modern .nav-link:hover,
.pt-modern .nav-link.active {
  color: #0accbe;
}

.pt-modern .nav-link:hover .nav-item-indicator,
.pt-modern .nav-link.active .nav-item-indicator {
  border-color: #0accbe;
}

.pt-modern .nav-link.active .nav-item-indicator::after {
  opacity: 1;
  visibility: visible;
  background: #0accbe;
}

.pt-modern .tab-content {
  display: flex;
}

.pt-modern .tab-content > .tab-pane {
  display: flex;
  width: 0;
}

.pt-modern .tab-pane {
  flex-direction: column;
  transition: opacity 0.02s ease-in;
}

.pt-modern .tab-pane > * {
  border-top: 1px solid #ebebeb;
}

.pt-modern .tab-pane.active {
  width: 100%;
  transition: opacity 0.14s ease-in;
}

span.nav-item-indicator {
  position: absolute;
  display: inline-flex;
  left: 0;
  top: 3px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 3px solid #ebebeb;
}

span.nav-item-indicator::after {
  content: "";
  display: block;
  position: absolute;
  top: 25%;
  left: 25%;
  width: 50%;
  height: 50%;
  background: #ebebeb;
  border-radius: 50%;
  opacity: 0;
}

.pt-modern-features {
  color: #262f3c;
  margin-bottom: -10px;
  font-size: 14px;
}

.pt-modern-features > * {
  margin-bottom: 10px;
}

.pt-modern-features,
.pt-modern-features .icon {
  transition: 0.22s ease-in-out;
}

.pt-modern-features li {
  position: relative;
  padding-left: 24px;
}

.pt-modern-features .icon {
  position: absolute;
  left: 0;
  top: 0.2em;
  font-family: "Material Design Icons";
  color: #0accbe;
}

.pt-modern-features .icon::before {
  content: "\f222";
}

.pt-modern-features [aria-disabled="true"],
.pt-modern-features [aria-disabled="true"] .icon {
  color: #8491a5;
}

.pt-modern-features [aria-disabled="true"] .icon::before {
  content: "\f24c";
}

.pt-modern-features > li + li {
  margin-top: 0;
}

.pt-modern-price-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 15px;
}

.pt-modern-price {
  white-space: nowrap;
  font-family: "Samsung";
  font-size: 35px;
  line-height: 1;
  color: #262f3c;
}

.pt-modern-price > * {
  display: inline;
  vertical-align: top;
  line-height: 1;
  letter-spacing: 0;
}

.pt-modern-price > * + * {
  margin-left: 0.1em;
}

.pt-modern-price-value {
  font-weight: 100;
}

.pt-modern-price-currency,
.pt-modern-price-small {
  position: relative;
  top: 0.15em;
  font-weight: 400;
}

.pt-modern-price-currency {
  font-size: 0.56667em;
}

.pt-modern-price-small {
  font-size: 0.5em;
}

.pt-modern-price-caption {
  color: #262f3c;
}

.pt-modern-element .link {
  display: flex;
  justify-content: center;
  padding: 20px 15px;
  text-align: center;
}

.pt-modern-element .link:hover {
  color: #2c343b;
  background: rgba(249, 248, 248, 0.66);
}

.nav-link:not(.active) + .pt-modern-features,
.nav-link:not(.active) + .pt-modern-features .icon {
  color: #8491a5;
}

* + .pt-modern {
  margin-top: 35px;
}

* + .pt-modern-features {
  margin-top: 15px;
}

* + .pt-modern-price-caption {
  margin-top: 4px;
}

@media (max-width: 767.98px) {
  .pt-modern .nav-tabs-outer {
    position: relative;
  }
  .pt-modern .nav-tabs-outer::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 40px;
    right: 0;
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    z-index: 2;
    pointer-events: none;
  }
  .pt-modern .nav-tabs {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    max-width: 100%;
    width: 100%;
    overflow-x: auto;
    padding-bottom: 20px;
  }
  .pt-modern .nav-item {
    flex-shrink: 0;
    min-width: 200px;
    padding-right: 25px;
  }
  .pt-modern-price-outer {
    flex-direction: row;
  }
  * + .pt-modern-price-caption {
    margin-top: 0;
    margin-left: 30px;
  }
}

@media (max-width: 767.98px) and (min-width: 410px) {
  .pt-modern .tab-pane {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .pt-modern-price-outer {
    width: 50%;
    flex-direction: column;
  }
  .pt-modern-price-outer + .pt-modern-price-outer {
    border-left: 1px solid #ebebeb;
  }
  .pt-modern-element {
    width: 100%;
  }
  * + .pt-modern-price-caption {
    margin-top: 5px;
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .pt-modern {
    display: flex;
  }
  .pt-modern .nav-tabs-outer {
    width: 100%;
    flex-grow: 1;
  }
  .pt-modern .nav-tabs {
    display: flex;
    flex-wrap: wrap;
    padding: 40px 20px 50px 20px;
    margin-bottom: -20px;
  }
  .pt-modern .nav-tabs > * {
    margin-bottom: 20px;
  }
  .pt-modern .nav-item {
    width: 50%;
  }
  .pt-modern .nav-link {
    padding-left: 36px;
    font-size: 18px;
  }
  .pt-modern .tab-content {
    display: flex;
    width: 23.5%;
    border-left: 1px solid #ebebeb;
  }
  .pt-modern .tab-pane > * + * {
    margin-top: 0;
    border-top: 1px solid #ebebeb;
  }
  span.nav-item-indicator {
    width: 26px;
    height: 26px;
  }
  .pt-modern-price-currency {
    font-size: 0.36667em;
  }
  .pt-modern-price-small {
    font-size: 0.33333em;
  }
  .pt-modern-price-caption {
    font-size: 17px;
    line-height: 1.52941;
  }
  .pt-modern-element .link {
    padding: 30px 20px;
  }
  * + pt-modern-price-caption {
    margin-top: 12px;
  }
}

@media (min-width: 992px) {
  .pt-modern .nav-tabs {
    padding: 43px 20px 75px 28px;
  }
  .pt-modern .nav-item {
    padding: 15px;
  }
  .pt-modern .nav-link {
    padding-left: 40px;
    font-size: 22px;
  }
  span.nav-item-indicator {
    top: 5px;
  }
  .pt-modern-features {
    font-size: 15px;
  }
  .pt-modern-price {
    font-size: 60px;
  }
  .pt-modern-price-currency,
  .pt-modern-price-small {
    top: 0.45em;
  }
  .pt-modern-element .link {
    padding: 35px 20px;
  }
}

@media (min-width: 1200px) {
  .pt-modern .nav-tabs {
    margin-bottom: -30px;
  }
  .pt-modern .nav-tabs > * {
    margin-bottom: 30px;
  }
  .pt-modern .tab-content {
    width: 17.5%;
  }
  .pt-modern-features {
    columns: 2;
    break-inside: avoid;
    column-gap: 10px;
  }
  .pt-modern-features > * {
    display: inline-block;
    width: 100%;
  }
  * + .pt-modern {
    margin-top: 55px;
  }
  * + .pt-modern-features {
    margin-top: 27px;
  }
}

/*
*
* Frames
*/
.phone-frame-1 {
  position: relative;
  overflow: hidden;
}

.phone-frame-1_mod-1 {
  position: relative;
  display: none;
}

@media (min-width: 768px) {
  .phone-frame-1_mod-1 {
    display: block;
    margin: -50px -100px -80px -120px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .phone-frame-1_mod-1 {
    transform: rotate(-20deg);
  }
}

@media (min-width: 992px) {
  .phone-frame-1_mod-1 {
    margin: 0 -40px -80px -80px;
  }
}

@media (min-width: 1200px) {
  .phone-frame-1_mod-1 {
    top: -4px;
    left: -53px;
    margin: 0 -80px -70px 0;
  }
}

.phone-frame-2 {
  position: relative;
  max-width: 325px;
  pointer-events: none;
}

.phone-frame-2::before {
  content: "";
  position: absolute;
  top: 1%;
  right: 1%;
  bottom: 1%;
  left: 1%;
  z-index: -10;
  box-shadow: -21px 10px 32px 0px rgba(75, 81, 91, 0.11);
  border-radius: 10%;
}

.phone-frame-2-outer {
  position: relative;
  z-index: 1;
  width: 100%;
}

.phone-frame-2-inner {
  position: absolute;
  left: 6%;
  top: 2%;
  bottom: 3%;
  right: 6%;
  z-index: 0;
  background-position: center center;
  background-size: cover;
  border-radius: 15px;
  pointer-events: auto;
}

.slick-slider-phone-frame-2 {
  width: 100%;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  transform: translate3d(10%, 0, 0);
  border: 1px solid transparent;
  pointer-events: none;
}

.slick-slider-phone-frame-2,
.slick-slider-phone-frame-2 * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.slick-slider-phone-frame-2 .slick-list {
  padding: 70px;
  margin: -70px;
  user-select: none;
}

.slick-slider-phone-frame-2 .slick-track {
  display: flex;
  align-items: center;
}

.slick-slider-phone-frame-2 .slick-slide {
  float: none;
  height: auto;
  opacity: 0;
  visibility: hidden;
}

.slick-slider-phone-frame-2
  .slick-slide:not(.slick-current)
  .tooltip-point::before,
.slick-slider-phone-frame-2
  .slick-slide:not(.slick-current)
  .tooltip-point::after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: none;
}

.slick-slider-phone-frame-2 .slick-active {
  opacity: 0.55;
  visibility: visible;
  transition: 0.05s ease;
  cursor: pointer;
}

@media (min-width: 1200px) {
  .slick-slider-phone-frame-2 .slick-active {
    opacity: 1;
  }
}

.slick-slider-phone-frame-2 .slick-current {
  opacity: 1;
  z-index: 2;
  transition: 0.22s ease;
  cursor: default;
}

.slick-slider-phone-frame-2 .slick-current .phone-frame-2 {
  transition: 0.44s ease;
  transform: scale3d(1.14, 1.14, 1.14);
}

.slick-slider-phone-frame-2 .slick-current .tooltip-point {
  pointer-events: auto;
}

.slick-slider-phone-frame-2 .slick-active + .slick-active {
  transform: translate3d(-47%, 0, 0);
}

.slick-slider-phone-frame-2 .phone-frame-2 {
  pointer-events: auto;
  transition: 0.22s ease;
}

@media (min-width: 768px) {
  .slick-slider-phone-frame-2 {
    max-width: 460px;
  }
}

@media (min-width: 992px) {
  .slick-slider-phone-frame-2 {
    margin: 0;
    transform: translate3d(0, 0, 0);
  }
}

.slick-slider-phone-frame-2.slick-style-1 {
  position: relative;
  padding: 35px 0 20px;
}

@media (min-width: 768px) {
  .slick-slider-phone-frame-2.slick-style-1 {
    padding: 35px 20px 0 0;
    width: auto;
    right: 30px;
    margin-right: -90px;
  }
}

@media (min-width: 992px) {
  .slick-slider-phone-frame-2.slick-style-1 {
    right: -30px;
    margin-right: 0;
    margin-left: 0;
  }
}

@media (min-width: 1200px) {
  .slick-slider-phone-frame-2.slick-style-1 {
    right: -40px;
  }
}

.phone-frame-3 {
  position: relative;
  overflow: hidden;
  z-index: 0;
  width: 100%;
  max-width: 238px;
}

.phone-frame-3::before {
  content: "";
  background: #ffffff;
}

.phone-frame-3-outer {
  display: block;
  width: 100%;
}

.phone-frame-3::before,
.phone-frame-3-inner {
  position: absolute;
  top: 2.5%;
  right: 5.2%;
  bottom: 4%;
  left: 5.5%;
  z-index: 1;
  border-radius: 15px;
  background-position: center center;
  background-size: cover;
}

.iphone-frame-4 {
  position: relative;
  max-width: 320px;
  min-width: 200px;
  flex-shrink: 0;
}

.iphone-frame-4 .owl-carousel {
  position: absolute;
  overflow: hidden;
  top: 4%;
  left: 19.7%;
  right: 6%;
  bottom: 9%;
  width: auto;
  border-radius: 10px;
  background: #ffffff;
}

.iphone-frame-4 .owl-carousel .owl-stage-outer,
.iphone-frame-4 .owl-carousel .owl-stage-outer * {
  height: 100%;
}

.iphone-frame-4 .owl-carousel .owl-stage {
  display: flex;
}

.iphone-frame-4 .owl-carousel .item {
  display: flex;
  background-position: center top;
  background-size: cover;
}

.iphone-frame-4-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
}

.iphone-frame-4-group,
.iphone-frame-4-group * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.iphone-frame-4-group > * {
  pointer-events: auto;
}

.iphone-frame-4-group > * + * {
  margin-top: 10px;
  margin-left: -15px;
}

.iphone-frame-4-outer {
  position: relative;
  max-width: 405px;
}

.iphone-frame-4-inner {
  position: relative;
  z-index: 2;
  pointer-events: none;
}

.iphone-frame-4-next {
  position: relative;
  z-index: 5;
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  transition: 0.25s;
  left: 32px;
}

@media (min-width: 576px) {
  .iphone-frame-4-next {
    left: 26px;
  }
}

@media (min-width: 768px) {
  .iphone-frame-4-next {
    left: 0;
  }
}

.iphone-frame-4-next .icon {
  color: #0accbe;
}

.iphone-frame-4-next:hover .icon {
  color: #ffffff;
}

.iphone-frame-4-next > * + * {
  margin-top: -4px;
  margin-left: 12px;
}

h5.iphone-frame-4-next-text {
  font-weight: 600;
}

@media (max-width: 767.98px) {
  .iphone-frame-4-next .icon {
    opacity: 0.7;
  }
}

@media (min-width: 768px) {
  .iphone-frame-4-group {
    flex-direction: row;
    align-items: flex-start;
  }
  .iphone-frame-4-group > * + * {
    margin-left: 25px;
    margin-top: 30px;
  }
  .iphone-frame-4-next {
    transform-origin: 0 50%;
    transform: rotate(90deg);
  }
  .iphone-frame-4-next .icon {
    color: #0accbe;
    transform: rotate(-90deg);
  }
  h5.iphone-frame-4-next-text {
    color: #262f3c;
  }
}

@media (min-width: 768px) {
  .iphone-frame-4-group > * + * {
    margin-left: 15px;
    margin-top: 70px;
  }
}

@media (min-width: 992px) {
  .iphone-frame-4-group > * + * {
    margin-left: 35px;
    margin-top: 70px;
  }
}

@media (min-width: 1400px) {
  .iphone-frame-4 {
    max-width: 405px;
  }
}

.safari-frame-1 {
  position: relative;
  z-index: 0;
  width: 100%;
  padding: 9% 20px 0;
}

.safari-frame-1-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to bottom, #00cab8 0%, #44d1e8 100%);
}

.safari-frame-1-bg::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* background: url(../images/dot-pattern.png) repeat; */
  opacity: 0.22;
}

.safari-frame-1-outer {
  position: relative;
  z-index: 2;
  max-width: 891px;
  border-radius: 3px 3px 0 0;
}

.safari-frame-1-outer::before {
  content: "";
  position: absolute;
  bottom: -1.8%;
  width: 138%;
  left: -33%;
  height: 15%;
  /* background: url(../images/safari-frame-1-1236x81.png) no-repeat; */
  background-size: cover;
  pointer-events: none;
}

.safari-frame-1-header {
  display: block;
  width: 100%;
}

.safari-frame-1-inner {
  position: relative;
  z-index: 1;
  background-position: center center;
  background-size: cover;
  width: 100%;
  padding-bottom: 55.21886%;
}

@media (max-width: 575.98px) {
  .safari-frame-1-outer {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 768px) {
  .safari-frame-1 {
    padding: 8.5% 0 0 10%;
    margin-bottom: 8%;
  }
  .safari-frame-1-bg {
    bottom: 17%;
  }
  .safari-frame-1-bg::before {
    top: 1%;
    left: 1%;
    bottom: 1.5%;
    right: 10%;
  }
  .safari-frame-1-outer {
    margin-right: -260px;
  }
  .safari-frame-1-header {
    border-radius: 8px 8px 0 0;
  }
  .safari-frame-1-inner {
    border-radius: 0 0 8px 8px;
  }
}

@media (min-width: 1200px) {
  .safari-frame-1-bg::before {
    right: 19%;
  }
}

@media (min-width: 1600px) {
  .safari-frame-1-outer {
    margin-right: -4%;
  }
}

.safari-frame-2 {
  position: relative;
  overflow: hidden;
  z-index: 0;
  max-width: 100%;
  width: 610px;
  border-radius: 4px;
  box-shadow: -3px 12px 24px 0px rgba(75, 81, 91, 0.11);
}

.safari-frame-2-header {
  display: block;
  width: 100%;
}

.safari-frame-2-inner {
  position: relative;
  z-index: 1;
  background-position: center center;
  background-size: cover;
  width: 100%;
  padding-bottom: 64.2623%;
}

@media (min-width: 768px) {
  .safari-frame-2 {
    border-radius: 8px;
  }
}

@media (min-width: 992px) {
  .safari-frame-2-right {
    margin-left: 20px;
  }
  .safari-frame-2-left {
    margin-right: 20px;
  }
}

.mac-frame-1 {
  position: relative;
  overflow: hidden;
  z-index: 0;
  width: 100%;
  max-width: 1100px;
}

.mac-frame-1::before {
  content: "";
  background: #ffffff;
}

.mac-frame-1-outer {
  display: block;
  width: 100%;
}

.mac-frame-1::before,
.mac-frame-1-inner {
  position: absolute;
  top: 6.4%;
  right: 13.5%;
  bottom: 12%;
  left: 13.8%;
  z-index: 1;
  background-position: center center;
  background-size: cover;
}

.mac-frame-1-inner {
  max-width: 797px;
  max-height: 503px;
}

.mac-frame-1-inner::before {
  content: "";
  display: block;
  padding-bottom: 63.11167%;
}

.frame-group-1 {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.frame-group-1 .mac-frame-1 + .phone-frame-3 {
  margin-left: -33.5%;
  margin-bottom: -1.6%;
}

.frame-group-1 .phone-frame-3 {
  max-width: 20.5%;
}

.frame-group-2 {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.frame-group-2 .phone-frame-3 + .mac-frame-1 {
  margin-left: -25%;
  margin-bottom: -6.5%;
}

.frame-group-2 .phone-frame-3 {
  position: relative;
  z-index: 2;
  max-width: 22%;
}

@media (min-width: 1600px) {
  .frame-group-2 {
    position: relative;
    right: -75px;
  }
}

.laptop-frame-1 {
  position: relative;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}

.laptop-frame-1::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -6.09%;
  left: -47.53%;
  right: -49.38%;
  bottom: -18.82%;
  /* background: url(../images/shadow-1.png); */
  background-size: cover;
  background-position: center center;
  pointer-events: none;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .laptop-frame-1 {
    margin-right: -80px;
    margin-left: -30px;
  }
}

@media (min-width: 992px) {
  .laptop-frame-1 {
    right: -20px;
  }
}

/*
* Page layout
*/
.page {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  /* opacity: 0; */
}

.page.fadeIn {
  animation-timing-function: ease-out;
}

.page.fadeOut {
  animation-timing-function: ease-in;
}

[data-x-mode] .page {
  opacity: 1;
}

.page-header-1 {
  position: relative;
  overflow: hidden;
}

.page-header-1 .rd-navbar-modern.rd-navbar-static {
  z-index: 1080;
}

.page-header-1 .rd-navbar-modern.rd-navbar-static .rd-navbar-element::after {
  opacity: 1;
  visibility: visible;
  transition: 0.22s;
}

.page-header-1
  .rd-navbar-modern.rd-navbar-static:not(.rd-navbar--is-stuck)
  .rd-navbar-element::after {
  opacity: 0;
  visibility: hidden;
}

.page-header-2 {
  position: relative;
  z-index: 100;
}

.page-header-2 .rd-navbar-modern.rd-navbar-static {
  background-color: transparent;
}

.page-header-2 .rd-navbar-modern.rd-navbar-static::after {
  bottom: 0;
}

.page-header-2 .rd-navbar-modern.rd-navbar-static .rd-navbar-element::after {
  display: none;
}

.page-header-2 .rd-navbar-modern.rd-navbar-static .rd-navbar-nav {
  text-align: center;
}

.page-header-2 .rd-navbar-modern.rd-navbar-static .rd-nav-link {
  color: #ffffff;
}

.page-header-2 .rd-navbar-modern.rd-navbar-static .rd-nav-link:hover {
  color: #ffea5c;
}

.page-header-2
  .rd-navbar-modern.rd-navbar-static
  .rd-nav-item.focus
  .rd-nav-link,
.page-header-2
  .rd-navbar-modern.rd-navbar-static
  .rd-nav-item.focus
  > .rd-navbar-submenu-toggle,
.page-header-2
  .rd-navbar-modern.rd-navbar-static
  .rd-nav-item.active
  .rd-nav-link,
.page-header-2
  .rd-navbar-modern.rd-navbar-static
  .rd-nav-item.active
  > .rd-navbar-submenu-toggle,
.page-header-2
  .rd-navbar-modern.rd-navbar-static
  .rd-nav-item.opened
  .rd-nav-link,
.page-header-2
  .rd-navbar-modern.rd-navbar-static
  .rd-nav-item.opened
  > .rd-navbar-submenu-toggle {
  color: #ffea5c;
}

.page-header-2 .rd-navbar-modern.rd-navbar-static.rd-navbar--is-stuck {
  background: #2c343b;
  border-bottom-color: #262f3c;
}

.page-header-2 .rd-navbar-modern.rd-navbar-static.rd-navbar--is-stuck::after {
  border-bottom-color: #262f3c;
}

.page-header-2 .rd-navbar-modern.rd-navbar-fixed .rd-navbar-panel {
  background: #2c343b;
  border-bottom-color: #000000;
}

.page-header-2 .rd-navbar-modern.rd-navbar-fixed .rd-navbar-element {
  background-color: transparent;
}

.page-header-2 .rd-navbar-modern.rd-navbar-fixed .rd-navbar-aside-open-toggle {
  opacity: 0.7;
}

.page-header-2-inner {
  padding: 40px 0;
}

.page-header-2-inner-aside {
  padding: 20px 0 0;
}

.page-header-2-inner-aside + h4,
.page-header-2-inner-aside + .heading-4 {
  margin-top: 20px;
}

@media (max-width: 767.98px) {
  .page-header-2-inner {
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 768px) {
  .page-header-2-inner {
    padding-bottom: 60px;
  }
  .page-header-2-inner-aside + h4,
  .page-header-2-inner-aside + .heading-4 {
    margin-top: 35px;
  }
}

@media (min-width: 1200px) {
  .page-header-2-inner {
    padding: 40px 0 100px;
  }
}

.page-header-3 {
  position: relative;
  z-index: 1080;
}

@media (min-width: 1600px) {
  .page-header-3 {
    margin-left: 5.25%;
  }
}

.page-header-3 .rd-navbar-wrap {
  position: relative;
  z-index: 10;
}

.page-header-3 .rd-navbar-classic.rd-navbar-static {
  border-bottom-color: #ebebeb;
}

.page-header-3 .rd-navbar-classic.rd-navbar-static .rd-navbar-panel {
  position: relative;
  z-index: 2;
  overflow: hidden;
  justify-content: flex-start;
  border: 0;
  background: transparent;
}

.page-header-3 .rd-navbar-classic.rd-navbar-static .brand-logo-dark {
  display: none;
}

.page-header-3 .rd-navbar-classic.rd-navbar-static .brand-logo-light {
  display: block;
}

.page-header-3 .rd-navbar-classic.rd-navbar-static .rd-navbar-slogan {
  z-index: 1;
  margin-left: -190px;
  background-color: transparent;
  display: none;
}

@media screen and (min-width: 1654px) {
  .page-header-3 .rd-navbar-classic.rd-navbar-static .rd-navbar-slogan {
    display: flex;
  }
}

.page-header-3 .rd-navbar-classic.rd-navbar-static p.rd-navbar-slogan {
  margin-left: 1px;
}

.page-header-3
  .rd-navbar-classic.rd-navbar-static
  .rd-navbar-link-1:not(:hover) {
  background-color: transparent;
}

.page-header-3
  .rd-navbar-classic.rd-navbar-static.rd-navbar--is-stuck
  .rd-navbar-slogan {
  visibility: visible !important;
}

.rd-navbar-static-linked .page-header-3 .box-custom-4-aside-decoration {
  top: -100px;
}

.header-section .box-custom-4-aside-content {
  margin-left: 0;
  padding-right: 0px !important;
}

.header-section .box-custom-4-aside .iphone-frame-4-group {
  transform: none;
  margin: 0;
}

@media (min-width: 576px) {
  .header-section .box-custom-4-aside {
    width: 50%;
  }
}

.section-gray {
  position: relative;
  z-index: 2;
}

.section-gray > * {
  z-index: 1;
}

.section-gray:before {
  content: "";
  position: absolute;
  width: 100%;
  height: calc(100% + 76px);
  left: -6.25%;
  top: -76px;
  display: block;
  z-index: -1;
  background-color: #f9f8f8;
}

.section-gray-2 {
  z-index: 2;
}

.section-gray-2 > * {
  z-index: 1;
}

.section-gray-2:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  display: block;
  z-index: -1;
  background-color: #f9f8f8;
}

@media (min-width: 1600px) {
  .header-offset {
    margin-bottom: 2%;
  }
}

/*
* Text styling
*/
.text-italic {
  font-style: italic;
}

.text-normal {
  font-style: normal;
}

.text-underline {
  text-decoration: underline;
}

.text-strike {
  text-decoration: line-through;
}

.font-weight-thin {
  font-weight: 100;
}

.extra-light {
  font-weight: 200;
}

.extra-lighter {
  font-weight: 300;
}

.font-weight-regular {
  font-weight: 400;
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-sbold {
  font-weight: 600;
}

.font-weight-bold {
  font-weight: 700;
}

.font-weight-extra-black {
  font-weight: 800;
}

.font-weight-ubold {
  font-weight: 900;
}

.ls-001 {
  letter-spacing: 0.01em;
}

.text-default {
  color: #8491a5;
}

.text-primary {
  color: #0accbe;
}

.text-secondary {
  color: #5f67df;
}

.text-white {
  color: #ffffff;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5);
}

.text-black {
  color: #000000;
}

.text-transparent {
  color: transparent;
}

.text-color-2 {
  color: #e0a755;
}

.text-color-3 {
  color: #2e71cc;
}

.text-color-6 {
  color: #83b4f7;
}

.text-color-7 {
  color: #7389a9;
}

a.text-primary:hover {
  color: #5f67df;
}

.text-gray-900 {
  color: #262f3c;
}

.context-dark .text-primary,
.bg-gray-900 .text-primary,
.bg-accent .text-primary {
  color: #ffea5c;
}

/*
* Offsets
*/
* + p {
  margin-top: 15px;
}

* + h2,
* + .heading-2 {
  margin-top: 13px;
}

* + h4,
* + .heading-4 {
  margin-top: 7px;
}

h2 + p,
.heading-2 + p {
  margin-top: 12px;
}

h4 + p,
.heading-4 + p {
  margin-top: 10px;
}

p + h2,
p + .heading-2 {
  margin-top: 15px;
}

p + p {
  margin-top: 12px;
}

img + p {
  margin-top: 15px;
}

p + hr {
  margin-top: 20px;
}

h3 + img {
  margin-top: 42px;
}

* + .row {
  margin-top: 30px;
}

* + .big {
  margin-top: 20px;
}

* + .text-block {
  margin-top: 30px;
}

* + .button {
  margin-top: 30px;
}

* + .list-md {
  margin-top: 20px;
}

* + .hr.container {
  margin-top: 0;
}

.brand + p {
  margin-top: 14px;
}

p + .button {
  margin-top: 20px;
}

.big + hr {
  margin-top: 10px;
}

.row + h3,
.row + .heading-3 {
  margin-top: 80px;
}

.badge + h3,
.badge + .heading-3 {
  margin-top: 10px;
}

h3 + .rd-form,
.heading-3 + .rd-form {
  margin-top: 25px;
}

h3 + .big,
.heading-3 + .big {
  margin-top: 15px;
}

h4 + .big,
.heading-4 + .big {
  margin-top: 10px;
}

h3 + p,
.heading-3 + p {
  margin-top: 17px;
}

h2 + h4,
h2 + .heading-4,
.heading-2 + h4,
.heading-2 + .heading-4 {
  margin-top: 12px;
}

h2 + h5,
h2 + .heading-5,
.heading-2 + h5,
.heading-2 + .heading-5 {
  margin-top: 12px;
}

h3 + h4,
h3 + .heading-4,
.heading-3 + h4,
.heading-3 + .heading-4 {
  margin-top: 12px;
}

h4 + h3,
h4 + .heading-3,
.heading-4 + h3,
.heading-4 + .heading-3 {
  margin-top: 10px;
}

h3 + .owl-carousel,
h3 + .owl-carousel {
  margin-top: 30px;
}

.container + .container {
  margin-top: 50px;
}

.row + .row {
  margin-top: 35px;
}

.big + .row {
  margin-top: 30px;
}

.text-extra-large + h3,
.text-extra-large + .heading-3 {
  margin-top: 15px;
}

.big + .quote-light {
  margin-top: 25px;
}

.big + .list-marked {
  margin-top: 25px;
}

.heading-alternate-1 + .big {
  margin-top: 10px;
}

* + .ot0 {
  margin-top: 0;
}

* + .offset-top-1 {
  margin-top: 35px;
}

* + .offset-top-2 {
  margin-top: 40px;
}

* + .offset-top-4 {
  margin-top: 28px;
}

* + .offset-top-6 {
  margin-top: 28px;
}

* + .offset-top-7 {
  margin-top: 15px;
}

.page * + .offset-top-3 {
  margin-top: 18px;
}

.page * + .offset-top-5 {
  margin-top: 18px;
}

html .page * + .ot30 {
  margin-top: 30px;
}

html .page * + .ot50 {
  margin-top: 50px;
}

@media (min-width: 768px) {
  p + hr {
    margin-top: 30px;
  }
  * + .row {
    margin-top: 40px;
  }
  * + .button {
    margin-top: 35px;
  }
  * + .list-md {
    margin-top: 30px;
  }
  .container + .container {
    margin-top: 50px;
  }
  .big + .row {
    margin-top: 40px;
  }
  .big + .group {
    margin-top: 30px;
  }
  .text-extra-large + h3,
  .text-extra-large + .heading-3 {
    margin-top: 5px;
  }
  h3 + .owl-carousel,
  h3 + .owl-carousel {
    margin-top: 50px;
  }
  h2 + h4,
  h2 + .heading-4,
  .heading-2 + h4,
  .heading-2 + .heading-4 {
    margin-top: 20px;
  }
  h3 + h4,
  h3 + .heading-4,
  .heading-3 + h4,
  .heading-3 + .heading-4 {
    margin-top: 16px;
  }
  h4 + h3,
  h4 + .heading-3,
  .heading-4 + h3,
  .heading-4 + .heading-3 {
    margin-top: 16px;
  }
  * + .offset-top-1 {
    margin-top: 55px;
  }
  * + .offset-top-2 {
    margin-top: 45px;
  }
  * + .offset-top-6 {
    margin-top: 45px;
  }
  .page * + .offset-top-3 {
    margin-top: 25px;
  }
  .page * + .offset-top-5 {
    margin-top: 25px;
  }
}

@media (min-width: 992px) {
  .badge + h3,
  .badge + .heading-3 {
    margin-top: 15px;
  }
  .big + .list-marked {
    margin-top: 30px;
  }
  .container + .container {
    margin-top: 65px;
  }
}

@media (min-width: 1200px) {
  * + .row {
    margin-top: 250px;
  }
  * + .rd-form {
    margin-top: 30px;
  }
  .brand + p {
    margin-top: 22px;
  }
  p + .button {
    margin-top: 30px;
  }
  .big + .quote-light {
    margin-top: 35px;
  }
  .container + .container {
    margin-top: 85px;
  }
  .row + .row {
    margin-top: 60px;
  }
  h3 + .rd-form,
  .heading-3 + .rd-form {
    margin-top: 45px;
  }
  h2 + p,
  .heading-2 + p {
    margin-top: 30px;
  }
  h3 + p,
  .heading-3 + p {
    margin-top: 30px;
  }
  h2 + h4,
  h2 + .heading-4,
  .heading-2 + h4,
  .heading-2 + .heading-4 {
    margin-top: 30px;
  }
  * + .offset-top-1 {
    margin-top: 70px;
  }
  * + .offset-top-2 {
    margin-top: 60px;
  }
}

@media (min-width: 1600px) {
  h3 + .rd-form,
  .heading-3 + .rd-form {
    margin-top: 60px;
  }
  .big + .group {
    margin-top: 50px;
  }
}

.row-0 {
  margin-bottom: 0px;
}

.row-0:empty {
  margin-bottom: 0;
}

.row-0 > * {
  margin-bottom: 0px;
}

.row-10 {
  margin-bottom: -10px;
}

.row-10:empty {
  margin-bottom: 0;
}

.row-10 > * {
  margin-bottom: 10px;
}

.row-20 {
  margin-bottom: -20px;
}

.row-20:empty {
  margin-bottom: 0;
}

.row-20 > * {
  margin-bottom: 20px;
}

.row-30 {
  margin-bottom: -30px;
}

.row-30:empty {
  margin-bottom: 0;
}

.row-30 > * {
  margin-bottom: 30px;
}

.row-50 {
  margin-bottom: -50px;
}

.row-50:empty {
  margin-bottom: 0;
}

.row-50 > * {
  margin-bottom: 50px;
}

@media (min-width: 576px) {
  .row-sm-35 {
    margin-bottom: -35px;
  }
  .row-sm-35:empty {
    margin-bottom: 0;
  }
  .row-sm-35 > * {
    margin-bottom: 35px;
  }
}

@media (min-width: 768px) {
  .row-md-30 {
    margin-bottom: -30px;
  }
  .row-md-30:empty {
    margin-bottom: 0;
  }
  .row-md-30 > * {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) {
  .row-md-50 {
    margin-bottom: -50px;
  }
  .row-md-50:empty {
    margin-bottom: 0;
  }
  .row-md-50 > * {
    margin-bottom: 50px;
  }
}

@media (min-width: 992px) {
  .row-lg-50 {
    margin-bottom: -50px;
  }
  .row-lg-50:empty {
    margin-bottom: 0;
  }
  .row-lg-50 > * {
    margin-bottom: 50px;
  }
}

@media (min-width: 1200px) {
  .row-xl-40 {
    margin-bottom: -40px;
  }
  .row-xl-40:empty {
    margin-bottom: 0;
  }
  .row-xl-40 > * {
    margin-bottom: 40px;
  }
  .row-xl-70 {
    margin-bottom: -70px;
  }
  .row-xl-70:empty {
    margin-bottom: 0;
  }
  .row-xl-70 > * {
    margin-bottom: 70px;
  }
  .row-xl-60 {
    margin-bottom: -60px;
  }
  .row-xl-60:empty {
    margin-bottom: 0;
  }
  .row-xl-60 > * {
    margin-bottom: 60px;
  }
}

/*
*
* Insets
*/
@media (min-width: 992px) {
  .inset-1 {
    padding-left: 20px;
  }
}

@media (min-width: 1200px) {
  .inset-1 {
    padding-left: 45px;
  }
}

/*
* Sections
*/
.section-xs {
  padding: 25px 0;
}

.section-sm,
.section-md,
.section-lg,
.section-xl {
  padding: 50px 0;
}

.section-1 {
  padding: 15px 0 30px;
}

.section-2 {
  padding: 35px 0 50px;
}

.section-collapse + .section-collapse {
  padding-top: 0;
}

.section-collapse:last-child {
  padding-bottom: 0;
}

html [class*="section-"].section-top-0 {
  padding-top: 0;
}

html [class*="section-"].section-bottom-0 {
  padding-bottom: 0;
}

.section-md-last {
  padding-top: 70px;
}

@media (min-width: 768px) {
  .section-md-last {
    padding-top: 55px;
  }
}

@media (min-width: 992px) {
  .section-md-last {
    padding-top: 95px;
  }
}

@media (min-width: 1200px) {
  .section-md-last {
    padding-top: 100px;
  }
}

@media (max-width: 991.98px) {
  section.section-sm:first-of-type,
  section.section-md:first-of-type,
  section.section-lg:first-of-type,
  section.section-xl:first-of-type,
  section.section-xxl:first-of-type {
    padding-top: 40px;
  }
}

@media (min-width: 768px) {
  .section-sm {
    padding: 55px 0;
  }
  .section-md {
    padding: 70px 0;
  }
  .section-lg {
    padding: 85px 0;
  }
  .section-1 {
    padding: 60px 0 70px;
  }
  .section-2 {
    padding: 55px 0 75px;
  }
}

@media (min-width: 992px) {
  .section-sm {
    padding: 60px 0 65px;
  }
  .section-md {
    padding: 85px 0 95px;
  }
  .section-lg {
    padding: 100px 0;
  }
}

@media (min-width: 1200px) {
  .section-lg {
    padding: 100px 0 110px;
  }
  .section-1 {
    padding: 80px 0;
  }
}

.section-single {
  display: flex;
  text-align: center;
}

.section-single p {
  margin-left: auto;
  margin-right: auto;
}

.section-single * + .rights {
  margin-top: 35px;
}

.section-single * + .countdown-wrap {
  margin-top: 35px;
}

.section-single .countdown-wrap + * {
  margin-top: 35px;
}

@media (min-width: 992px) {
  .section-single * + .rights {
    margin-top: 60px;
  }
  .section-single .countdown-wrap + * {
    margin-top: 35px;
  }
}

.section-single .rd-mailform-wrap {
  max-width: 670px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.section-single * + .rd-mailform-wrap {
  margin-top: 20px;
}

.section-single-header {
  padding: calc(1em + 3vh) 0 calc(1em + 2vh);
}

.section-single-main {
  padding: calc(1em + 4vh) 0;
}

.section-single-footer {
  padding: calc(1em + 2vh) 0 calc(1em + 3vh);
}

.section-single-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 100vh;
}

.section-single-inner > * {
  width: 100%;
}

@supports (display: grid) {
  .section-single-inner {
    display: grid;
    justify-items: center;
    align-content: space-between;
    grid-template-columns: 1fr;
  }
}

.section-layout-1 {
  position: relative;
  display: flex;
}

.section-layout-1 .rights {
  color: #262f3c;
}

.section-layout-1-image {
  display: none;
  background-repeat: no-repeat;
}

.section-layout-1-inner {
  position: relative;
  z-index: 1;
  display: flex;
  min-height: 100vh;
  flex-grow: 1;
}

.section-layout-1-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.section-layout-1-header,
.section-layout-1-footer {
  text-align: center;
}

.section-layout-1-header,
.section-layout-1-main,
.section-layout-1-footer {
  padding: 30px 0;
}

@media (min-width: 768px) {
  .section-layout-1-content {
    max-width: 740px;
  }
  .section-layout-1-header {
    padding-top: 60px;
  }
  .section-layout-1-footer {
    padding-bottom: 60px;
  }
}

@media (min-width: 1200px) {
  .section-layout-1-image {
    position: relative;
    display: block;
    flex-basis: 43.69792%;
    flex-shrink: 10;
    background-size: cover;
    background-position: 40% 0;
  }
  .section-layout-1-image::after {
    display: none;
  }
  .section-layout-1-inner {
    min-width: 56.30208%;
    flex-shrink: 0;
    padding: 0 60px;
  }
  .section-layout-1-content {
    margin-left: 0;
  }
  .section-layout-1-header,
  .section-layout-1-footer {
    text-align: left;
  }
}

@media (min-width: 1600px) {
  .section-layout-1-image {
    background-position: 48% 100px;
  }
  .section-layout-1-inner {
    padding-left: 130px;
  }
}

@media (min-width: 1200px) and (min-height: 820px) {
  .section-layout-1-header {
    padding-top: 90px;
    padding-bottom: 40px;
  }
  .section-layout-1-footer {
    padding-top: 40px;
    padding-bottom: 90px;
  }
}

.section-layout-2 {
  position: relative;
  display: flex;
}

.section-layout-2 .rights {
  color: #262f3c;
}

.section-layout-2-figure {
  display: none;
}

.section-layout-2-inner {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 100vh;
  width: 100%;
}

.section-layout-2-header,
.section-layout-2-main,
.section-layout-2-footer {
  padding: 30px 0;
  text-align: center;
}

.section-layout-2-header,
.section-layout-2-footer {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  .section-layout-2-content {
    max-width: 640px;
  }
  .section-layout-2-header {
    padding-top: 60px;
  }
  .section-layout-2-footer {
    padding-bottom: 60px;
  }
}

@media (min-width: 1200px) {
  .section-layout-2-main {
    display: flex;
    align-items: center;
    flex-grow: 1;
    text-align: left;
  }
  .section-layout-2-figure {
    position: relative;
    display: block;
    flex-basis: 43.69792%;
    align-self: stretch;
  }
  .section-layout-2-figure::before {
    content: "";
    display: block;
    padding-bottom: 71.61836%;
  }
  .section-layout-2-figure img {
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translate(-5%, -50%);
    min-height: 100%;
    min-width: 100%;
    width: auto;
    height: auto;
    max-width: none;
  }
  @supports (object-fit: cover) {
    .section-layout-2-figure img {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      transform: none;
      object-fit: cover;
      object-position: 100% center;
    }
  }
  .section-layout-2-content {
    min-width: 56.30208%;
    flex-shrink: 0;
    max-width: 740px;
    padding: 30px 70px 70px;
    margin-left: 0;
  }
}

@media (min-width: 1600px) {
  .section-layout-2-content {
    padding-left: 140px;
    padding-bottom: 80px;
  }
}

@media (min-width: 1200px) and (min-height: 820px) {
  .section-layout-2-header {
    padding-top: 60px;
    padding-bottom: 50px;
  }
  .section-layout-2-footer {
    padding-top: 50px;
    padding-bottom: 60px;
  }
}

.section-layout-3-outer {
  display: flex;
  flex-direction: column;
}

.section-layout-3 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.section-layout-3 > * {
  width: 100%;
}

.section-layout-3::after {
  content: "";
  align-content: space-between;
  opacity: 0;
  visibility: hidden;
}

.section-decoration-1 {
  position: relative;
  overflow: hidden;
}

.section-decoration-1::before {
  position: absolute;
  top: 45%;
  left: 70%;
  transform: translate3d(-50%, -55%, 0);
  display: block;
  content: "\e996";
  font-family: "Linearicons";
  font-size: 54vmax;
  vertical-align: middle;
  line-height: 0;
  color: #f3f3f3;
  user-select: none;
  opacity: 0.5;
}

.section-decoration-1 > * {
  position: relative;
  z-index: 1;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .section-decoration-1::before {
    font-size: 100vmax;
  }
}

@media (min-width: 992px) {
  .section-decoration-1::before {
    top: 55%;
    left: 70%;
    transform: translate3d(-50%, -35%, 0);
    font-size: 90vmax;
  }
}

@media (min-width: 1600px) {
  .section-decoration-1::before {
    font-size: 54vmax;
  }
}

.section + .section-decoration-1,
.section-decoration-1 + .section {
  border-top: 1px solid #ebebeb;
}

.section-decoration-2 {
  position: relative;
  overflow: hidden;
  background: linear-gradient(to bottom, #f8f8f8, #ffffff);
}

.section-decoration-2 > * {
  position: relative;
  z-index: 1;
}

.section-decoration-2-element {
  position: absolute;
  z-index: 0;
  top: 25%;
  left: 53%;
  transform: translate3d(-50%, -55%, 0);
  display: block;
  font-family: "Linearicons";
  font-size: 45vmax;
  vertical-align: middle;
  line-height: 1;
  color: #f3f3f3;
  user-select: none;
}

.section-decoration-2-element::before {
  content: "\e996";
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .section-decoration-2-element {
    font-size: 100vmax;
  }
}

@media (min-width: 768px) {
  .section-decoration-2-element {
    top: 24%;
    left: 0;
    transform: translate3d(-50%, -44%, 0);
    font-size: 80vmax;
  }
}

@media (min-width: 992px) {
  .section-decoration-2-element {
    font-size: 70vmax;
  }
}

@media (min-width: 1600px) {
  .section-decoration-2-element {
    font-size: 48vmax;
    left: 0;
    transform: translate3d(-42.5%, -44%, 0);
  }
}

.section-decoration-3 {
  position: relative;
  overflow: hidden;
  padding: 50px 0 0;
}

.section-decoration-3-text {
  margin-left: 0.24em;
  margin-bottom: -0.25em;
  font-size: 40px;
  line-height: 1;
  font-weight: 800;
  letter-spacing: 0.12em;
  color: rgba(132, 145, 165, 0.17);
  text-transform: uppercase;
  white-space: nowrap;
}

@media (min-width: 768px) {
  .section-decoration-3 {
    padding: 85px 0 0;
  }
  .section-decoration-3-text {
    font-size: 90px;
  }
}

@media (min-width: 992px) {
  .section-decoration-3-text {
    font-size: 100px;
    letter-spacing: 0.3em;
  }
  * + .section-decoration-3-text {
    margin-top: 50px;
  }
}

@media (min-width: 1200px) {
  .section-decoration-3-text {
    font-size: 115px;
    letter-spacing: 0.44em;
  }
}

.section-decoration-4 {
  position: relative;
  overflow: hidden;
  padding: 40px 0 100px;
  min-height: 30.20833vw;
}

html .section-decoration-4 {
  background-color: transparent;
}

.section-decoration-4 > * {
  position: relative;
  z-index: 3;
}

.section-decoration-4::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  z-index: 2;
  background: linear-gradient(
    to top,
    rgba(134, 180, 204, 0.05) 0%,
    #4cadd5 100%
  );
}

.section-decoration-4-decoration {
  position: absolute;
  left: -10%;
  bottom: -24%;
  right: -10%;
  z-index: 1;
  opacity: 0.8;
  width: auto;
  height: auto;
  pointer-events: none;
}

.section-decoration-4-decoration img {
  width: 100%;
}

@media (max-width: 767.98px) {
  .section-decoration-4-decoration {
    min-width: 1000px;
  }
}

@media (min-width: 1200px) {
  .section-decoration-4 {
    padding: 100px 0 240px;
  }
}

@media (min-width: 2050px) {
  .section-decoration-4 {
    min-height: 580px;
  }
}

.section-group-top {
  padding: 50px 0 40px;
}

.section-group-bottom {
  padding: 10px 0 50px;
}

.section-group-bottom-content {
  position: relative;
  z-index: 1;
}

@media (min-width: 576px) {
  .section-group-top {
    padding-bottom: 80px;
  }
  .section-group-top + .section-group-bottom {
    padding-top: 0;
    margin-top: -40px;
  }
}

@media (min-width: 768px) {
  .section-group-top {
    padding: 80px 0 130px;
  }
  .section-group-top + .section-group-bottom {
    margin-top: -90px;
  }
}

@media (min-width: 1200px) {
  .section-group-top {
    padding: 100px 0 190px;
  }
  .section-group-bottom {
    padding: 80px 0 100px;
  }
  .section-group-top + .section-group-bottom {
    margin-top: -140px;
  }
}

/*
* Grid modules
*/
.grid-demo {
  letter-spacing: 0;
  text-align: center;
}

.grid-demo h6 {
  font-weight: 600;
}

.grid-demo p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.grid-demo * + p {
  margin-top: 5px;
}

.grid-demo * + .row {
  margin-top: 20px;
}

.grid-demo .row + .row {
  margin-top: 0;
}

@media (min-width: 1200px) {
  .grid-demo {
    text-align: left;
  }
  .grid-demo p {
    white-space: normal;
    overflow: visible;
    text-overflow: unset;
  }
  .grid-demo * + p {
    margin-top: 25px;
  }
}

.grid-demo-bordered .row {
  border: 0 solid #ebebeb;
  border-top-width: 1px;
}

.grid-demo-bordered .row:last-child {
  border-bottom-width: 1px;
}

.grid-demo-bordered [class*="col"] {
  padding: 5px 15px;
}

.grid-demo-bordered [class*="col"]:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: -1px;
  border-left: 1px solid #ebebeb;
}

@media (min-width: 768px) {
  .grid-demo-bordered [class*="col"] {
    padding: 30px;
  }
}

@media (min-width: 1200px) {
  .grid-demo-bordered [class*="col"] {
    padding: 50px 50px 45px;
  }
}

.grid-demo-underlined .row {
  border-bottom: 1px solid #ebebeb;
}

.grid-demo-underlined [class*="col"] {
  padding: 5px 15px;
}

@media (min-width: 768px) {
  .grid-demo-underlined [class*="col"] {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

@media (min-width: 1200px) {
  .grid-demo-underlined [class*="col"] {
    padding-top: 45px;
    padding-bottom: 45px;
  }
}

.row-x-16 {
  margin-left: -8px;
  margin-right: -8px;
}

.row-x-16 > [class*="col"] {
  padding-left: 8px;
  padding-right: 8px;
}

@media (min-width: 992px) {
  .row-lg-x-50 {
    margin-left: -25px;
    margin-right: -25px;
  }
  .row-lg-x-50 > [class*="col"] {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (min-width: 1200px) {
  .container-wide {
    max-width: 100%;
  }
}

@media (min-width: 1600px) {
  .container-wide {
    max-width: 1600px;
  }
}

.container-fullwidth {
  max-width: 2050px;
  margin-left: auto;
  margin-right: auto;
}

* + .container-fullwidth {
  margin-top: 25px;
}

@media (min-width: 768px) {
  * + .container-fullwidth {
    margin-top: 40px;
  }
}

@media (min-width: 1200px) {
  * + .container-fullwidth {
    margin-top: 50px;
  }
}

.range {
  display: flex;
  flex-wrap: wrap;
}

.cell-inner {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.range > [class*="cell"] {
  position: relative;
  width: 100%;
  min-height: 1px;
}

.range > [class*="cell"] > .row {
  margin: 0;
}

.range > [class*="cell"].container {
  padding: 0;
}

.range > [class*="col"] {
  padding: 0;
}

@media (min-width: 576px) {
  .cell-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .cell-sm-6 .cell-inner {
    max-width: 270px;
  }
  .range > [class*="cell-sm"]:nth-child(odd) .cell-inner {
    margin-left: auto;
  }
  .range.flex-sm-row-reverse > [class*="cell-sm"]:nth-child(odd) .cell-inner {
    margin-left: 0;
  }
  .range.flex-sm-row-reverse > [class*="cell-sm"]:nth-child(even) .cell-inner {
    margin-left: auto;
  }
  .range > [class*="cell-sm"].container {
    margin: 0;
  }
}

@media (min-width: 768px) {
  .range > [class*="cell-md"]:nth-child(odd) .cell-inner {
    margin-left: auto;
  }
  .range.flex-md-row-reverse > [class*="cell-md"]:nth-child(odd) .cell-inner {
    margin-left: 0;
  }
  .range.flex-md-row-reverse > [class*="cell-md"]:nth-child(even) .cell-inner {
    margin-left: auto;
  }
  .range > [class*="cell-md"].container {
    margin: 0;
  }
  .cell-md-5 {
    flex: 0 0 calc(50% - 60px);
    max-width: calc(50% - 60px);
  }
  .cell-md-5 .cell-inner {
    max-width: 300px;
  }
  .cell-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .cell-md-6 .cell-inner {
    max-width: 360px;
  }
}

@media (min-width: 992px) {
  [class*="cell-lg"] > .row > [class*="col"] {
    flex-basis: 100%;
  }
  .cell-lg-5 {
    flex: 0 0 calc(50% - 80px);
    max-width: calc(50% - 80px);
  }
  .cell-lg-5 .cell-inner {
    max-width: 400px;
  }
  .cell-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .cell-lg-6 .cell-inner {
    max-width: 480px;
  }
  .range > [class*="cell-lg"]:nth-child(odd) .cell-inner {
    margin-left: auto;
  }
  .range.flex-lg-row-reverse > [class*="cell-lg"]:nth-child(odd) .cell-inner {
    margin-left: 0;
  }
  .range.flex-lg-row-reverse > [class*="cell-lg"]:nth-child(even) .cell-inner {
    margin-left: auto;
  }
  .range > [class*="cell-lg"].container {
    margin: 0;
  }
}

@media (min-width: 1200px) {
  .range > [class*="cell-xl"].container {
    margin: 0;
  }
  .range > [class*="cell-xl"]:nth-child(odd) .cell-inner {
    margin-left: auto;
  }
  .range .cell-inner {
    flex-basis: 100%;
  }
  .cell-xl-7 {
    flex: 0 0 calc(50% - -100px);
    max-width: calc(50% - -100px);
  }
  .cell-xl-7 .cell-inner {
    max-width: 700px;
  }
  .cell-lg-6,
  .cell-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .cell-lg-6 .cell-inner,
  .cell-xl-6 .cell-inner {
    max-width: 600px;
  }
  .cell-xl-5 {
    flex: 0 0 calc(50% - 100px);
    max-width: calc(50% - 100px);
  }
  .cell-xl-5 .cell-inner {
    max-width: 500px;
  }
  .cell-xl-4 {
    flex: 0 0 calc(50% - 200px);
    max-width: calc(50% - 200px);
  }
  .cell-xl-4 .cell-inner {
    max-width: 400px;
  }
}

@media (min-width: 1600px) {
  .cell-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .cell-xxl-6 .cell-inner {
    max-width: 600px;
  }
}

/*
* Backgrounds
*/
.context-dark .brand .brand-logo-dark,
.bg-gray-900 .brand .brand-logo-dark,
.bg-accent .brand .brand-logo-dark {
  display: none;
}

.context-dark .brand .brand-logo-light,
.bg-gray-900 .brand .brand-logo-light,
.bg-accent .brand .brand-logo-light {
  display: block;
}

.context-dark .button-default,
.bg-gray-900 .button-default,
.bg-accent .button-default {
  color: #2c343b;
  background: #ffffff;
  border-color: #ffffff;
}

.context-dark .button-default:hover,
.bg-gray-900 .button-default:hover,
.bg-accent .button-default:hover {
  background: #ffea5c;
  border-color: #ffea5c;
}

.context-dark .heading-decoration-1::before,
.bg-gray-900 .heading-decoration-1::before,
.bg-accent .heading-decoration-1::before {
  background: rgba(255, 255, 255, 0.66);
}

.context-dark .select2-container--default .select2-selection--single,
.bg-gray-900 .select2-container--default .select2-selection--single,
.bg-accent .select2-container--default .select2-selection--single {
  border-color: #ffffff;
}

/*
* Light Backgrounds
*/
.bg-default {
  background: linear-gradient(0deg, #4a80f0 0%, #5c8cf1 100%);
}

.bg-white {
  background-color: #ffffff;
}

.bg-white:not([style*="background-"]) + .bg-white:not([style*="background-"]) {
  padding-top: 0;
}

.bg-gray-100 {
  background-color: #f9f8f8;
}

.bg-gray-100:not([style*="background-"])
  + .bg-gray-100:not([style*="background-"]) {
  padding-top: 0;
}

.bg-gray-100-color {
  background: #f9f8f8;
}

.bg-gray-3 {
  background-color: #fefefe;
}

.bg-gray-3:not([style*="background-"])
  + .bg-gray-3:not([style*="background-"]) {
  padding-top: 0;
}

/*
* Dark Backgrounds
*/
.bg-gray-900 {
  background-color: #262f3c;
}

.bg-gray-900:not([style*="background-"])
  + .bg-gray-900:not([style*="background-"]) {
  padding-top: 0;
}

/*
* Accent Backgrounds 
*/
.bg-accent {
  background: #0accbe;
}

.bg-accent .button-gray-700:hover {
  color: #3f4856;
  background: #ffffff;
  border-color: #ffffff;
}

.bg-accent .button-default-outline {
  color: #ffffff;
  border-color: rgba(71, 29, 29, 0.48);
}

.bg-accent .button-default-outline:hover {
  border-color: #5f67df;
}

.bg-accent .button-secondary:hover {
  color: #262f3c;
  background: #ffffff;
  border-color: #ffffff;
}

.bg-accent .button-color-8 {
  background: #89c5e6;
  border-color: #89c5e6;
}

.bg-accent .button-color-8:hover {
  background: #5f67df;
  border-color: #5f67df;
}

.bg-accent .rd-navbar-aside-open-toggle span {
  background: #ffffff;
}

/*
* Background Image
*/
[class*="bg-"] {
  background-size: cover;
  background-position: center center;
}

@media (min-width: 992px) {
  html:not(.tablet):not(.mobile) .bg-fixed {
    background-attachment: fixed;
  }
}

[class*="bg-overlay"] {
  position: relative;
}

[class*="bg-overlay"]::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 1;
}

[class*="bg-overlay"] > * {
  position: relative;
  z-index: 1;
}

.bg-overlay-1::before {
  opacity: 0.55;
  background-image: linear-gradient(31deg, #00a2b7 0%, #00d0cb 100%);
}

.bg-overlay-2::before {
  background: rgba(0, 0, 0, 0.12);
}

.bg-overlay-3::before {
  opacity: 0.77;
  background-image: linear-gradient(-90deg, #07ccbd 0%, #41d2e8 100%);
}

.bg-overlay-4::before {
  opacity: 0.78;
  background-image: linear-gradient(to top, white 0%, #08a5c1 100%);
}

.bg-overlay-5::before {
  opacity: 0.78;
  background-image: linear-gradient(to bottom, #13b6d2 0%, #1e88d2 100%);
}

@media (min-width: 1200px) {
  .left-pattern {
    position: relative;
    z-index: 2;
  }
  .left-pattern::before {
    content: "";
    position: absolute;
    z-index: -1 !important;
    right: 67%;
    top: 42%;
    height: 45%;
    width: 66%;
    background: radial-gradient(
      circle at 10px 7px,
      #e4e5e7 5px,
      transparent 5px
    );
    background-size: 30px 30px;
    background-position: 100%;
    background-repeat: repeat;
  }
}

@media (min-width: 1200px) {
  .right-pattern {
    position: relative;
    z-index: 2;
  }
  .right-pattern::before {
    content: "";
    position: absolute;
    z-index: -1 !important;
    left: 58%;
    top: 42%;
    height: 75%;
    width: 42%;
    background: radial-gradient(
      circle at 10px 7px,
      #e4e5e7 5px,
      transparent 5px
    );
    background-size: 30px 30px;
    background-position: 100%;
    background-repeat: repeat;
  }
}

@media (min-width: 1200px) {
  .center-pattern {
    position: relative;
    z-index: 2;
  }
  .center-pattern::before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 0%;
    top: 49%;
    height: 60%;
    width: 140%;
    background: radial-gradient(
      circle at 10px 7px,
      rgba(255, 255, 255, 0.1) 5px,
      transparent 5px
    );
    background-size: 30px 30px;
    background-position: 100%;
    background-repeat: repeat;
  }
}

@media (min-width: 1600px) {
  .center-pattern {
    position: relative;
  }
  .center-pattern::before {
    content: "";
    position: absolute;
    left: 0%;
    top: 49%;
    height: 32%;
    width: 136%;
    background: radial-gradient(
      circle at 10px 7px,
      rgba(255, 255, 255, 0.1) 5px,
      transparent 5px
    );
    background-size: 30px 30px;
    background-position: 100%;
    background-repeat: repeat;
  }
}

/*
* Utilities custom
*/
.height-fill {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.height-fill > * {
  flex-grow: 1;
  flex-shrink: 0;
}

.z-2 {
  position: relative;
  z-index: 2;
}

.object-inline {
  white-space: nowrap;
}

.object-inline > * {
  display: inline-block;
  min-width: 20px;
  vertical-align: top;
  white-space: normal;
}

.object-inline > * + * {
  margin-left: 5px;
}

html .oh {
  position: relative;
  overflow: hidden;
}

.text-decoration-lines {
  position: relative;
  overflow: hidden;
  width: 100%;
  text-align: center;
}

.text-decoration-lines-content {
  position: relative;
  display: inline-block;
  padding: 0 10px;
  min-width: 170px;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.text-decoration-lines-content::before,
.text-decoration-lines-content::after {
  content: "";
  position: absolute;
  height: 1px;
  background: #ebebeb;
  top: 50%;
  width: 100vw;
}

.text-decoration-lines-content::before {
  left: 0;
  transform: translate3d(-100%, 0, 0);
}

.text-decoration-lines-content::after {
  right: 0;
  transform: translate3d(100%, 0, 0);
}

* + .text-decoration-lines {
  margin-top: 25px;
}

p.rights {
  font-size: 15px;
  color: #8491a5;
}

p.rights a {
  color: inherit;
}

p.rights a:hover {
  color: #0accbe;
}

.text-block > * {
  margin-left: 0.125em;
  margin-right: 0.125em;
}

@media (min-width: 992px) {
  .inset-1 {
    padding-right: 30px;
  }
}

.map {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 720px;
  min-height: 280px;
  margin-left: auto;
  margin-right: auto;
}

.map-inner {
  position: relative;
}

.map-image {
  opacity: 0.14;
}

.map-marker {
  background: none;
  border: none;
  display: inline-block;
  padding: 0;
  outline: none;
  outline-offset: 0;
  cursor: pointer;
  -webkit-appearance: none;
  position: absolute;
  z-index: 2;
  width: 10px;
  height: 10px;
  background: #0accbe;
  cursor: pointer;
}

.map-marker::-moz-focus-inner {
  border: none;
  padding: 0;
}

.map-marker,
.map-marker::before {
  border-radius: 50%;
}

.map-marker::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  margin: -50% 0 0 -50%;
  z-index: 2;
  width: 200%;
  height: 200%;
  background: rgba(10, 204, 190, 0.3);
  transition: 0.33s;
  cursor: pointer;
}

.map-marker.active::before {
  transform: scale3d(0.9, 0.9, 0.9);
}

.map-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 3;
  width: 100%;
  max-width: 300px;
  backface-visibility: hidden;
  background: rgba(255, 255, 255, 0.97);
  border-radius: 9px;
  color: #8491a5;
  box-shadow: 0 2px 16px 0 rgba(75, 81, 91, 0.14);
  opacity: 0;
  visibility: hidden;
  transition: 0.22s;
}

.map-popup .owl-dots {
  text-align: left;
}

.map-popup .quote-minimal {
  padding: 0;
  background-color: transparent;
  box-shadow: none;
}

.map-popup.active {
  opacity: 1;
  visibility: visible;
}

.map-popup-toggle {
  position: absolute;
  top: 3px;
  right: 12px;
  font-size: 20px;
  color: #ebebeb;
  cursor: pointer;
  transition: 0.22s;
}

.map-popup-toggle:hover {
  color: #5f67df;
}

.map-popup-inner {
  padding-left: 20px;
  padding-right: 20px;
}

.map-popup-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 10px;
  padding-bottom: 10px;
}

.map-popup-header > * {
  margin-right: 15px;
}

.map-popup-header > * + * {
  margin-top: 0;
}

.map-popup-main {
  padding-top: 15px;
  padding-bottom: 30px;
}

.map-popup-location {
  font-family: "Samsung";
  font-size: 20px;
  line-height: 1.2;
  color: #0accbe;
}

.map-popup-meta {
  position: relative;
  top: 2px;
  font-weight: 300;
}

.context-dark .map-image,
.bg-gray-900 .map-image,
.bg-accent .map-image {
  opacity: 0.45;
}

.context-dark .map-marker,
.bg-gray-900 .map-marker,
.bg-accent .map-marker {
  background: #ffffff;
}

.context-dark .map-marker::before,
.bg-gray-900 .map-marker::before,
.bg-accent .map-marker::before {
  background: rgba(255, 255, 255, 0.3);
}

.map-popup-inner + .map-popup-inner {
  border-top: 1px solid #ebebeb;
}

@media (min-width: 576px) {
  .map-marker {
    width: 16px;
    height: 16px;
  }
  .map-popup {
    max-width: 370px;
  }
  .map-popup-inner {
    padding-left: 35px;
    padding-right: 35px;
  }
  .map-popup-header {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media (min-width: 992px) {
  .map-popup-meta {
    font-size: 17px;
    line-height: 1.52941;
  }
}

@media (min-width: 1200px) {
  .map-popup-toggle {
    display: none;
  }
}

@media (min-width: 1600px) {
  .map-popup-meta {
    top: 0;
  }
}

.layers-group {
  position: relative;
  top: 10px;
  right: 30px;
  display: flex;
}

.layers-group::after {
  content: "";
  display: block;
  width: 100%;
  padding-bottom: 59.87461%;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: -1000;
}

.layers-group-inner,
.layers-group::after {
  flex-shrink: 0;
  width: 100%;
}

.layers-group-inner {
  position: relative;
  display: flex;
  align-items: flex-end;
}

[class*="layers-group-layer"] {
  display: block;
}

.layers-group-layer-1 {
  width: 100%;
  max-width: 595px;
}

.layers-group-layer-2 {
  position: absolute;
  top: 7.4712%;
  right: 6.4%;
  bottom: 0;
  width: 81.34796%;
}

.layers-group-layer-3 {
  position: absolute;
  top: 5.2356%;
  right: 4.70219%;
  width: 72.57%;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .layers-group {
    margin-right: -50px;
    margin-left: -10px;
  }
}

/*
*
* Animate.css
*/
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  opacity: 1;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.hinge {
  animation-duration: 2s;
}

html:not(.lt-ie10) .not-animated {
  opacity: 0;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}

@-webkit-keyframes slideInDown {
  0% {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInDown {
  0% {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  animation-name: slideInDown;
}

@-webkit-keyframes slideInLeft {
  0% {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInLeft {
  0% {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
  0% {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInRight {
  0% {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  animation-name: slideInRight;
}

@-webkit-keyframes slideOutDown {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes slideOutDown {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
  }
}

.slideOutDown {
  animation-name: slideOutDown;
}

.fadeInRightSmall {
  animation-name: fadeInRightSmall;
}

@keyframes fadeInRightSmall {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInLeftSmall {
  animation-name: fadeInLeftSmall;
}

@keyframes fadeInLeftSmall {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInUpSmall {
  animation-name: fadeInUpSmall;
  transform-origin: 50% 100%;
}

@keyframes fadeInUpSmall {
  0% {
    opacity: 0;
    transform: translateY(60px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInDownSmall {
  animation-name: fadeInDownSmall;
  transform-origin: 50% 100%;
}

@keyframes fadeInDownSmall {
  0% {
    opacity: 0;
    transform: translateY(-60px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slideInUpSmall {
  animation-name: slideInUpSmall;
  transform-origin: 50% 100%;
}

@keyframes slideInUpSmall {
  0% {
    transform: translateY(60px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes scaleIn {
  0% {
    opacity: 0;
    transform: scale3d(0.8, 0.8, 0.8);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.scaleIn {
  animation-name: scaleIn;
}

@keyframes slideInLeftLg {
  0% {
    opacity: 0;
    transform: translate3d(-120%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeftLg {
  animation-name: slideInLeftLg;
}

@keyframes slideInRightLg {
  0% {
    opacity: 0;
    transform: translate3d(120%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.slideInRightLg {
  animation-name: slideInRightLg;
}

.clipInLeft {
  animation-name: fadeInLeftSmall;
}

.clipInRight {
  animation-name: fadeInRightSmall;
}

.clipInUp {
  animation-name: fadeInUpSmall;
}

.clipInDown {
  animation-name: fadeInDownSmall;
}

@supports (clip-path: polygon(0 0)) {
  @keyframes clipInLeft {
    0% {
      clip-path: polygon(-10% -10%, -10% -10%, -10% 110%, -10% 110%);
    }
    100% {
      clip-path: polygon(-10% -10%, 110% -10%, 110% 110%, -10% 110%);
    }
  }
  @keyframes clipInRight {
    0% {
      clip-path: polygon(110% -10%, 110% -10%, 110% 110%, 110% 110%);
    }
    100% {
      clip-path: polygon(-10% -10%, 110% -10%, 110% 110%, -10% 110%);
    }
  }
  @keyframes clipInUp {
    0% {
      clip-path: polygon(-10% 110%, -10% 110%, 110% 110%, 110% 110%);
    }
    100% {
      clip-path: polygon(-10% 110%, -10% -10%, 110% -10%, 110% 110%);
    }
  }
  @keyframes clipInDown {
    0% {
      clip-path: polygon(-10% -10%, -10% -10%, 110% -10%, 110% -10%);
    }
    100% {
      clip-path: polygon(-10% 110%, -10% -10%, 110% -10%, 110% 110%);
    }
  }
  .clipInLeft {
    animation-name: clipInLeft;
  }
  .clipInRight {
    animation-name: clipInRight;
  }
  .clipInUp {
    animation-name: clipInUp;
  }
  .clipInDown {
    animation-name: clipInDown;
  }
}

/*
*
* Preloader
*/
.preloader {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: #ffffff;
  transition: 0.1s;
}

.preloader.loaded {
  opacity: 0;
  visibility: hidden;
}

.preloader.loaded .object {
  animation: none;
}

#loading {
  background-color: #ffffff;
}

#loading-center-object {
  width: 200px;
  height: 200px;
  transform: rotate(-135deg);
}

.preloader-body {
  text-align: center;
}

.object {
  border-radius: 50%;
  position: absolute;
  border: 4px solid #0accbe;
  border-bottom-color: transparent;
  border-right-color: transparent;
  animation: animate 2s infinite;
}

#object_one {
  left: 75px;
  top: 75px;
  width: 50px;
  height: 50px;
}

#object_two {
  top: 65px;
  left: 65px;
  width: 70px;
  height: 70px;
  animation-delay: 0.2s;
}

#object_three {
  top: 55px;
  left: 55px;
  width: 90px;
  height: 90px;
  animation-delay: 0.4s;
}

#object_four {
  top: 45px;
  left: 45px;
  width: 110px;
  height: 110px;
  animation-delay: 0.6s;
}

@keyframes animate {
  50% {
    transform: rotate(360deg) scale(0.8);
  }
}

/*
*
* ToTop
*/
.ui-to-top {
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 100;
  width: 54px;
  height: 54px;
  font-size: 24px;
  line-height: 53px;
  color: #ffffff;
  background: linear-gradient(0deg, #4273d8 0%, #4a80f0 100%);
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  transition: 0.45s all ease-in-out;
  transform: translate3d(0, 100px, 0);
}

.ui-to-top:hover {
  color: #ffffff;
  background: #0accbe;
  text-decoration: none;
}

.ui-to-top:focus {
  color: #ffffff;
}

.ui-to-top.active {
  transform: translate3d(0, 0, 0);
}

html.mobile .ui-to-top,
html.tablet .ui-to-top {
  display: none !important;
}

@media (min-width: 576px) {
  .ui-to-top {
    right: 40px;
    bottom: 40px;
  }
}
/*
*
* RD Navbar
*/
@keyframes rd-navbar-slide-down {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes rd-navbar-slide-up {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

.rd-navbar-wrap,
.rd-navbar,
.rd-menu,
.rd-navbar-nav,
.rd-navbar-panel,
.rd-navbar-static .rd-menu,
.rd-navbar-fixed .rd-navbar-nav-wrap.active,
.rd-navbar-fixed .rd-navbar-dummy,
.rd-navbar-fixed .rd-navbar-submenu {
  transition: 0.8s all ease-in-out;
}

.rd-navbar--no-transition,
.rd-navbar--no-transition * {
  transition: none !important;
}

.rd-navbar,
.rd-navbar.rd-navbar--is-clone {
  display: none;
}

.rd-navbar.rd-navbar-fixed + .rd-navbar.rd-navbar--is-clone,
.rd-navbar.rd-navbar-sidebar + .rd-navbar.rd-navbar--is-clone {
  display: none;
}

.rd-navbar {
  display: none;
  background: #ffffff;
}

.rd-navbar-toggle {
  display: inline-block;
  position: relative;
  width: 48px;
  height: 48px;
  line-height: 48px;
  cursor: pointer;
  color: #2c343b;
  background-color: transparent;
  border: none;
  display: none;
}

.rd-navbar-toggle span {
  position: relative;
  display: block;
  margin: auto;
  transition: 0.3s all ease;
}

.rd-navbar-toggle span::after,
.rd-navbar-toggle span::before {
  content: "";
  position: absolute;
  left: 0;
  top: -6px;
  transition: 0.3s all ease;
}

.rd-navbar-toggle span::after {
  top: 6px;
}

.rd-navbar-toggle span::after,
.rd-navbar-toggle span::before,
.rd-navbar-toggle span {
  width: 24px;
  height: 2px;
  background-color: #ffffff;
  backface-visibility: hidden;
  border-radius: 0;
}

.rd-navbar-toggle span::before,
.rd-navbar-toggle span::after {
  transition-duration: 0.3s, 0.3s;
  transition-delay: 0.3s, 0s;
  transition-property: top, transform;
}

.rd-navbar-toggle.active span {
  transition: background 0.3s 0s ease;
  background: transparent;
}

.rd-navbar-toggle.active span::before,
.rd-navbar-toggle.active span::after {
  top: 0;
  transition-delay: 0s, 0.3s;
}

.rd-navbar-toggle.active span::before {
  transform: rotate(45deg);
}

.rd-navbar-toggle.active span::after {
  transform: rotate(-45deg);
}

.context-dark .rd-navbar-toggle,
.bg-gray-900 .rd-navbar-toggle,
.bg-accent .rd-navbar-toggle {
  display: inline-block;
  position: relative;
  width: 48px;
  height: 48px;
  line-height: 48px;
  cursor: pointer;
  color: #ffea5c;
  display: none;
}

.context-dark .rd-navbar-toggle span,
.bg-gray-900 .rd-navbar-toggle span,
.bg-accent .rd-navbar-toggle span {
  position: relative;
  display: block;
  margin: auto;
  transition: 0.3s all ease;
}

.context-dark .rd-navbar-toggle span::after,
.bg-gray-900 .rd-navbar-toggle span::after,
.bg-accent .rd-navbar-toggle span::after,
.context-dark .rd-navbar-toggle span::before,
.bg-gray-900 .rd-navbar-toggle span::before,
.bg-accent .rd-navbar-toggle span::before {
  content: "";
  position: absolute;
  left: 0;
  top: -6px;
  transition: 0.3s all ease;
}

.context-dark .rd-navbar-toggle span::after,
.bg-gray-900 .rd-navbar-toggle span::after,
.bg-accent .rd-navbar-toggle span::after {
  top: 6px;
}

.context-dark .rd-navbar-toggle span::after,
.bg-gray-900 .rd-navbar-toggle span::after,
.bg-accent .rd-navbar-toggle span::after,
.context-dark .rd-navbar-toggle span::before,
.bg-gray-900 .rd-navbar-toggle span::before,
.bg-accent .rd-navbar-toggle span::before,
.context-dark .rd-navbar-toggle span,
.bg-gray-900 .rd-navbar-toggle span,
.bg-accent .rd-navbar-toggle span {
  width: 24px;
  height: 2px;
  background-color: #ffffff;
  backface-visibility: hidden;
  border-radius: 0;
}

.context-dark .rd-navbar-toggle span::before,
.bg-gray-900 .rd-navbar-toggle span::before,
.bg-accent .rd-navbar-toggle span::before,
.context-dark .rd-navbar-toggle span::after,
.bg-gray-900 .rd-navbar-toggle span::after,
.bg-accent .rd-navbar-toggle span::after {
  transition-duration: 0.3s, 0.3s;
  transition-delay: 0.3s, 0s;
  transition-property: top, transform;
}

.context-dark .rd-navbar-toggle.active span,
.bg-gray-900 .rd-navbar-toggle.active span,
.bg-accent .rd-navbar-toggle.active span {
  transition: background 0.3s 0s ease;
  background: transparent;
}

.context-dark .rd-navbar-toggle.active span::before,
.bg-gray-900 .rd-navbar-toggle.active span::before,
.bg-accent .rd-navbar-toggle.active span::before,
.context-dark .rd-navbar-toggle.active span::after,
.bg-gray-900 .rd-navbar-toggle.active span::after,
.bg-accent .rd-navbar-toggle.active span::after {
  top: 0;
  transition-delay: 0s, 0.3s;
}

.context-dark .rd-navbar-toggle.active span::before,
.bg-gray-900 .rd-navbar-toggle.active span::before,
.bg-accent .rd-navbar-toggle.active span::before {
  transform: rotate(45deg);
}

.context-dark .rd-navbar-toggle.active span::after,
.bg-gray-900 .rd-navbar-toggle.active span::after,
.bg-accent .rd-navbar-toggle.active span::after {
  transform: rotate(-45deg);
}

.rd-navbar-collapse-toggle {
  display: inline-block;
  position: relative;
  width: 48px;
  height: 48px;
  line-height: 48px;
  cursor: pointer;
  color: #ffffff;
  display: none;
}

.rd-navbar-collapse-toggle span {
  top: 50%;
  margin-top: -3px;
}

.rd-navbar-collapse-toggle span,
.rd-navbar-collapse-toggle span::before,
.rd-navbar-collapse-toggle span::after {
  position: absolute;
  width: 6px;
  height: 6px;
  line-height: 6px;
  text-align: center;
  background: #ffffff;
  left: 50%;
  margin-left: -3px;
  border-radius: 50%;
  transition: 0.3s all ease;
}

.rd-navbar-collapse-toggle span::before,
.rd-navbar-collapse-toggle span::after {
  content: "";
}

.rd-navbar-collapse-toggle span::before {
  bottom: 100%;
  margin-bottom: 3px;
}

.rd-navbar-collapse-toggle span::after {
  top: 100%;
  margin-top: 3px;
}

.rd-navbar-collapse-toggle.active span {
  transform: scale(0.7);
}

.rd-navbar-collapse-toggle.active span::before {
  transform: translateY(18px);
}

.rd-navbar-collapse-toggle.active span::after {
  transform: translateY(-18px);
}

.rd-navbar-brand a {
  display: block;
}

.rd-navbar-search {
  position: relative;
  display: inline-flex;
}

.rd-navbar-search .form-input {
  padding-right: 50px;
}

.rd-navbar-search .rd-search-form-submit {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: #d7d7d7;
  transition: 0.33s;
}

.rd-navbar-search .rd-search-form-submit:hover {
  color: #0accbe;
}

.rd-navbar-search-toggle {
  background: none;
  border: none;
  display: inline-block;
  padding: 0;
  outline: none;
  outline-offset: 0;
  cursor: pointer;
  -webkit-appearance: none;
}

.rd-navbar-search-toggle::-moz-focus-inner {
  border: none;
  padding: 0;
}

.rd-navbar-dropdown {
  display: none;
}

.rd-navbar-link {
  padding: 10px 25px;
  font-size: 15px;
  line-height: 1.25;
  letter-spacing: 0.02em;
  color: #262f3c;
  text-align: center;
}

.rd-navbar-link:hover {
  color: #ffffff;
  background: #0accbe;
  border-color: #0accbe;
}

.rd-navbar-link-1 {
  background: #f9f8f8;
}

.rd-navbar-link-1:hover {
  background: #5f67df;
  border-color: #5f67df;
}

.rd-navbar-slogan {
  line-height: 1.3;
}

.rd-navbar-dummy {
  display: none;
}

.rd-navbar-aside-open-toggle {
  position: relative;
  width: 30px;
  height: 20px;
  padding: 0;
  border: 0;
  margin: 0;
  font-size: 0;
  line-height: 0;
  background-color: transparent;
  cursor: pointer;
}

.rd-navbar-aside-open-toggle span {
  position: relative;
  display: block;
  height: 2px;
  background: #2c343b;
}

.rd-navbar-aside-open-toggle span + span {
  margin-top: 7px;
}

.rd-navbar-aside-open-toggle span:nth-child(1),
.rd-navbar-aside-open-toggle span:nth-child(3) {
  width: 100%;
}

.rd-navbar-aside-open-toggle span:nth-child(2) {
  left: 0;
  width: 66%;
  transition: 0.33s;
}

.rd-navbar-aside-open-toggle:hover span:nth-child(2) {
  left: 33%;
}

/*
* @subsection  RD Navbar Static
*/
.rd-navbar-static {
  display: block;
}

.rd-navbar-static .rd-nav-item {
  display: inline-block;
  white-space: nowrap;
}

.rd-navbar-static .rd-nav-item > * {
  display: inline-block;
  white-space: normal;
}

@media (min-width: 1600px) {
  .rd-navbar-static .rd-nav-item > * {
    padding-right: 20px;
  }
}

.rd-navbar-static .rd-nav-item.focus .rd-nav-link,
.rd-navbar-static .rd-nav-item.opened .rd-nav-link {
  color: #0accbe;
  background: transparent;
}

.rd-navbar-static .rd-nav-item.focus > .rd-navbar-submenu-toggle,
.rd-navbar-static .rd-nav-item.opened > .rd-navbar-submenu-toggle {
  color: #0accbe;
}

.rd-navbar-static .rd-nav-item.active .rd-nav-link {
  color: #0accbe;
  background: transparent;
}

.rd-navbar-static .rd-nav-item.active > .rd-navbar-submenu-toggle {
  color: #0accbe;
}

.rd-navbar-static .rd-nav-item.focus > .rd-navbar-submenu-toggle::before,
.rd-navbar-static .rd-nav-item.opened > .rd-navbar-submenu-toggle::before,
.rd-navbar-static
  .rd-nav-item
  .rd-nav-link:hover
  + .rd-navbar-submenu-toggle::before {
  transform: rotate(180deg);
}

.rd-navbar-static .rd-nav-item > .rd-navbar-submenu-toggle {
  margin-left: 4px;
  font-family: "Material Design Icons";
  font-size: 16px;
  cursor: pointer;
  color: white;
}

.rd-navbar-static .rd-nav-item > .rd-navbar-submenu-toggle::before {
  position: relative;
  display: inline-block;
  transition: 0.22s;
  content: "\f236";
}

.rd-navbar-static .rd-nav-item > .rd-navbar-submenu-toggle:hover {
  color: #0accbe;
}

@media (min-width: 1600px) {
  .rd-navbar-static .rd-nav-item > .rd-navbar-submenu-toggle {
    margin-left: -15px;
  }
}

.rd-navbar-static .rd-nav-item > .rd-navbar-submenu {
  margin-top: 20px;
}

.rd-navbar-static .rd-nav-item + .rd-nav-item {
  margin-left: 38px;
}

.rd-navbar-static .rd-nav-link {
  position: relative;
  display: inline-block;
  color: #ffffff;
  font-size: 15px;
  line-height: 1.2;
  transition: 0.25s;
}

.rd-navbar-static .rd-nav-link:hover {
  color: #0accbe;
}

.rd-navbar-static .rd-menu {
  z-index: 15;
  position: absolute;
  display: block;
  margin-top: 27px;
  visibility: hidden;
  opacity: 0;
  text-align: left;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transform-origin: 50% 0;
  transform: translate3d(0, 1px, 0);
}

.rd-navbar-static .rd-navbar-dropdown {
  position: absolute;
  left: -23px;
  width: 230px;
  padding: 25px 25px 30px;
  background: #ffffff;
  z-index: 5;
}

.rd-navbar-static .rd-navbar-dropdown .rd-navbar-dropdown {
  top: -1px;
  left: 100%;
  z-index: 2;
  margin-top: -15px;
  margin-left: 15px;
  transform: translate3d(10px, 0, 0);
}

.rd-navbar-static .rd-navbar-dropdown .rd-navbar-dropdown.rd-navbar-open-left {
  left: auto;
  right: 100%;
  margin-left: 0;
  margin-right: 15px;
  transform: translate3d(-10px, 0, 0);
}

.rd-navbar-static .rd-dropdown-item.focus .rd-dropdown-link,
.rd-navbar-static .rd-dropdown-item.opened .rd-dropdown-link {
  color: #0accbe;
}

.rd-navbar-static .rd-dropdown-link {
  color: #2c343b;
}

.rd-navbar-static .rd-dropdown-link:hover {
  color: #0accbe;
}

.rd-navbar-static .rd-navbar-dropdown a,
.rd-navbar-static .rd-megamenu-list a {
  display: block;
  transition: 0.1s ease;
}

.rd-navbar-static .rd-navbar-dropdown a:hover,
.rd-navbar-static .rd-megamenu-list a:hover {
  transition: 0.15s ease;
}

.rd-navbar-static .rd-dropdown-item + .rd-dropdown-item,
.rd-navbar-static .rd-megamenu-list-item + .rd-megamenu-list-item {
  margin-top: 5px;
}

.rd-navbar-static .rd-navbar-megamenu {
  position: absolute;
  border: none;
  width: 100%;
  border-radius: 0;
}

.rd-navbar-static .rd-navbar-megamenu-inner {
  display: flex;
  width: 100%;
  padding: 30px 25px 50px;
  background: linear-gradient(0deg, #4a80f0 0%, #5c8cf1 100%);
}
@media (min-width:1280px),(max-width:1360px)  {
  .rd-navbar-static .rd-navbar-megamenu-inner-acc {
  display: flex;
  width: 20%;
  margin-left: 520px;
  text-align: center;
  padding: 30px 25px 50px;
  background: linear-gradient(0deg, #4a80f0 0%, #5c8cf1 100%);
}
}
@media (min-width:1360px),(max-width:1440px)  {
  .rd-navbar-static .rd-navbar-megamenu-inner-acc {
  display: flex;
  width: 20%;
  margin-left: 500px;
  text-align: center;
  padding: 30px 25px 50px;
  background: linear-gradient(0deg, #4a80f0 0%, #5c8cf1 100%);
}
}

.rd-navbar-static .rd-megamenu-item {
  flex-basis: 50%;
  flex-grow: 1;
}

.rd-navbar-static .rd-megamenu-item + .rd-megamenu-item {
  padding-left: 30px;
}

.rd-navbar-static .rd-megamenu-list-item + .rd-megamenu-list-item {
  margin-top: 12px;
}

.rd-navbar-static .rd-megamenu-title {
  font-family: "Samsung";
  color: #262f3c;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 600;
}

.rd-navbar-static .rd-megamenu-list-link {
  color: #ffffff;
}

.rd-navbar-static .rd-megamenu-list-link:hover {
  color: #0accbe;
}

.rd-navbar-static * + .rd-megamenu-list {
  margin-top: 23px;
}

.rd-navbar-static .rd-navbar-submenu.focus > .rd-menu,
.rd-navbar-static .rd-navbar-submenu.opened > .rd-menu {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

@media (min-width: 1170px) {
  .rd-navbar-static .rd-navbar-megamenu {
    left: 50%;
    margin-left: -585px;
    width: 1170px;
  }
}

@media (min-width: 1600px) {
  .rd-navbar-static .rd-megamenu-item + .rd-megamenu-item {
    padding-left: 70px;
  }
}

.rd-navbar-static.rd-navbar--is-clone {
  display: block;
  transform: translate3d(0, -100%, 0);
}

.rd-navbar-static.rd-navbar--is-clone.rd-navbar--is-stuck {
  transform: translate3d(0, 0, 0);
}

.rd-navbar-static.rd-navbar--is-stuck,
.rd-navbar-static.rd-navbar--is-clone {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1080;
  background: transparent;
}

.rd-navbar-static .rd-navbar--has-dropdown {
  position: relative;
}

/*
*
* RD Navbar Fixed
*/
.rd-navbar-fixed {
  display: block;
}

.rd-navbar-fixed .rd-navbar-toggle {
  display: inline-block;
}

.rd-navbar-fixed .rd-navbar-brand {
  position: relative;
  margin-left: 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}

.rd-navbar-fixed .rd-navbar-brand img {
  max-width: 160px;
  max-height: 50px;
}

.rd-navbar-fixed .rd-navbar-panel {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1080;
  display: flex;
  align-items: center;
  height: 56px;
  padding: 4px;
}

.rd-navbar-fixed .rd-navbar-nav-wrap {
  position: fixed;
  left: 0;
  top: -56px;
  bottom: -56px;
  z-index: 1079;
  width: 370px;
  max-width: 95%;
  padding: 117.6px 10px 81px;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  font-size: 14px;
  line-height: 34px;
  transform-origin: 0 50%;
  transform: translate3d(-10px, 0, 0);
  opacity: 0;
  visibility: hidden;
  transition: 0.11s;
}

.rd-navbar-fixed .rd-navbar-nav-wrap::-webkit-scrollbar {
  width: 3px;
}

.rd-navbar-fixed .rd-navbar-nav-wrap::-webkit-scrollbar-thumb {
  background: #0accbe;
  border: none;
  border-radius: 0;
  opacity: 0.2;
}

.rd-navbar-fixed .rd-navbar-nav-wrap::-webkit-scrollbar-track {
  background: #ebebeb;
  border: none;
  border-radius: 0;
}

.rd-navbar-fixed .rd-navbar-nav-wrap.active {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.rd-navbar-fixed .rd-navbar-nav-wrap .rd-navbar-element {
  margin: 10px;
  padding: 5px;
  text-align: center;
}

.rd-navbar-fixed .rd-navbar-dummy {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: -1000px;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  z-index: 1000;
  pointer-events: none;
}

.rd-navbar-fixed .rd-navbar-nav-wrap.active ~ .rd-navbar-dummy {
  opacity: 1;
}

.rd-navbar-fixed .rd-navbar-nav {
  display: block;
  margin: 10px 0;
  height: auto;
  text-align: left;
}

.rd-navbar-fixed .rd-nav-item {
  text-align: center;
}

.rd-navbar-fixed .rd-nav-item:last-child .rd-nav-link {
  border-bottom: 2px solid #ebebeb;
}

.rd-navbar-fixed .rd-nav-link {
  display: block;
  /* border-top: 1px solid #ebebeb; */
  padding: 15px 0 !important;
  font-size: 17px !important;
  /* margin-right: 15px; */
}

.rd-navbar-fixed li.opened > .rd-navbar-dropdown {
  border-top: 1px solid #ebebeb;
  padding: 4px 0 20px;
}

.rd-navbar-fixed li.opened > .rd-navbar-megamenu {
  border-top: 1px solid #ebebeb;
  padding-top: 15px;
  padding-bottom: 20px;
}

.rd-navbar-fixed li.opened > .rd-menu {
  opacity: 1;
  height: auto;
}

.rd-navbar-fixed li.opened > .rd-navbar-submenu-toggle::after {
  transform: rotate(180deg);
}

.rd-navbar-fixed .rd-menu {
  display: none;
  transition: opacity 0.3s, height 0.4s ease;
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.rd-navbar-fixed .rd-navbar-submenu {
  position: relative;
}

.rd-navbar-fixed
  .rd-navbar-submenu
  .rd-navbar-dropdown
  .rd-navbar-submenu-toggle:after {
  height: 34px;
  line-height: 34px;
}

.rd-navbar-fixed .rd-navbar-submenu .rd-navbar-dropdown > li > a,
.rd-navbar-fixed .rd-navbar-submenu .rd-navbar-megamenu ul li li > a {
  padding-left: 16px;
}

.rd-navbar-fixed .rd-navbar-submenu .rd-navbar-dropdown li li > a {
  padding-left: 25px;
}

.rd-navbar-fixed .rd-navbar-submenu.opened > .rd-navbar-dropdown,
.rd-navbar-fixed .rd-navbar-submenu.opened > .rd-navbar-megamenu {
  display: block;
}

.rd-navbar-fixed .rd-megamenu-list > li > a,
.rd-navbar-fixed .rd-navbar-dropdown > li > a {
  display: block;
  padding: 9px 10px 9px 4px;
  font-size: 12px;
  line-height: 1.5;
}

.rd-navbar-fixed .rd-megamenu-list > li + li,
.rd-navbar-fixed .rd-navbar-dropdown > li + li {
  margin-top: 3px;
}

.rd-navbar-fixed .rd-megamenu-item {
  position: relative;
}

.rd-navbar-fixed .rd-megamenu-title {
  position: absolute;
  top: -10%;
  right: 2%;
  display: block;
  padding: 0 14px 7px;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 700;
  color: #8491a5;
  opacity: 0.15;
  transform-origin: 41% 162%;
  transform: rotate(90deg);
}

.rd-navbar-fixed .rd-megamenu-list > li > a {
  padding-left: 20px;
}

.rd-navbar-fixed * + .rd-megamenu-list {
  margin-top: 11px;
}

.rd-navbar-fixed * + .rd-megamenu-title {
  margin-top: 20px;
}

.rd-navbar-fixed .rd-megamenu-item + .rd-megamenu-item {
  margin-top: 20px;
}

.rd-navbar-fixed .rd-navbar-submenu-toggle {
  cursor: pointer;
}

.rd-navbar-fixed .rd-navbar-submenu-toggle::after {
  content: "\f236";
  position: absolute;
  margin-top: 6px;
  margin-right: 45px;
  top: 0;
  right: 0;
  width: 56px;
  height: 52px;
  font: 400 16px "Material Design Icons";
  line-height: 52px;
  text-align: center;
  transition: 0.4s all ease;
  z-index: 2;
  cursor: pointer;
}

.rd-navbar-fixed .rd-navbar-link {
  display: flex;
  padding: 16px 20px;
  /* border: 1px solid #ebebeb;*/
}

.rd-navbar-fixed * + .rd-navbar-nav {
  /* margin-top: 20px; */
  margin-right: 10px;
}

.rd-navbar-fixed * + .rd-navbar-link {
  margin-top: 10px;
}

.rd-navbar-fixed .rd-navbar-nav + .rd-navbar-link {
  margin-top: 35px;
}

.rd-navbar-fixed .rd-navbar-slogan {
  padding: 20px 10px 10px;
  text-align: center;
}

.rd-navbar-fixed.rd-navbar--is-clone {
  display: none;
}

.rd-navbar-fixed .rd-navbar-panel {
  color: #ffffff;
  box-shadow: none;
  background: linear-gradient(0deg, #5c8cf1 0%, #4a80f0 100%);
}

.rd-navbar-fixed .rd-navbar-nav-wrap {
  background: linear-gradient(0deg, #5c8cf1 0%, #4a80f0 100%);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-left: 0;
  box-shadow: none;
}

.rd-navbar-fixed .rd-nav-item:hover .rd-nav-link,
.rd-navbar-fixed .rd-nav-item.focus .rd-nav-link,
.rd-navbar-fixed .rd-nav-item.active .rd-nav-link,
.rd-navbar-fixed .rd-nav-item.opened .rd-nav-link {
  color: #0accbe;
}

.rd-navbar-fixed .rd-nav-item:hover > .rd-navbar-submenu-toggle,
.rd-navbar-fixed .rd-nav-item.focus > .rd-navbar-submenu-toggle,
.rd-navbar-fixed .rd-nav-item.active > .rd-navbar-submenu-toggle,
.rd-navbar-fixed .rd-nav-item.opened > .rd-navbar-submenu-toggle {
  color: #ffffff;
}

.rd-navbar-fixed .rd-nav-link {
  color: #ffffff;
}

.rd-navbar-fixed .rd-dropdown-link,
.rd-navbar-fixed .rd-megamenu-list-link {
  color: #ffffff;
}

.rd-navbar-fixed .rd-megamenu-list > li:hover > a,
.rd-navbar-fixed .rd-megamenu-list > li.focus > a,
.rd-navbar-fixed .rd-megamenu-list > li.active > a,
.rd-navbar-fixed .rd-megamenu-list > li.opened > a,
.rd-navbar-fixed .rd-navbar-dropdown > li:hover > a,
.rd-navbar-fixed .rd-navbar-dropdown > li.focus > a,
.rd-navbar-fixed .rd-navbar-dropdown > li.active > a,
.rd-navbar-fixed .rd-navbar-dropdown > li.opened > a {
  color: #0accbe;
}

.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title::after {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title a,
.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title a:focus,
.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title a:active {
  color: #ffffff;
}

.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title a:hover {
  color: #0accbe;
}

.rd-navbar-fixed .rd-navbar-submenu-toggle {
  color: #ffffff;
}

.rd-navbar-fixed .rd-navbar-search .rd-search-form-submit {
  color: #d7d7d7;
}

.rd-navbar-fixed .rd-navbar-search .rd-search-form-submit:hover {
  color: #0accbe;
}

.rd-navbar-fixed .rd-navbar-search-toggle {
  color: #ffffff;
}

.rd-navbar-fixed .rd-navbar-search-toggle:hover {
  color: #0accbe;
}

.rd-navbar-fixed .rd-navbar-collapse {
  background-color: linear-gradient(0deg, #5c8cf1 0%, #4a80f0 100%);
  box-shadow: 0 0 22px -4px rgba(0, 0, 0, 0.17);
}

.rd-navbar-fixed .rd-navbar-collapse,
.rd-navbar-fixed .rd-navbar-collapse a {
  color: #ffffff;
}

html.rd-navbar-fixed-linked .page {
  padding-top: 56px;
}

/**
*
* RD Navbar Classic
*/
.rd-navbar-classic.rd-navbar-static {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-main {
  position: relative;
  display: flex;
  background: linear-gradient(
    0deg,
    #5c8cf1 0%,
    #4a80f0 100%
  ); /*1st bottom 2nd top*/
  padding-left: 2.5%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.01);
}

@media (min-width: 1600px) {
  .rd-navbar-classic.rd-navbar-static .rd-navbar-main {
    margin-left: 5.25%;
    padding-left: 14%;
  }
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-panel {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-brand {
  background-color: transparent;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-brand img {
  max-width: 200px;
  max-height: 200px;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-nav-wrap {
  display: flex;
  flex-grow: 1;
  min-height: 75px;
  background-color: transparent;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-nav-wrap > * {
  display: flex;
  align-items: center;
}

@media (min-width: 1600px) {
  .rd-navbar-classic.rd-navbar-static .rd-navbar-nav-wrap > *:first-child {
    padding-left: 10px;
  }
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-nav {
  flex-grow: 1;
  justify-content: flex-start;
  padding-left: 20px;
}

@media (min-width: 1200px) {
  .rd-navbar-classic.rd-navbar-static .rd-navbar-nav {
    padding-left: 80px;
    margin-left: 18%;
  }
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-link,
.rd-navbar-classic.rd-navbar-static .rd-navbar-element {
  flex-shrink: 0;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-link {
  padding: 15px 20px;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-element {
  display: flex;
  align-items: center;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-element_centered {
  padding: 10px 20px;
  text-align: center;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-element_right {
  padding: 0 15px;
}

.rd-navbar-classic.rd-navbar-static * + .rd-navbar-element {
  /* margin-left: 50px; */
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .rd-navbar-classic.rd-navbar-static .rd-navbar-nav > li + li {
    margin-left: 25px;
  }
  .rd-navbar-classic.rd-navbar-static .rd-nav-link,
  .rd-navbar-classic.rd-navbar-static .rd-dropdown-link,
  .rd-navbar-classic.rd-navbar-static .rd-megamenu-list-link {
    font-size: 14px;
  }
  .rd-navbar-classic.rd-navbar-static .rd-navbar-submenu-toggle {
    position: relative;
    top: 1px;
    font-size: 15px;
  }
}

@media (min-width: 992px) and (max-width: 1599.98px) {
  .rd-navbar-classic.rd-navbar-static .rd-menu {
    margin-top: 25px;
  }
}

@media (min-width: 1200px) {
  .rd-navbar-classic.rd-navbar-static .rd-navbar-element_right {
    padding: 0 25px;
  }
}

@media (min-width: 1400px) {
  .rd-navbar-classic.rd-navbar-static .rd-navbar-link {
    padding: 15px 30px;
  }
  .rd-navbar-classic.rd-navbar-static .rd-navbar-element_centered {
    padding: 10px 30px;
  }
}

@media (min-width: 1880px) {
  .rd-navbar-classic.rd-navbar-static .rd-navbar-nav {
    padding-left: 255px;
  }
  .rd-navbar-classic.rd-navbar-static .rd-navbar-element_centered {
    padding: 10px 25px;
  }
  .rd-navbar-classic.rd-navbar-static .rd-navbar-element_right {
    padding: 0 35px;
  }
  .rd-navbar-classic.rd-navbar-static .rd-navbar-link {
    padding: 15px 40px;
  }
}

.rd-navbar-classic.rd-navbar-static.rd-navbar--is-stuck,
.rd-navbar-classic.rd-navbar-static.rd-navbar--is-clone {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

.rd-navbar-classic.rd-navbar-static.rd-navbar--is-stuck .rd-navbar-aside-outer,
.rd-navbar-classic.rd-navbar-static.rd-navbar--is-clone .rd-navbar-aside-outer {
  display: none;
}

.rd-navbar-classic.rd-navbar-static.rd-navbar--is-stuck
  .rd-navbar-nav-item
  > .rd-navbar-submenu,
.rd-navbar-classic.rd-navbar-static.rd-navbar--is-clone
  .rd-navbar-nav-item
  > .rd-navbar-submenu {
  margin-top: 17px;
}

.rd-navbar-classic.rd-navbar-fixed .list-localization {
  position: fixed;
  right: 8px;
  top: 28px;
  display: flex;
  align-items: center;
  z-index: 1081;
  transform: translateY(-50%);
}

.rd-navbar-slogan-2 {
  display: none;
}

/**
*
* RD Navbar Modern
*/
.rd-navbar-modern .rd-navbar-element {
  display: flex;
  align-items: center;
}

.rd-navbar-modern .rd-navbar-aside-open-toggle {
  position: relative;
  transition: 0.11s;
}

.rd-navbar-modern .rd-navbar-aside-open-toggle.active {
  transform: translate3d(20px, 0, 0);
}

.rd-navbar-modern .rd-navbar-aside-close-toggle {
  padding: 0;
  background-color: transparent;
  border: 0;
  font-size: 16px;
  cursor: pointer;
}

.rd-navbar-modern .rd-navbar-aside {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 385px;
  background: #ffffff;
  z-index: 1081;
  box-shadow: -5.299px 8.48px 24px 0px rgba(0, 0, 0, 0.11);
  transform: translate3d(10px, 0, 0);
  opacity: 0;
  visibility: hidden;
  transition: 0.11s;
}

.rd-navbar-modern .rd-navbar-aside.active {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.rd-navbar-modern .rd-navbar-aside-header,
.rd-navbar-modern .rd-navbar-aside-link {
  padding-left: 20px;
  padding-right: 20px;
}

.rd-navbar-modern .rd-navbar-aside-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 25px;
  padding-bottom: 40px;
}

.rd-navbar-modern .rd-navbar-aside-header > * + * {
  margin-left: 10px;
}

.rd-navbar-modern .rd-navbar-aside-main {
  position: relative;
  max-width: 385px;
  flex-grow: 1;
  overflow: hidden;
  overflow-y: auto;
}

.rd-navbar-modern .rd-navbar-aside-main::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  border-top: 1px solid #ebebeb;
}

.rd-navbar-modern .rd-navbar-aside-title,
.rd-navbar-modern .rd-navbar-aside-link {
  font-family: "Samsung";
  color: #262f3c;
  line-height: 1.2;
}

.rd-navbar-modern .rd-navbar-aside-title {
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.rd-navbar-modern .rd-navbar-aside-link {
  position: relative;
  display: block;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 17px;
}

.rd-navbar-modern .rd-navbar-aside-link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100vw;
  border-top: 1px solid #ebebeb;
}

.rd-navbar-modern .rd-navbar-aside-link.active {
  color: #0accbe;
  pointer-events: none;
}

.rd-navbar-modern .rd-navbar-aside-link:hover {
  color: #0accbe;
}

.rd-navbar-modern * + .button {
  margin-top: 0;
}

@media (min-width: 768px) {
  .rd-navbar-modern .rd-navbar-aside-close-toggle {
    font-size: 24px;
  }
  .rd-navbar-modern .rd-navbar-aside-header,
  .rd-navbar-modern .rd-navbar-aside-link {
    padding-left: 40px;
    padding-right: 20px;
  }
  .rd-navbar-modern .rd-navbar-aside-title {
    font-size: 17px;
  }
  .rd-navbar-modern .rd-navbar-aside-link {
    padding-top: 18px;
    padding-bottom: 18px;
    font-size: 18px;
  }
}

@media (min-width: 1200px) {
  .rd-navbar-modern .rd-navbar-aside-header {
    padding-right: 5px;
  }
  .rd-navbar-modern .rd-navbar-aside-link {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 22px;
  }
}

.rd-navbar-modern.rd-navbar-static::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: 1px solid transparent;
  transition: inherit;
}

.rd-navbar-modern.rd-navbar-static .rd-navbar-main {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  background-color: #5f67e0;
}

.rd-navbar-modern.rd-navbar-static .rd-navbar-main > * + * {
  margin-left: 25px;
}

.rd-navbar-modern.rd-navbar-static .rd-navbar-panel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rd-navbar-modern.rd-navbar-static .rd-navbar-brand img {
  max-width: 200px;
  max-height: 200px;
}

.rd-navbar-modern.rd-navbar-static .rd-navbar-nav-wrap {
  display: flex;
  flex-grow: 1;
}

.rd-navbar-modern.rd-navbar-static .rd-navbar-nav {
  display: flex;
  align-items: center;
  min-height: 75px;
  flex-grow: 1;
  padding: 20px 40px;
}

.rd-navbar-modern.rd-navbar-static .rd-navbar-nav > li + li {
  margin-left: 20px;
}

.rd-navbar-modern.rd-navbar-static .rd-navbar-dropdown,
.rd-navbar-modern.rd-navbar-static .rd-navbar-megamenu-inner {
  border-radius: 8px;
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.18);
}

.rd-navbar-modern.rd-navbar-static .rd-navbar-dropdown {
  padding: 0;
  border: 0;
}

.rd-navbar-modern.rd-navbar-static .rd-navbar-megamenu {
  border: 0;
}

.rd-navbar-modern.rd-navbar-static .rd-dropdown-item + .rd-dropdown-item {
  border-top: 1px solid #ebebeb;
}

.rd-navbar-modern.rd-navbar-static .rd-dropdown-link {
  padding: 10px 10px 10px 25px;
  font-family: "Samsung";
  font-size: 15px;
}

.rd-navbar-modern.rd-navbar-static .rd-navbar-element {
  position: relative;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding: 12px 0 12px 25px;
  background-color: transparent;
}

.rd-navbar-modern.rd-navbar-static .rd-navbar-element > * + * {
  margin-left: 25px;
}

.rd-navbar-modern.rd-navbar-static .rd-navbar-element::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  background: linear-gradient(227deg, #00a4c4 0%, #00c7b9 100%);
  border-radius: 10px;
  z-index: -1;
  border: 1px solid transparent;
  transform: skewX(-13deg);
  pointer-events: none;
  backface-visibility: hidden;
}

.rd-navbar-modern.rd-navbar-static .rd-navbar-element .button {
  min-width: 130px;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .rd-navbar-modern.rd-navbar-static .rd-nav-link,
  .rd-navbar-modern.rd-navbar-static .rd-dropdown-link,
  .rd-navbar-modern.rd-navbar-static .rd-megamenu-list-link {
    font-size: 14px;
  }
  .rd-navbar-modern.rd-navbar-static .rd-navbar-submenu-toggle {
    position: relative;
    top: 1px;
    font-size: 15px;
  }
}

@media (min-width: 1920px) {
  .rd-navbar-modern.rd-navbar-static .rd-navbar-aside {
    width: auto;
    left: calc(50% + 600px - 30px - 25px);
  }
}

@media (min-width: 1200px) {
  .rd-navbar-modern.rd-navbar-static .rd-dropdown-link {
    font-size: 17px;
  }
}

@media (min-width: 1600px) {
  .rd-navbar-modern.rd-navbar-static .rd-navbar-nav {
    padding: 15px 30px 20px 85px;
  }
  .rd-navbar-modern.rd-navbar-static .rd-navbar-nav > li + li {
    margin-left: 46px;
  }
}

.rd-navbar-modern.rd-navbar-static.rd-navbar--is-stuck
  .rd-navbar-nav-item
  > .rd-navbar-submenu,
.rd-navbar-modern.rd-navbar-static.rd-navbar--is-clone
  .rd-navbar-nav-item
  > .rd-navbar-submenu {
  margin-top: 17px;
}

.rd-navbar-modern.rd-navbar-fixed .rd-navbar-element {
  position: fixed;
  top: 0;
  right: 0;
  height: 56px;
  z-index: 1081;
  padding-left: 20px;
  padding-right: 10px;
}

.rd-navbar-modern.rd-navbar-fixed .rd-navbar-element > * + * {
  margin-left: 30px;
}

.rd-navbar-modern.rd-navbar-fixed .rd-navbar-aside {
  max-width: 90%;
}

@media (max-width: 575.98px) {
  .rd-navbar-modern.rd-navbar-fixed .rd-navbar-element .button {
    padding: 0;
    border: 0;
    background-color: transparent;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .rd-navbar-modern.rd-navbar-fixed .rd-navbar-aside {
    max-width: 320px;
  }
}

/*
*
* Swiper
*/
.swiper-container {
  display: flex;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  height: auto;
  width: 100%;
  /* Fix of Webkit flickering */
  z-index: 1;
}

.swiper-container-no-flexbox .swiper-slide {
  float: left;
}

.swiper-container-vertical > .swiper-wrapper {
  flex-direction: column;
}

.swiper-wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  align-self: stretch;
  align-items: stretch;
  width: 100%;
  height: auto;
  min-height: inherit;
  transition-property: transform;
  box-sizing: content-box;
}

.swiper-container {
  flex-shrink: 0;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
  flex-wrap: wrap;
}

.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: ease-out;
  margin: 0 auto;
}

/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}

/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
  touch-action: pan-y;
}

.swiper-wp8-vertical {
  touch-action: pan-x;
}

/* Coverflow */
.swiper-container-coverflow .swiper-wrapper {
  /* Windows 8 IE 10 fix */
}

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  transition-timing-function: ease-out;
}

.swiper-container-fade .swiper-slide {
  pointer-events: none;
}

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  transform-origin: 50%;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
}

.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  background-size: 100%;
  background-repeat: no-repeat;
}

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg);
  }
}

.swiper-slide > .vide__body,
.swiper-slide > .parallax_cnt {
  height: 100%;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: none;
  font-size: 26px;
  line-height: 1;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  transition: 0.2s ease-in;
  will-change: transform;
  z-index: 10;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  color: #0accbe;
}

@media (min-width: 768px) {
  .swiper-button-prev,
  .swiper-button-next {
    display: block;
  }
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}

.swiper-button-prev {
  left: 30px;
}

.swiper-button-prev:before {
  font-family: "FontAwesome";
  content: "\f060";
}

.swiper-button-next {
  right: 30px;
}

.swiper-button-next:before {
  font-family: "FontAwesome";
  content: "\f061";
}

.swiper-slider.swiper-container-rtl .swiper-button-prev::before {
  content: "\f061";
}

.swiper-slider.swiper-container-rtl .swiper-button-next::before {
  content: "\f060";
}

.swiper-pagination {
  position: absolute;
  display: block;
  text-align: center;
  transition: 0.3s;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

.swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.6);
  transition: 0.2s;
}

.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active,
.swiper-pagination .swiper-pagination-bullet:hover {
  background: white;
}

.swiper-pagination.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-black .swiper-pagination-bullet {
  background: rgba(0, 0, 0, 0.6);
}

.swiper-pagination-black
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: black;
}

.swiper-container-vertical > .swiper-pagination {
  right: 10px;
  top: 50%;
  transform: translate3d(0px, -50%, 0);
}

.swiper-container-vertical > .swiper-pagination .swiper-pagination-bullet {
  margin: 5px 0;
  display: block;
}

.swiper-container-horizontal > .swiper-pagination {
  bottom: 20px;
  left: 0;
  width: 100%;
}

.swiper-container-horizontal > .swiper-pagination .swiper-pagination-bullet {
  margin: 0 5px;
}

.swiper-slide {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  white-space: nowrap;
}

.swiper-slide-caption {
  width: 100%;
  white-space: normal;
}

.swiper-slider-1 {
  min-height: 40.10417vw;
}

/*
*
* Google Maps
*/
.google-map-markers {
  display: none;
}

.google-map-container {
  width: 100%;
}

.google-map {
  width: 100%;
  height: 200px;
}

@media (min-width: 576px) {
  .google-map {
    height: 250px;
  }
}

@media (min-width: 992px) {
  .google-map {
    height: 450px;
  }
}

@media (min-width: 1200px) {
  .google-map {
    height: 525px;
  }
}

.gm-style-iw {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 15px;
  line-height: 1.6;
  color: #262f3c;
}

/*
*
* Search Results
*/
.rd-search-results-live {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  max-height: 600px;
  overflow-y: auto;
  margin: -3px 0 0;
  text-align: left;
  z-index: 998;
}

.rd-search-results-live #search-results {
  position: relative;
  padding: 16px 0 0;
  color: #8491a5;
  background: #ffffff;
  opacity: 0;
  visibility: hidden;
  transform-origin: 50% 0;
  transform: scale(1, 0.9);
  transition: 0.3s all ease;
}

.rd-search-results-live #search-results::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: -1;
  border: 1px solid #ebebeb;
  border-top: 0;
}

.rd-search-results-live #search-results.active {
  opacity: 1;
  visibility: visible;
  transform: scale(1, 1);
}

.rd-search-results-live .search-quick-result {
  padding-left: 15px;
  padding-right: 15px;
  font-family: "Samsung";
  font-size: 14px;
  font-weight: 700;
  line-height: 30px;
  text-transform: uppercase;
  color: #262f3c;
}

.rd-search-results-live .search-list {
  margin-top: 11px;
}

.rd-search-results-live .search-list li:only-child {
  padding: 0 15px 15px;
}

.rd-search-results-live .search-link {
  color: #d7d7d7;
}

.rd-search-results-live .search-link:hover {
  color: #0accbe;
}

.rd-search-results-live .search-error {
  font-size: 14px;
  line-height: 1.6;
}

.rd-search-results-live .search-title {
  position: relative;
  font-family: "Samsung";
  font-size: 14px;
  font-weight: 700;
  color: #262f3c;
}

.rd-search-results-live .search-title a:hover {
  color: #0accbe;
}

.rd-search-results-live .search-list-item-all {
  margin-top: 18px;
  width: 100%;
}

.rd-search-results-live .search-submit {
  position: relative;
  overflow: hidden;
  z-index: 0;
  display: block;
  padding: 8px;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.01em;
  text-transform: none;
  text-align: center;
  color: #ffffff;
  background: #0accbe;
}

.rd-search-results-live .search-submit:hover {
  color: #ffffff;
  background: #d7d7d7;
}

.rd-search-results-live .match {
  display: none;
}

.not-empty ~ .rd-search-results-live {
  visibility: visible;
  opacity: 1;
}

.rd-search-results-live p {
  font-size: 11px;
}

.rd-search-results-live p * {
  margin: 0;
}

.rd-search-results-live .search-list-item {
  padding: 0 15px;
}

.rd-search-results-live * + p {
  margin-top: 5px;
}

.rd-search-results-live .search-list-item + .search-list-item {
  margin-top: 17px;
}

.rd-search-results .search-list {
  counter-reset: li;
  text-align: left;
  padding-left: 0;
  font-size: 18px;
  list-style-type: none;
  overflow: hidden;
}

.rd-search-results .search-list li div {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
}

.rd-search-results .search-list li:only-child::before {
  display: none;
}

.rd-search-results .search-list-item {
  position: relative;
  padding-left: 40px;
  font-size: 15px;
  color: #777;
}

.rd-search-results .search-list-item::before {
  content: counter(li, decimal-leading-zero) ".";
  counter-increment: li;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 14px;
  line-height: inherit;
  font-weight: 400;
  color: #8491a5;
}

.rd-search-results .search-title {
  font-size: 16px;
  font-weight: 400;
  color: #262f3c;
}

.rd-search-results .search {
  color: #ffffff;
  padding: 0 0.25em;
  background: #0accbe;
}

.rd-search-results .match {
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.7;
  color: rgba(132, 145, 165, 0.7);
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

.rd-search-results .match em {
  margin: 0;
  font-style: normal;
}

.rd-search-results p * {
  margin: 0;
}

.rd-search-results * + .match {
  margin-top: 10px;
}

.rd-search-results .search-list-item + .search-list-item {
  margin-top: 40px;
}

@media (min-width: 768px) {
  .rd-search-results .search-title {
    font-size: 18px;
  }
  .rd-search-results .search-list-item::before {
    top: 2px;
    font-size: 16px;
  }
  .rd-search-results * + p {
    margin-top: 12px;
  }
  .rd-search-results .search-list-item + .search-list-item {
    margin-top: 55px;
  }
}

@media (min-width: 992px) {
  .rd-search-results .search-list-item {
    padding-left: 40px;
  }
}

* + .rd-search-results {
  margin-top: 40px;
}

@media (min-width: 768px) {
  * + .rd-search-results {
    margin-top: 55px;
  }
}

@media (min-width: 1200px) {
  * + .rd-search-results {
    margin-top: 80px;
  }
}

.rd-search-classic {
  position: relative;
}

.rd-search-classic .form-input {
  padding-right: 50px;
}

.rd-search-classic .form-input,
.rd-search-classic .form-label {
  letter-spacing: 0;
}

.rd-search-classic .rd-search-submit {
  background: none;
  border: none;
  display: inline-block;
  padding: 0;
  outline: none;
  outline-offset: 0;
  cursor: pointer;
  -webkit-appearance: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50px;
  padding-top: 3px;
  padding-right: 2px;
  margin: 0;
  text-align: center;
  color: #d7d7d7;
  font-size: 0;
  line-height: 0;
}

.rd-search-classic .rd-search-submit::-moz-focus-inner {
  border: none;
  padding: 0;
}

.rd-search-classic .rd-search-submit:before {
  position: relative;
  top: -1px;
  content: "\e014";
  font: 400 20px "fl-chapps";
  line-height: 1;
  transition: 0.33s all ease;
}

.rd-search-classic .rd-search-submit:hover {
  color: #0accbe;
}

.rd-search.rd-search-inline {
  position: relative;
}

.rd-search.rd-search-inline .form-input {
  padding-right: 54px;
}

.rd-search.rd-search-inline .button-link {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 54px;
  right: 0;
  margin: 0;
  font-size: 21px;
  color: #d7d7d7;
  transition: 0.33s;
}

.rd-search.rd-search-inline .button-link::before {
  display: block;
  margin: auto;
}

.rd-search.rd-search-inline .button-link:hover {
  color: #0accbe;
}

.rd-search.rd-search-inline.form-sm .form-input {
  padding-right: 40px;
}

.rd-search.rd-search-inline.form-sm .button-link {
  width: 40px;
  font-size: 18px;
}

.rd-search.rd-search-inline.form-lg .form-input {
  padding-right: 70px;
}

.rd-search.rd-search-inline.form-lg .button-link {
  width: 70px;
}

/*
*
* Isotope
*/
@keyframes sprite-animation {
  from {
    background-position: 0 0;
  }
}

[data-isotope-layout] {
  z-index: 0;
  display: block;
  min-height: 160px;
  margin-left: -15px;
  margin-right: -15px;
  transition: 0.4s all ease;
}

[data-isotope-layout]:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 64px;
  height: 64px;
  margin-top: 15px;
  /* background-image: url("../images/isotope-loader.png"); */
  background-position: -1152px 0;
  animation: 0.7s sprite-animation steps(18) infinite;
  transition: 0.4s all ease;
  transform: translate3d(-50%, -50%, 0);
}

[data-isotope-layout] .row {
  margin-bottom: -30px;
}

[data-isotope-layout] .row > [class*="col-"] {
  margin-top: 30px;
}

[data-isotope-layout] .row-narrow {
  margin-bottom: -30px;
}

[data-isotope-layout] .row-narrow > [class*="col-"] {
  margin-top: 30px;
}

[data-isotope-layout] .row-condensed {
  margin-bottom: 0;
}

[data-isotope-layout] .row-condensed > [class*="col-"] {
  margin-top: 0;
}

[data-isotope-layout] [class*="col-"] {
  display: block;
  opacity: 0;
  will-change: transform;
  transition: 0.1s opacity ease-in;
}

@media (max-width: 575.98px) {
  [data-isotope-layout] [class*="col-"] {
    max-width: 100%;
  }
}

[data-isotope-layout].isotope--loaded [class*="col-"] {
  opacity: 1;
}

[data-isotope-layout].isotope--loaded:after {
  opacity: 0;
  visibility: hidden;
}

.isotope-filters > * {
  margin-top: 0;
  vertical-align: middle;
}

.isotope-filters .inline-list {
  position: relative;
  word-spacing: 0;
}

.isotope-filters .inline-list li {
  display: inline-block;
}

.isotope-filters .inline-list a {
  position: relative;
  transition: 0.3s;
  color: #d7d7d7;
}

.isotope-filters .inline-list .isotope-filters-trigger {
  display: none;
}

.isotope-filters-modern .inline-list {
  transform: translate3d(0, -10px, 0);
  margin-bottom: -10px;
  margin-left: -10px;
  margin-right: -10px;
}

.isotope-filters-modern .inline-list > * {
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.isotope-filters-modern .inline-list > li {
  font-family: "Samsung";
  font-size: 18px;
}

.isotope-filters-modern .inline-list a {
  position: relative;
  padding-bottom: 10px;
}

.isotope-filters-modern .inline-list a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  transition: 0.4s;
  background: #0accbe;
}

.isotope-filters-modern .inline-list a:hover {
  color: #0accbe;
}

.isotope-filters-modern .inline-list a.active::after {
  width: 100%;
}

@media (min-width: 992px) {
  .isotope-filters-modern .inline-list {
    transform: translate3d(0, -10px, 0);
    margin-bottom: -10px;
    margin-left: -23px;
    margin-right: -23px;
  }
  .isotope-filters-modern .inline-list > * {
    margin-top: 10px;
    padding-left: 23px;
    padding-right: 23px;
  }
}

.isotope-modern-wrap [class*="col"] {
  margin-top: 30px;
}

@media (max-width: 767.98px) {
  .isotope-modern-wrap [class*="col"] {
    width: 100%;
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  html:not(.tablet):not(.mobile) .isotope-modern-wrap [class*="col"] {
    margin-top: 0;
  }
}

.isotope-condensed {
  margin-left: 0;
  margin-right: 0;
}

.isotope-condensed .row > * {
  margin-top: -1px;
  margin-bottom: -1px;
}

* + .isotope-outer {
  margin-top: 30px;
}

@media (min-width: 768px) {
  * + .isotope-outer {
    margin-top: 45px;
  }
}

.tabs-custom {
  text-align: left;
}

.nav {
  display: block;
}

.navbar {
  position: static;
  display: block;
}

.nav-tabs {
  font-size: 0;
  line-height: 0;
  word-spacing: 0;
  border: 0;
}

.nav-tabs:before,
.nav-tabs:after {
  display: none;
}

.nav-tabs .nav-link {
  border: 0;
  border-radius: 0;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border: none;
}

.nav-item {
  float: none;
  border: 0;
  cursor: pointer;
  transition: 0.33s all ease;
}

.nav-link {
  padding: 0;
  margin: 0;
  border-radius: 0;
}

.nav-link.active {
  cursor: default;
}

.tab-content > .tab-pane {
  display: block;
  visibility: hidden;
  height: 0;
  overflow: hidden;
}

.tab-content > .active {
  visibility: visible;
  height: auto;
  width: auto;
  overflow: visible;
}

* + .tabs-line {
  margin-top: 30px;
}

@media (min-width: 768px) {
  * + .tabs-line {
    margin-top: 40px;
  }
}

.tabs-line .nav-tabs {
  position: relative;
  text-align: center;
}

.tabs-line .nav-link {
  display: block;
  font-family: "Samsung";
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  vertical-align: middle;
}

.tabs-line .nav-link span {
  line-height: 1.4;
}

.tabs-line * + .navbar {
  margin-top: 15px;
}

.tabs-line * + .tab-content {
  margin-top: 20px;
}

@media (max-width: 767.98px) {
  .tabs-line .nav-tabs {
    max-width: 100%;
  }
  .tabs-line .nav-item {
    margin: -1px;
  }
  .tabs-line .nav-item:first-child {
    border-top: 1px solid #d7d7d7;
  }
  .tabs-line .nav-link {
    padding: 7px 10px;
    color: #8491a5;
    background: transparent;
    border-bottom: 1px solid #d7d7d7;
  }
  .tabs-line .nav-link:hover,
  .tabs-line .nav-link.active {
    color: #ffffff;
    background: #0accbe;
    border-color: #0accbe;
  }
  .context-dark .tabs-line .nav-item:first-child,
  .bg-gray-900 .tabs-line .nav-item:first-child,
  .bg-accent .tabs-line .nav-item:first-child {
    border-top-color: rgba(255, 255, 255, 0.4);
  }
  .context-dark .tabs-line .nav-link,
  .bg-gray-900 .tabs-line .nav-link,
  .bg-accent .tabs-line .nav-link {
    border-bottom-color: rgba(255, 255, 255, 0.4);
  }
  .context-dark .tabs-line .nav-link:hover,
  .bg-gray-900 .tabs-line .nav-link:hover,
  .bg-accent .tabs-line .nav-link:hover,
  .context-dark .tabs-line .nav-link.active,
  .bg-gray-900 .tabs-line .nav-link.active,
  .bg-accent .tabs-line .nav-link.active {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.3);
    border-color: rgba(255, 255, 255, 0.3);
  }
}

@media (max-width: 767.98px) {
  .tabs-line-responsive {
    position: relative;
    z-index: 10;
    text-align: center;
  }
  .tabs-line-responsive .navbar {
    position: static;
  }
  .tabs-line-responsive .navbar-inner {
    position: relative;
    width: 100%;
  }
  .tabs-line-responsive .stuck,
  .tabs-line-responsive .stick {
    min-height: 44px;
  }
  .tabs-line-responsive .stuck .navbar-inner,
  .tabs-line-responsive .stick .navbar-inner {
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    width: 101vw;
  }
  .tabs-line-responsive .stuck .tabs-line-toggle,
  .tabs-line-responsive .stick .tabs-line-toggle {
    border-radius: 0;
    box-shadow: none;
  }
  .tabs-line-responsive .tabs-line-toggle {
    background: none;
    border: none;
    display: inline-block;
    padding: 0;
    outline: none;
    outline-offset: 0;
    cursor: pointer;
    -webkit-appearance: none;
    position: relative;
    z-index: 45;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px 5px;
    border-radius: 10px;
    background-color: #ffffff;
    border: 1px solid #ebebeb;
    font-family: "Samsung";
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
    color: #262f3c;
    box-shadow: 0 0 24px 0 rgba(75, 81, 91, 0.12);
    transition: 0.22s ease-in;
  }
  .tabs-line-responsive .tabs-line-toggle::-moz-focus-inner {
    border: none;
    padding: 0;
  }
  .tabs-line-responsive .tabs-line-toggle .icon {
    position: relative;
    top: 1px;
    display: block;
    margin-left: 5px;
    transition: 0.22s;
    will-change: transform;
  }
  .tabs-line-responsive .tabs-line-toggle.active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .tabs-line-responsive .tabs-line-toggle.active .icon {
    transform: scale(1, -1);
  }
  .tabs-line-responsive ul.nav-tabs {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 10;
    padding: 20px;
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    margin-top: -1px;
    background: rgba(255, 255, 255, 0.95);
    border: 1px solid #ebebeb;
    transition: 0.22s ease-in;
    text-align: center;
  }
  .tabs-line-responsive ul.nav-tabs.active {
    opacity: 1;
    visibility: visible;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .tabs-line-responsive ul.nav-tabs .nav-item {
    border: 0;
    width: 340px;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }
  .tabs-line-responsive ul.nav-tabs .nav-item:nth-child(n) a {
    border: 0;
  }
  .tabs-line-responsive ul.nav-tabs .nav-link {
    padding: 0;
    color: #2c343b;
    border-radius: 18px;
  }
  .tabs-line-responsive ul.nav-tabs .nav-link:hover,
  .tabs-line-responsive ul.nav-tabs .nav-link.active {
    background-color: transparent;
    color: #0accbe;
  }
  .tabs-line-responsive ul.nav-tabs .nav-item + .nav-item {
    margin-top: 12px;
  }
}

@media (min-width: 768px) {
  .tabs-line-responsive .tabs-line-toggle {
    display: none;
  }
}

.tabs-creative .nav-tabs {
  display: flex;
  flex-wrap: wrap;
  max-width: 490px;
  margin-bottom: -25px;
  text-align: left;
}

.tabs-creative .nav-tabs-outer {
  flex-shrink: 0;
  padding-top: 20px;
}

.tabs-creative .nav-item {
  width: 50%;
  padding-right: 10px;
  margin-bottom: 25px;
}

.tabs-creative .nav-link {
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 1.2;
}

.tabs-creative .nav-link .icon {
  position: relative;
  top: 0.025em;
  display: block;
  margin-right: 10px;
  font-size: 28px;
}

.tabs-creative .nav-link.active {
  color: #262f3c;
}

.tabs-creative .nav-link.active .icon {
  color: #0accbe;
}

.tabs-creative .safari-frame-2 {
  width: 100%;
  max-width: 650px;
}

.tabs-creative .tab-content {
  position: relative;
  display: flex;
  padding-bottom: 64.2623%;
}

.tabs-creative .tab-pane {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100% !important;
  opacity: 1 !important;
  visibility: visible !important;
  width: 100%;
  flex-shrink: 0;
  animation-duration: 0.7s;
  animation-timing-function: ease;
  transform: translate3d(0, 0, 0);
}

.tabs-creative .tab-pane,
.tabs-creative .tab-pane * {
  backface-visibility: hidden;
}

@keyframes show {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.tabs-creative .tab-pane.active {
  z-index: 2;
  animation-name: show;
}

.tabs-creative .tab-pane::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 6px;
  left: -6px;
  background: #ffffff;
  box-shadow: -0.279px -3.99px 20.58px 0.42px rgba(75, 81, 91, 0.34);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.tabs-creative .tab-pane.showing::after {
  opacity: 1;
  visibility: visible;
}

.tabs-creative .tab-pane.hiding {
  z-index: 1;
}

.tabs-creative * + .nav-tabs {
  margin-top: 25px;
}

.tabs-creative * + .tab-content-outer {
  margin-top: 25px;
}

@media (max-width: 575.98px) {
  .tabs-creative .nav-link {
    font-size: 14px;
  }
  .tabs-creative .nav-link .icon {
    display: none;
  }
}

@media (min-width: 576px) {
  .tabs-creative h3,
  .tabs-creative .heading-3 {
    max-width: 370px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .tabs-creative {
    max-width: 570px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .tabs-creative .nav-tabs {
    max-width: 460px;
  }
  .tabs-creative .nav-item {
    width: 50%;
  }
  .tabs-creative .nav-link {
    padding-right: 20px;
  }
}

@media (min-width: 992px) {
  .tabs-creative {
    display: flex;
    align-items: center;
  }
  .tabs-creative .nav-tabs {
    margin-bottom: -40px;
  }
  .tabs-creative .nav-item {
    margin-bottom: 40px;
  }
  .tabs-creative .nav-link {
    font-size: 16px;
  }
  .tabs-creative .nav-tabs-outer + .tab-content-outer {
    margin-left: 10px;
  }
  .tabs-creative * + h3,
  .tabs-creative * + .heading-3 {
    margin-top: 20px;
  }
  .tabs-creative * + .nav-tabs-inner {
    margin-top: 40px;
  }
  .tabs-creative * + .group {
    margin-top: 45px;
  }
}

@media (min-width: 1200px) {
  .tabs-creative .nav-link {
    font-size: 17px;
  }
  .tabs-creative .nav-link .icon {
    margin-right: 20px;
    font-size: 35px;
  }
  .tabs-creative .nav-tabs-outer + .tab-content-outer {
    margin-left: 35px;
  }
}

@media (max-width: 767.98px) {
  .tabs-creative {
    position: relative;
    z-index: 10;
    text-align: center;
  }
  .tabs-creative .nav-tabs-outer {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .tabs-creative .nav-tabs {
    width: 100%;
    order: 2;
  }
}

.context-dark .tabs-line_1 .nav-link,
.bg-gray-900 .tabs-line_1 .nav-link,
.bg-accent .tabs-line_1 .nav-link {
  color: #ffffff;
}

.context-dark .tabs-line_1 .nav-link span::after,
.bg-gray-900 .tabs-line_1 .nav-link span::after,
.bg-accent .tabs-line_1 .nav-link span::after {
  width: 100%;
  background: #ebebeb;
  opacity: 0.65;
}

.context-dark .tabs-line_1 .nav-link:hover span::after,
.bg-gray-900 .tabs-line_1 .nav-link:hover span::after,
.bg-accent .tabs-line_1 .nav-link:hover span::after,
.context-dark .tabs-line_1 .nav-link.active span::after,
.bg-gray-900 .tabs-line_1 .nav-link.active span::after,
.bg-accent .tabs-line_1 .nav-link.active span::after {
  background: #ffea5c;
  opacity: 1;
}

.tabs-features {
  width: 100%;
}

.tabs-features .nav-link {
  position: relative;
  z-index: 0;
  padding: 5px 0 5px 20px;
  border-radius: 35px;
  font-size: 14px;
  line-height: 1.5;
  cursor: pointer;
  color: #262f3c;
  transition: 0.22s;
}

.tabs-features .nav-link::before {
  content: "";
  position: absolute;
  left: 0;
  z-index: -1;
  margin-top: 5px;
  top: 0.5em;
  display: inline-block;
  width: 6px;
  height: 6px;
  margin-right: 17px;
  border-radius: 35px;
  background: #5f67df;
  transition: none;
}

.tabs-features .nav-item {
  border: 1px solid transparent;
}

.tabs-features .nav-item + .nav-item {
  margin-top: 5px;
}

.tabs-features .tab-content {
  position: relative;
  z-index: 1;
  background-position: center center;
  background-size: cover;
  max-width: 100%;
  flex-shrink: 0;
}

.tabs-features .tab-content::before {
  content: "";
  display: block;
  padding-bottom: 56.35104%;
}

.tabs-features .tab-pane {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  background-position: center center;
  background-size: cover;
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba(75, 81, 91, 0.15);
}

.tabs-features * + .nav-tabs,
.tabs-features * + .navigation-custom {
  margin-top: 15px;
}

.tabs-features .tabs-features-aside + .tab-content {
  margin-top: 30px;
}

@media (max-width: 767.98px) {
  .tabs-features {
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
  }
  .tabs-features .nav-link::before {
    transition: 0.22s;
  }
  .tabs-features .tab-content {
    background: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .tabs-features .tab-content {
    margin-left: -50px;
    margin-right: -50px;
  }
}

@media (min-width: 768px) {
  .tabs-features .nav-tabs {
    padding-left: 5px;
  }
  .tabs-features .nav-item + .nav-item {
    margin-top: 10px;
  }
  .tabs-features .tab-content {
    max-width: 866px;
    flex-grow: 1;
  }
  .tabs-features .tab-pane {
    top: 6.2%;
    left: 13.9%;
    right: 13.4%;
    bottom: 12%;
    border-radius: 0;
    box-shadow: none;
  }
}

@media (min-width: 992px) {
  .tabs-features {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-right: -40px;
  }
  .tabs-features .tabs-features-aside {
    width: 28%;
    padding-top: 15px;
    max-width: 340px;
    flex-shrink: 0;
    flex-grow: 1;
  }
  .tabs-features .tabs-features-aside + .tab-content {
    margin-top: 0;
    margin-left: 20px;
  }
}

@media (max-width: 1199.98px) {
  .tabs-features .nav-link.active::before {
    background: #0accbe;
  }
}

@media (min-width: 1200px) {
  .tabs-features .nav-tabs {
    padding-left: 20px;
  }
  .tabs-features .tabs-features-aside {
    max-width: 370px;
  }
  .tabs-features .nav-link::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    left: 100%;
    width: 300px;
    border-bottom: 1px solid #ebebeb;
    opacity: 0;
    visibility: hidden;
  }
  .tabs-features .nav-link.active {
    padding-top: 18px;
    padding-bottom: 18px;
    margin: 7px 0;
    background: #ebebeb;
  }
  .tabs-features .nav-link.active::before {
    opacity: 0;
    visibility: hidden;
    transition: 0.11s;
  }
  .tabs-features .nav-link.active::after {
    opacity: 1;
    visibility: visible;
    transition: 0.11s;
  }
  .tabs-features * + h3,
  .tabs-features * + .heading-3 {
    margin-top: 24px;
  }
  .tabs-features * + .nav-tabs,
  .tabs-features * + .navigation-custom {
    margin-top: 50px;
  }
}

@media (min-width: 1600px) {
  .tabs-features {
    margin-right: -100px;
  }
}

@media (min-width: 768px) {
  .tabs-line .nav-item {
    transform: translate3d(0, 0, 0);
    will-change: transform;
  }
  .tabs-line .nav-link {
    position: relative;
    font-size: 19px;
  }
  .tabs-line .nav-link span {
    position: relative;
    display: inline-flex;
    padding: 12px 2px 13px;
    margin-bottom: -1px;
  }
  .tabs-line .nav-link span::after {
    content: "";
    position: absolute;
    right: 0;
    left: auto;
    bottom: 0;
    height: 3px;
    width: 0;
    transition: 0.33s;
    background: #0accbe;
  }
  .tabs-line .nav-link.active span::after {
    left: 0;
    right: auto;
    width: 100%;
  }
  .tabs-line * + .nav-tabs {
    margin-top: 25px;
  }
  .tabs-line .tabs-line-toggle + .nav-tabs {
    margin-top: 0;
  }
  * + .tabs-line_1 {
    margin-top: 20px;
  }
  .tabs-horizontal.tabs-line .nav-tabs {
    border: 0;
    border-bottom: 1px solid #ebebeb;
  }
  .tabs-horizontal.tabs-line .nav-item {
    display: inline-block;
    margin-bottom: -1px;
  }
  .tabs-horizontal.tabs-line .nav-item:not(:last-child) {
    margin-right: 52px;
  }
  .tabs-horizontal.tabs-line * + .tab-content {
    margin-top: 30px;
  }
  .tabs-horizontal.tabs-line.tabs-line_1 .nav-tabs {
    text-align: left;
    border-bottom: 0;
  }
  .tabs-horizontal.tabs-line.tabs-line_1 .nav-link span {
    padding-bottom: 8px;
  }
  .tabs-vertical {
    display: flex;
    align-items: flex-start;
  }
  .tabs-vertical .nav-tabs {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  .tabs-vertical .nav-item {
    border: 0;
    width: 100%;
    text-align: left;
  }
  .tabs-vertical .nav-link.active {
    background-color: transparent;
  }
  .tabs-vertical .tab-content {
    flex-grow: 1;
  }
  .tabs-vertical.tabs-line {
    align-items: stretch;
    margin-left: -35px;
  }
  .tabs-vertical.tabs-line > * {
    margin-left: 35px;
  }
  .tabs-vertical.tabs-line .nav-tabs {
    flex-shrink: 0;
    max-width: 50%;
    min-width: 200px;
  }
  .tabs-vertical.tabs-line .nav-tabs-outer {
    position: relative;
    flex-shrink: 0;
    max-width: 50%;
    min-width: 200px;
  }
  .tabs-vertical.tabs-line .nav-tabs-outer .nav-tabs {
    width: 100%;
    max-width: 100%;
  }
  .tabs-vertical.tabs-line .nav-item {
    padding-right: 10px;
    border-bottom: 1px solid #d7d7d7;
    cursor: default;
  }
  .tabs-vertical.tabs-line .nav-item:first-child .nav-link span {
    padding-top: 0;
  }
  .tabs-vertical.tabs-line .nav-item + .nav-item {
    margin-top: 8px;
  }
  .tabs-vertical.tabs-line .tab-content {
    margin-top: 0;
  }
  .tabs-vertical.tabs-line-1 .nav-tabs {
    width: 32%;
  }
  .tabs-vertical.tabs-line-1 .nav-tabs-outer {
    width: 32%;
  }
  .tabs-vertical.tabs-line-1 .nav-tabs-outer .nav-tabs {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .tabs-horizontal.tabs-line .nav-link span {
    padding-bottom: 16px;
  }
  .tabs-vertical.tabs-line {
    margin-left: -50px;
  }
  .tabs-vertical.tabs-line > * {
    margin-left: 50px;
  }
  .tabs-vertical.tabs-line .nav-tabs {
    width: 370px;
    min-width: 370px;
    border-bottom: 0;
  }
  .tabs-vertical.tabs-line .nav-tabs-outer {
    width: 370px;
    border-bottom: 0;
  }
  .tabs-vertical.tabs-line .nav-tabs-outer .nav-tabs {
    width: 100%;
  }
  .tabs-vertical.tabs-line .nav-link span {
    padding: 15px 2px;
  }
}

@media (min-width: 1200px) {
  .tabs-line .nav-link {
    font-size: 22px;
  }
  .tabs-vertical.tabs-line {
    margin-left: -90px;
  }
  .tabs-vertical.tabs-line > * {
    margin-left: 90px;
  }
  .tabs-vertical.tabs-line .nav-item + .nav-item {
    margin-top: 11px;
  }
}

.card-group-custom {
  margin-bottom: 0;
}

.card-group-custom .card-header + .collapse > .card-body,
.card-group-custom .card-header + .collapse > .list-group {
  border-top: 0;
}

.card-group-custom .card + .card {
  margin-top: 0;
}

.card-custom {
  display: block;
  margin: 0;
  background: inherit;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  text-align: left;
}

.card-custom a {
  display: block;
}

.card-custom .card-header {
  padding: 0;
  border-bottom: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: transparent;
}

.card-custom .card-body {
  padding: 0;
  border: 0;
}

.card-custom .card-title {
  margin-bottom: 0;
}

* + .card-group-custom {
  margin-top: 35px;
}

@media (min-width: 768px) {
  * + .card-group-custom {
    margin-top: 50px;
  }
}

.card-corporate {
  position: relative;
  text-align: left;
}

.card-corporate::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 100vw;
  transform: translate3d(-50%, 0, 0);
  border-bottom: 1px solid #ebebeb;
}

.card-corporate .card-title a {
  position: relative;
  z-index: 1;
  display: block;
  padding: 18px 20px 18px 0;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.005em;
  color: #2c343b;
  transition: 0.1s all ease;
}

.card-corporate .card-title a .card-arrow:after {
  opacity: 0;
  visibility: hidden;
}

.card-corporate .card-title a.collapsed {
  border-bottom-width: 0;
}

.card-corporate .card-title a.collapsed .card-arrow:after {
  opacity: 1;
  visibility: visible;
}

.card-corporate .card-arrow {
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  right: 17px;
  z-index: 2;
  transition: 0.33s all ease;
}

.card-corporate .card-arrow::before,
.card-corporate .card-arrow::after {
  position: absolute;
  top: 50%;
  z-index: 4;
  transform: translateY(-50%);
  color: #262f3c;
  font-family: "Material Design Icons";
  transition: 0.23s all ease;
}

.card-corporate .card-arrow::before {
  content: "\f465";
}

.card-corporate .card-arrow::after {
  content: "\f504";
}

.card-corporate .card-body {
  position: relative;
  z-index: 1;
  padding-bottom: 20px;
  color: #8491a5;
}

.card-corporate .collapsing {
  transition-timing-function: ease-in-out;
}

@media (min-width: 768px) {
  .card-corporate .card-title a {
    padding: 30px 75px 30px 0;
    font-size: 18px;
  }
  .card-corporate .card-arrow {
    right: 50px;
  }
  .card-corporate .card-body {
    padding: 0 50px 35px 0;
  }
}

@media (min-width: 992px) {
  .card-corporate .card-title a {
    font-size: 22px;
  }
}

@media (min-width: 1200px) {
  .card-corporate .card-body {
    padding-right: 140px;
  }
  .card-corporate .card-text {
    font-size: 17px;
    line-height: 1.52941;
    font-weight: 400;
  }
}

@media (min-width: 1600px) {
  .card-corporate .card-title a {
    padding: 35px 60px 35px 0;
    font-size: 26px;
  }
}

.card-group-custom.card-group-classic .card + .card {
  margin-top: 20px;
}

@media (min-width: 1200px) {
  .card-group-custom.card-group-classic .card + .card {
    margin-top: 30px;
  }
}

.card-classic {
  position: relative;
  overflow: hidden;
  text-align: left;
  border: 1px solid #d7d7d7;
  border-radius: 10px;
  transition: 0.35s ease;
}

.card-classic.active {
  box-shadow: 0 0 24px 0 rgba(75, 81, 91, 0.12);
  background: #f9f8f8;
  border-color: #f9f8f8;
  transition: 0.15s ease;
}

.card-classic.active .card-shape {
  opacity: 1;
  visibility: visible;
}

.card-classic .card-title a,
.card-classic .card-body {
  padding-left: 25px;
}

.card-classic .card-title a {
  position: relative;
  z-index: 1;
  padding: 25px 60px 25px 25px;
  font-family: "Samsung";
  font-weight: 500;
  font-size: 17px;
  line-height: 1.4;
  letter-spacing: 0.015em;
  color: #2c343b;
  background-color: transparent;
}

.card-classic .card-title a .card-arrow:after {
  opacity: 0;
  visibility: hidden;
}

.card-classic .card-title a.collapsed .card-arrow::before,
.card-classic .card-title a.collapsed .card-arrow::after {
  color: #8491a5;
}

.card-classic .card-title a.collapsed .card-arrow::before {
  opacity: 0;
  visibility: hidden;
}

.card-classic .card-title a.collapsed .card-arrow::after {
  opacity: 1;
  visibility: visible;
}

.card-classic .card-arrow {
  position: absolute;
  top: 24px;
  right: 20px;
  z-index: 2;
  height: 28px;
  width: 28px;
  transition: 0.33s all ease;
  text-align: center;
}

.card-classic .card-arrow::before,
.card-classic .card-arrow::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  font-family: "Material Design Icons";
  font-size: 22px;
  transition: 0.15s;
  color: #0accbe;
}

.card-classic .card-arrow::before {
  content: "\f24f";
  opacity: 1;
  visibility: visible;
}

.card-classic .card-arrow::after {
  content: "\f236";
  opacity: 0;
  visibility: hidden;
}

.card-classic .card-body {
  position: relative;
  z-index: 1;
  padding: 0 44px 25px 25px;
  letter-spacing: 0.025em;
}

.card-classic .card-shape {
  position: absolute;
  top: 5%;
  left: 30px;
  fill: rgba(10, 204, 190, 0.08);
  width: auto;
  height: 90%;
  max-height: 156px;
  opacity: 0;
  visibility: hidden;
  transition: 0.33s;
  pointer-events: none;
}

@media (min-width: 1200px) {
  .card-classic .card-title a {
    padding: 35px 60px 35px 45px;
    font-size: 22px;
  }
  .card-classic .card-arrow {
    top: 31px;
    right: 35px;
  }
  .card-classic .card-arrow::before,
  .card-classic .card-arrow::after {
    font-size: 28px;
  }
  .card-classic .card-body {
    padding: 0 44px 25px 45px;
  }
  .card-classic .card-shape {
    top: 30px;
  }
}

/*
*
* Tooltop Custom
*/
.tooltip {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 15px;
  line-height: 1.2;
  letter-spacing: 0.05em;
}

.tooltip-inner {
  border-radius: 0;
}

.tooltip-light .tooltip-inner {
  padding: 15px 16px;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0 7px 16px 0 rgba(0, 0, 0, 0.08);
  color: #2c343b;
}

.tooltip-light.bs-tooltip-top .arrow::before {
  border-top-color: #ffffff;
}

.tooltip-light.bs-tooltip-right .arrow::before {
  border-right-color: #ffffff;
}

.tooltip-light.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #ffffff;
}

.tooltip-light.bs-tooltip-left .arrow::before {
  border-left-color: #ffffff;
}

.tooltip-point {
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 10px;
  background: #0accbe;
  box-shadow: 0 0 9.5px 0.5px rgba(30, 34, 44, 0.3);
  transition: opacity 0.5s ease-out, transform 0.25s;
  transform: scale(1);
  backface-visibility: hidden;
  cursor: pointer;
}

.tooltip-point,
.tooltip-point::before,
.tooltip-point::after {
  border-radius: 50%;
}

.tooltip-point::before,
.tooltip-point::after {
  content: "";
  position: absolute;
  top: -43px;
  left: -43px;
  z-index: -1;
  width: 100px;
  height: 100px;
  background: rgba(10, 204, 190, 0.4);
  pointer-events: none;
}

.tooltip-point::before {
  animation: preview-marker-pulsar 1.7s infinite ease-out;
}

.tooltip-point::after {
  animation: preview-marker-pulsar-inner 1.7s infinite ease-out;
}

.tooltip-point:hover::after,
.tooltip-point:hover::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: none;
}

@keyframes preview-marker-pulsar-inner {
  0% {
    opacity: 0;
    transform: scale(0.1);
  }
  24%,
  90% {
    opacity: 0;
  }
  25% {
    opacity: 0.65;
    transform: scale(0.1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes preview-marker-pulsar {
  0% {
    opacity: 0.65;
    transform: scale(0.1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

/*
*
* Counter
*/
.counter-classic {
  text-align: center;
}

.counter-classic-main {
  font-family: "Samsung";
  font-size: 40px;
  line-height: 1;
  font-weight: 200;
  color: #000000;
  white-space: nowrap;
}

.counter-classic-main > * {
  display: inline-block;
  vertical-align: bottom;
}

.counter-classic-main .small {
  position: relative;
  top: -0.05em;
  font-size: 39px;
  line-height: 1;
  font-weight: 300;
}

* + .counter-classic-title {
  margin-top: 10px;
}

@media (min-width: 768px) {
  .counter-classic-main {
    font-size: 50px;
  }
  * + .counter-classic-title {
    margin-top: 15px;
  }
}

@media (min-width: 992px) {
  .counter-classic-main {
    font-size: 60px;
  }
}

@media (min-width: 1200px) {
  .counter-classic-main {
    font-size: 70px;
  }
}

/*
*
* Countdown
*/
.countdown-modern .countdown-row {
  display: flex;
  margin-left: -25px;
  margin-right: -25px;
}

.countdown-modern .countdown-section {
  width: 100%;
  max-width: 25%;
  padding: 0 20px;
  text-align: center;
}

.countdown-modern .countdown-amount {
  display: block;
  font-family: "Samsung";
  font-weight: 400;
  letter-spacing: 0.04em;
  color: #0accbe;
  font-size: 32px;
  line-height: 1;
  min-width: 2em;
}

.countdown-modern .countdown-period {
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-size: 10px;
  line-height: 1.2;
  color: #262f3c;
}

.countdown-modern .countdown-section + .countdown-section {
  position: relative;
}

.countdown-modern .countdown-section + .countdown-section::before,
.countdown-modern .countdown-section + .countdown-section::after {
  content: "";
  position: absolute;
  left: 0;
  width: 5px;
  height: 5px;
  background: #ebebeb;
}

.countdown-modern .countdown-section + .countdown-section::before {
  top: 30%;
}

.countdown-modern .countdown-section + .countdown-section::after {
  bottom: 30%;
}

* + .countdown-modern {
  margin-top: 25px;
}

.countdown-modern + * {
  margin-top: 15px;
}

@media (max-width: 380px) {
  .countdown-modern .countdown-section:last-child {
    display: none;
  }
}

@media (min-width: 768px) {
  .countdown-modern .countdown-amount {
    font-size: 60px;
  }
  .countdown-modern .countdown-period {
    font-size: 12px;
    letter-spacing: 0.34em;
  }
  * + .countdown-modern {
    margin-top: 40px;
  }
  .countdown-modern + * {
    margin-top: 25px;
  }
}

@media (min-width: 992px) {
  .countdown-modern .countdown-section + .countdown-section::before,
  .countdown-modern .countdown-section + .countdown-section::after {
    width: 9px;
    height: 9px;
  }
  .countdown-modern + * {
    margin-top: 50px;
  }
}

@media (min-width: 1600px) {
  .countdown-modern .countdown-amount {
    font-size: 84px;
  }
}

/** @section Countdown */
.countdown {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.2;
  text-align: center;
  margin-bottom: -15px;
  margin-left: -15px;
}

.countdown:empty {
  margin-bottom: 0;
  margin-left: 0;
}

.countdown > * {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 15px;
  margin-left: 15px;
}

@media (min-width: 768px) {
  .countdown {
    margin-bottom: -30px;
    margin-left: -30px;
  }
  .countdown:empty {
    margin-bottom: 0;
    margin-left: 0;
  }
  .countdown > * {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 30px;
    margin-left: 30px;
  }
}

.countdown-block {
  position: relative;
  flex: 1 1 33.33%;
}

@media (min-width: 576px) {
  .countdown-block {
    flex: 1 1 25%;
  }
}

.countdown-block-seconds {
  display: none;
}

@media (min-width: 576px) {
  .countdown-block-seconds {
    display: block;
  }
}

.countdown-wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.countdown-circle {
  fill: transparent;
}

html:not(.ie-11):not(.ie-10) .countdown-circle {
  width: 100%;
  height: auto;
}

.countdown-circle-bg {
  stroke: white;
  stroke-width: 5px;
}

.countdown-circle-fg {
  stroke: #0accbe;
  stroke-width: 10px;
}

.countdown-counter {
  line-height: 1;
  letter-spacing: 0;
}

.countdown-title {
  font-size: 13px;
  line-height: 1.2;
  text-transform: uppercase;
  color: #868e96;
}

* + .countdown {
  margin-top: 35px;
}

.countdown + * {
  margin-top: 35px;
}

@media (min-width: 768px) {
  .countdown-circle-bg {
    stroke-width: 3px;
  }
  .countdown-circle-fg {
    stroke-width: 5px;
  }
}

/*
*
* Progress Bars
*/
/** @section Progress Bars */
.progress-linear {
  position: relative;
  text-align: left;
}

.progress-linear-body {
  height: 9px;
  background: #f4f3f3;
}

.progress-linear-bar {
  width: 0;
  height: inherit;
  background: #0accbe;
  transition: 1s all ease-in-out;
}

* + .progress-linear-body {
  margin-top: 8px;
}

.progress-linear + .progress-linear {
  margin-top: 15px;
}

@media (min-width: 992px) {
  .progress-linear + .progress-linear {
    margin-top: 25px;
  }
}

.progress-linear {
  position: relative;
  text-align: left;
}

.progress-linear-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: translate3d(0, -10px, 0);
  margin-bottom: -10px;
  margin-left: -5px;
  margin-right: -5px;
  font-size: 11px;
  font-family: "Samsung";
  letter-spacing: 0.34em;
  line-height: 1.2;
  text-transform: uppercase;
  color: #8491a5;
}

.progress-linear-header > * {
  margin-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.progress-linear-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
}

.progress-linear .progress-linear-body,
.progress-linear .progress-linear-bar {
  border-radius: 5px;
}

.progress-linear .progress-bar-linear-wrap {
  height: 9px;
  background: #f4f3f3;
}

.progress-linear .progress-linear-bar {
  width: 0;
  height: inherit;
  background: #0accbe;
  transition: 0.5s all ease-in-out;
}

.progress-linear .progress-linear-counter {
  font-weight: 800;
  color: #262f3c;
  letter-spacing: 0.15em;
}

.progress-linear .progress-linear-counter::after {
  content: "\0025";
}

.progress-linear-secondary .progress-linear-bar {
  background: #5f67df;
}

.progress-linear-gradient-1 .progress-linear-bar {
  background: linear-gradient(90deg, #4c47c6 0%, #6e7ff3 100%);
}

.progress-linear-gradient-2 .progress-linear-bar {
  background: linear-gradient(3deg, #a3ede0 0%, #68dcf5 100%);
}

.progress-linear-gradient-3 .progress-linear-bar {
  background: linear-gradient(3deg, #f8bcc3 0%, #b4c7fe 100%);
}

* + .progress-bar-linear-wrap {
  margin-top: 8px;
}

.progress-linear + .progress-linear {
  margin-top: 20px;
}

@media (min-width: 992px) {
  .progress-linear + .progress-linear {
    margin-top: 40px;
  }
}

/** @section Progress Circle */
.progress-circle {
  display: inline-block;
  text-align: center;
  line-height: 1.2;
}

.progress-circle-block {
  position: relative;
}

.progress-circle-bg {
  fill: transparent;
  stroke: #f5f5f5;
  stroke-width: 5px;
}

.progress-circle-fg {
  fill: transparent;
  stroke: #16b6d2;
  stroke-width: 5px;
}

.progress-circle-counter {
  position: absolute;
  top: 50%;
  left: 51%;
  font-size: 36px;
  line-height: 36px;
  transform: translate(-50%, -50%);
  color: #262f3c;
}

.progress-circle-counter::after {
  content: "%";
  display: inline-block;
  margin-left: 4px;
  opacity: 0.45;
  font-size: 0.7em;
}

.progress-circle-title {
  letter-spacing: 0.25em;
  font-size: 11px;
  font-weight: 600;
  line-height: 1.4;
  text-transform: uppercase;
  color: #262f3c;
  font-family: "Samsung";
}

* + .progress-bar-circle-title {
  margin-top: 12px;
}

/*
*
* Time Circles
*/
.time_circles {
  position: relative;
  width: 100%;
}

.time_circles > div {
  position: absolute;
  top: 0 !important;
  bottom: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -10px;
  text-align: center;
}

@media (min-width: 1200px) {
  #DateCountdown {
    width: 100%;
  }
}

.time_circles > div > h4 {
  position: absolute;
  left: 0;
  top: calc(100% + 10px);
  right: 0;
  transform: translateY(-100%);
  margin: 0;
  padding: 0;
  text-align: center;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
  font-size: 13px !important;
  line-height: 1.2 !important;
  color: #868e96;
  text-transform: uppercase;
}

.time_circles > div > h4 + * {
  margin-top: 0;
}

.time_circles > div > span {
  display: block;
  text-align: center;
  letter-spacing: 0;
  font-size: 24px !important;
  font-weight: 400;
  line-height: 1 !important;
  color: #3f4856;
}

@media (min-width: 768px) {
  .time_circles > div > span {
    font-size: 34px !important;
  }
}

@media (min-width: 992px) {
  .time_circles > div > span {
    font-size: 48px !important;
  }
}

.context-dark .time_circles > div > span,
.bg-gray-900 .time_circles > div > span,
.bg-accent .time_circles > div > span {
  color: #ffffff;
}

.countdown-wrap {
  max-width: 730px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20px;
}

* + .countdown-wrap {
  margin-top: 35px;
}

.countdown-wrap + .button {
  margin-top: 40px;
}

/*
*
* jScrollPane
*/
.jspContainer {
  overflow: hidden;
  position: relative;
}

.jspPane {
  position: absolute;
  right: 10px;
}

.jspVerticalBar {
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 100%;
  background: #ebebeb;
  pointer-events: auto;
}

.jspHorizontalBar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 16px;
  background: #ebebeb;
  pointer-events: auto;
}

.jspCap {
  display: none;
}

.jspHorizontalBar .jspCap {
  float: left;
}

.jspTrack {
  background: #ebebeb;
  position: relative;
}

.jspDrag {
  background: #0accbe;
  width: 8px;
  position: relative;
  top: 0;
  left: 0;
  cursor: pointer;
}

.jspHorizontalBar .jspTrack,
.jspHorizontalBar .jspDrag {
  float: left;
  height: 100%;
}

/*
* Arrow 
*/
.jspArrow {
  background: #50506d;
  text-indent: -20000px;
  display: block;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.jspArrow.jspDisabled {
  cursor: default;
  background: #80808d;
}

.jspVerticalBar .jspArrow {
  height: 16px;
}

.jspHorizontalBar .jspArrow {
  width: 16px;
  float: left;
  height: 100%;
}

.jspVerticalBar .jspArrow:focus {
  outline: none;
}

.jspCorner {
  background: #eeeef4;
  float: left;
  height: 100%;
}

/*
*
* Owl Carousel
*/
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* 
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
  cursor: pointer;
  user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
  display: none;
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/* 
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}

/* 
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  font: 400 40px/80px "FontAwesome";
  cursor: pointer;
  z-index: 1;
  transition: scale 100ms ease;
}

.owl-carousel .owl-video-play-icon:before {
  content: "\f144";
}

.owl-carousel .owl-video-play-icon:hover {
  transform: scale(1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
}

/*
 * Owl Navigation
 */
.owl-nav.disabled {
  display: none !important;
}

.owl-prev,
.owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  /* border-radius: 50%; */
  border: 1px solid #000000 !important;
  font: 400 18px/40px "Material Design Icons";
  /* color: #262f3c;
  background: #f9f8f8; */
  color: #000000 !important;
  background: #ffffff !important;
  cursor: pointer;
  transition: 0.33s;
  text-align: center;
}

.owl-prev:hover,
.owl-next:hover {
  /* color: #0accbe; */
  color: #ffffff !important;
  background: #000000 !important;
}
/* button.owl-next,
button.owl-prev {
  color: #000000 !important;
  border: 1px solid #000000 !important;
} */

@media (min-width: 768px) {
  .owl-prev,
  .owl-next {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 24px;
  }
}

.owl-prev {
  left: 0;
}

.owl-prev::before {
  content: "" !important;
}

.owl-next {
  right: 0;
}

.owl-next::before {
  content: "" !important;
}

.owl-prev.disabled,
.owl-next.disabled {
  display: none !important;
}

/*
 * Owl Pagination
 */
.owl-dots {
  text-align: center;
  margin-top: 25px;
}

.owl-dots.disabled {
  display: none !important;
}

.owl-dot {
  position: relative;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-left: 6px;
  margin-right: 6px;
  text-align: center;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  transition: 0.2s;
  background: #ebebeb;
}

.owl-dot:only-child {
  display: none;
}

.owl-dot:hover,
.owl-dot:focus {
  background-color: #0accbe;
}

.owl-dot.active {
  background-color: #0accbe;
}

@media (min-width: 1200px) {
  .owl-carousel-main .owl-dot {
    display: none;
  }
}

.owl-carousel_profile-modern .owl-stage-outer {
  margin: -25px;
  padding: 25px;
}

.owl-carousel_profile-modern .owl-item {
  flex-grow: 1;
  flex-shrink: 0;
  opacity: 0;
  transition: 0.22s;
}

.owl-carousel_profile-modern .owl-item.active {
  opacity: 1;
}

* + .owl-carousel_profile-modern {
  margin-top: 35px;
}

@media (min-width: 768px) {
  .owl-carousel_profile-modern .owl-dots {
    margin-top: 40px;
  }
  * + .owl-carousel_profile-modern {
    margin-top: 40px;
  }
}

@media (min-width: 1200px) {
  .owl-carousel_profile-modern .owl-dots {
    margin-top: 60px;
  }
  * + .owl-carousel_profile-modern {
    margin-top: 60px;
  }
}

.owl-carousel_type-1 {
  padding: 30px 15px 40px;
  border-radius: 7px;
  background: #ffffff;
  box-shadow: 0 0 29px 0 rgba(75, 81, 91, 0.17);
}
.team-carousel {
  padding: 0 2rem;
}
.team-carousel .owl-next,
.team-carousel .owl-prev {
  margin-top: -2rem !important;
}

.owl-carousel_type-1 .owl-dots {
  margin-top: 35px;
}

.owl-carousel_type-1 .owl-nav {
  display: none !important;
}

* + .owl-carousel_type-1 {
  margin-top: 30px;
}

@media (min-width: 576px) {
  .owl-carousel_type-1 {
    padding: 50px;
  }
}
.mdi-24px {
  font-size: 50px;
}
@media (min-width: 768px) {
  .owl-carousel_type-1 {
    padding: 50px 90px;
  }
  .owl-carousel_type-1 .quote-classic {
    max-width: 710px;
    margin-left: auto;
    margin-right: auto;
  }
  .owl-carousel_type-1 .owl-nav {
    display: block !important;
  }
  .owl-carousel_type-1 .owl-prev {
    left: 15px;
  }
  .owl-carousel_type-1 .owl-next {
    right: 15px;
  }
  * + .owl-carousel_type-1 {
    margin-top: 40px;
  }
}

@media (min-width: 992px) {
  .owl-carousel_type-1 {
    padding: 60px 150px 65px;
  }
  .owl-carousel_type-1 .owl-prev {
    left: 40px;
  }
  .owl-carousel_type-1 .owl-next {
    right: 40px;
  }
  .owl-carousel_type-1 .owl-dots {
    margin-top: 50px;
  }
  * + .owl-carousel_type-1 {
    margin-top: 50px;
  }
}

@media (min-width: 1200px) {
  .owl-carousel_type-1 .owl-prev {
    left: 70px;
  }
  .owl-carousel_type-1 .owl-next {
    right: 70px;
  }
}

.owl-carousel_type-2 .owl-stage-outer {
  padding: 24px;
  margin: -24px;
}

.owl-carousel_type-2 .owl-stage {
  display: flex;
  align-items: center;
}

.owl-carousel_type-2 .owl-item {
  flex-grow: 1;
  flex-shrink: 0;
}

.owl-carousel_type-2 .owl-item:not(.active) > * {
  box-shadow: none;
}

.owl-carousel_type-2 .owl-next,
.owl-carousel_type-2 .owl-prev {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}

.owl-carousel_type-2 .owl-next.disabled,
.owl-carousel_type-2 .owl-prev.disabled {
  display: none;
}

@media (max-width: 575.98px) {
  .owl-carousel_type-2 .owl-next,
  .owl-carousel_type-2 .owl-prev {
    background: rgba(249, 248, 248, 0.5);
  }
  .owl-carousel_type-2 .owl-prev {
    left: -5px;
  }
  .owl-carousel_type-2 .owl-next {
    right: -5px;
  }
}

@media (max-width: 1199.98px) {
  .owl-carousel_type-2 .pt-classic {
    animation: none !important;
  }
}

@media (min-width: 768px) {
  .owl-carousel_type-2 .owl-prev {
    left: 0;
    transform: translate(-50%, -50%);
  }
  .owl-carousel_type-2 .owl-next {
    right: 0;
    transform: translate(50%, -50%);
  }
}

.owl-carousel_type-3 .owl-stage-outer {
  padding: 30px;
  margin: -30px;
  pointer-events: none;
}

.owl-carousel_type-3 .owl-stage {
  display: flex;
  pointer-events: auto;
}

.owl-carousel_type-3 .owl-item {
  display: flex;
  padding: 1px;
  float: none;
}

.owl-carousel_type-3 .owl-item > * {
  width: 100%;
}

.owl-carousel_type-3 .owl-item:not(.active) > * {
  box-shadow: none;
}

* + .owl-carousel_type-3 {
  margin-top: 35px;
}

@media (min-width: 768px) {
  .owl-carousel_type-3 .owl-dots {
    margin-top: 25px;
  }
}

@media (min-width: 1200px) {
  .owl-carousel_type-3 .owl-dots {
    margin-top: 50px;
  }
  * + .owl-carousel_type-3 {
    margin-top: 60px;
  }
}

.owl-carousel-box-modern .owl-stage {
  display: flex;
}

.owl-carousel-box-modern .owl-item {
  display: flex;
}

.owl-carousel-box-modern .owl-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: -1px;
  pointer-events: none;
  border: 1px solid #ebebeb;
  height: 15%;
  border-bottom: 0;
}

.owl-carousel-box-modern .box-modern {
  position: relative;
  background-color: transparent;
  box-shadow: none;
  padding-left: 30px;
  padding-right: 30px;
}

.owl-carousel-box-modern .box-modern::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: -1px;
  pointer-events: none;
  border: 1px solid #ebebeb;
  height: 15%;
  border-top: 0;
}

.owl-carousel-box-modern .owl-prev,
.owl-carousel-box-modern .owl-next {
  top: 47%;
}

@media (max-width: 991.98px) {
  .owl-carousel-box-modern .owl-nav {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .owl-carousel-box-modern .owl-dots {
    display: none !important;
  }
}

* + .owl-carousel-box-modern {
  margin-top: 25px;
}

@media (min-width: 992px) {
  html:not(.tablet):not(.mobile) .owl-carousel-box-modern .owl-stage-outer {
    padding: 50px 0;
    margin: -50px 0;
  }
  html:not(.tablet):not(.mobile) .owl-carousel-box-modern .owl-item::after {
    bottom: 10%;
    transition: 0.44s;
  }
  html:not(.tablet):not(.mobile)
    .owl-carousel-box-modern
    .owl-item:hover::after {
    bottom: 0;
  }
  html:not(.tablet):not(.mobile) .owl-carousel-box-modern .box-modern::before,
  html:not(.tablet):not(.mobile) .owl-carousel-box-modern .box-modern::after {
    bottom: 13%;
    transition: 0.44s;
  }
  html:not(.tablet):not(.mobile) .owl-carousel-box-modern .box-modern::before {
    content: "";
    position: absolute;
    left: 50%;
    z-index: 2;
    transform: translate3d(-50%, 0, 0);
    height: 4px;
    background: #5f67df;
    opacity: 0;
    visibility: hidden;
    width: 0;
  }
  html:not(.tablet):not(.mobile) .owl-carousel-box-modern .box-modern-text {
    padding: 0 25px;
    opacity: 0;
    visibility: hidden;
    transition: 0.44s;
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 0% 0%, 100% 0%);
    clip-path: polygon(0% 0%, 100% 0%, 0% 0%, 100% 0%);
  }
  html:not(.tablet):not(.mobile)
    .owl-carousel-box-modern
    .box-modern:hover::before,
  html:not(.tablet):not(.mobile)
    .owl-carousel-box-modern
    .box-modern:hover::after {
    bottom: 1%;
  }
  html:not(.tablet):not(.mobile)
    .owl-carousel-box-modern
    .box-modern:hover::before {
    width: 82px;
    opacity: 1;
    visibility: visible;
  }
  html:not(.tablet):not(.mobile)
    .owl-carousel-box-modern
    .box-modern:hover
    .box-modern-text {
    opacity: 1;
    visibility: visible;
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0 100%);
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0 100%);
  }
}

@media (min-width: 1200px) {
  .owl-carousel-box-modern .owl-item {
    padding-top: 35px;
  }
}

.owl-carousel-centered .owl-stage {
  display: flex;
}

.owl-carousel-centered .owl-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.owl-carousel-box-light {
  position: relative;
  overflow: hidden;
  border-radius: 7px;
  background: #ffffff;
}

.owl-carousel-box-light .box-light {
  padding: 30px 20px;
  background: transparent;
}

.owl-carousel-box-light .box-light > * {
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
}

.owl-carousel-box-light .owl-dots {
  margin-top: 0;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .owl-carousel-box-light .owl-dots {
    display: none !important;
  }
}

.owl-carousel-box-light-nav {
  display: flex;
  align-items: center;
}

.owl-carousel-box-light-nav .owl-prev,
.owl-carousel-box-light-nav .owl-next {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: none;
  background-color: transparent;
  color: #262f3c;
  border: 1px solid;
}

.owl-carousel-box-light-nav .owl-prev::before,
.owl-carousel-box-light-nav .owl-next::before {
  position: relative;
  top: -2px;
}

.owl-carousel-box-light-nav .owl-prev.disabled,
.owl-carousel-box-light-nav .owl-next.disabled {
  display: block !important;
  color: #8491a5;
}

.owl-carousel-box-light-nav .owl-prev + .owl-next {
  margin-left: 10px;
}

.context-dark .owl-carousel-box-light-nav .owl-prev,
.bg-gray-900 .owl-carousel-box-light-nav .owl-prev,
.bg-accent .owl-carousel-box-light-nav .owl-prev,
.context-dark .owl-carousel-box-light-nav .owl-next,
.bg-gray-900 .owl-carousel-box-light-nav .owl-next,
.bg-accent .owl-carousel-box-light-nav .owl-next {
  color: #ffffff;
}

.context-dark .owl-carousel-box-light-nav .owl-prev.disabled,
.bg-gray-900 .owl-carousel-box-light-nav .owl-prev.disabled,
.bg-accent .owl-carousel-box-light-nav .owl-prev.disabled,
.context-dark .owl-carousel-box-light-nav .owl-next.disabled,
.bg-gray-900 .owl-carousel-box-light-nav .owl-next.disabled,
.bg-accent .owl-carousel-box-light-nav .owl-next.disabled {
  opacity: 0.7;
}

@media (min-width: 576px) {
  .owl-carousel-box-light .owl-item {
    position: relative;
  }
  .owl-carousel-box-light .owl-item::before,
  .owl-carousel-box-light .owl-item::after {
    content: "";
    position: absolute;
    right: 0;
    border-right: 1px solid #ebebeb;
  }
  .owl-carousel-box-light .owl-item::before {
    top: 0;
    height: 55px;
  }
  .owl-carousel-box-light .owl-item::after {
    content: "";
    bottom: 0;
    height: 33%;
  }
}

@media (min-width: 768px) {
  .owl-carousel-box-light .box-light {
    padding: 45px 25px;
  }
  .owl-carousel-box-light .owl-prev,
  .owl-carousel-box-light .owl-next {
    border-width: 2px;
  }
  .owl-carousel-box-light .owl-prev + .owl-next {
    margin-left: 20px;
  }
}

@media (min-width: 1200px) {
  .owl-carousel-box-light .box-light {
    padding-top: 55px;
    padding-bottom: 65px;
  }
}

.owl-carousel-gallery-1 {
  pointer-events: none;
}

.owl-carousel-gallery-1,
.owl-carousel-gallery-1 * {
  backface-visibility: hidden;
}

.owl-carousel-gallery-1 .owl-dots {
  pointer-events: auto;
}

.owl-carousel-gallery-1 .owl-stage-outer {
  padding: 76px;
  margin: -76px;
}

.owl-carousel-gallery-1 .owl-item .item {
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: block;
  width: 100%;
  background-position: center top;
  background-size: cover;
  padding-bottom: 124%;
  border-radius: 8px;
  box-shadow: 0 0 76px 0 rgba(75, 81, 91, 0.13);
  pointer-events: auto;
}

.owl-carousel-gallery-1 .owl-item .item:hover .item-text {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.owl-carousel-gallery-1 .item-text {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
  padding: 60px 10px 10px;
  font: 700 15px/22px "Samsung";
  letter-spacing: 0.05em;
  text-transform: uppercase;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  transform: translate3d(0, 5%, 0);
  white-space: nowrap;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.owl-carousel-gallery-1 .item-text::after {
  position: relative;
  top: 1px;
  display: block;
  margin-left: 5px;
  font-size: 16px;
  content: "\f14f";
  font-family: "Material Design Icons";
  vertical-align: middle;
}

@media (min-width: 768px) {
  .owl-carousel-gallery-1 .owl-item {
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: transform 0.22s, opacity 0.1s;
    transform-origin: 50% 10%;
  }
  .owl-carousel-gallery-1 .owl-item.active {
    opacity: 1;
    visibility: visible;
    transition: 0.22s;
  }
}

@media (min-width: 1200px) {
  .owl-carousel-gallery-1 .owl-stage {
    display: flex;
    align-items: center;
  }
  .owl-carousel-gallery-1 .owl-item .item {
    width: 20.83333vw;
    max-width: 100%;
  }
  .owl-carousel-gallery-1 .owl-item.active + .owl-item.active {
    margin-left: -42px;
  }
  .owl-carousel-gallery-1 .owl-item.active + .owl-item.active {
    z-index: 2;
  }
  .owl-carousel-gallery-1 .owl-item.active + .owl-item.active .item {
    width: 17.70833vw;
    padding-bottom: 114.11765%;
  }
  .owl-carousel-gallery-1
    .owl-item.active
    + .owl-item.active
    + .owl-item.active {
    z-index: 1;
  }
  .owl-carousel-gallery-1
    .owl-item.active
    + .owl-item.active
    + .owl-item.active
    .item {
    width: 16.25vw;
    padding-bottom: 101.2987%;
  }
  .owl-carousel-gallery-1 .owl-dots {
    display: none !important;
  }
}

.lg-sub-html,
.lg-toolbar {
  background-color: rgba(0, 0, 0, 0.45);
}

/* @font-face {
  font-family: lg;
  src: url(../../fonts/lg.eot?n1z373);
  src: url(../../fonts/lg.eot?#iefixn1z373) format("embedded-opentype"),
    url(../../fonts/lg.woff?n1z373) format("woff"),
    url(../../fonts/lg.ttf?n1z373) format("truetype"),
    url(../../fonts/lg.svg?n1z373#lg) format("svg");
  font-weight: 400;
  font-style: normal;
} */

.lg-icon {
  font-family: lg;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lg-actions .lg-next,
.lg-actions .lg-prev {
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  color: #999;
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  position: absolute;
  top: 50%;
  z-index: 1180;
  border: none;
  outline: 0;
}

.lg-actions .lg-next.disabled,
.lg-actions .lg-prev.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.lg-actions .lg-next:hover,
.lg-actions .lg-prev:hover {
  color: #fff;
}

.lg-actions .lg-next {
  right: 20px;
}

.lg-actions .lg-next:before {
  content: "\e095";
}

.lg-actions .lg-prev {
  left: 20px;
}

.lg-actions .lg-prev:after {
  content: "\e094";
}

@-webkit-keyframes lg-right-end {
  0%,
  100% {
    left: 0;
  }
  50% {
    left: -30px;
  }
}

@-moz-keyframes lg-right-end {
  0%,
  100% {
    left: 0;
  }
  50% {
    left: -30px;
  }
}

@-ms-keyframes lg-right-end {
  0%,
  100% {
    left: 0;
  }
  50% {
    left: -30px;
  }
}

@keyframes lg-right-end {
  0%,
  100% {
    left: 0;
  }
  50% {
    left: -30px;
  }
}

@-webkit-keyframes lg-left-end {
  0%,
  100% {
    left: 0;
  }
  50% {
    left: 30px;
  }
}

@-moz-keyframes lg-left-end {
  0%,
  100% {
    left: 0;
  }
  50% {
    left: 30px;
  }
}

@-ms-keyframes lg-left-end {
  0%,
  100% {
    left: 0;
  }
  50% {
    left: 30px;
  }
}

@keyframes lg-left-end {
  0%,
  100% {
    left: 0;
  }
  50% {
    left: 30px;
  }
}

.lg-outer.lg-right-end .lg-object {
  -webkit-animation: lg-right-end 0.3s;
  -o-animation: lg-right-end 0.3s;
  animation: lg-right-end 0.3s;
  position: relative;
}

.lg-outer.lg-left-end .lg-object {
  -webkit-animation: lg-left-end 0.3s;
  -o-animation: lg-left-end 0.3s;
  animation: lg-left-end 0.3s;
  position: relative;
}

.lg-toolbar {
  z-index: 1182;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.lg-toolbar .lg-icon {
  color: #999;
  cursor: pointer;
  float: right;
  font-size: 24px;
  height: 47px;
  line-height: 27px;
  padding: 10px 0;
  text-align: center;
  width: 50px;
  text-decoration: none !important;
  outline: 0;
  transition: color 0.2s linear;
}

@media (max-width: 575.98px) {
  .lg-toolbar .lg-icon {
    font-size: 21px;
    width: 36px;
  }
}

@media (max-width: 575.98px) {
  #lg-counter {
    font-size: 14px;
    line-height: 27px;
  }
}

.lg-toolbar .lg-icon:hover {
  color: #fff;
}

.lg-toolbar .lg-close:after {
  content: "\e070";
}

.lg-toolbar .lg-download:after {
  content: "\e0f2";
}

.lg-sub-html {
  bottom: 0;
  color: #eee;
  font-size: 16px;
  left: 0;
  padding: 10px 40px;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 1180;
}

.lg-sub-html h4 {
  margin: 0;
  font-size: 13px;
  font-weight: 700;
}

.lg-sub-html p {
  font-size: 12px;
  margin: 5px 0 0;
}

#lg-counter {
  color: #999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  vertical-align: middle;
}

.lg-next,
.lg-prev,
.lg-toolbar {
  opacity: 1;
  transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
}

.lg-hide-items .lg-prev {
  opacity: 0;
  transform: translate3d(-10px, 0, 0);
}

.lg-hide-items .lg-next {
  opacity: 0;
  transform: translate3d(10px, 0, 0);
}

.lg-hide-items .lg-toolbar {
  opacity: 0;
  transform: translate3d(0, -10px, 0);
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

body:not(.lg-from-hash)
  .lg-outer.lg-start-zoom
  .lg-item.lg-complete
  .lg-object {
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.lg-outer .lg-thumb-outer {
  background-color: #0d0a0a;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 1180;
  max-height: 350px;
  transform: translate3d(0, 100%, 0);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
  transition-duration: 0s !important;
}

.lg-outer.lg-thumb-open .lg-thumb-outer {
  transform: translate3d(0, 0, 0);
}

.lg-outer .lg-thumb {
  padding: 10px 0;
  height: 100%;
  margin-bottom: -5px;
  margin-left: auto;
  margin-right: auto;
}

.lg-outer .lg-thumb-item {
  cursor: pointer;
  float: left;
  overflow: hidden;
  height: 100%;
  border: 2px solid #fff;
  border-radius: 4px;
  margin-bottom: 5px;
}

@media (min-width: 1025px) {
  .lg-outer .lg-thumb-item {
    transition: border-color 0.25s ease;
  }
}

.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
  border-color: #a90707;
}

.lg-outer .lg-thumb-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.lg-outer.lg-has-thumb .lg-item {
  padding-bottom: 120px;
}

.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0;
}

.lg-outer.lg-pull-caption-up .lg-sub-html {
  transition: bottom 0.25s ease;
}

.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
  bottom: 100px;
}

.lg-outer .lg-toogle-thumb {
  background-color: #0d0a0a;
  border-radius: 2px 2px 0 0;
  color: #999;
  cursor: pointer;
  font-size: 24px;
  height: 39px;
  line-height: 27px;
  padding: 5px 0;
  position: absolute;
  right: 20px;
  text-align: center;
  top: -39px;
  width: 50px;
}

.lg-outer .lg-toogle-thumb:hover,
.lg-outer.lg-dropdown-active #lg-share {
  color: #fff;
}

.lg-outer .lg-toogle-thumb:after {
  content: "\e1ff";
}

.lg-outer .lg-video-cont {
  display: inline-block;
  vertical-align: middle;
  max-width: 1140px;
  max-height: 100%;
  width: 100%;
  padding: 0 5px;
}

.lg-outer .lg-video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative;
}

.lg-outer .lg-video .lg-object {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.lg-outer .lg-video .lg-video-play {
  width: 84px;
  height: 59px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -42px;
  margin-top: -30px;
  z-index: 1180;
  cursor: pointer;
}

.lg-outer .lg-has-iframe .lg-video {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}
/* 
.lg-outer .lg-has-vimeo .lg-video-play {
  background: url(../images/vimeo-play.png) no-repeat;
} */

/* .lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url(../images/vimeo-play.png) 0 -58px no-repeat;
} */

.lg-outer .lg-has-html5 .lg-video-play {
  /* background: url(../images/video-play.png) no-repeat; */
  height: 64px;
  margin-left: -32px;
  margin-top: -32px;
  width: 64px;
  opacity: 0.8;
}

.lg-outer .lg-has-html5:hover .lg-video-play {
  opacity: 1;
}

/* .lg-outer .lg-has-youtube .lg-video-play {
  background: url(../images/youtube-play.png) no-repeat;
} */

/* .lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url(../images/youtube-play.png) 0 -60px no-repeat;
} */

.lg-outer .lg-video-object {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
}

.lg-outer .lg-has-video .lg-video-object {
  visibility: hidden;
}

.lg-outer .lg-has-video.lg-video-playing .lg-object,
.lg-outer .lg-has-video.lg-video-playing .lg-video-play {
  display: none;
}

.lg-outer .lg-has-video.lg-video-playing .lg-video-object {
  visibility: visible;
}

.lg-progress-bar {
  background-color: #333;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1183;
  opacity: 0;
  transition: opacity 80ms ease 0s;
}

.lg-progress-bar .lg-progress {
  background-color: #a90707;
  height: 5px;
  width: 0;
}

.lg-progress-bar.lg-start .lg-progress {
  width: 100%;
}

.lg-show-autoplay .lg-progress-bar {
  opacity: 1;
}

.lg-autoplay-button:after {
  content: "\e01d";
}

.lg-show-autoplay .lg-autoplay-button:after {
  content: "\e01a";
}

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image,
.lg-outer.lg-css3.lg-zoom-dragging
  .lg-item.lg-complete.lg-zoomable
  .lg-img-wrap {
  transition-duration: 0s;
}

.lg-outer.lg-use-transition-for-zoom
  .lg-item.lg-complete.lg-zoomable
  .lg-img-wrap {
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-outer.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s,
    top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
  transform: scale3d(1, 1, 1);
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

#lg-zoom-in:after {
  content: "\e311";
}

#lg-actual-size {
  font-size: 20px;
}

#lg-actual-size:after {
  content: "\e033";
}

#lg-zoom-out {
  opacity: 0.5;
  pointer-events: none;
}

#lg-zoom-out:after {
  content: "\e312";
}

.lg-zoomed #lg-zoom-out {
  opacity: 1;
  pointer-events: auto;
}

.lg-outer .lg-pager-outer {
  bottom: 60px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1180;
  height: 10px;
}

.lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
  overflow: visible;
}

.lg-outer .lg-pager-cont {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  margin: 0 5px;
}

.lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
  box-shadow: 0 0 0 2px #fff inset;
}

.lg-outer .lg-pager-thumb-cont {
  background-color: #fff;
  color: #fff;
  bottom: 100%;
  height: 83px;
  left: 0;
  margin-bottom: 20px;
  margin-left: -60px;
  opacity: 0;
  padding: 5px;
  position: absolute;
  width: 120px;
  border-radius: 3px;
  transition: opacity 0.15s ease 0s, transform 0.15s ease 0s;
  transform: translate3d(0, 5px, 0);
}

.lg-outer .lg-pager-thumb-cont img {
  width: 100%;
  height: 100%;
}

.lg-outer .lg-pager {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
  display: block;
  height: 12px;
  transition: box-shadow 0.3s ease 0s;
  width: 12px;
}

.lg-outer .lg-pager:focus,
.lg-outer .lg-pager:hover {
  box-shadow: 0 0 0 8px #fff inset;
}

.lg-outer .lg-caret {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px dashed;
  bottom: -10px;
  display: inline-block;
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  vertical-align: middle;
  width: 0;
}

.lg-fullscreen:after {
  content: "\e20c";
}

.lg-fullscreen-on .lg-fullscreen:after {
  content: "\e20d";
}

.lg-outer #lg-dropdown-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
  cursor: default;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1181;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
}

.lg-outer.lg-dropdown-active #lg-dropdown-overlay,
.lg-outer.lg-dropdown-active .lg-dropdown {
  transition-delay: 0s;
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
  visibility: visible;
}

.lg-outer .lg-dropdown {
  background-color: #fff;
  border-radius: 2px;
  font-size: 14px;
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 50px;
  opacity: 0;
  visibility: hidden;
  -moz-transform: translate3d(0, 5px, 0);
  -ms-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  transition: transform 0.18s linear 0s, visibility 0s linear 0.5s,
    opacity 0.18s linear 0s;
}

.lg-outer .lg-dropdown:after {
  content: "";
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  border: 8px solid transparent;
  border-bottom-color: #fff;
  right: 16px;
  top: -16px;
}

.lg-outer .lg-dropdown > li:last-child {
  margin-bottom: 0;
}

.lg-outer .lg-dropdown > li:hover .lg-icon,
.lg-outer .lg-dropdown > li:hover a {
  color: #333;
}

.lg-outer .lg-dropdown a {
  color: #333;
  display: block;
  white-space: pre;
  padding: 4px 12px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
}

.lg-outer .lg-dropdown a:hover {
  background-color: rgba(0, 0, 0, 0.07);
}

.lg-outer .lg-dropdown .lg-dropdown-text {
  display: inline-block;
  line-height: 1;
  margin-top: -3px;
  vertical-align: middle;
}

.lg-outer .lg-dropdown .lg-icon {
  color: #333;
  display: inline-block;
  float: none;
  font-size: 20px;
  height: auto;
  line-height: 1;
  margin-right: 8px;
  padding: 0;
  vertical-align: middle;
  width: auto;
}

.lg-outer,
.lg-outer .lg,
.lg-outer .lg-inner {
  width: 100%;
  height: 100%;
}

.lg-outer #lg-share {
  position: relative;
}

.lg-outer #lg-share:after {
  content: "\e80d";
}

.lg-outer #lg-share-facebook .lg-icon {
  color: #3b5998;
}

.lg-outer #lg-share-facebook .lg-icon:after {
  content: "\e901";
}

.lg-outer #lg-share-twitter .lg-icon {
  color: #00aced;
}

.lg-outer #lg-share-twitter .lg-icon:after {
  content: "\e904";
}

.lg-outer #lg-share-googleplus .lg-icon {
  color: #dd4b39;
}

.lg-outer #lg-share-googleplus .lg-icon:after {
  content: "\e902";
}

.lg-outer #lg-share-pinterest .lg-icon {
  color: #cb2027;
}

.lg-outer #lg-share-pinterest .lg-icon:after {
  content: "\e903";
}

.lg-group:after {
  content: "";
  display: table;
  clear: both;
}

.lg-outer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1150;
  text-align: left;
  opacity: 0;
  transition: opacity 0.15s ease 0s;
}

.lg-outer * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.lg-outer.lg-visible {
  opacity: 1;
}

.lg-outer.lg-css3 .lg-item.lg-current,
.lg-outer.lg-css3 .lg-item.lg-next-slide,
.lg-outer.lg-css3 .lg-item.lg-prev-slide {
  transition-duration: inherit !important;
  transition-timing-function: inherit !important;
}

.lg-outer.lg-css3.lg-dragging .lg-item.lg-current,
.lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide,
.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide {
  transition-duration: 0s !important;
  opacity: 1;
}

.lg-outer.lg-grab img.lg-object {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.lg-outer .lg {
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 100%;
}

.lg-outer .lg-inner {
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
}

.lg-outer .lg-item {
  /* background: url(../images/loading.gif) center center no-repeat; */
  display: none !important;
}

.lg-outer.lg-css .lg-current,
.lg-outer.lg-css3 .lg-current,
.lg-outer.lg-css3 .lg-next-slide,
.lg-outer.lg-css3 .lg-prev-slide {
  display: inline-block !important;
}

.lg-outer .lg-img-wrap,
.lg-outer .lg-item {
  display: inline-block;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.lg-outer .lg-img-wrap:before,
.lg-outer .lg-item:before {
  content: "";
  display: inline-block;
  height: 50%;
  width: 1px;
  margin-right: -1px;
}

.lg-outer .lg-img-wrap {
  position: absolute;
  padding: 0 5px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.lg-outer .lg-item.lg-complete {
  background-image: none;
}

.lg-outer .lg-item.lg-current {
  z-index: 1160;
}

.lg-outer .lg-image {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  width: auto !important;
  height: auto !important;
}

.lg-outer.lg-show-after-load .lg-item .lg-object,
.lg-outer.lg-show-after-load .lg-item .lg-video-play {
  opacity: 0;
  transition: opacity 0.15s ease 0s;
}

.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object,
.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
  opacity: 1;
}

.lg-outer .lg-empty-html,
.lg-outer.lg-hide-download #lg-download {
  display: none;
}

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1140;
  background-color: #000;
  opacity: 0;
  transition: opacity 0.15s ease 0s;
}

.lg-css3.lg-no-trans .lg-current,
.lg-css3.lg-no-trans .lg-next-slide,
.lg-css3.lg-no-trans .lg-prev-slide {
  transition: none 0s ease 0s !important;
}

.lg-css3.lg-use-css3 .lg-item,
.lg-css3.lg-use-left .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.lg-css3.lg-fade .lg-item {
  opacity: 0;
}

.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1;
}

.lg-css3.lg-fade .lg-item.lg-current,
.lg-css3.lg-fade .lg-item.lg-next-slide,
.lg-css3.lg-fade .lg-item.lg-prev-slide {
  transition: opacity 0.1s ease 0s;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  transform: translate3d(-100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  transform: translate3d(100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current,
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide,
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-slide.lg-use-left .lg-item {
  opacity: 0;
  position: absolute;
  left: 0;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
  left: -100%;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
  left: 100%;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  left: 0;
  opacity: 1;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current,
.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide,
.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
  transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-sub-html,
.lg-toolbar {
  background-color: rgba(0, 0, 0, 0.45);
}

/* @font-face {
  font-family: lg;
  src: url(../../fonts/lg.eot?n1z373);
  src: url(../../fonts/lg.eot?#iefixn1z373) format("embedded-opentype"),
    url(../../fonts/lg.woff?n1z373) format("woff"),
    url(../../fonts/lg.ttf?n1z373) format("truetype"),
    url(../../fonts/lg.svg?n1z373#lg) format("svg");
  font-weight: 400;
  font-style: normal;
} */

.lg-icon {
  font-family: lg;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lg-actions .lg-next,
.lg-actions .lg-prev {
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  color: #999;
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  position: absolute;
  top: 50%;
  z-index: 1180;
  border: none;
  outline: 0;
}

.lg-actions .lg-next.disabled,
.lg-actions .lg-prev.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.lg-actions .lg-next:hover,
.lg-actions .lg-prev:hover {
  color: #fff;
}

.lg-actions .lg-next {
  right: 20px;
}

.lg-actions .lg-next:before {
  content: "\e095";
}

.lg-actions .lg-prev {
  left: 20px;
}

.lg-actions .lg-prev:after {
  content: "\e094";
}

@-webkit-keyframes lg-right-end {
  0%,
  100% {
    left: 0;
  }
  50% {
    left: -30px;
  }
}

@-moz-keyframes lg-right-end {
  0%,
  100% {
    left: 0;
  }
  50% {
    left: -30px;
  }
}

@-ms-keyframes lg-right-end {
  0%,
  100% {
    left: 0;
  }
  50% {
    left: -30px;
  }
}

@keyframes lg-right-end {
  0%,
  100% {
    left: 0;
  }
  50% {
    left: -30px;
  }
}

@-webkit-keyframes lg-left-end {
  0%,
  100% {
    left: 0;
  }
  50% {
    left: 30px;
  }
}

@-moz-keyframes lg-left-end {
  0%,
  100% {
    left: 0;
  }
  50% {
    left: 30px;
  }
}

@-ms-keyframes lg-left-end {
  0%,
  100% {
    left: 0;
  }
  50% {
    left: 30px;
  }
}

@keyframes lg-left-end {
  0%,
  100% {
    left: 0;
  }
  50% {
    left: 30px;
  }
}

.lg-outer.lg-right-end .lg-object {
  -webkit-animation: lg-right-end 0.3s;
  -o-animation: lg-right-end 0.3s;
  animation: lg-right-end 0.3s;
  position: relative;
}

.lg-outer.lg-left-end .lg-object {
  -webkit-animation: lg-left-end 0.3s;
  -o-animation: lg-left-end 0.3s;
  animation: lg-left-end 0.3s;
  position: relative;
}

.lg-toolbar {
  z-index: 1182;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.lg-toolbar .lg-icon:hover {
  color: #fff;
}

.lg-toolbar .lg-close:after {
  content: "\e070";
}

.lg-toolbar .lg-download:after {
  content: "\e0f2";
}

.lg-sub-html {
  bottom: 0;
  color: #eee;
  font-size: 16px;
  left: 0;
  padding: 10px 40px;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 1180;
}

.lg-sub-html h4 {
  margin: 0;
  font-size: 13px;
  font-weight: 700;
}

.lg-sub-html p {
  font-size: 12px;
  margin: 5px 0 0;
}

#lg-counter {
  color: #999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  vertical-align: middle;
}

.lg-next,
.lg-prev,
.lg-toolbar {
  opacity: 1;
  transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
}

.lg-hide-items .lg-prev {
  opacity: 0;
  transform: translate3d(-10px, 0, 0);
}

.lg-hide-items .lg-next {
  opacity: 0;
  transform: translate3d(10px, 0, 0);
}

.lg-hide-items .lg-toolbar {
  opacity: 0;
  transform: translate3d(0, -10px, 0);
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

body:not(.lg-from-hash)
  .lg-outer.lg-start-zoom
  .lg-item.lg-complete
  .lg-object {
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.lg-outer .lg-thumb-outer {
  background-color: #0d0a0a;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 1180;
  max-height: 350px;
  transform: translate3d(0, 100%, 0);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
  transition-duration: 0s !important;
}

.lg-outer.lg-thumb-open .lg-thumb-outer {
  transform: translate3d(0, 0, 0);
}

.lg-outer .lg-thumb {
  padding: 10px 0;
  height: 100%;
  margin-bottom: -5px;
}

.lg-outer .lg-thumb-item {
  cursor: pointer;
  float: left;
  overflow: hidden;
  height: 100%;
  border: 2px solid rgba(255, 255, 255, 0.4);
  border-radius: 0;
  margin-bottom: 5px;
}

@media (min-width: 1025px) {
  .lg-outer .lg-thumb-item {
    transition: border-color 0.25s ease;
  }
}

.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
  border-color: #0accbe;
}

.lg-outer .lg-thumb-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0;
}

.lg-outer.lg-has-thumb .lg-item {
  padding-bottom: 120px;
}

.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0;
}

.lg-outer.lg-pull-caption-up .lg-sub-html {
  transition: bottom 0.25s ease;
}

.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
  bottom: 100px;
}

.lg-outer .lg-toogle-thumb {
  background-color: #0d0a0a;
  border-radius: 2px 2px 0 0;
  color: #999;
  cursor: pointer;
  font-size: 24px;
  height: 39px;
  line-height: 27px;
  padding: 5px 0;
  position: absolute;
  right: 20px;
  text-align: center;
  top: -39px;
  width: 50px;
}

.lg-outer .lg-toogle-thumb:hover,
.lg-outer.lg-dropdown-active #lg-share {
  color: #fff;
}

.lg-outer .lg-toogle-thumb:after {
  content: "\e1ff";
}

.lg-outer .lg-video-cont {
  display: inline-block;
  vertical-align: middle;
  max-width: 1140px;
  max-height: 100%;
  width: 100%;
  padding: 0 5px;
}

.lg-outer .lg-video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative;
}

.lg-outer .lg-video .lg-object {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.lg-outer .lg-video .lg-video-play {
  width: 84px;
  height: 59px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -42px;
  margin-top: -30px;
  z-index: 1180;
  cursor: pointer;
}

.lg-outer .lg-has-iframe .lg-video {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}

/* .lg-outer .lg-has-vimeo .lg-video-play {
  background: url(../images/vimeo-play.png) no-repeat;
}

.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url(../images/vimeo-play.png) 0 -58px no-repeat;
} */

.lg-outer .lg-has-html5 .lg-video-play {
  /* background: url(../images/video-play.png) no-repeat; */
  height: 64px;
  margin-left: -32px;
  margin-top: -32px;
  width: 64px;
  opacity: 0.8;
}

.lg-outer .lg-has-html5:hover .lg-video-play {
  opacity: 1;
}

/* .lg-outer .lg-has-youtube .lg-video-play {
  background: url(../images/youtube-play.png) no-repeat;
}

.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url(../images/youtube-play.png) 0 -60px no-repeat;
} */

.lg-outer .lg-video-object {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
}

.lg-outer .lg-has-video .lg-video-object {
  visibility: hidden;
}

.lg-outer .lg-has-video.lg-video-playing .lg-object,
.lg-outer .lg-has-video.lg-video-playing .lg-video-play {
  display: none;
}

.lg-outer .lg-has-video.lg-video-playing .lg-video-object {
  visibility: visible;
}

.lg-progress-bar {
  background-color: #333;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1183;
  opacity: 0;
  transition: opacity 80ms ease 0s;
}

.lg-progress-bar .lg-progress {
  background-color: #0accbe;
  height: 5px;
  width: 0;
}

.lg-progress-bar.lg-start .lg-progress {
  width: 100%;
}

.lg-show-autoplay .lg-progress-bar {
  opacity: 1;
}

.lg-autoplay-button:after {
  content: "\e01d";
}

.lg-show-autoplay .lg-autoplay-button:after {
  content: "\e01a";
}

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image,
.lg-outer.lg-css3.lg-zoom-dragging
  .lg-item.lg-complete.lg-zoomable
  .lg-img-wrap {
  transition-duration: 0s;
}

.lg-outer.lg-use-transition-for-zoom
  .lg-item.lg-complete.lg-zoomable
  .lg-img-wrap {
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-outer.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s,
    top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
  transform: scale3d(1, 1, 1);
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

#lg-zoom-in:after {
  content: "\e311";
}

#lg-actual-size {
  font-size: 20px;
}

#lg-actual-size:after {
  content: "\e033";
}

#lg-zoom-out {
  opacity: 0.5;
  pointer-events: none;
}

#lg-zoom-out:after {
  content: "\e312";
}

.lg-zoomed #lg-zoom-out {
  opacity: 1;
  pointer-events: auto;
}

.lg-outer .lg-pager-outer {
  bottom: 60px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1180;
  height: 10px;
}

.lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
  overflow: visible;
}

.lg-outer .lg-pager-cont {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  margin: 0 5px;
}

.lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
  box-shadow: 0 0 0 2px #fff inset;
}

.lg-outer .lg-pager-thumb-cont {
  background-color: #fff;
  color: #fff;
  bottom: 100%;
  height: 83px;
  left: 0;
  margin-bottom: 20px;
  margin-left: -60px;
  opacity: 0;
  padding: 5px;
  position: absolute;
  width: 120px;
  border-radius: 3px;
  transition: opacity 0.15s ease 0s, transform 0.15s ease 0s;
  transform: translate3d(0, 5px, 0);
}

.lg-outer .lg-pager-thumb-cont img {
  width: 100%;
  height: 100%;
}

.lg-outer .lg-pager {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
  display: block;
  height: 12px;
  transition: box-shadow 0.3s ease 0s;
  width: 12px;
}

.lg-outer .lg-pager:focus,
.lg-outer .lg-pager:hover {
  box-shadow: 0 0 0 8px #fff inset;
}

.lg-outer .lg-caret {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px dashed;
  bottom: -10px;
  display: inline-block;
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  vertical-align: middle;
  width: 0;
}

.lg-fullscreen:after {
  content: "\e20c";
}

.lg-fullscreen-on .lg-fullscreen:after {
  content: "\e20d";
}

.lg-outer #lg-dropdown-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
  cursor: default;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1181;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
}

.lg-outer.lg-dropdown-active #lg-dropdown-overlay,
.lg-outer.lg-dropdown-active .lg-dropdown {
  transition-delay: 0s;
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
  visibility: visible;
}

.lg-outer .lg-dropdown {
  background-color: #fff;
  border-radius: 2px;
  font-size: 14px;
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 50px;
  opacity: 0;
  visibility: hidden;
  -moz-transform: translate3d(0, 5px, 0);
  -ms-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  transition: transform 0.18s linear 0s, visibility 0s linear 0.5s,
    opacity 0.18s linear 0s;
}

.lg-outer .lg-dropdown:after {
  content: "";
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  border: 8px solid transparent;
  border-bottom-color: #fff;
  right: 16px;
  top: -16px;
}

.lg-outer .lg-dropdown > li:last-child {
  margin-bottom: 0;
}

.lg-outer .lg-dropdown > li:hover .lg-icon,
.lg-outer .lg-dropdown > li:hover a {
  color: #333;
}

.lg-outer .lg-dropdown a {
  color: #333;
  display: block;
  white-space: pre;
  padding: 4px 12px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
}

.lg-outer .lg-dropdown a:hover {
  background-color: rgba(0, 0, 0, 0.07);
}

.lg-outer .lg-dropdown .lg-dropdown-text {
  display: inline-block;
  line-height: 1;
  margin-top: -3px;
  vertical-align: middle;
}

.lg-outer .lg-dropdown .lg-icon {
  color: #333;
  display: inline-block;
  float: none;
  font-size: 20px;
  height: auto;
  line-height: 1;
  margin-right: 8px;
  padding: 0;
  vertical-align: middle;
  width: auto;
}

.lg-outer,
.lg-outer .lg,
.lg-outer .lg-inner {
  width: 100%;
  height: 100%;
}

.lg-outer #lg-share {
  position: relative;
}

.lg-outer #lg-share:after {
  content: "\e80d";
}

.lg-outer #lg-share-facebook .lg-icon {
  color: #3b5998;
}

.lg-outer #lg-share-facebook .lg-icon:after {
  content: "\e901";
}

.lg-outer #lg-share-twitter .lg-icon {
  color: #00aced;
}

.lg-outer #lg-share-twitter .lg-icon:after {
  content: "\e904";
}

.lg-outer #lg-share-googleplus .lg-icon {
  color: #dd4b39;
}

.lg-outer #lg-share-googleplus .lg-icon:after {
  content: "\e902";
}

.lg-outer #lg-share-pinterest .lg-icon {
  color: #cb2027;
}

.lg-outer #lg-share-pinterest .lg-icon:after {
  content: "\e903";
}

.lg-group:after {
  content: "";
  display: table;
  clear: both;
}

.lg-outer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1150;
  text-align: left;
  opacity: 0;
  transition: opacity 0.15s ease 0s;
}

.lg-outer * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.lg-outer.lg-visible {
  opacity: 1;
}

.lg-outer.lg-css3 .lg-item.lg-current,
.lg-outer.lg-css3 .lg-item.lg-next-slide,
.lg-outer.lg-css3 .lg-item.lg-prev-slide {
  transition-duration: inherit !important;
  transition-timing-function: inherit !important;
}

.lg-outer.lg-css3.lg-dragging .lg-item.lg-current,
.lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide,
.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide {
  transition-duration: 0s !important;
  opacity: 1;
}

.lg-outer.lg-grab img.lg-object {
  cursor: grab;
}

.lg-outer.lg-grabbing img.lg-object {
  cursor: grabbing;
}

.lg-outer .lg {
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 100%;
}

.lg-outer .lg-inner {
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
}

.lg-outer .lg-item {
  /* background: url(../images/loading.gif) center center no-repeat; */
  display: none !important;
}

.lg-outer.lg-css .lg-current,
.lg-outer.lg-css3 .lg-current,
.lg-outer.lg-css3 .lg-next-slide,
.lg-outer.lg-css3 .lg-prev-slide {
  display: inline-block !important;
}

.lg-outer .lg-img-wrap,
.lg-outer .lg-item {
  display: inline-block;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.lg-outer .lg-img-wrap:before,
.lg-outer .lg-item:before {
  content: "";
  display: inline-block;
  height: 50%;
  width: 1px;
  margin-right: -1px;
}

.lg-outer .lg-img-wrap {
  position: absolute;
  padding: 0 5px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.lg-outer .lg-item.lg-complete {
  background-image: none;
}

.lg-outer .lg-item.lg-current {
  z-index: 1160;
}

.lg-outer .lg-image {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  width: auto !important;
  height: auto !important;
}

.lg-outer.lg-show-after-load .lg-item .lg-object,
.lg-outer.lg-show-after-load .lg-item .lg-video-play {
  opacity: 0;
  transition: opacity 0.15s ease 0s;
}

.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object,
.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
  opacity: 1;
}

.lg-outer .lg-empty-html,
.lg-outer.lg-hide-download #lg-download {
  display: none;
}

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1140;
  background-color: #000;
  opacity: 0;
  transition: opacity 0.15s ease 0s;
}

.lg-backdrop.in {
  opacity: 0.8;
}

.lg-css3.lg-no-trans .lg-current,
.lg-css3.lg-no-trans .lg-next-slide,
.lg-css3.lg-no-trans .lg-prev-slide {
  transition: none 0s ease 0s !important;
}

.lg-css3.lg-use-css3 .lg-item,
.lg-css3.lg-use-left .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.lg-css3.lg-fade .lg-item {
  opacity: 0;
}

.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1;
}

.lg-css3.lg-fade .lg-item.lg-current,
.lg-css3.lg-fade .lg-item.lg-next-slide,
.lg-css3.lg-fade .lg-item.lg-prev-slide {
  transition: opacity 0.1s ease 0s;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  transform: translate3d(-100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  transform: translate3d(100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current,
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide,
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-slide.lg-use-left .lg-item {
  opacity: 0;
  position: absolute;
  left: 0;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
  left: -100%;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
  left: 100%;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  left: 0;
  opacity: 1;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current,
.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide,
.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
  transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lightgallery-iframe .lg-toolbar {
  background-color: transparent;
}

.lightgallery-iframe .lg-close ~ * {
  display: none;
}

/*
* Material Parallax
*/
.parallax-content {
  position: relative;
  z-index: 1;
}

.parallax-container {
  position: relative;
  overflow: hidden;
  background-position: center center;
  background-size: cover;
}

@supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
  .parallax-container {
    overflow: visible;
  }
  .material-parallax {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

.material-parallax {
  position: absolute;
  top: 0;
  left: -1px;
  right: -1px;
  bottom: 0;
  z-index: 0;
  background-position: center center;
}

.ipad .parallax-container,
.iphone .parallax-container {
  background-attachment: scroll !important;
}

.material-parallax {
  pointer-events: none;
}

.material-parallax img {
  display: none;
  position: absolute;
  left: 50%;
  bottom: 0;
  min-width: 101%;
  min-height: 101%;
  max-width: none;
  transform: translate3d(-50%, 0, 0);
}

[data-x-mode="true"] .material-parallax {
  pointer-events: auto;
}

.parallax-outer .parallax-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}

/*
*
* Slick Carousel
*/
/*rtl:begin:ignore*/
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}

.slick-track::before,
.slick-track::after {
  content: "";
  display: table;
}

.slick-track::after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* .slick-loading .slick-list {
  background: #ffffff url("./../images/ajax-loader.gif") center center no-repeat;
} */

/* Icons */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 0;
  font-size: 0;
  color: #262f3c;
  background: #f9f8f8;
  border: none;
  outline: none;
  z-index: 9;
  cursor: pointer;
  transition: 0.33s;
  text-align: center;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  color: #0accbe;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.8;
}

.slick-prev:before,
.slick-next:before {
  font-family: "Material Design Icons";
  font-size: 24px;
  line-height: 1;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: 0;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: 0;
}

.slick-prev:before {
  content: "";
}

[dir="rtl"] .slick-prev:before {
  content: "";
}

.slick-next {
  right: 0;
}

[dir="rtl"] .slick-next {
  left: 0;
  right: auto;
}

.slick-next:before {
  content: "";
}

[dir="rtl"] .slick-next:before {
  content: "";
}

/* Dots */
.slick-dots {
  position: absolute;
  bottom: -45px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0;
  font-size: 0;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "Material Design Icons";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.8;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 1;
}

/*rtl:end:ignore*/
.slick-slider-2-main-inner {
  padding: 30px;
  border-radius: 7px 7px 0 0;
}

.slick-slider-2-main-inner .slick-slider {
  padding: 0 30px;
  margin-bottom: 0;
}

.slick-slider-2-aside .slick-track {
  display: flex;
}

.slick-slider-2-aside .slick-slide {
  padding: 20px;
  text-align: center;
  cursor: pointer;
  font-family: "Samsung";
  font-size: 18px;
  white-space: nowrap;
}

.slick-slider-2-aside .slick-current {
  color: #0accbe;
}

.slick-slider-2-aside .slick-slide + .slick-slide {
  border-left: 1px solid #ebebeb;
  margin-left: -1px;
}

.slick-slider-2-main-inner,
.slick-slider-2-aside-inner {
  background: #ffffff;
  box-shadow: 0 0 29px 0 rgba(75, 81, 91, 0.17);
}

.slick-slider-2-aside-inner {
  border-radius: 0 0 7px 7px;
  border-top: 1px solid #ebebeb;
}

* + .slick-slider-2-main {
  margin-top: 35px;
}

@media (max-width: 767.98px) {
  .slick-slider-2-main-inner .slick-arrow {
    background-color: transparent;
  }
  .slick-slider-2-main-inner .slick-prev {
    left: -30px;
  }
  .slick-slider-2-main-inner .slick-next {
    right: -30px;
  }
}

@media (min-width: 768px) {
  .slick-slider-2-main-inner {
    padding: 80px 25px 60px;
  }
  .slick-slider-2-main-inner .slick-slider {
    padding: 0 55px;
  }
  .slick-slider-2-aside .slick-slide {
    padding: 32px 20px;
  }
}

@media (min-width: 992px) {
  .slick-slider-2-main-inner {
    padding-left: 70px;
    padding-right: 70px;
  }
  .slick-slider-2-aside .slick-slide {
    font-size: 20px;
  }
}

@media (min-width: 1200px) {
  .slick-slider-2-aside .slick-slide {
    padding: 35px;
    font-size: 22px;
  }
  * + .slick-slider-2-main {
    margin-top: 55px;
  }
}

/*
*
* Stuck
*/
.stuck-navbar {
  padding: 0;
}

.stuck-navbar .navbar-inner {
  z-index: 100;
}

.stuck-navbar.stick .navbar-inner {
  position: absolute;
  top: auto;
  bottom: 0;
}

.stuck-navbar.stuck .navbar-inner {
  position: fixed;
  top: 55px;
}

.stuck-1 {
  position: static;
  display: flex;
}

.stuck-1 .navbar-inner {
  display: flex;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;
  border-bottom: 1px solid transparent;
  background: #ffffff;
  transition: 0.22s;
}

.stuck-1 .list-nav-classic {
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
}

.stuck-1.stuck .navbar-inner,
.stuck-1.stick .navbar-inner {
  height: 70px;
}

.stuck-1.stuck .navbar-inner {
  border-bottom-color: #ebebeb;
}

.stuck-1,
.stuck-1 .navbar-inner {
  height: 60px;
}

@media (max-width: 767.98px) {
  .stuck-1 .list-nav-classic a::after {
    display: none;
  }
}

@media (min-width: 768px) {
  .stuck-1,
  .stuck-1 .navbar-inner {
    height: 80px;
  }
}

@media (min-width: 1200px) {
  .stuck-1,
  .stuck-1 .navbar-inner {
    height: 150px;
  }
}

.rd-navbar-static-linked .stuck-1.stuck .navbar-inner {
  top: 75px;
}

.stuck-2 {
  flex-shrink: 0;
  height: 45px;
}

.stuck-2 .navbar-inner {
  width: 100%;
}

.stuck-2 .nav-tabs {
  width: auto;
  max-width: unset;
  flex-wrap: nowrap;
}

.stuck-2.stick .navbar-inner {
  position: absolute;
  bottom: 1px;
  top: auto;
}

@media (min-width: 768px) {
  .stuck-2 {
    height: auto;
  }
  .stuck-2 .nav-tabs {
    width: 100%;
  }
  .stuck-2 .navbar-inner {
    top: 0;
    width: auto;
    min-width: inherit;
  }
  .stuck-2.stuck .navbar-inner {
    padding-top: 10px;
  }
}

@media (min-width: 992px) {
  .stuck-2 {
    border-bottom: 0;
  }
  .stuck-2 .nav-tabs {
    margin-top: 0;
    padding-top: 25px;
  }
}

/*
*
* Parallax js
*/
.parallax-scene {
  overflow: hidden;
}

/*
*
* Typed text
*/
.typed-text-heading .Typewriter {
  font-size: 2.4375rem;
}
.design-text .Typewriter {
  font-size: 1.4rem;
}
.typed-text-heading {
  vertical-align: top;
}

.typed-text-heading span {
  margin-right: 0.2em;
}

.typed-text-heading .typed-cursor {
  margin-left: -0.2em;
}

.typed-cursor {
  vertical-align: text-top;
  opacity: 1;
  animation: blink 1.2s infinite;
}

.text-primary + .typed-cursor {
  color: #0accbe;
}

.context-dark .text-primary + .typed-cursor,
.bg-gray-900 .text-primary + .typed-cursor,
.bg-accent .text-primary + .typed-cursor {
  color: #ffea5c;
}

@media (min-width: 1600px) {
  .typed-text {
    max-width: 570px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}


/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us .content {
  padding: 60px 100px 0 100px;
}
.why-us .content h3 {
  font-weight: 400;
  font-size: 34px;
  color: #262f3c;
}
.why-us .content h4 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 5px;
}
.why-us .content p {
  font-size: 15px;
  color: #8491a5;
}
.why-us .img {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.why-us .accordion-list {
  padding: 10px 100px 60px 100px;
}
.why-us .accordion-list ul {
  padding: 0;
  list-style: none;
}
.why-us .accordion-list li + li {
  margin-top: 15px;
}
.why-us .accordion-list li {
  padding: 20px;
  background: #fff;
  border-radius: 14px;
}
.why-us .accordion-list a {
  display: block;
  position: relative;
  font-family: "Avenir";
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding-right: 30px;
  outline: none;
  cursor: pointer;
}
.why-us .accordion-list span {
  color: #0accbe;
  font-weight: 600;
  font-size: 18px;
  padding-right: 10px;
}
.why-us .accordion-list i {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}
.why-us li a[aria-expanded="true"]{
    color: #0accbe;
}
.why-us .accordion-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}
.why-us .accordion-list .icon-show {
  display: none;
}
.why-us .accordion-list a.collapsed {
  color: #343a40;
}
.why-us .accordion-list a.collapsed:hover {
  color: #0accbe;
}
.why-us .accordion-list a.collapsed .icon-show {
  display: inline-block;
}
.why-us .accordion-list a.collapsed .icon-close {
  display: none;
}

@media (max-width: 1024px) {
  .why-us .content,
  .why-us .accordion-list {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 992px) {
  .why-us .img {
    min-height: 400px;
  }
  .why-us .content {
    padding-top: 30px;
  }
  .why-us .accordion-list {
    padding-bottom: 30px;
  }
}
@media (max-width: 575px) {
  .why-us .img {
    min-height: 500px;
  }
  .typed-text-heading .Typewriter {
    font-size: 1.1rem;
  }
  .brochureText {
    left: 0 !important;
  }
  .smallProducts {
    gap: 1rem;
  }
  .team-carousel {
    padding: 0;
  }
}

.first {
  --lord-icon-primary: #fff;
}