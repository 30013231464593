@media only screen and (max-width: 530px) {
    .otp-input {
        width: 2rem !important;
        height: 2rem !important;
        margin: 0 0.5rem !important;
        font-size: 15px !important;
        border-radius: 4px !important;
        border: 1px solid rgba(0,0,0,0.3);
        justify-content: center;
        align-items: center;
    };
}

@media only screen and (min-width: 530px) {
    .otp-input {
        width: 3rem !important;
        height: 3rem !important;
        margin: 0 1rem !important;
        font-size: 20px !important;
        border-radius: 4px !important;
        border: 1px solid rgba(0,0,0,0.3);    
        justify-content: center;
        align-items: center;
    }
}

.focussed-otp-input {
    border-width: 2px;
}

.validateContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}