/* 
.services .icon-box {
  padding: 30px;
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
}
.services .icon-box::before {
  content: "";
  position: absolute;
  background: #e1f0fa;
  right: -60px;
  top: -40px;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  transition: all 0.3s;
  z-index: -1;
}
.services .icon-box:hover::before {
  background: #4e83f1;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 0px;
}
.services .icon {
  margin: 0 auto 20px auto;
  padding-top: 10px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background: #4e83f1;
  transition: all 0.3s ease-in-out;
}
.services .icon i {
  font-size: 36px;
  line-height: 1;
  color: #fff;
}
.services .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}
.services .title a {
  color: #111;
}
.services .description {
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 0;
}
.services .icon-box:hover .title a,
.services .icon-box:hover .description {
  color: #fff;
}
.services .icon-box:hover .icon {
  background: #fff;
}
.services .icon-box:hover .icon i {
  color: #4e83f1;
} */

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
    font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, "Helvetica Neue", Arial, sans-serif;
    padding: 50px 20px;
    margin-top: 35px;
    margin-bottom: 25px;
    text-align: center;
    height: 240px; /*earlier 260px*/
    position: relative;
    background: #161616 !important;
    border-radius: 18px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1) !important;
}
.services .icon {
    position: absolute;
    top: -36px;
    left: calc(50% - 36px);
    transition: 0.2s;
    border-radius: 50%;
    border: 6px solid #131313 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 72px;
    height: 72px;
    background: #000 !important;
}
.services .icon i {
    color: #fff;
    font-size: 24px;
    line-height: 0;
}
.services .title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
    text-transform: uppercase;
}
.services .title a {
    color: #343a40 !important;
}

.services .icon-box:hover .icon i {
    color: #4e83f1;
}
.services .icon-box:hover .title a {
    color: #4e83f1;
}
.services .description {
    line-height: 24px;
    font-size: 14px;
}

.item {
    background: rgb(255, 254, 254);
    margin-bottom: 5%;
    /* max-width: 75%; */
    border-radius: 15px;
}
.title {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.title h4:hover {
    color: #0accbe;
}
.container-fluid {
    /* background:rgb(251, 248, 248); */
    min-width: 100%;
}
.Accorcontent {
    max-height: 0px;
    height: 0px;
    overflow: hidden;
    /* transition: all 0.5s cubic-bezier(1,0,1,0); */
    /* padding-bottom: 5%; */
    margin-bottom: 10px;
    /* padding: 10px; */
}
.Accorcontent.show {
    height: auto;
    max-height: 100%;
    transition: all 1s cubic-bezier(0, 0.4, 0.6, 1);
    padding-bottom: 5%;
}
.wrapper {
    display: flex;
}
