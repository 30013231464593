/** @group Fonts */
/** @section Font Awesome Icons 4.7.0 */
/* @font-face {
	font-family: 'FontAwesome'; */
	/* src: url("./fonts/fontawesome-webfont.eot?v=4.7.0"); */
	/* src: url("./fonts/fontawesome/webfonts/fa-brands-400.eot"); */
	/* src: url("./fonts/fontawesome-webfont.eot?#iefix&v=4.7.0") format("embedded-opentype"), url("../fonts/fontawesome-webfont.woff2?v=4.7.0") format("woff2"), url("../fonts/fontawesome-webfont.woff?v=4.7.0") format("woff"), url("../fonts/fontawesome-webfont.ttf?v=4.7.0") format("truetype"), url("../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg"); */
	/* font-weight: normal;
	font-style: normal;
} */

.fa {
	display: inline-block;
	font-family: 'FontAwesome';
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class*='fa-']:before {
	font-weight: 400;
	font-family: 'FontAwesome';
}

/* makes the font 33% larger relative to the icon container */
.fa-lg {
	font-size: 1.33333333em;
	line-height: 0.75em;
	vertical-align: -15%;
}

.fa-2x {
	font-size: 2em;
}

.fa-3x {
	font-size: 3em;
}

.fa-4x {
	font-size: 4em;
}

.fa-5x {
	font-size: 5em;
}

.fa-fw {
	width: 1.28571429em;
	text-align: center;
}

.fa-ul {
	padding-left: 0;
	margin-left: 2.14285714em;
	list-style-type: none;
}

.fa-ul > li {
	position: relative;
}

.fa-li {
	position: absolute;
	left: -2.14285714em;
	width: 2.14285714em;
	top: 0.14285714em;
	text-align: center;
}

.fa-li.fa-lg {
	left: -1.85714286em;
}

.fa-border {
	padding: .2em .25em .15em;
	border: solid 0.08em #eeeeee;
	border-radius: .1em;
}

.fa-pull-left {
	float: left;
}

.fa-pull-right {
	float: right;
}

.fa.fa-pull-left {
	margin-right: .3em;
}

.fa.fa-pull-right {
	margin-left: .3em;
}

/* Deprecated as of 4.4.0 */
.pull-right {
	float: right;
}

.pull-left {
	float: left;
}

.fa.pull-left {
	margin-right: .3em;
}

.fa.pull-right {
	margin-left: .3em;
}

.fa-spin {
	-webkit-animation: fa-spin 2s infinite linear;
	animation: fa-spin 2s infinite linear;
}

.fa-pulse {
	-webkit-animation: fa-spin 1s infinite steps(8);
	animation: fa-spin 1s infinite steps(8);
}

@-webkit-keyframes fa-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

@keyframes fa-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

/* .fa-rotate-90 {
	-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

.fa-rotate-180 {
	-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

.fa-rotate-270 {
	-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
	-webkit-transform: rotate(270deg);
	-ms-transform: rotate(270deg);
	transform: rotate(270deg);
}

.fa-flip-horizontal {
	-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
	-webkit-transform: scale(-1, 1);
	-ms-transform: scale(-1, 1);
	transform: scale(-1, 1);
}

.fa-flip-vertical {
	-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
	-webkit-transform: scale(1, -1);
	-ms-transform: scale(1, -1);
	transform: scale(1, -1);
} */

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
	filter: none;
}

.fa-stack {
	position: relative;
	display: inline-block;
	width: 2em;
	height: 2em;
	line-height: 2em;
	vertical-align: middle;
}

.fa-stack-1x,
.fa-stack-2x {
	position: absolute;
	left: 0;
	width: 100%;
	text-align: center;
}

.fa-stack-1x {
	line-height: inherit;
}

.fa-stack-2x {
	font-size: 2em;
}

.fa-inverse {
	color: #ffffff;
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
.fa-glass:before {
	content: "\f000";
}

.fa-music:before {
	content: "\f001";
}

.fa-search:before {
	content: "\f002";
}

.fa-envelope-o:before {
	content: "\f003";
}

.fa-heart:before {
	content: "\f004";
}

.fa-star:before {
	content: "\f005";
}

.fa-star-o:before {
	content: "\f006";
}

.fa-user:before {
	content: "\f007";
}

.fa-film:before {
	content: "\f008";
}

.fa-th-large:before {
	content: "\f009";
}

.fa-th:before {
	content: "\f00a";
}

.fa-th-list:before {
	content: "\f00b";
}

.fa-check:before {
	content: "\f00c";
}

.fa-remove:before,
.fa-close:before,
.fa-times:before {
	content: "\f00d";
}

.fa-search-plus:before {
	content: "\f00e";
}

.fa-search-minus:before {
	content: "\f010";
}

.fa-power-off:before {
	content: "\f011";
}

.fa-signal:before {
	content: "\f012";
}

.fa-gear:before,
.fa-cog:before {
	content: "\f013";
}

.fa-trash-o:before {
	content: "\f014";
}

.fa-home:before {
	content: "\f015";
}

.fa-file-o:before {
	content: "\f016";
}

.fa-clock-o:before {
	content: "\f017";
}

.fa-road:before {
	content: "\f018";
}

.fa-download:before {
	content: "\f019";
}

.fa-arrow-circle-o-down:before {
	content: "\f01a";
}

.fa-arrow-circle-o-up:before {
	content: "\f01b";
}

.fa-inbox:before {
	content: "\f01c";
}

.fa-play-circle-o:before {
	content: "\f01d";
}

.fa-rotate-right:before,
.fa-repeat:before {
	content: "\f01e";
}

.fa-refresh:before {
	content: "\f021";
}

.fa-list-alt:before {
	content: "\f022";
}

.fa-lock:before {
	content: "\f023";
}

.fa-flag:before {
	content: "\f024";
}

.fa-headphones:before {
	content: "\f025";
}

.fa-volume-off:before {
	content: "\f026";
}

.fa-volume-down:before {
	content: "\f027";
}

.fa-volume-up:before {
	content: "\f028";
}

.fa-qrcode:before {
	content: "\f029";
}

.fa-barcode:before {
	content: "\f02a";
}

.fa-tag:before {
	content: "\f02b";
}

.fa-tags:before {
	content: "\f02c";
}

.fa-book:before {
	content: "\f02d";
}

.fa-bookmark:before {
	content: "\f02e";
}

.fa-print:before {
	content: "\f02f";
}

.fa-camera:before {
	content: "\f030";
}

.fa-font:before {
	content: "\f031";
}

.fa-bold:before {
	content: "\f032";
}

.fa-italic:before {
	content: "\f033";
}

.fa-text-height:before {
	content: "\f034";
}

.fa-text-width:before {
	content: "\f035";
}

.fa-align-left:before {
	content: "\f036";
}

.fa-align-center:before {
	content: "\f037";
}

.fa-align-right:before {
	content: "\f038";
}

.fa-align-justify:before {
	content: "\f039";
}

.fa-list:before {
	content: "\f03a";
}

.fa-dedent:before,
.fa-outdent:before {
	content: "\f03b";
}

.fa-indent:before {
	content: "\f03c";
}

.fa-video-camera:before {
	content: "\f03d";
}

.fa-photo:before,
.fa-image:before,
.fa-picture-o:before {
	content: "\f03e";
}

.fa-pencil:before {
	content: "\f040";
}

.fa-map-marker:before {
	content: "\f041";
}

.fa-adjust:before {
	content: "\f042";
}

.fa-tint:before {
	content: "\f043";
}

.fa-edit:before,
.fa-pencil-square-o:before {
	content: "\f044";
}

.fa-share-square-o:before {
	content: "\f045";
}

.fa-check-square-o:before {
	content: "\f046";
}

.fa-arrows:before {
	content: "\f047";
}

.fa-step-backward:before {
	content: "\f048";
}

.fa-fast-backward:before {
	content: "\f049";
}

.fa-backward:before {
	content: "\f04a";
}

.fa-play:before {
	content: "\f04b";
}

.fa-pause:before {
	content: "\f04c";
}

.fa-stop:before {
	content: "\f04d";
}

.fa-forward:before {
	content: "\f04e";
}

.fa-fast-forward:before {
	content: "\f050";
}

.fa-step-forward:before {
	content: "\f051";
}

.fa-eject:before {
	content: "\f052";
}

.fa-chevron-left:before {
	content: "\f053";
}

.fa-chevron-right:before {
	content: "\f054";
}

.fa-plus-circle:before {
	content: "\f055";
}

.fa-minus-circle:before {
	content: "\f056";
}

.fa-times-circle:before {
	content: "\f057";
}

.fa-check-circle:before {
	content: "\f058";
}

.fa-question-circle:before {
	content: "\f059";
}

.fa-info-circle:before {
	content: "\f05a";
}

.fa-crosshairs:before {
	content: "\f05b";
}

.fa-times-circle-o:before {
	content: "\f05c";
}

.fa-check-circle-o:before {
	content: "\f05d";
}

.fa-ban:before {
	content: "\f05e";
}

.fa-arrow-left:before {
	content: "\f060";
}

.fa-arrow-right:before {
	content: "\f061";
}

.fa-arrow-up:before {
	content: "\f062";
}

.fa-arrow-down:before {
	content: "\f063";
}

.fa-mail-forward:before,
.fa-share:before {
	content: "\f064";
}

.fa-expand:before {
	content: "\f065";
}

.fa-compress:before {
	content: "\f066";
}

.fa-plus:before {
	content: "\f067";
}

.fa-minus:before {
	content: "\f068";
}

.fa-asterisk:before {
	content: "\f069";
}

.fa-exclamation-circle:before {
	content: "\f06a";
}

.fa-gift:before {
	content: "\f06b";
}

.fa-leaf:before {
	content: "\f06c";
}

.fa-fire:before {
	content: "\f06d";
}

.fa-eye:before {
	content: "\f06e";
}

.fa-eye-slash:before {
	content: "\f070";
}

.fa-warning:before,
.fa-exclamation-triangle:before {
	content: "\f071";
}

.fa-plane:before {
	content: "\f072";
}

.fa-calendar:before {
	content: "\f073";
}

.fa-random:before {
	content: "\f074";
}

.fa-comment:before {
	content: "\f075";
}

.fa-magnet:before {
	content: "\f076";
}

.fa-chevron-up:before {
	content: "\f077";
}

.fa-chevron-down:before {
	content: "\f078";
}

.fa-retweet:before {
	content: "\f079";
}

.fa-shopping-cart:before {
	content: "\f07a";
}

.fa-folder:before {
	content: "\f07b";
}

.fa-folder-open:before {
	content: "\f07c";
}

.fa-arrows-v:before {
	content: "\f07d";
}

.fa-arrows-h:before {
	content: "\f07e";
}

.fa-bar-chart-o:before,
.fa-bar-chart:before {
	content: "\f080";
}

.fa-twitter-square:before {
	display:flex;
	align-items: center;
	justify-content: center;
	content: "\f081";
}

.fa-facebook-square:before {
	content: "\f082";
}

.fa-camera-retro:before {
	content: "\f083";
}

.fa-key:before {
	content: "\f084";
}

.fa-gears:before,
.fa-cogs:before {
	content: "\f085";
}

.fa-comments:before {
	content: "\f086";
}

.fa-thumbs-o-up:before {
	content: "\f087";
}

.fa-thumbs-o-down:before {
	content: "\f088";
}

.fa-star-half:before {
	content: "\f089";
}

.fa-heart-o:before {
	content: "\f08a";
}

.fa-sign-out:before {
	content: "\f08b";
}

.fa-linkedin-square:before {
	content: "\f08c";
}

.fa-thumb-tack:before {
	content: "\f08d";
}

.fa-external-link:before {
	content: "\f08e";
}

.fa-sign-in:before {
	content: "\f090";
}

.fa-trophy:before {
	content: "\f091";
}

.fa-github-square:before {
	content: "\f092";
}

.fa-upload:before {
	content: "\f093";
}

.fa-lemon-o:before {
	content: "\f094";
}

.fa-phone:before {
	content: "\f095";
}

.fa-square-o:before {
	content: "\f096";
}

.fa-bookmark-o:before {
	content: "\f097";
}

.fa-phone-square:before {
	content: "\f098";
}

.fa-twitter:before {
	content: "\f099";
}

.fa-facebook-f:before,
.fa-facebook:before {
	content: "\f09a";
}

.fa-github:before {
	content: "\f09b";
}

.fa-unlock:before {
	content: "\f09c";
}

.fa-credit-card:before {
	content: "\f09d";
}

.fa-feed:before,
.fa-rss:before {
	content: "\f09e";
}

.fa-hdd-o:before {
	content: "\f0a0";
}

.fa-bullhorn:before {
	content: "\f0a1";
}

.fa-bell:before {
	content: "\f0f3";
}

.fa-certificate:before {
	content: "\f0a3";
}

.fa-hand-o-right:before {
	content: "\f0a4";
}

.fa-hand-o-left:before {
	content: "\f0a5";
}

.fa-hand-o-up:before {
	content: "\f0a6";
}

.fa-hand-o-down:before {
	content: "\f0a7";
}

.fa-arrow-circle-left:before {
	content: "\f0a8";
}

.fa-arrow-circle-right:before {
	content: "\f0a9";
}

.fa-arrow-circle-up:before {
	content: "\f0aa";
}

.fa-arrow-circle-down:before {
	content: "\f0ab";
}

.fa-globe:before {
	content: "\f0ac";
}

.fa-wrench:before {
	content: "\f0ad";
}

.fa-tasks:before {
	content: "\f0ae";
}

.fa-filter:before {
	content: "\f0b0";
}

.fa-briefcase:before {
	content: "\f0b1";
}

.fa-arrows-alt:before {
	content: "\f0b2";
}

.fa-group:before,
.fa-users:before {
	content: "\f0c0";
}

.fa-chain:before,
.fa-link:before {
	content: "\f0c1";
}

.fa-cloud:before {
	content: "\f0c2";
}

.fa-flask:before {
	content: "\f0c3";
}

.fa-cut:before,
.fa-scissors:before {
	content: "\f0c4";
}

.fa-copy:before,
.fa-files-o:before {
	content: "\f0c5";
}

.fa-paperclip:before {
	content: "\f0c6";
}

.fa-save:before,
.fa-floppy-o:before {
	content: "\f0c7";
}

.fa-square:before {
	content: "\f0c8";
}

.fa-navicon:before,
.fa-reorder:before,
.fa-bars:before {
	content: "\f0c9";
}

.fa-list-ul:before {
	content: "\f0ca";
}

.fa-list-ol:before {
	content: "\f0cb";
}

.fa-strikethrough:before {
	content: "\f0cc";
}

.fa-underline:before {
	content: "\f0cd";
}

.fa-table:before {
	content: "\f0ce";
}

.fa-magic:before {
	content: "\f0d0";
}

.fa-truck:before {
	content: "\f0d1";
}

.fa-pinterest:before {
	content: "\f0d2";
}

.fa-pinterest-square:before {
	content: "\f0d3";
}

.fa-google-plus-square:before {
	content: "\f0d4";
}

.fa-google-plus:before {
	content: "\f0d5";
}

.fa-money:before {
	content: "\f0d6";
}

.fa-caret-down:before {
	content: "\f0d7";
}

.fa-caret-up:before {
	content: "\f0d8";
}

.fa-caret-left:before {
	content: "\f0d9";
}

.fa-caret-right:before {
	content: "\f0da";
}

.fa-columns:before {
	content: "\f0db";
}

.fa-unsorted:before,
.fa-sort:before {
	content: "\f0dc";
}

.fa-sort-down:before,
.fa-sort-desc:before {
	content: "\f0dd";
}

.fa-sort-up:before,
.fa-sort-asc:before {
	content: "\f0de";
}

.fa-envelope:before {
	content: "\f0e0";
}

.fa-linkedin:before {
	content: "\f0e1";
}

.fa-rotate-left:before,
.fa-undo:before {
	content: "\f0e2";
}

.fa-legal:before,
.fa-gavel:before {
	content: "\f0e3";
}

.fa-dashboard:before,
.fa-tachometer:before {
	content: "\f0e4";
}

.fa-comment-o:before {
	content: "\f0e5";
}

.fa-comments-o:before {
	content: "\f0e6";
}

.fa-flash:before,
.fa-bolt:before {
	content: "\f0e7";
}

.fa-sitemap:before {
	content: "\f0e8";
}

.fa-umbrella:before {
	content: "\f0e9";
}

.fa-paste:before,
.fa-clipboard:before {
	content: "\f0ea";
}

.fa-lightbulb-o:before {
	content: "\f0eb";
}

.fa-exchange:before {
	content: "\f0ec";
}

.fa-cloud-download:before {
	content: "\f0ed";
}

.fa-cloud-upload:before {
	content: "\f0ee";
}

.fa-user-md:before {
	content: "\f0f0";
}

.fa-stethoscope:before {
	content: "\f0f1";
}

.fa-suitcase:before {
	content: "\f0f2";
}

.fa-bell-o:before {
	content: "\f0a2";
}

.fa-coffee:before {
	content: "\f0f4";
}

.fa-cutlery:before {
	content: "\f0f5";
}

.fa-file-text-o:before {
	content: "\f0f6";
}

.fa-building-o:before {
	content: "\f0f7";
}

.fa-hospital-o:before {
	content: "\f0f8";
}

.fa-ambulance:before {
	content: "\f0f9";
}

.fa-medkit:before {
	content: "\f0fa";
}

.fa-fighter-jet:before {
	content: "\f0fb";
}

.fa-beer:before {
	content: "\f0fc";
}

.fa-h-square:before {
	content: "\f0fd";
}

.fa-plus-square:before {
	content: "\f0fe";
}

.fa-angle-double-left:before {
	content: "\f100";
}

.fa-angle-double-right:before {
	content: "\f101";
}

.fa-angle-double-up:before {
	content: "\f102";
}

.fa-angle-double-down:before {
	content: "\f103";
}

.fa-angle-left:before {
	content: "\f104";
}

.fa-angle-right:before {
	content: "\f105";
}

.fa-angle-up:before {
	content: "\f106";
}

.fa-angle-down:before {
	content: "\f107";
}

.fa-desktop:before {
	content: "\f108";
}

.fa-laptop:before {
	content: "\f109";
}

.fa-tablet:before {
	content: "\f10a";
}

.fa-mobile-phone:before,
.fa-mobile:before {
	content: "\f10b";
}

.fa-circle-o:before {
	content: "\f10c";
}

.fa-quote-left:before {
	content: "\f10d";
}

.fa-quote-right:before {
	content: "\f10e";
}

.fa-spinner:before {
	content: "\f110";
}

.fa-circle:before {
	content: "\f111";
}

.fa-mail-reply:before,
.fa-reply:before {
	content: "\f112";
}

.fa-github-alt:before {
	content: "\f113";
}

.fa-folder-o:before {
	content: "\f114";
}

.fa-folder-open-o:before {
	content: "\f115";
}

.fa-smile-o:before {
	content: "\f118";
}

.fa-frown-o:before {
	content: "\f119";
}

.fa-meh-o:before {
	content: "\f11a";
}

.fa-gamepad:before {
	content: "\f11b";
}

.fa-keyboard-o:before {
	content: "\f11c";
}

.fa-flag-o:before {
	content: "\f11d";
}

.fa-flag-checkered:before {
	content: "\f11e";
}

.fa-terminal:before {
	content: "\f120";
}

.fa-code:before {
	content: "\f121";
}

.fa-mail-reply-all:before,
.fa-reply-all:before {
	content: "\f122";
}

.fa-star-half-empty:before,
.fa-star-half-full:before,
.fa-star-half-o:before {
	content: "\f123";
}

.fa-location-arrow:before {
	content: "\f124";
}

.fa-crop:before {
	content: "\f125";
}

.fa-code-fork:before {
	content: "\f126";
}

.fa-unlink:before,
.fa-chain-broken:before {
	content: "\f127";
}

.fa-question:before {
	content: "\f128";
}

.fa-info:before {
	content: "\f129";
}

.fa-exclamation:before {
	content: "\f12a";
}

.fa-superscript:before {
	content: "\f12b";
}

.fa-subscript:before {
	content: "\f12c";
}

.fa-eraser:before {
	content: "\f12d";
}

.fa-puzzle-piece:before {
	content: "\f12e";
}

.fa-microphone:before {
	content: "\f130";
}

.fa-microphone-slash:before {
	content: "\f131";
}

.fa-shield:before {
	content: "\f132";
}

.fa-calendar-o:before {
	content: "\f133";
}

.fa-fire-extinguisher:before {
	content: "\f134";
}

.fa-rocket:before {
	content: "\f135";
}

.fa-maxcdn:before {
	content: "\f136";
}

.fa-chevron-circle-left:before {
	content: "\f137";
}

.fa-chevron-circle-right:before {
	content: "\f138";
}

.fa-chevron-circle-up:before {
	content: "\f139";
}

.fa-chevron-circle-down:before {
	content: "\f13a";
}

.fa-html5:before {
	content: "\f13b";
}

.fa-css3:before {
	content: "\f13c";
}

.fa-anchor:before {
	content: "\f13d";
}

.fa-unlock-alt:before {
	content: "\f13e";
}

.fa-bullseye:before {
	content: "\f140";
}

.fa-ellipsis-h:before {
	content: "\f141";
}

.fa-ellipsis-v:before {
	content: "\f142";
}

.fa-rss-square:before {
	content: "\f143";
}

.fa-play-circle:before {
	content: "\f144";
}

.fa-ticket:before {
	content: "\f145";
}

.fa-minus-square:before {
	content: "\f146";
}

.fa-minus-square-o:before {
	content: "\f147";
}

.fa-level-up:before {
	content: "\f148";
}

.fa-level-down:before {
	content: "\f149";
}

.fa-check-square:before {
	content: "\f14a";
}

.fa-pencil-square:before {
	content: "\f14b";
}

.fa-external-link-square:before {
	content: "\f14c";
}

.fa-share-square:before {
	content: "\f14d";
}

.fa-compass:before {
	content: "\f14e";
}

.fa-toggle-down:before,
.fa-caret-square-o-down:before {
	content: "\f150";
}

.fa-toggle-up:before,
.fa-caret-square-o-up:before {
	content: "\f151";
}

.fa-toggle-right:before,
.fa-caret-square-o-right:before {
	content: "\f152";
}

.fa-euro:before,
.fa-eur:before {
	content: "\f153";
}

.fa-gbp:before {
	content: "\f154";
}

.fa-dollar:before,
.fa-usd:before {
	content: "\f155";
}

.fa-rupee:before,
.fa-inr:before {
	content: "\f156";
}

.fa-cny:before,
.fa-rmb:before,
.fa-yen:before,
.fa-jpy:before {
	content: "\f157";
}

.fa-ruble:before,
.fa-rouble:before,
.fa-rub:before {
	content: "\f158";
}

.fa-won:before,
.fa-krw:before {
	content: "\f159";
}

.fa-bitcoin:before,
.fa-btc:before {
	content: "\f15a";
}

.fa-file:before {
	content: "\f15b";
}

.fa-file-text:before {
	content: "\f15c";
}

.fa-sort-alpha-asc:before {
	content: "\f15d";
}

.fa-sort-alpha-desc:before {
	content: "\f15e";
}

.fa-sort-amount-asc:before {
	content: "\f160";
}

.fa-sort-amount-desc:before {
	content: "\f161";
}

.fa-sort-numeric-asc:before {
	content: "\f162";
}

.fa-sort-numeric-desc:before {
	content: "\f163";
}

.fa-thumbs-up:before {
	content: "\f164";
}

.fa-thumbs-down:before {
	content: "\f165";
}

.fa-youtube-square:before {
	content: "\f166";
}

.fa-youtube:before {
	content: "\f167";
}

.fa-xing:before {
	content: "\f168";
}

.fa-xing-square:before {
	content: "\f169";
}

.fa-youtube-play:before {
	content: "\f16a";
}

.fa-dropbox:before {
	content: "\f16b";
}

.fa-stack-overflow:before {
	content: "\f16c";
}

.fa-instagram:before {
	content: "\f16d";
}

.fa-flickr:before {
	content: "\f16e";
}

.fa-adn:before {
	content: "\f170";
}

.fa-bitbucket:before {
	content: "\f171";
}

.fa-bitbucket-square:before {
	content: "\f172";
}

.fa-tumblr:before {
	content: "\f173";
}

.fa-tumblr-square:before {
	content: "\f174";
}

.fa-long-arrow-down:before {
	content: "\f175";
}

.fa-long-arrow-up:before {
	content: "\f176";
}

.fa-long-arrow-left:before {
	content: "\f177";
}

.fa-long-arrow-right:before {
	content: "\f178";
}

.fa-apple:before {
	content: "\f179";
}

.fa-windows:before {
	content: "\f17a";
}

.fa-android:before {
	content: "\f17b";
}

.fa-linux:before {
	content: "\f17c";
}

.fa-dribbble:before {
	content: "\f17d";
}

.fa-skype:before {
	content: "\f17e";
}

.fa-foursquare:before {
	content: "\f180";
}

.fa-trello:before {
	content: "\f181";
}

.fa-female:before {
	content: "\f182";
}

.fa-male:before {
	content: "\f183";
}

.fa-gittip:before,
.fa-gratipay:before {
	content: "\f184";
}

.fa-sun-o:before {
	content: "\f185";
}

.fa-moon-o:before {
	content: "\f186";
}

.fa-archive:before {
	content: "\f187";
}

.fa-bug:before {
	content: "\f188";
}

.fa-vk:before {
	content: "\f189";
}

.fa-weibo:before {
	content: "\f18a";
}

.fa-renren:before {
	content: "\f18b";
}

.fa-pagelines:before {
	content: "\f18c";
}

.fa-stack-exchange:before {
	content: "\f18d";
}

.fa-arrow-circle-o-right:before {
	content: "\f18e";
}

.fa-arrow-circle-o-left:before {
	content: "\f190";
}

.fa-toggle-left:before,
.fa-caret-square-o-left:before {
	content: "\f191";
}

.fa-dot-circle-o:before {
	content: "\f192";
}

.fa-wheelchair:before {
	content: "\f193";
}

.fa-vimeo-square:before {
	content: "\f194";
}

.fa-turkish-lira:before,
.fa-try:before {
	content: "\f195";
}

.fa-plus-square-o:before {
	content: "\f196";
}

.fa-space-shuttle:before {
	content: "\f197";
}

.fa-slack:before {
	content: "\f198";
}

.fa-envelope-square:before {
	content: "\f199";
}

.fa-wordpress:before {
	content: "\f19a";
}

.fa-openid:before {
	content: "\f19b";
}

.fa-institution:before,
.fa-bank:before,
.fa-university:before {
	content: "\f19c";
}

.fa-mortar-board:before,
.fa-graduation-cap:before {
	content: "\f19d";
}

.fa-yahoo:before {
	content: "\f19e";
}

.fa-google:before {
	content: "\f1a0";
}

.fa-reddit:before {
	content: "\f1a1";
}

.fa-reddit-square:before {
	content: "\f1a2";
}

.fa-stumbleupon-circle:before {
	content: "\f1a3";
}

.fa-stumbleupon:before {
	content: "\f1a4";
}

.fa-delicious:before {
	content: "\f1a5";
}

.fa-digg:before {
	content: "\f1a6";
}

.fa-pied-piper-pp:before {
	content: "\f1a7";
}

.fa-pied-piper-alt:before {
	content: "\f1a8";
}

.fa-drupal:before {
	content: "\f1a9";
}

.fa-joomla:before {
	content: "\f1aa";
}

.fa-language:before {
	content: "\f1ab";
}

.fa-fax:before {
	content: "\f1ac";
}

.fa-building:before {
	content: "\f1ad";
}

.fa-child:before {
	content: "\f1ae";
}

.fa-paw:before {
	content: "\f1b0";
}

.fa-spoon:before {
	content: "\f1b1";
}

.fa-cube:before {
	content: "\f1b2";
}

.fa-cubes:before {
	content: "\f1b3";
}

.fa-behance:before {
	content: "\f1b4";
}

.fa-behance-square:before {
	content: "\f1b5";
}

.fa-steam:before {
	content: "\f1b6";
}

.fa-steam-square:before {
	content: "\f1b7";
}

.fa-recycle:before {
	content: "\f1b8";
}

.fa-automobile:before,
.fa-car:before {
	content: "\f1b9";
}

.fa-cab:before,
.fa-taxi:before {
	content: "\f1ba";
}

.fa-tree:before {
	content: "\f1bb";
}

.fa-spotify:before {
	content: "\f1bc";
}

.fa-deviantart:before {
	content: "\f1bd";
}

.fa-soundcloud:before {
	content: "\f1be";
}

.fa-database:before {
	content: "\f1c0";
}

.fa-file-pdf-o:before {
	content: "\f1c1";
}

.fa-file-word-o:before {
	content: "\f1c2";
}

.fa-file-excel-o:before {
	content: "\f1c3";
}

.fa-file-powerpoint-o:before {
	content: "\f1c4";
}

.fa-file-photo-o:before,
.fa-file-picture-o:before,
.fa-file-image-o:before {
	content: "\f1c5";
}

.fa-file-zip-o:before,
.fa-file-archive-o:before {
	content: "\f1c6";
}

.fa-file-sound-o:before,
.fa-file-audio-o:before {
	content: "\f1c7";
}

.fa-file-movie-o:before,
.fa-file-video-o:before {
	content: "\f1c8";
}

.fa-file-code-o:before {
	content: "\f1c9";
}

.fa-vine:before {
	content: "\f1ca";
}

.fa-codepen:before {
	content: "\f1cb";
}

.fa-jsfiddle:before {
	content: "\f1cc";
}

.fa-life-bouy:before,
.fa-life-buoy:before,
.fa-life-saver:before,
.fa-support:before,
.fa-life-ring:before {
	content: "\f1cd";
}

.fa-circle-o-notch:before {
	content: "\f1ce";
}

.fa-ra:before,
.fa-resistance:before,
.fa-rebel:before {
	content: "\f1d0";
}

.fa-ge:before,
.fa-empire:before {
	content: "\f1d1";
}

.fa-git-square:before {
	content: "\f1d2";
}

.fa-git:before {
	content: "\f1d3";
}

.fa-y-combinator-square:before,
.fa-yc-square:before,
.fa-hacker-news:before {
	content: "\f1d4";
}

.fa-tencent-weibo:before {
	content: "\f1d5";
}

.fa-qq:before {
	content: "\f1d6";
}

.fa-wechat:before,
.fa-weixin:before {
	content: "\f1d7";
}

.fa-send:before,
.fa-paper-plane:before {
	content: "\f1d8";
}

.fa-send-o:before,
.fa-paper-plane-o:before {
	content: "\f1d9";
}

.fa-history:before {
	content: "\f1da";
}

.fa-circle-thin:before {
	content: "\f1db";
}

.fa-header:before {
	content: "\f1dc";
}

.fa-paragraph:before {
	content: "\f1dd";
}

.fa-sliders:before {
	content: "\f1de";
}

.fa-share-alt:before {
	content: "\f1e0";
}

.fa-share-alt-square:before {
	content: "\f1e1";
}

.fa-bomb:before {
	content: "\f1e2";
}

.fa-soccer-ball-o:before,
.fa-futbol-o:before {
	content: "\f1e3";
}

.fa-tty:before {
	content: "\f1e4";
}

.fa-binoculars:before {
	content: "\f1e5";
}

.fa-plug:before {
	content: "\f1e6";
}

.fa-slideshare:before {
	content: "\f1e7";
}

.fa-twitch:before {
	content: "\f1e8";
}

.fa-yelp:before {
	content: "\f1e9";
}

.fa-newspaper-o:before {
	content: "\f1ea";
}

.fa-wifi:before {
	content: "\f1eb";
}

.fa-calculator:before {
	content: "\f1ec";
}

.fa-paypal:before {
	content: "\f1ed";
}

.fa-google-wallet:before {
	content: "\f1ee";
}

.fa-cc-visa:before {
	content: "\f1f0";
}

.fa-cc-mastercard:before {
	content: "\f1f1";
}

.fa-cc-discover:before {
	content: "\f1f2";
}

.fa-cc-amex:before {
	content: "\f1f3";
}

.fa-cc-paypal:before {
	content: "\f1f4";
}

.fa-cc-stripe:before {
	content: "\f1f5";
}

.fa-bell-slash:before {
	content: "\f1f6";
}

.fa-bell-slash-o:before {
	content: "\f1f7";
}

.fa-trash:before {
	content: "\f1f8";
}

.fa-copyright:before {
	content: "\f1f9";
}

.fa-at:before {
	content: "\f1fa";
}

.fa-eyedropper:before {
	content: "\f1fb";
}

.fa-paint-brush:before {
	content: "\f1fc";
}

.fa-birthday-cake:before {
	content: "\f1fd";
}

.fa-area-chart:before {
	content: "\f1fe";
}

.fa-pie-chart:before {
	content: "\f200";
}

.fa-line-chart:before {
	content: "\f201";
}

.fa-lastfm:before {
	content: "\f202";
}

.fa-lastfm-square:before {
	content: "\f203";
}

.fa-toggle-off:before {
	content: "\f204";
}

.fa-toggle-on:before {
	content: "\f205";
}

.fa-bicycle:before {
	content: "\f206";
}

.fa-bus:before {
	content: "\f207";
}

.fa-ioxhost:before {
	content: "\f208";
}

.fa-angellist:before {
	content: "\f209";
}

.fa-cc:before {
	content: "\f20a";
}

.fa-shekel:before,
.fa-sheqel:before,
.fa-ils:before {
	content: "\f20b";
}

.fa-meanpath:before {
	content: "\f20c";
}

.fa-buysellads:before {
	content: "\f20d";
}

.fa-connectdevelop:before {
	content: "\f20e";
}

.fa-dashcube:before {
	content: "\f210";
}

.fa-forumbee:before {
	content: "\f211";
}

.fa-leanpub:before {
	content: "\f212";
}

.fa-sellsy:before {
	content: "\f213";
}

.fa-shirtsinbulk:before {
	content: "\f214";
}

.fa-simplybuilt:before {
	content: "\f215";
}

.fa-skyatlas:before {
	content: "\f216";
}

.fa-cart-plus:before {
	content: "\f217";
}

.fa-cart-arrow-down:before {
	content: "\f218";
}

.fa-diamond:before {
	content: "\f219";
}

.fa-ship:before {
	content: "\f21a";
}

.fa-user-secret:before {
	content: "\f21b";
}

.fa-motorcycle:before {
	content: "\f21c";
}

.fa-street-view:before {
	content: "\f21d";
}

.fa-heartbeat:before {
	content: "\f21e";
}

.fa-venus:before {
	content: "\f221";
}

.fa-mars:before {
	content: "\f222";
}

.fa-mercury:before {
	content: "\f223";
}

.fa-intersex:before,
.fa-transgender:before {
	content: "\f224";
}

.fa-transgender-alt:before {
	content: "\f225";
}

.fa-venus-double:before {
	content: "\f226";
}

.fa-mars-double:before {
	content: "\f227";
}

.fa-venus-mars:before {
	content: "\f228";
}

.fa-mars-stroke:before {
	content: "\f229";
}

.fa-mars-stroke-v:before {
	content: "\f22a";
}

.fa-mars-stroke-h:before {
	content: "\f22b";
}

.fa-neuter:before {
	content: "\f22c";
}

.fa-genderless:before {
	content: "\f22d";
}

.fa-facebook-official:before {
	content: "\f230";
}

.fa-pinterest-p:before {
	content: "\f231";
}

.fa-whatsapp:before {
	content: "\f232";
}

.fa-server:before {
	content: "\f233";
}

.fa-user-plus:before {
	content: "\f234";
}

.fa-user-times:before {
	content: "\f235";
}

.fa-hotel:before,
.fa-bed:before {
	content: "\f236";
}

.fa-viacoin:before {
	content: "\f237";
}

.fa-train:before {
	content: "\f238";
}

.fa-subway:before {
	content: "\f239";
}

.fa-medium:before {
	content: "\f23a";
}

.fa-yc:before,
.fa-y-combinator:before {
	content: "\f23b";
}

.fa-optin-monster:before {
	content: "\f23c";
}

.fa-opencart:before {
	content: "\f23d";
}

.fa-expeditedssl:before {
	content: "\f23e";
}

.fa-battery-4:before,
.fa-battery:before,
.fa-battery-full:before {
	content: "\f240";
}

.fa-battery-3:before,
.fa-battery-three-quarters:before {
	content: "\f241";
}

.fa-battery-2:before,
.fa-battery-half:before {
	content: "\f242";
}

.fa-battery-1:before,
.fa-battery-quarter:before {
	content: "\f243";
}

.fa-battery-0:before,
.fa-battery-empty:before {
	content: "\f244";
}

.fa-mouse-pointer:before {
	content: "\f245";
}

.fa-i-cursor:before {
	content: "\f246";
}

.fa-object-group:before {
	content: "\f247";
}

.fa-object-ungroup:before {
	content: "\f248";
}

.fa-sticky-note:before {
	content: "\f249";
}

.fa-sticky-note-o:before {
	content: "\f24a";
}

.fa-cc-jcb:before {
	content: "\f24b";
}

.fa-cc-diners-club:before {
	content: "\f24c";
}

.fa-clone:before {
	content: "\f24d";
}

.fa-balance-scale:before {
	content: "\f24e";
}

.fa-hourglass-o:before {
	content: "\f250";
}

.fa-hourglass-1:before,
.fa-hourglass-start:before {
	content: "\f251";
}

.fa-hourglass-2:before,
.fa-hourglass-half:before {
	content: "\f252";
}

.fa-hourglass-3:before,
.fa-hourglass-end:before {
	content: "\f253";
}

.fa-hourglass:before {
	content: "\f254";
}

.fa-hand-grab-o:before,
.fa-hand-rock-o:before {
	content: "\f255";
}

.fa-hand-stop-o:before,
.fa-hand-paper-o:before {
	content: "\f256";
}

.fa-hand-scissors-o:before {
	content: "\f257";
}

.fa-hand-lizard-o:before {
	content: "\f258";
}

.fa-hand-spock-o:before {
	content: "\f259";
}

.fa-hand-pointer-o:before {
	content: "\f25a";
}

.fa-hand-peace-o:before {
	content: "\f25b";
}

.fa-trademark:before {
	content: "\f25c";
}

.fa-registered:before {
	content: "\f25d";
}

.fa-creative-commons:before {
	content: "\f25e";
}

.fa-gg:before {
	content: "\f260";
}

.fa-gg-circle:before {
	content: "\f261";
}

.fa-tripadvisor:before {
	content: "\f262";
}

.fa-odnoklassniki:before {
	content: "\f263";
}

.fa-odnoklassniki-square:before {
	content: "\f264";
}

.fa-get-pocket:before {
	content: "\f265";
}

.fa-wikipedia-w:before {
	content: "\f266";
}

.fa-safari:before {
	content: "\f267";
}

.fa-chrome:before {
	content: "\f268";
}

.fa-firefox:before {
	content: "\f269";
}

.fa-opera:before {
	content: "\f26a";
}

.fa-internet-explorer:before {
	content: "\f26b";
}

.fa-tv:before,
.fa-television:before {
	content: "\f26c";
}

.fa-contao:before {
	content: "\f26d";
}

.fa-500px:before {
	content: "\f26e";
}

.fa-amazon:before {
	content: "\f270";
}

.fa-calendar-plus-o:before {
	content: "\f271";
}

.fa-calendar-minus-o:before {
	content: "\f272";
}

.fa-calendar-times-o:before {
	content: "\f273";
}

.fa-calendar-check-o:before {
	content: "\f274";
}

.fa-industry:before {
	content: "\f275";
}

.fa-map-pin:before {
	content: "\f276";
}

.fa-map-signs:before {
	content: "\f277";
}

.fa-map-o:before {
	content: "\f278";
}

.fa-map:before {
	content: "\f279";
}

.fa-commenting:before {
	content: "\f27a";
}

.fa-commenting-o:before {
	content: "\f27b";
}

.fa-houzz:before {
	content: "\f27c";
}

.fa-vimeo:before {
	content: "\f27d";
}

.fa-black-tie:before {
	content: "\f27e";
}

.fa-fonticons:before {
	content: "\f280";
}

.fa-reddit-alien:before {
	content: "\f281";
}

.fa-edge:before {
	content: "\f282";
}

.fa-credit-card-alt:before {
	content: "\f283";
}

.fa-codiepie:before {
	content: "\f284";
}

.fa-modx:before {
	content: "\f285";
}

.fa-fort-awesome:before {
	content: "\f286";
}

.fa-usb:before {
	content: "\f287";
}

.fa-product-hunt:before {
	content: "\f288";
}

.fa-mixcloud:before {
	content: "\f289";
}

.fa-scribd:before {
	content: "\f28a";
}

.fa-pause-circle:before {
	content: "\f28b";
}

.fa-pause-circle-o:before {
	content: "\f28c";
}

.fa-stop-circle:before {
	content: "\f28d";
}

.fa-stop-circle-o:before {
	content: "\f28e";
}

.fa-shopping-bag:before {
	content: "\f290";
}

.fa-shopping-basket:before {
	content: "\f291";
}

.fa-hashtag:before {
	content: "\f292";
}

.fa-bluetooth:before {
	content: "\f293";
}

.fa-bluetooth-b:before {
	content: "\f294";
}

.fa-percent:before {
	content: "\f295";
}

.fa-gitlab:before {
	content: "\f296";
}

.fa-wpbeginner:before {
	content: "\f297";
}

.fa-wpforms:before {
	content: "\f298";
}

.fa-envira:before {
	content: "\f299";
}

.fa-universal-access:before {
	content: "\f29a";
}

.fa-wheelchair-alt:before {
	content: "\f29b";
}

.fa-question-circle-o:before {
	content: "\f29c";
}

.fa-blind:before {
	content: "\f29d";
}

.fa-audio-description:before {
	content: "\f29e";
}

.fa-volume-control-phone:before {
	content: "\f2a0";
}

.fa-braille:before {
	content: "\f2a1";
}

.fa-assistive-listening-systems:before {
	content: "\f2a2";
}

.fa-asl-interpreting:before,
.fa-american-sign-language-interpreting:before {
	content: "\f2a3";
}

.fa-deafness:before,
.fa-hard-of-hearing:before,
.fa-deaf:before {
	content: "\f2a4";
}

.fa-glide:before {
	content: "\f2a5";
}

.fa-glide-g:before {
	content: "\f2a6";
}

.fa-signing:before,
.fa-sign-language:before {
	content: "\f2a7";
}

.fa-low-vision:before {
	content: "\f2a8";
}

.fa-viadeo:before {
	content: "\f2a9";
}

.fa-viadeo-square:before {
	content: "\f2aa";
}

.fa-snapchat:before {
	content: "\f2ab";
}

.fa-snapchat-ghost:before {
	content: "\f2ac";
}

.fa-snapchat-square:before {
	content: "\f2ad";
}

.fa-pied-piper:before {
	content: "\f2ae";
}

.fa-first-order:before {
	content: "\f2b0";
}

.fa-yoast:before {
	content: "\f2b1";
}

.fa-themeisle:before {
	content: "\f2b2";
}

.fa-google-plus-circle:before,
.fa-google-plus-official:before {
	content: "\f2b3";
}

.fa-fa:before,
.fa-font-awesome:before {
	content: "\f2b4";
}

.fa-handshake-o:before {
	content: "\f2b5";
}

.fa-envelope-open:before {
	content: "\f2b6";
}

.fa-envelope-open-o:before {
	content: "\f2b7";
}

.fa-linode:before {
	content: "\f2b8";
}

.fa-address-book:before {
	content: "\f2b9";
}

.fa-address-book-o:before {
	content: "\f2ba";
}

.fa-vcard:before,
.fa-address-card:before {
	content: "\f2bb";
}

.fa-vcard-o:before,
.fa-address-card-o:before {
	content: "\f2bc";
}

.fa-user-circle:before {
	content: "\f2bd";
}

.fa-user-circle-o:before {
	content: "\f2be";
}

.fa-user-o:before {
	content: "\f2c0";
}

.fa-id-badge:before {
	content: "\f2c1";
}

.fa-drivers-license:before,
.fa-id-card:before {
	content: "\f2c2";
}

.fa-drivers-license-o:before,
.fa-id-card-o:before {
	content: "\f2c3";
}

.fa-quora:before {
	content: "\f2c4";
}

.fa-free-code-camp:before {
	content: "\f2c5";
}

.fa-telegram:before {
	content: "\f2c6";
}

.fa-thermometer-4:before,
.fa-thermometer:before,
.fa-thermometer-full:before {
	content: "\f2c7";
}

.fa-thermometer-3:before,
.fa-thermometer-three-quarters:before {
	content: "\f2c8";
}

.fa-thermometer-2:before,
.fa-thermometer-half:before {
	content: "\f2c9";
}

.fa-thermometer-1:before,
.fa-thermometer-quarter:before {
	content: "\f2ca";
}

.fa-thermometer-0:before,
.fa-thermometer-empty:before {
	content: "\f2cb";
}

.fa-shower:before {
	content: "\f2cc";
}

.fa-bathtub:before,
.fa-s15:before,
.fa-bath:before {
	content: "\f2cd";
}

.fa-podcast:before {
	content: "\f2ce";
}

.fa-window-maximize:before {
	content: "\f2d0";
}

.fa-window-minimize:before {
	content: "\f2d1";
}

.fa-window-restore:before {
	content: "\f2d2";
}

.fa-times-rectangle:before,
.fa-window-close:before {
	content: "\f2d3";
}

.fa-times-rectangle-o:before,
.fa-window-close-o:before {
	content: "\f2d4";
}

.fa-bandcamp:before {
	content: "\f2d5";
}

.fa-grav:before {
	content: "\f2d6";
}

.fa-etsy:before {
	content: "\f2d7";
}

.fa-imdb:before {
	content: "\f2d8";
}

.fa-ravelry:before {
	content: "\f2d9";
}

.fa-eercast:before {
	content: "\f2da";
}

.fa-microchip:before {
	content: "\f2db";
}

.fa-snowflake-o:before {
	content: "\f2dc";
}

.fa-superpowers:before {
	content: "\f2dd";
}

.fa-wpexplorer:before {
	content: "\f2de";
}

.fa-meetup:before {
	content: "\f2e0";
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	clip: auto;
}

/** @section Material Design Icons */
@font-face {
	font-family: "Material Design Icons";

	src: url("fonts/materialdesignicons-webfont.woff2?v=1.4.57") format("woff2"), url("fonts/materialdesignicons-webfont.woff?v=1.4.57") format("woff");
	font-weight: normal;
	font-style: normal;
}

.mdi {
	display: inline-block;
	/* changing '1' to '2' below to fix icons sticking to top bug */
	font: normal normal normal 24px/2 "Material Design Icons";
	/* font-size: inherit; */
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	transform: translate(0, 0);
}

.mdi-access-point:before {
	content: "\f101";
}

.mdi-access-point-network:before {
	content: "\f102";
}

.mdi-account:before {
	content: "\f103";
}

.mdi-account-alert:before {
	content: "\f104";
}

.mdi-account-box:before {
	content: "\f105";
}

.mdi-account-box-outline:before {
	content: "\f106";
}

.mdi-account-check:before {
	content: "\f107";
}

.mdi-account-circle:before {
	content: "\f108";
}

.mdi-account-convert:before {
	content: "\f109";
}

.mdi-account-key:before {
	content: "\f10a";
}

.mdi-account-location:before {
	content: "\f10b";
}

.mdi-account-minus:before {
	content: "\f10c";
}

.mdi-account-multiple:before {
	content: "\f10d";
}

.mdi-account-multiple-outline:before {
	content: "\f10e";
}

.mdi-account-multiple-plus:before {
	content: "\f10f";
}

.mdi-account-network:before {
	content: "\f110";
}

.mdi-account-off:before {
	content: "\f111";
}

.mdi-account-outline:before {
	content: "\f112";
}

.mdi-account-plus:before {
	content: "\f113";
}

.mdi-account-remove:before {
	content: "\f114";
}

.mdi-account-search:before {
	content: "\f115";
}

.mdi-account-star:before {
	content: "\f116";
}

.mdi-account-star-variant:before {
	content: "\f117";
}

.mdi-account-switch:before {
	content: "\f118";
}

.mdi-adjust:before {
	content: "\f119";
}

.mdi-air-conditioner:before {
	content: "\f11a";
}

.mdi-airballoon:before {
	content: "\f11b";
}

.mdi-airplane:before {
	content: "\f11c";
}

.mdi-airplane-off:before {
	content: "\f11d";
}

.mdi-airplay:before {
	content: "\f11e";
}

.mdi-alarm:before {
	content: "\f11f";
}

.mdi-alarm-check:before {
	content: "\f120";
}

.mdi-alarm-multiple:before {
	content: "\f121";
}

.mdi-alarm-off:before {
	content: "\f122";
}

.mdi-alarm-plus:before {
	content: "\f123";
}

.mdi-album:before {
	content: "\f124";
}

.mdi-alert:before {
	content: "\f125";
}

.mdi-alert-box:before {
	content: "\f126";
}

.mdi-alert-circle:before {
	content: "\f127";
}

.mdi-alert-octagon:before {
	content: "\f128";
}

.mdi-alert-outline:before {
	content: "\f129";
}

.mdi-alpha:before {
	content: "\f12a";
}

.mdi-alphabetical:before {
	content: "\f12b";
}

.mdi-amazon:before {
	content: "\f12c";
}

.mdi-amazon-clouddrive:before {
	content: "\f12d";
}

.mdi-ambulance:before {
	content: "\f12e";
}

.mdi-anchor:before {
	content: "\f12f";
}

.mdi-android:before {
	content: "\f130";
}

.mdi-android-debug-bridge:before {
	content: "\f131";
}

.mdi-android-studio:before {
	content: "\f132";
}

.mdi-apple:before {
	content: "\f133";
}

.mdi-apple-finder:before {
	content: "\f134";
}

.mdi-apple-ios:before {
	content: "\f135";
}

.mdi-apple-mobileme:before {
	content: "\f136";
}

.mdi-apple-safari:before {
	content: "\f137";
}

.mdi-appnet:before {
	content: "\f138";
}

.mdi-apps:before {
	content: "\f139";
}

.mdi-archive:before {
	content: "\f13a";
}

.mdi-arrange-bring-forward:before {
	content: "\f13b";
}

.mdi-arrange-bring-to-front:before {
	content: "\f13c";
}

.mdi-arrange-send-backward:before {
	content: "\f13d";
}

.mdi-arrange-send-to-back:before {
	content: "\f13e";
}

.mdi-arrow-all:before {
	content: "\f13f";
}

.mdi-arrow-bottom-drop-circle:before {
	content: "\f140";
}

.mdi-arrow-bottom-left:before {
	content: "\f141";
}

.mdi-arrow-bottom-right:before {
	content: "\f142";
}

.mdi-arrow-collapse:before {
	content: "\f143";
}

.mdi-arrow-down:before {
	content: "\f144";
}

.mdi-arrow-down-bold:before {
	content: "\f145";
}

.mdi-arrow-down-bold-circle:before {
	content: "\f146";
}

.mdi-arrow-down-bold-circle-outline:before {
	content: "\f147";
}

.mdi-arrow-down-bold-hexagon-outline:before {
	content: "\f148";
}

.mdi-arrow-expand:before {
	content: "\f149";
}

.mdi-arrow-left:before {
	content: "\f14a";
}

.mdi-arrow-left-bold:before {
	content: "\f14b";
}

.mdi-arrow-left-bold-circle:before {
	content: "\f14c";
}

.mdi-arrow-left-bold-circle-outline:before {
	content: "\f14d";
}

.mdi-arrow-left-bold-hexagon-outline:before {
	content: "\f14e";
}

.mdi-arrow-right:before {
	content: "\f14f";
}

.mdi-arrow-right-bold:before {
	content: "\f150";
}

.mdi-arrow-right-bold-circle:before {
	content: "\f151";
}

.mdi-arrow-right-bold-circle-outline:before {
	content: "\f152";
}

.mdi-arrow-right-bold-hexagon-outline:before {
	content: "\f153";
}

.mdi-arrow-top-left:before {
	content: "\f154";
}

.mdi-arrow-top-right:before {
	content: "\f155";
}

.mdi-arrow-up:before {
	content: "\f156";
}

.mdi-arrow-up-bold:before {
	content: "\f157";
}

.mdi-arrow-up-bold-circle:before {
	content: "\f158";
}

.mdi-arrow-up-bold-circle-outline:before {
	content: "\f159";
}

.mdi-arrow-up-bold-hexagon-outline:before {
	content: "\f15a";
}

.mdi-assistant:before {
	content: "\f15b";
}

.mdi-at:before {
	content: "\f15c";
}

.mdi-attachment:before {
	content: "\f15d";
}

.mdi-audiobook:before {
	content: "\f15e";
}

.mdi-auto-fix:before {
	content: "\f15f";
}

.mdi-auto-upload:before {
	content: "\f160";
}

.mdi-autorenew:before {
	content: "\f161";
}

.mdi-av-timer:before {
	content: "\f162";
}

.mdi-baby:before {
	content: "\f163";
}

.mdi-backburger:before {
	content: "\f164";
}

.mdi-backspace:before {
	content: "\f165";
}

.mdi-backup-restore:before {
	content: "\f166";
}

.mdi-bank:before {
	content: "\f167";
}

.mdi-barcode:before {
	content: "\f168";
}

.mdi-barcode-scan:before {
	content: "\f169";
}

.mdi-barley:before {
	content: "\f16a";
}

.mdi-barrel:before {
	content: "\f16b";
}

.mdi-basecamp:before {
	content: "\f16c";
}

.mdi-basket:before {
	content: "\f16d";
}

.mdi-basket-fill:before {
	content: "\f16e";
}

.mdi-basket-unfill:before {
	content: "\f16f";
}

.mdi-battery:before {
	content: "\f170";
}

.mdi-battery-10:before {
	content: "\f171";
}

.mdi-battery-20:before {
	content: "\f172";
}

.mdi-battery-30:before {
	content: "\f173";
}

.mdi-battery-40:before {
	content: "\f174";
}

.mdi-battery-50:before {
	content: "\f175";
}

.mdi-battery-60:before {
	content: "\f176";
}

.mdi-battery-70:before {
	content: "\f177";
}

.mdi-battery-80:before {
	content: "\f178";
}

.mdi-battery-90:before {
	content: "\f179";
}

.mdi-battery-alert:before {
	content: "\f17a";
}

.mdi-battery-charging:before {
	content: "\f17b";
}

.mdi-battery-charging-100:before {
	content: "\f17c";
}

.mdi-battery-charging-20:before {
	content: "\f17d";
}

.mdi-battery-charging-30:before {
	content: "\f17e";
}

.mdi-battery-charging-40:before {
	content: "\f17f";
}

.mdi-battery-charging-60:before {
	content: "\f180";
}

.mdi-battery-charging-80:before {
	content: "\f181";
}

.mdi-battery-charging-90:before {
	content: "\f182";
}

.mdi-battery-minus:before {
	content: "\f183";
}

.mdi-battery-negative:before {
	content: "\f184";
}

.mdi-battery-outline:before {
	content: "\f185";
}

.mdi-battery-plus:before {
	content: "\f186";
}

.mdi-battery-positive:before {
	content: "\f187";
}

.mdi-battery-unknown:before {
	content: "\f188";
}

.mdi-beach:before {
	content: "\f189";
}

.mdi-beaker:before {
	content: "\f18a";
}

.mdi-beaker-empty:before {
	content: "\f18b";
}

.mdi-beaker-empty-outline:before {
	content: "\f18c";
}

.mdi-beaker-outline:before {
	content: "\f18d";
}

.mdi-beats:before {
	content: "\f18e";
}

.mdi-beer:before {
	content: "\f18f";
}

.mdi-behance:before {
	content: "\f190";
}

.mdi-bell:before {
	content: "\f191";
}

.mdi-bell-off:before {
	content: "\f192";
}

.mdi-bell-outline:before {
	content: "\f193";
}

.mdi-bell-plus:before {
	content: "\f194";
}

.mdi-bell-ring:before {
	content: "\f195";
}

.mdi-bell-ring-outline:before {
	content: "\f196";
}

.mdi-bell-sleep:before {
	content: "\f197";
}

.mdi-beta:before {
	content: "\f198";
}

.mdi-bike:before {
	content: "\f199";
}

.mdi-bing:before {
	content: "\f19a";
}

.mdi-binoculars:before {
	content: "\f19b";
}

.mdi-bio:before {
	content: "\f19c";
}

.mdi-biohazard:before {
	content: "\f19d";
}

.mdi-bitbucket:before {
	content: "\f19e";
}

.mdi-black-mesa:before {
	content: "\f19f";
}

.mdi-blackberry:before {
	content: "\f1a0";
}

.mdi-blender:before {
	content: "\f1a1";
}

.mdi-blinds:before {
	content: "\f1a2";
}

.mdi-block-helper:before {
	content: "\f1a3";
}

.mdi-blogger:before {
	content: "\f1a4";
}

.mdi-bluetooth:before {
	content: "\f1a5";
}

.mdi-bluetooth-audio:before {
	content: "\f1a6";
}

.mdi-bluetooth-connect:before {
	content: "\f1a7";
}

.mdi-bluetooth-off:before {
	content: "\f1a8";
}

.mdi-bluetooth-settings:before {
	content: "\f1a9";
}

.mdi-bluetooth-transfer:before {
	content: "\f1aa";
}

.mdi-blur:before {
	content: "\f1ab";
}

.mdi-blur-linear:before {
	content: "\f1ac";
}

.mdi-blur-off:before {
	content: "\f1ad";
}

.mdi-blur-radial:before {
	content: "\f1ae";
}

.mdi-bone:before {
	content: "\f1af";
}

.mdi-book:before {
	content: "\f1b0";
}

.mdi-book-multiple:before {
	content: "\f1b1";
}

.mdi-book-multiple-variant:before {
	content: "\f1b2";
}

.mdi-book-open:before {
	content: "\f1b3";
}

.mdi-book-open-variant:before {
	content: "\f1b4";
}

.mdi-book-variant:before {
	content: "\f1b5";
}

.mdi-bookmark:before {
	content: "\f1b6";
}

.mdi-bookmark-check:before {
	content: "\f1b7";
}

.mdi-bookmark-music:before {
	content: "\f1b8";
}

.mdi-bookmark-outline:before {
	content: "\f1b9";
}

.mdi-bookmark-outline-plus:before {
	content: "\f1ba";
}

.mdi-bookmark-plus:before {
	content: "\f1bb";
}

.mdi-bookmark-remove:before {
	content: "\f1bc";
}

.mdi-border-all:before {
	content: "\f1bd";
}

.mdi-border-bottom:before {
	content: "\f1be";
}

.mdi-border-color:before {
	content: "\f1bf";
}

.mdi-border-horizontal:before {
	content: "\f1c0";
}

.mdi-border-inside:before {
	content: "\f1c1";
}

.mdi-border-left:before {
	content: "\f1c2";
}

.mdi-border-none:before {
	content: "\f1c3";
}

.mdi-border-outside:before {
	content: "\f1c4";
}

.mdi-border-right:before {
	content: "\f1c5";
}

.mdi-border-style:before {
	content: "\f1c6";
}

.mdi-border-top:before {
	content: "\f1c7";
}

.mdi-border-vertical:before {
	content: "\f1c8";
}

.mdi-bowling:before {
	content: "\f1c9";
}

.mdi-box:before {
	content: "\f1ca";
}

.mdi-box-cutter:before {
	content: "\f1cb";
}

.mdi-briefcase:before {
	content: "\f1cc";
}

.mdi-briefcase-check:before {
	content: "\f1cd";
}

.mdi-briefcase-download:before {
	content: "\f1ce";
}

.mdi-briefcase-upload:before {
	content: "\f1cf";
}

.mdi-brightness-1:before {
	content: "\f1d0";
}

.mdi-brightness-2:before {
	content: "\f1d1";
}

.mdi-brightness-3:before {
	content: "\f1d2";
}

.mdi-brightness-4:before {
	content: "\f1d3";
}

.mdi-brightness-5:before {
	content: "\f1d4";
}

.mdi-brightness-6:before {
	content: "\f1d5";
}

.mdi-brightness-7:before {
	content: "\f1d6";
}

.mdi-brightness-auto:before {
	content: "\f1d7";
}

.mdi-broom:before {
	content: "\f1d8";
}

.mdi-brush:before {
	content: "\f1d9";
}

.mdi-bug:before {
	content: "\f1da";
}

.mdi-bulletin-board:before {
	content: "\f1db";
}

.mdi-bullhorn:before {
	content: "\f1dc";
}

.mdi-bus:before {
	content: "\f1dd";
}

.mdi-cached:before {
	content: "\f1de";
}

.mdi-cake:before {
	content: "\f1df";
}

.mdi-cake-layered:before {
	content: "\f1e0";
}

.mdi-cake-variant:before {
	content: "\f1e1";
}

.mdi-calculator:before {
	content: "\f1e2";
}

.mdi-calendar:before {
	content: "\f1e3";
}

.mdi-calendar-blank:before {
	content: "\f1e4";
}

.mdi-calendar-check:before {
	content: "\f1e5";
}

.mdi-calendar-clock:before {
	content: "\f1e6";
}

.mdi-calendar-multiple:before {
	content: "\f1e7";
}

.mdi-calendar-multiple-check:before {
	content: "\f1e8";
}

.mdi-calendar-plus:before {
	content: "\f1e9";
}

.mdi-calendar-remove:before {
	content: "\f1ea";
}

.mdi-calendar-text:before {
	content: "\f1eb";
}

.mdi-calendar-today:before {
	content: "\f1ec";
}

.mdi-call-made:before {
	content: "\f1ed";
}

.mdi-call-merge:before {
	content: "\f1ee";
}

.mdi-call-missed:before {
	content: "\f1ef";
}

.mdi-call-received:before {
	content: "\f1f0";
}

.mdi-call-split:before {
	content: "\f1f1";
}

.mdi-camcorder:before {
	content: "\f1f2";
}

.mdi-camcorder-box:before {
	content: "\f1f3";
}

.mdi-camcorder-box-off:before {
	content: "\f1f4";
}

.mdi-camcorder-off:before {
	content: "\f1f5";
}

.mdi-camera:before {
	content: "\f1f6";
}

.mdi-camera-enhance:before {
	content: "\f1f7";
}

.mdi-camera-front:before {
	content: "\f1f8";
}

.mdi-camera-front-variant:before {
	content: "\f1f9";
}

.mdi-camera-iris:before {
	content: "\f1fa";
}

.mdi-camera-party-mode:before {
	content: "\f1fb";
}

.mdi-camera-rear:before {
	content: "\f1fc";
}

.mdi-camera-rear-variant:before {
	content: "\f1fd";
}

.mdi-camera-switch:before {
	content: "\f1fe";
}

.mdi-camera-timer:before {
	content: "\f1ff";
}

.mdi-candycane:before {
	content: "\f200";
}

.mdi-car:before {
	content: "\f201";
}

.mdi-car-battery:before {
	content: "\f202";
}

.mdi-car-connected:before {
	content: "\f203";
}

.mdi-car-wash:before {
	content: "\f204";
}

.mdi-carrot:before {
	content: "\f205";
}

.mdi-cart:before {
	content: "\f206";
}

.mdi-cart-outline:before {
	content: "\f207";
}

.mdi-cart-plus:before {
	content: "\f208";
}

.mdi-case-sensitive-alt:before {
	content: "\f209";
}

.mdi-cash:before {
	content: "\f20a";
}

.mdi-cash-100:before {
	content: "\f20b";
}

.mdi-cash-multiple:before {
	content: "\f20c";
}

.mdi-cash-usd:before {
	content: "\f20d";
}

.mdi-cast:before {
	content: "\f20e";
}

.mdi-cast-connected:before {
	content: "\f20f";
}

.mdi-castle:before {
	content: "\f210";
}

.mdi-cat:before {
	content: "\f211";
}

.mdi-cellphone:before {
	content: "\f212";
}

.mdi-cellphone-android:before {
	content: "\f213";
}

.mdi-cellphone-basic:before {
	content: "\f214";
}

.mdi-cellphone-dock:before {
	content: "\f215";
}

.mdi-cellphone-iphone:before {
	content: "\f216";
}

.mdi-cellphone-link:before {
	content: "\f217";
}

.mdi-cellphone-link-off:before {
	content: "\f218";
}

.mdi-cellphone-settings:before {
	content: "\f219";
}

.mdi-certificate:before {
	content: "\f21a";
}

.mdi-chair-school:before {
	content: "\f21b";
}

.mdi-chart-arc:before {
	content: "\f21c";
}

.mdi-chart-areaspline:before {
	content: "\f21d";
}

.mdi-chart-bar:before {
	content: "\f21e";
}

.mdi-chart-histogram:before {
	content: "\f21f";
}

.mdi-chart-line:before {
	content: "\f220";
}

.mdi-chart-pie:before {
	content: "\f221";
}

.mdi-check:before {
	content: "\f222";
}

.mdi-check-all:before {
	content: "\f223";
}

.mdi-checkbox-blank:before {
	content: "\f224";
}

.mdi-checkbox-blank-circle:before {
	content: "\f225";
}

.mdi-checkbox-blank-circle-outline:before {
	content: "\f226";
}

.mdi-checkbox-blank-outline:before {
	content: "\f227";
}

.mdi-checkbox-marked:before {
	content: "\f228";
}

.mdi-checkbox-marked-circle:before {
	content: "\f229";
}

.mdi-checkbox-marked-circle-outline:before {
	content: "\f22a";
}

.mdi-checkbox-marked-outline:before {
	content: "\f22b";
}

.mdi-checkbox-multiple-blank:before {
	content: "\f22c";
}

.mdi-checkbox-multiple-blank-outline:before {
	content: "\f22d";
}

.mdi-checkbox-multiple-marked:before {
	content: "\f22e";
}

.mdi-checkbox-multiple-marked-outline:before {
	content: "\f22f";
}

.mdi-checkerboard:before {
	content: "\f230";
}

.mdi-chemical-weapon:before {
	content: "\f231";
}

.mdi-chevron-double-down:before {
	content: "\f232";
}

.mdi-chevron-double-left:before {
	content: "\f233";
}

.mdi-chevron-double-right:before {
	content: "\f234";
}

.mdi-chevron-double-up:before {
	content: "\f235";
}

.mdi-chevron-down:before {
	content: "\f236";
}

.mdi-chevron-left:before {
	content: "\f237";
}

.mdi-chevron-right:before {
	content: "\f238";
}

.mdi-chevron-up:before {
	content: "\f239";
}

.mdi-church:before {
	content: "\f23a";
}

.mdi-cisco-webex:before {
	content: "\f23b";
}

.mdi-city:before {
	content: "\f23c";
}

.mdi-clipboard:before {
	content: "\f23d";
}

.mdi-clipboard-account:before {
	content: "\f23e";
}

.mdi-clipboard-alert:before {
	content: "\f23f";
}

.mdi-clipboard-arrow-down:before {
	content: "\f240";
}

.mdi-clipboard-arrow-left:before {
	content: "\f241";
}

.mdi-clipboard-check:before {
	content: "\f242";
}

.mdi-clipboard-outline:before {
	content: "\f243";
}

.mdi-clipboard-text:before {
	content: "\f244";
}

.mdi-clippy:before {
	content: "\f245";
}

.mdi-clock:before {
	content: "\f246";
}

.mdi-clock-end:before {
	content: "\f247";
}

.mdi-clock-fast:before {
	content: "\f248";
}

.mdi-clock-in:before {
	content: "\f249";
}

.mdi-clock-out:before {
	content: "\f24a";
}

.mdi-clock-start:before {
	content: "\f24b";
}

.mdi-close:before {
	content: "\f24c";
}

.mdi-close-box:before {
	content: "\f24d";
}

.mdi-close-box-outline:before {
	content: "\f24e";
}

.mdi-close-circle:before {
	content: "\f24f";
}

.mdi-close-circle-outline:before {
	content: "\f250";
}

.mdi-close-network:before {
	content: "\f251";
}

.mdi-close-octagon:before {
	content: "\f252";
}

.mdi-close-octagon-outline:before {
	content: "\f253";
}

.mdi-closed-caption:before {
	content: "\f254";
}

.mdi-cloud:before {
	content: "\f255";
}

.mdi-cloud-check:before {
	content: "\f256";
}

.mdi-cloud-circle:before {
	content: "\f257";
}

.mdi-cloud-download:before {
	content: "\f258";
}

.mdi-cloud-outline:before {
	content: "\f259";
}

.mdi-cloud-outline-off:before {
	content: "\f25a";
}

.mdi-cloud-print:before {
	content: "\f25b";
}

.mdi-cloud-print-outline:before {
	content: "\f25c";
}

.mdi-cloud-upload:before {
	content: "\f25d";
}

.mdi-code-array:before {
	content: "\f25e";
}

.mdi-code-braces:before {
	content: "\f25f";
}

.mdi-code-brackets:before {
	content: "\f260";
}

.mdi-code-equal:before {
	content: "\f261";
}

.mdi-code-greater-than:before {
	content: "\f262";
}

.mdi-code-greater-than-or-equal:before {
	content: "\f263";
}

.mdi-code-less-than:before {
	content: "\f264";
}

.mdi-code-less-than-or-equal:before {
	content: "\f265";
}

.mdi-code-not-equal:before {
	content: "\f266";
}

.mdi-code-not-equal-variant:before {
	content: "\f267";
}

.mdi-code-parentheses:before {
	content: "\f268";
}

.mdi-code-string:before {
	content: "\f269";
}

.mdi-code-tags:before {
	content: "\f26a";
}

.mdi-codepen:before {
	content: "\f26b";
}

.mdi-coffee:before {
	content: "\f26c";
}

.mdi-coffee-to-go:before {
	content: "\f26d";
}

.mdi-coin:before {
	content: "\f26e";
}

.mdi-color-helper:before {
	content: "\f26f";
}

.mdi-comment:before {
	content: "\f270";
}

.mdi-comment-account:before {
	content: "\f271";
}

.mdi-comment-account-outline:before {
	content: "\f272";
}

.mdi-comment-alert:before {
	content: "\f273";
}

.mdi-comment-alert-outline:before {
	content: "\f274";
}

.mdi-comment-check:before {
	content: "\f275";
}

.mdi-comment-check-outline:before {
	content: "\f276";
}

.mdi-comment-multiple-outline:before {
	content: "\f277";
}

.mdi-comment-outline:before {
	content: "\f278";
}

.mdi-comment-plus-outline:before {
	content: "\f279";
}

.mdi-comment-processing:before {
	content: "\f27a";
}

.mdi-comment-processing-outline:before {
	content: "\f27b";
}

.mdi-comment-question-outline:before {
	content: "\f27c";
}

.mdi-comment-remove-outline:before {
	content: "\f27d";
}

.mdi-comment-text:before {
	content: "\f27e";
}

.mdi-comment-text-outline:before {
	content: "\f27f";
}

.mdi-compare:before {
	content: "\f280";
}

.mdi-compass:before {
	content: "\f281";
}

.mdi-compass-outline:before {
	content: "\f282";
}

.mdi-console:before {
	content: "\f283";
}

.mdi-contact-mail:before {
	content: "\f284";
}

.mdi-content-copy:before {
	content: "\f285";
}

.mdi-content-cut:before {
	content: "\f286";
}

.mdi-content-duplicate:before {
	content: "\f287";
}

.mdi-content-paste:before {
	content: "\f288";
}

.mdi-content-save:before {
	content: "\f289";
}

.mdi-content-save-all:before {
	content: "\f28a";
}

.mdi-contrast:before {
	content: "\f28b";
}

.mdi-contrast-box:before {
	content: "\f28c";
}

.mdi-contrast-circle:before {
	content: "\f28d";
}

.mdi-cookie:before {
	content: "\f28e";
}

.mdi-cow:before {
	content: "\f28f";
}

.mdi-credit-card:before {
	content: "\f290";
}

.mdi-credit-card-multiple:before {
	content: "\f291";
}

.mdi-credit-card-scan:before {
	content: "\f292";
}

.mdi-crop:before {
	content: "\f293";
}

.mdi-crop-free:before {
	content: "\f294";
}

.mdi-crop-landscape:before {
	content: "\f295";
}

.mdi-crop-portrait:before {
	content: "\f296";
}

.mdi-crop-square:before {
	content: "\f297";
}

.mdi-crosshairs:before {
	content: "\f298";
}

.mdi-crosshairs-gps:before {
	content: "\f299";
}

.mdi-crown:before {
	content: "\f29a";
}

.mdi-cube:before {
	content: "\f29b";
}

.mdi-cube-outline:before {
	content: "\f29c";
}

.mdi-cube-send:before {
	content: "\f29d";
}

.mdi-cube-unfolded:before {
	content: "\f29e";
}

.mdi-cup:before {
	content: "\f29f";
}

.mdi-cup-water:before {
	content: "\f2a0";
}

.mdi-currency-btc:before {
	content: "\f2a1";
}

.mdi-currency-eur:before {
	content: "\f2a2";
}

.mdi-currency-gbp:before {
	content: "\f2a3";
}

.mdi-currency-inr:before {
	content: "\f2a4";
}

.mdi-currency-ngn:before {
	content: "\f2a5";
}

.mdi-currency-rub:before {
	content: "\f2a6";
}

.mdi-currency-try:before {
	content: "\f2a7";
}

.mdi-currency-usd:before {
	content: "\f2a8";
}

.mdi-cursor-default:before {
	content: "\f2a9";
}

.mdi-cursor-default-outline:before {
	content: "\f2aa";
}

.mdi-cursor-move:before {
	content: "\f2ab";
}

.mdi-cursor-pointer:before {
	content: "\f2ac";
}

.mdi-database:before {
	content: "\f2ad";
}

.mdi-database-minus:before {
	content: "\f2ae";
}

.mdi-database-plus:before {
	content: "\f2af";
}

.mdi-debug-step-into:before {
	content: "\f2b0";
}

.mdi-debug-step-out:before {
	content: "\f2b1";
}

.mdi-debug-step-over:before {
	content: "\f2b2";
}

.mdi-decimal-decrease:before {
	content: "\f2b3";
}

.mdi-decimal-increase:before {
	content: "\f2b4";
}

.mdi-delete:before {
	content: "\f2b5";
}

.mdi-delete-variant:before {
	content: "\f2b6";
}

.mdi-delta:before {
	content: "\f2b7";
}

.mdi-deskphone:before {
	content: "\f2b8";
}

.mdi-desktop-mac:before {
	content: "\f2b9";
}

.mdi-desktop-tower:before {
	content: "\f2ba";
}

.mdi-details:before {
	content: "\f2bb";
}

.mdi-deviantart:before {
	content: "\f2bc";
}

.mdi-diamond:before {
	content: "\f2bd";
}

.mdi-dice:before {
	content: "\f2be";
}

.mdi-dice-1:before {
	content: "\f2bf";
}

.mdi-dice-2:before {
	content: "\f2c0";
}

.mdi-dice-3:before {
	content: "\f2c1";
}

.mdi-dice-4:before {
	content: "\f2c2";
}

.mdi-dice-5:before {
	content: "\f2c3";
}

.mdi-dice-6:before {
	content: "\f2c4";
}

.mdi-directions:before {
	content: "\f2c5";
}

.mdi-disk-alert:before {
	content: "\f2c6";
}

.mdi-disqus:before {
	content: "\f2c7";
}

.mdi-disqus-outline:before {
	content: "\f2c8";
}

.mdi-division:before {
	content: "\f2c9";
}

.mdi-division-box:before {
	content: "\f2ca";
}

.mdi-dns:before {
	content: "\f2cb";
}

.mdi-domain:before {
	content: "\f2cc";
}

.mdi-dots-horizontal:before {
	content: "\f2cd";
}

.mdi-dots-vertical:before {
	content: "\f2ce";
}

.mdi-download:before {
	content: "\f2cf";
}

.mdi-drag:before {
	content: "\f2d0";
}

.mdi-drag-horizontal:before {
	content: "\f2d1";
}

.mdi-drag-vertical:before {
	content: "\f2d2";
}

.mdi-drawing:before {
	content: "\f2d3";
}

.mdi-drawing-box:before {
	content: "\f2d4";
}

.mdi-dribbble:before {
	content: "\f2d5";
}

.mdi-dribbble-box:before {
	content: "\f2d6";
}

.mdi-drone:before {
	content: "\f2d7";
}

.mdi-dropbox:before {
	content: "\f2d8";
}

.mdi-drupal:before {
	content: "\f2d9";
}

.mdi-duck:before {
	content: "\f2da";
}

.mdi-dumbbell:before {
	content: "\f2db";
}

.mdi-earth:before {
	content: "\f2dc";
}

.mdi-earth-off:before {
	content: "\f2dd";
}

.mdi-edge:before {
	content: "\f2de";
}

.mdi-eject:before {
	content: "\f2df";
}

.mdi-elevation-decline:before {
	content: "\f2e0";
}

.mdi-elevation-rise:before {
	content: "\f2e1";
}

.mdi-elevator:before {
	content: "\f2e2";
}

.mdi-email:before {
	content: "\f2e3";
}

.mdi-email-open:before {
	content: "\f2e4";
}

.mdi-email-outline:before {
	content: "\f2e5";
}

.mdi-email-secure:before {
	content: "\f2e6";
}

.mdi-emoticon:before {
	content: "\f2e7";
}

.mdi-emoticon-cool:before {
	content: "\f2e8";
}

.mdi-emoticon-devil:before {
	content: "\f2e9";
}

.mdi-emoticon-happy:before {
	content: "\f2ea";
}

.mdi-emoticon-neutral:before {
	content: "\f2eb";
}

.mdi-emoticon-poop:before {
	content: "\f2ec";
}

.mdi-emoticon-sad:before {
	content: "\f2ed";
}

.mdi-emoticon-tongue:before {
	content: "\f2ee";
}

.mdi-engine:before {
	content: "\f2ef";
}

.mdi-engine-outline:before {
	content: "\f2f0";
}

.mdi-equal:before {
	content: "\f2f1";
}

.mdi-equal-box:before {
	content: "\f2f2";
}

.mdi-eraser:before {
	content: "\f2f3";
}

.mdi-escalator:before {
	content: "\f2f4";
}

.mdi-ethernet:before {
	content: "\f2f5";
}

.mdi-ethernet-cable:before {
	content: "\f2f6";
}

.mdi-ethernet-cable-off:before {
	content: "\f2f7";
}

.mdi-etsy:before {
	content: "\f2f8";
}

.mdi-evernote:before {
	content: "\f2f9";
}

.mdi-exclamation:before {
	content: "\f2fa";
}

.mdi-exit-to-app:before {
	content: "\f2fb";
}

.mdi-export:before {
	content: "\f2fc";
}

.mdi-eye:before {
	content: "\f2fd";
}

.mdi-eye-off:before {
	content: "\f2fe";
}

.mdi-eyedropper:before {
	content: "\f2ff";
}

.mdi-eyedropper-variant:before {
	content: "\f300";
}

.mdi-facebook:before {
	content: "\f301";
}

.mdi-facebook-box:before {
	content: "\f302";
}

.mdi-facebook-messenger:before {
	content: "\f303";
}

.mdi-factory:before {
	content: "\f304";
}

.mdi-fan:before {
	content: "\f305";
}

.mdi-fast-forward:before {
	content: "\f306";
}

.mdi-fax:before {
	content: "\f307";
}

.mdi-ferry:before {
	content: "\f308";
}

.mdi-file:before {
	content: "\f309";
}

.mdi-file-chart:before {
	content: "\f30a";
}

.mdi-file-check:before {
	content: "\f30b";
}

.mdi-file-cloud:before {
	content: "\f30c";
}

.mdi-file-delimited:before {
	content: "\f30d";
}

.mdi-file-document:before {
	content: "\f30e";
}

.mdi-file-document-box:before {
	content: "\f30f";
}

.mdi-file-excel:before {
	content: "\f310";
}

.mdi-file-excel-box:before {
	content: "\f311";
}

.mdi-file-export:before {
	content: "\f312";
}

.mdi-file-find:before {
	content: "\f313";
}

.mdi-file-image:before {
	content: "\f314";
}

.mdi-file-import:before {
	content: "\f315";
}

.mdi-file-lock:before {
	content: "\f316";
}

.mdi-file-multiple:before {
	content: "\f317";
}

.mdi-file-music:before {
	content: "\f318";
}

.mdi-file-outline:before {
	content: "\f319";
}

.mdi-file-pdf:before {
	content: "\f31a";
}

.mdi-file-pdf-box:before {
	content: "\f31b";
}

.mdi-file-powerpoint:before {
	content: "\f31c";
}

.mdi-file-powerpoint-box:before {
	content: "\f31d";
}

.mdi-file-presentation-box:before {
	content: "\f31e";
}

.mdi-file-send:before {
	content: "\f31f";
}

.mdi-file-video:before {
	content: "\f320";
}

.mdi-file-word:before {
	content: "\f321";
}

.mdi-file-word-box:before {
	content: "\f322";
}

.mdi-file-xml:before {
	content: "\f323";
}

.mdi-film:before {
	content: "\f324";
}

.mdi-filmstrip:before {
	content: "\f325";
}

.mdi-filmstrip-off:before {
	content: "\f326";
}

.mdi-filter:before {
	content: "\f327";
}

.mdi-filter-outline:before {
	content: "\f328";
}

.mdi-filter-remove:before {
	content: "\f329";
}

.mdi-filter-remove-outline:before {
	content: "\f32a";
}

.mdi-filter-variant:before {
	content: "\f32b";
}

.mdi-fingerprint:before {
	content: "\f32c";
}

.mdi-fire:before {
	content: "\f32d";
}

.mdi-firefox:before {
	content: "\f32e";
}

.mdi-fish:before {
	content: "\f32f";
}

.mdi-flag:before {
	content: "\f330";
}

.mdi-flag-checkered:before {
	content: "\f331";
}

.mdi-flag-outline:before {
	content: "\f332";
}

.mdi-flag-outline-variant:before {
	content: "\f333";
}

.mdi-flag-triangle:before {
	content: "\f334";
}

.mdi-flag-variant:before {
	content: "\f335";
}

.mdi-flash:before {
	content: "\f336";
}

.mdi-flash-auto:before {
	content: "\f337";
}

.mdi-flash-off:before {
	content: "\f338";
}

.mdi-flashlight:before {
	content: "\f339";
}

.mdi-flashlight-off:before {
	content: "\f33a";
}

.mdi-flattr:before {
	content: "\f33b";
}

.mdi-flip-to-back:before {
	content: "\f33c";
}

.mdi-flip-to-front:before {
	content: "\f33d";
}

.mdi-floppy:before {
	content: "\f33e";
}

.mdi-flower:before {
	content: "\f33f";
}

.mdi-folder:before {
	content: "\f340";
}

.mdi-folder-account:before {
	content: "\f341";
}

.mdi-folder-download:before {
	content: "\f342";
}

.mdi-folder-google-drive:before {
	content: "\f343";
}

.mdi-folder-image:before {
	content: "\f344";
}

.mdi-folder-lock:before {
	content: "\f345";
}

.mdi-folder-lock-open:before {
	content: "\f346";
}

.mdi-folder-move:before {
	content: "\f347";
}

.mdi-folder-multiple:before {
	content: "\f348";
}

.mdi-folder-multiple-image:before {
	content: "\f349";
}

.mdi-folder-multiple-outline:before {
	content: "\f34a";
}

.mdi-folder-outline:before {
	content: "\f34b";
}

.mdi-folder-plus:before {
	content: "\f34c";
}

.mdi-folder-remove:before {
	content: "\f34d";
}

.mdi-folder-upload:before {
	content: "\f34e";
}

.mdi-food:before {
	content: "\f34f";
}

.mdi-food-apple:before {
	content: "\f350";
}

.mdi-food-variant:before {
	content: "\f351";
}

.mdi-football:before {
	content: "\f352";
}

.mdi-football-australian:before {
	content: "\f353";
}

.mdi-football-helmet:before {
	content: "\f354";
}

.mdi-format-align-center:before {
	content: "\f355";
}

.mdi-format-align-justify:before {
	content: "\f356";
}

.mdi-format-align-left:before {
	content: "\f357";
}

.mdi-format-align-right:before {
	content: "\f358";
}

.mdi-format-bold:before {
	content: "\f359";
}

.mdi-format-clear:before {
	content: "\f35a";
}

.mdi-format-color-fill:before {
	content: "\f35b";
}

.mdi-format-float-center:before {
	content: "\f35c";
}

.mdi-format-float-left:before {
	content: "\f35d";
}

.mdi-format-float-none:before {
	content: "\f35e";
}

.mdi-format-float-right:before {
	content: "\f35f";
}

.mdi-format-header-1:before {
	content: "\f360";
}

.mdi-format-header-2:before {
	content: "\f361";
}

.mdi-format-header-3:before {
	content: "\f362";
}

.mdi-format-header-4:before {
	content: "\f363";
}

.mdi-format-header-5:before {
	content: "\f364";
}

.mdi-format-header-6:before {
	content: "\f365";
}

.mdi-format-header-decrease:before {
	content: "\f366";
}

.mdi-format-header-equal:before {
	content: "\f367";
}

.mdi-format-header-increase:before {
	content: "\f368";
}

.mdi-format-header-pound:before {
	content: "\f369";
}

.mdi-format-indent-decrease:before {
	content: "\f36a";
}

.mdi-format-indent-increase:before {
	content: "\f36b";
}

.mdi-format-italic:before {
	content: "\f36c";
}

.mdi-format-line-spacing:before {
	content: "\f36d";
}

.mdi-format-list-bulleted:before {
	content: "\f36e";
}

.mdi-format-list-bulleted-type:before {
	content: "\f36f";
}

.mdi-format-list-numbers:before {
	content: "\f370";
}

.mdi-format-paint:before {
	content: "\f371";
}

.mdi-format-paragraph:before {
	content: "\f372";
}

.mdi-format-quote:before {
	content: "\f373";
}

.mdi-format-size:before {
	content: "\f374";
}

.mdi-format-strikethrough:before {
	content: "\f375";
}

.mdi-format-strikethrough-variant:before {
	content: "\f376";
}

.mdi-format-subscript:before {
	content: "\f377";
}

.mdi-format-superscript:before {
	content: "\f378";
}

.mdi-format-text:before {
	content: "\f379";
}

.mdi-format-textdirection-l-to-r:before {
	content: "\f37a";
}

.mdi-format-textdirection-r-to-l:before {
	content: "\f37b";
}

.mdi-format-underline:before {
	content: "\f37c";
}

.mdi-format-wrap-inline:before {
	content: "\f37d";
}

.mdi-format-wrap-square:before {
	content: "\f37e";
}

.mdi-format-wrap-tight:before {
	content: "\f37f";
}

.mdi-format-wrap-top-bottom:before {
	content: "\f380";
}

.mdi-forum:before {
	content: "\f381";
}

.mdi-forward:before {
	content: "\f382";
}

.mdi-foursquare:before {
	content: "\f383";
}

.mdi-fridge:before {
	content: "\f384";
}

.mdi-fridge-filled:before {
	content: "\f385";
}

.mdi-fridge-filled-bottom:before {
	content: "\f386";
}

.mdi-fridge-filled-top:before {
	content: "\f387";
}

.mdi-fullscreen:before {
	content: "\f388";
}

.mdi-fullscreen-exit:before {
	content: "\f389";
}

.mdi-function:before {
	content: "\f38a";
}

.mdi-gamepad:before {
	content: "\f38b";
}

.mdi-gamepad-variant:before {
	content: "\f38c";
}

.mdi-gas-station:before {
	content: "\f38d";
}

.mdi-gate:before {
	content: "\f38e";
}

.mdi-gauge:before {
	content: "\f38f";
}

.mdi-gavel:before {
	content: "\f390";
}

.mdi-gender-female:before {
	content: "\f391";
}

.mdi-gender-male:before {
	content: "\f392";
}

.mdi-gender-male-female:before {
	content: "\f393";
}

.mdi-gender-transgender:before {
	content: "\f394";
}

.mdi-ghost:before {
	content: "\f395";
}

.mdi-gift:before {
	content: "\f396";
}

.mdi-git:before {
	content: "\f397";
}

.mdi-github-box:before {
	content: "\f398";
}

.mdi-github-circle:before {
	content: "\f399";
}

.mdi-glass-flute:before {
	content: "\f39a";
}

.mdi-glass-mug:before {
	content: "\f39b";
}

.mdi-glass-stange:before {
	content: "\f39c";
}

.mdi-glass-tulip:before {
	content: "\f39d";
}

.mdi-glasses:before {
	content: "\f39e";
}

.mdi-gmail:before {
	content: "\f39f";
}

.mdi-google:before {
	content: "\f3a0";
}

.mdi-google-cardboard:before {
	content: "\f3a1";
}

.mdi-google-chrome:before {
	content: "\f3a2";
}

.mdi-google-circles:before {
	content: "\f3a3";
}

.mdi-google-circles-communities:before {
	content: "\f3a4";
}

.mdi-google-circles-extended:before {
	content: "\f3a5";
}

.mdi-google-circles-group:before {
	content: "\f3a6";
}

.mdi-google-controller:before {
	content: "\f3a7";
}

.mdi-google-controller-off:before {
	content: "\f3a8";
}

.mdi-google-drive:before {
	content: "\f3a9";
}

.mdi-google-earth:before {
	content: "\f3aa";
}

.mdi-google-glass:before {
	content: "\f3ab";
}

.mdi-google-nearby:before {
	content: "\f3ac";
}

.mdi-google-pages:before {
	content: "\f3ad";
}

.mdi-google-physical-web:before {
	content: "\f3ae";
}

.mdi-google-play:before {
	content: "\f3af";
}

.mdi-google-plus:before {
	content: "\f3b0";
}

.mdi-google-plus-box:before {
	content: "\f3b1";
}

.mdi-google-translate:before {
	content: "\f3b2";
}

.mdi-google-wallet:before {
	content: "\f3b3";
}

.mdi-grid:before {
	content: "\f3b4";
}

.mdi-grid-off:before {
	content: "\f3b5";
}

.mdi-group:before {
	content: "\f3b6";
}

.mdi-guitar:before {
	content: "\f3b7";
}

.mdi-guitar-pick:before {
	content: "\f3b8";
}

.mdi-guitar-pick-outline:before {
	content: "\f3b9";
}

.mdi-hand-pointing-right:before {
	content: "\f3ba";
}

.mdi-hanger:before {
	content: "\f3bb";
}

.mdi-hangouts:before {
	content: "\f3bc";
}

.mdi-harddisk:before {
	content: "\f3bd";
}

.mdi-headphones:before {
	content: "\f3be";
}

.mdi-headphones-box:before {
	content: "\f3bf";
}

.mdi-headphones-settings:before {
	content: "\f3c0";
}

.mdi-headset:before {
	content: "\f3c1";
}

.mdi-headset-dock:before {
	content: "\f3c2";
}

.mdi-headset-off:before {
	content: "\f3c3";
}

.mdi-heart:before {
	content: "\f3c4";
}

.mdi-heart-box:before {
	content: "\f3c5";
}

.mdi-heart-box-outline:before {
	content: "\f3c6";
}

.mdi-heart-broken:before {
	content: "\f3c7";
}

.mdi-heart-outline:before {
	content: "\f3c8";
}

.mdi-help:before {
	content: "\f3c9";
}

.mdi-help-circle:before {
	content: "\f3ca";
}

.mdi-hexagon:before {
	content: "\f3cb";
}

.mdi-hexagon-outline:before {
	content: "\f3cc";
}

.mdi-history:before {
	content: "\f3cd";
}

.mdi-hololens:before {
	content: "\f3ce";
}

.mdi-home:before {
	content: "\f3cf";
}

.mdi-home-modern:before {
	content: "\f3d0";
}

.mdi-home-variant:before {
	content: "\f3d1";
}

.mdi-hops:before {
	content: "\f3d2";
}

.mdi-hospital:before {
	content: "\f3d3";
}

.mdi-hospital-building:before {
	content: "\f3d4";
}

.mdi-hospital-marker:before {
	content: "\f3d5";
}

.mdi-hotel:before {
	content: "\f3d6";
}

.mdi-houzz:before {
	content: "\f3d7";
}

.mdi-houzz-box:before {
	content: "\f3d8";
}

.mdi-human:before {
	content: "\f3d9";
}

.mdi-human-child:before {
	content: "\f3da";
}

.mdi-human-male-female:before {
	content: "\f3db";
}

.mdi-image:before {
	content: "\f3dc";
}

.mdi-image-album:before {
	content: "\f3dd";
}

.mdi-image-area:before {
	content: "\f3de";
}

.mdi-image-area-close:before {
	content: "\f3df";
}

.mdi-image-broken:before {
	content: "\f3e0";
}

.mdi-image-broken-variant:before {
	content: "\f3e1";
}

.mdi-image-filter:before {
	content: "\f3e2";
}

.mdi-image-filter-black-white:before {
	content: "\f3e3";
}

.mdi-image-filter-center-focus:before {
	content: "\f3e4";
}

.mdi-image-filter-center-focus-weak:before {
	content: "\f3e5";
}

.mdi-image-filter-drama:before {
	content: "\f3e6";
}

.mdi-image-filter-frames:before {
	content: "\f3e7";
}

.mdi-image-filter-hdr:before {
	content: "\f3e8";
}

.mdi-image-filter-none:before {
	content: "\f3e9";
}

.mdi-image-filter-tilt-shift:before {
	content: "\f3ea";
}

.mdi-image-filter-vintage:before {
	content: "\f3eb";
}

.mdi-image-multiple:before {
	content: "\f3ec";
}

.mdi-import:before {
	content: "\f3ed";
}

.mdi-inbox:before {
	content: "\f3ee";
}

.mdi-information:before {
	content: "\f3ef";
}

.mdi-information-outline:before {
	content: "\f3f0";
}

.mdi-instagram:before {
	content: "\f3f1";
}

.mdi-instapaper:before {
	content: "\f3f2";
}

.mdi-internet-explorer:before {
	content: "\f3f3";
}

.mdi-invert-colors:before {
	content: "\f3f4";
}

.mdi-jeepney:before {
	content: "\f3f5";
}

.mdi-jira:before {
	content: "\f3f6";
}

.mdi-jsfiddle:before {
	content: "\f3f7";
}

.mdi-keg:before {
	content: "\f3f8";
}

.mdi-key:before {
	content: "\f3f9";
}

.mdi-key-change:before {
	content: "\f3fa";
}

.mdi-key-minus:before {
	content: "\f3fb";
}

.mdi-key-plus:before {
	content: "\f3fc";
}

.mdi-key-remove:before {
	content: "\f3fd";
}

.mdi-key-variant:before {
	content: "\f3fe";
}

.mdi-keyboard:before {
	content: "\f3ff";
}

.mdi-keyboard-backspace:before {
	content: "\f400";
}

.mdi-keyboard-caps:before {
	content: "\f401";
}

.mdi-keyboard-close:before {
	content: "\f402";
}

.mdi-keyboard-off:before {
	content: "\f403";
}

.mdi-keyboard-return:before {
	content: "\f404";
}

.mdi-keyboard-tab:before {
	content: "\f405";
}

.mdi-keyboard-variant:before {
	content: "\f406";
}

.mdi-label:before {
	content: "\f407";
}

.mdi-label-outline:before {
	content: "\f408";
}

.mdi-lan:before {
	content: "\f409";
}

.mdi-lan-connect:before {
	content: "\f40a";
}

.mdi-lan-disconnect:before {
	content: "\f40b";
}

.mdi-lan-pending:before {
	content: "\f40c";
}

.mdi-language-csharp:before {
	content: "\f40d";
}

.mdi-language-css3:before {
	content: "\f40e";
}

.mdi-language-html5:before {
	content: "\f40f";
}

.mdi-language-javascript:before {
	content: "\f410";
}

.mdi-language-php:before {
	content: "\f411";
}

.mdi-language-python:before {
	content: "\f412";
}

.mdi-language-python-text:before {
	content: "\f413";
}

.mdi-laptop:before {
	content: "\f414";
}

.mdi-laptop-chromebook:before {
	content: "\f415";
}

.mdi-laptop-mac:before {
	content: "\f416";
}

.mdi-laptop-windows:before {
	content: "\f417";
}

.mdi-lastfm:before {
	content: "\f418";
}

.mdi-launch:before {
	content: "\f419";
}

.mdi-layers:before {
	content: "\f41a";
}

.mdi-layers-off:before {
	content: "\f41b";
}

.mdi-leaf:before {
	content: "\f41c";
}

.mdi-led-off:before {
	content: "\f41d";
}

.mdi-led-on:before {
	content: "\f41e";
}

.mdi-led-outline:before {
	content: "\f41f";
}

.mdi-led-variant-off:before {
	content: "\f420";
}

.mdi-led-variant-on:before {
	content: "\f421";
}

.mdi-led-variant-outline:before {
	content: "\f422";
}

.mdi-library:before {
	content: "\f423";
}

.mdi-library-books:before {
	content: "\f424";
}

.mdi-library-music:before {
	content: "\f425";
}

.mdi-library-plus:before {
	content: "\f426";
}

.mdi-lightbulb:before {
	content: "\f427";
}

.mdi-lightbulb-outline:before {
	content: "\f428";
}

.mdi-link:before {
	content: "\f429";
}

.mdi-link-off:before {
	content: "\f42a";
}

.mdi-link-variant:before {
	content: "\f42b";
}

.mdi-link-variant-off:before {
	content: "\f42c";
}

.mdi-linkedin:before {
	content: "\f42d";
}

.mdi-linkedin-box:before {
	content: "\f42e";
}

.mdi-linux:before {
	content: "\f42f";
}

.mdi-lock:before {
	content: "\f430";
}

.mdi-lock-open:before {
	content: "\f431";
}

.mdi-lock-open-outline:before {
	content: "\f432";
}

.mdi-lock-outline:before {
	content: "\f433";
}

.mdi-login:before {
	content: "\f434";
}

.mdi-logout:before {
	content: "\f435";
}

.mdi-looks:before {
	content: "\f436";
}

.mdi-loupe:before {
	content: "\f437";
}

.mdi-lumx:before {
	content: "\f438";
}

.mdi-magnet:before {
	content: "\f439";
}

.mdi-magnet-on:before {
	content: "\f43a";
}

.mdi-magnify:before {
	content: "\f43b";
}

.mdi-magnify-minus:before {
	content: "\f43c";
}

.mdi-magnify-plus:before {
	content: "\f43d";
}

.mdi-mail-ru:before {
	content: "\f43e";
}

.mdi-map:before {
	content: "\f43f";
}

.mdi-map-marker:before {
	content: "\f440";
}

.mdi-map-marker-circle:before {
	content: "\f441";
}

.mdi-map-marker-multiple:before {
	content: "\f442";
}

.mdi-map-marker-off:before {
	content: "\f443";
}

.mdi-map-marker-radius:before {
	content: "\f444";
}

.mdi-margin:before {
	content: "\f445";
}

.mdi-markdown:before {
	content: "\f446";
}

.mdi-marker-check:before {
	content: "\f447";
}

.mdi-martini:before {
	content: "\f448";
}

.mdi-material-ui:before {
	content: "\f449";
}

.mdi-math-compass:before {
	content: "\f44a";
}

.mdi-maxcdn:before {
	content: "\f44b";
}

.mdi-medium:before {
	content: "\f44c";
}

.mdi-memory:before {
	content: "\f44d";
}

.mdi-menu:before {
	content: "\f44e";
}

.mdi-menu-down:before {
	content: "\f44f";
}

.mdi-menu-left:before {
	content: "\f450";
}

.mdi-menu-right:before {
	content: "\f451";
}

.mdi-menu-up:before {
	content: "\f452";
}

.mdi-message:before {
	content: "\f453";
}

.mdi-message-alert:before {
	content: "\f454";
}

.mdi-message-draw:before {
	content: "\f455";
}

.mdi-message-image:before {
	content: "\f456";
}

.mdi-message-outline:before {
	content: "\f457";
}

.mdi-message-processing:before {
	content: "\f458";
}

.mdi-message-reply:before {
	content: "\f459";
}

.mdi-message-reply-text:before {
	content: "\f45a";
}

.mdi-message-text:before {
	content: "\f45b";
}

.mdi-message-text-outline:before {
	content: "\f45c";
}

.mdi-message-video:before {
	content: "\f45d";
}

.mdi-microphone:before {
	content: "\f45e";
}

.mdi-microphone-off:before {
	content: "\f45f";
}

.mdi-microphone-outline:before {
	content: "\f460";
}

.mdi-microphone-settings:before {
	content: "\f461";
}

.mdi-microphone-variant:before {
	content: "\f462";
}

.mdi-microphone-variant-off:before {
	content: "\f463";
}

.mdi-microsoft:before {
	content: "\f464";
}

.mdi-minus:before {
	content: "\f465";
}

.mdi-minus-box:before {
	content: "\f466";
}

.mdi-minus-circle:before {
	content: "\f467";
}

.mdi-minus-circle-outline:before {
	content: "\f468";
}

.mdi-minus-network:before {
	content: "\f469";
}

.mdi-monitor:before {
	content: "\f46a";
}

.mdi-monitor-multiple:before {
	content: "\f46b";
}

.mdi-more:before {
	content: "\f46c";
}

.mdi-motorbike:before {
	content: "\f46d";
}

.mdi-mouse:before {
	content: "\f46e";
}

.mdi-mouse-off:before {
	content: "\f46f";
}

.mdi-mouse-variant:before {
	content: "\f470";
}

.mdi-mouse-variant-off:before {
	content: "\f471";
}

.mdi-movie:before {
	content: "\f472";
}

.mdi-multiplication:before {
	content: "\f473";
}

.mdi-multiplication-box:before {
	content: "\f474";
}

.mdi-music-box:before {
	content: "\f475";
}

.mdi-music-box-outline:before {
	content: "\f476";
}

.mdi-music-circle:before {
	content: "\f477";
}

.mdi-music-note:before {
	content: "\f478";
}

.mdi-music-note-eighth:before {
	content: "\f479";
}

.mdi-music-note-half:before {
	content: "\f47a";
}

.mdi-music-note-off:before {
	content: "\f47b";
}

.mdi-music-note-quarter:before {
	content: "\f47c";
}

.mdi-music-note-sixteenth:before {
	content: "\f47d";
}

.mdi-music-note-whole:before {
	content: "\f47e";
}

.mdi-nature:before {
	content: "\f47f";
}

.mdi-nature-people:before {
	content: "\f480";
}

.mdi-navigation:before {
	content: "\f481";
}

.mdi-needle:before {
	content: "\f482";
}

.mdi-nest-protect:before {
	content: "\f483";
}

.mdi-nest-thermostat:before {
	content: "\f484";
}

.mdi-newspaper:before {
	content: "\f485";
}

.mdi-nfc:before {
	content: "\f486";
}

.mdi-nfc-tap:before {
	content: "\f487";
}

.mdi-nfc-variant:before {
	content: "\f488";
}

.mdi-nodejs:before {
	content: "\f489";
}

.mdi-note:before {
	content: "\f48a";
}

.mdi-note-outline:before {
	content: "\f48b";
}

.mdi-note-plus:before {
	content: "\f48c";
}

.mdi-note-plus-outline:before {
	content: "\f48d";
}

.mdi-note-text:before {
	content: "\f48e";
}

.mdi-notification-clear-all:before {
	content: "\f48f";
}

.mdi-numeric:before {
	content: "\f490";
}

.mdi-numeric-0-box:before {
	content: "\f491";
}

.mdi-numeric-0-box-multiple-outline:before {
	content: "\f492";
}

.mdi-numeric-0-box-outline:before {
	content: "\f493";
}

.mdi-numeric-1-box:before {
	content: "\f494";
}

.mdi-numeric-1-box-multiple-outline:before {
	content: "\f495";
}

.mdi-numeric-1-box-outline:before {
	content: "\f496";
}

.mdi-numeric-2-box:before {
	content: "\f497";
}

.mdi-numeric-2-box-multiple-outline:before {
	content: "\f498";
}

.mdi-numeric-2-box-outline:before {
	content: "\f499";
}

.mdi-numeric-3-box:before {
	content: "\f49a";
}

.mdi-numeric-3-box-multiple-outline:before {
	content: "\f49b";
}

.mdi-numeric-3-box-outline:before {
	content: "\f49c";
}

.mdi-numeric-4-box:before {
	content: "\f49d";
}

.mdi-numeric-4-box-multiple-outline:before {
	content: "\f49e";
}

.mdi-numeric-4-box-outline:before {
	content: "\f49f";
}

.mdi-numeric-5-box:before {
	content: "\f4a0";
}

.mdi-numeric-5-box-multiple-outline:before {
	content: "\f4a1";
}

.mdi-numeric-5-box-outline:before {
	content: "\f4a2";
}

.mdi-numeric-6-box:before {
	content: "\f4a3";
}

.mdi-numeric-6-box-multiple-outline:before {
	content: "\f4a4";
}

.mdi-numeric-6-box-outline:before {
	content: "\f4a5";
}

.mdi-numeric-7-box:before {
	content: "\f4a6";
}

.mdi-numeric-7-box-multiple-outline:before {
	content: "\f4a7";
}

.mdi-numeric-7-box-outline:before {
	content: "\f4a8";
}

.mdi-numeric-8-box:before {
	content: "\f4a9";
}

.mdi-numeric-8-box-multiple-outline:before {
	content: "\f4aa";
}

.mdi-numeric-8-box-outline:before {
	content: "\f4ab";
}

.mdi-numeric-9-box:before {
	content: "\f4ac";
}

.mdi-numeric-9-box-multiple-outline:before {
	content: "\f4ad";
}

.mdi-numeric-9-box-outline:before {
	content: "\f4ae";
}

.mdi-numeric-9-plus-box:before {
	content: "\f4af";
}

.mdi-numeric-9-plus-box-multiple-outline:before {
	content: "\f4b0";
}

.mdi-numeric-9-plus-box-outline:before {
	content: "\f4b1";
}

.mdi-nutrition:before {
	content: "\f4b2";
}

.mdi-octagon:before {
	content: "\f4b3";
}

.mdi-octagon-outline:before {
	content: "\f4b4";
}

.mdi-odnoklassniki:before {
	content: "\f4b5";
}

.mdi-office:before {
	content: "\f4b6";
}

.mdi-oil:before {
	content: "\f4b7";
}

.mdi-oil-temperature:before {
	content: "\f4b8";
}

.mdi-omega:before {
	content: "\f4b9";
}

.mdi-onedrive:before {
	content: "\f4ba";
}

.mdi-open-in-app:before {
	content: "\f4bb";
}

.mdi-open-in-new:before {
	content: "\f4bc";
}

.mdi-opera:before {
	content: "\f4bd";
}

.mdi-ornament:before {
	content: "\f4be";
}

.mdi-ornament-variant:before {
	content: "\f4bf";
}

.mdi-outbox:before {
	content: "\f4c0";
}

.mdi-owl:before {
	content: "\f4c1";
}

.mdi-package:before {
	content: "\f4c2";
}

.mdi-package-down:before {
	content: "\f4c3";
}

.mdi-package-up:before {
	content: "\f4c4";
}

.mdi-package-variant:before {
	content: "\f4c5";
}

.mdi-package-variant-closed:before {
	content: "\f4c6";
}

.mdi-palette:before {
	content: "\f4c7";
}

.mdi-palette-advanced:before {
	content: "\f4c8";
}

.mdi-panda:before {
	content: "\f4c9";
}

.mdi-pandora:before {
	content: "\f4ca";
}

.mdi-panorama:before {
	content: "\f4cb";
}

.mdi-panorama-fisheye:before {
	content: "\f4cc";
}

.mdi-panorama-horizontal:before {
	content: "\f4cd";
}

.mdi-panorama-vertical:before {
	content: "\f4ce";
}

.mdi-panorama-wide-angle:before {
	content: "\f4cf";
}

.mdi-paper-cut-vertical:before {
	content: "\f4d0";
}

.mdi-paperclip:before {
	content: "\f4d1";
}

.mdi-parking:before {
	content: "\f4d2";
}

.mdi-pause:before {
	content: "\f4d3";
}

.mdi-pause-circle:before {
	content: "\f4d4";
}

.mdi-pause-circle-outline:before {
	content: "\f4d5";
}

.mdi-pause-octagon:before {
	content: "\f4d6";
}

.mdi-pause-octagon-outline:before {
	content: "\f4d7";
}

.mdi-paw:before {
	content: "\f4d8";
}

.mdi-pen:before {
	content: "\f4d9";
}

.mdi-pencil:before {
	content: "\f4da";
}

.mdi-pencil-box:before {
	content: "\f4db";
}

.mdi-pencil-box-outline:before {
	content: "\f4dc";
}

.mdi-pencil-lock:before {
	content: "\f4dd";
}

.mdi-pencil-off:before {
	content: "\f4de";
}

.mdi-percent:before {
	content: "\f4df";
}

.mdi-pharmacy:before {
	content: "\f4e0";
}

.mdi-phone:before {
	content: "\f4e1";
}

.mdi-phone-bluetooth:before {
	content: "\f4e2";
}

.mdi-phone-forward:before {
	content: "\f4e3";
}

.mdi-phone-hangup:before {
	content: "\f4e4";
}

.mdi-phone-in-talk:before {
	content: "\f4e5";
}

.mdi-phone-incoming:before {
	content: "\f4e6";
}

.mdi-phone-locked:before {
	content: "\f4e7";
}

.mdi-phone-log:before {
	content: "\f4e8";
}

.mdi-phone-missed:before {
	content: "\f4e9";
}

.mdi-phone-outgoing:before {
	content: "\f4ea";
}

.mdi-phone-paused:before {
	content: "\f4eb";
}

.mdi-phone-settings:before {
	content: "\f4ec";
}

.mdi-phone-voip:before {
	content: "\f4ed";
}

.mdi-pi:before {
	content: "\f4ee";
}

.mdi-pi-box:before {
	content: "\f4ef";
}

.mdi-pig:before {
	content: "\f4f0";
}

.mdi-pill:before {
	content: "\f4f1";
}

.mdi-pin:before {
	content: "\f4f2";
}

.mdi-pin-off:before {
	content: "\f4f3";
}

.mdi-pine-tree:before {
	content: "\f4f4";
}

.mdi-pine-tree-box:before {
	content: "\f4f5";
}

.mdi-pinterest:before {
	content: "\f4f6";
}

.mdi-pinterest-box:before {
	content: "\f4f7";
}

.mdi-pizza:before {
	content: "\f4f8";
}

.mdi-play:before {
	content: "\f4f9";
}

.mdi-play-box-outline:before {
	content: "\f4fa";
}

.mdi-play-circle:before {
	content: "\f4fb";
}

.mdi-play-circle-outline:before {
	content: "\f4fc";
}

.mdi-play-pause:before {
	content: "\f4fd";
}

.mdi-play-protected-content:before {
	content: "\f4fe";
}

.mdi-playlist-minus:before {
	content: "\f4ff";
}

.mdi-playlist-play:before {
	content: "\f500";
}

.mdi-playlist-plus:before {
	content: "\f501";
}

.mdi-playlist-remove:before {
	content: "\f502";
}

.mdi-playstation:before {
	content: "\f503";
}

.mdi-plus:before {
	content: "\f504";
}

.mdi-plus-box:before {
	content: "\f505";
}

.mdi-plus-circle:before {
	content: "\f506";
}

.mdi-plus-circle-multiple-outline:before {
	content: "\f507";
}

.mdi-plus-circle-outline:before {
	content: "\f508";
}

.mdi-plus-network:before {
	content: "\f509";
}

.mdi-plus-one:before {
	content: "\f50a";
}

.mdi-pocket:before {
	content: "\f50b";
}

.mdi-pokeball:before {
	content: "\f50c";
}

.mdi-polaroid:before {
	content: "\f50d";
}

.mdi-poll:before {
	content: "\f50e";
}

.mdi-poll-box:before {
	content: "\f50f";
}

.mdi-polymer:before {
	content: "\f510";
}

.mdi-popcorn:before {
	content: "\f511";
}

.mdi-pound:before {
	content: "\f512";
}

.mdi-pound-box:before {
	content: "\f513";
}

.mdi-power:before {
	content: "\f514";
}

.mdi-power-settings:before {
	content: "\f515";
}

.mdi-power-socket:before {
	content: "\f516";
}

.mdi-presentation:before {
	content: "\f517";
}

.mdi-presentation-play:before {
	content: "\f518";
}

.mdi-printer:before {
	content: "\f519";
}

.mdi-printer-3d:before {
	content: "\f51a";
}

.mdi-printer-alert:before {
	content: "\f51b";
}

.mdi-professional-hexagon:before {
	content: "\f51c";
}

.mdi-projector:before {
	content: "\f51d";
}

.mdi-projector-screen:before {
	content: "\f51e";
}

.mdi-pulse:before {
	content: "\f51f";
}

.mdi-puzzle:before {
	content: "\f520";
}

.mdi-qrcode:before {
	content: "\f521";
}

.mdi-qrcode-scan:before {
	content: "\f522";
}

.mdi-quadcopter:before {
	content: "\f523";
}

.mdi-quality-high:before {
	content: "\f524";
}

.mdi-quicktime:before {
	content: "\f525";
}

.mdi-radar:before {
	content: "\f526";
}

.mdi-radiator:before {
	content: "\f527";
}

.mdi-radio:before {
	content: "\f528";
}

.mdi-radio-handheld:before {
	content: "\f529";
}

.mdi-radio-tower:before {
	content: "\f52a";
}

.mdi-radioactive:before {
	content: "\f52b";
}

.mdi-radiobox-blank:before {
	content: "\f52c";
}

.mdi-radiobox-marked:before {
	content: "\f52d";
}

.mdi-raspberrypi:before {
	content: "\f52e";
}

.mdi-ray-end:before {
	content: "\f52f";
}

.mdi-ray-end-arrow:before {
	content: "\f530";
}

.mdi-ray-start:before {
	content: "\f531";
}

.mdi-ray-start-arrow:before {
	content: "\f532";
}

.mdi-ray-start-end:before {
	content: "\f533";
}

.mdi-ray-vertex:before {
	content: "\f534";
}

.mdi-rdio:before {
	content: "\f535";
}

.mdi-read:before {
	content: "\f536";
}

.mdi-readability:before {
	content: "\f537";
}

.mdi-receipt:before {
	content: "\f538";
}

.mdi-record:before {
	content: "\f539";
}

.mdi-record-rec:before {
	content: "\f53a";
}

.mdi-recycle:before {
	content: "\f53b";
}

.mdi-reddit:before {
	content: "\f53c";
}

.mdi-redo:before {
	content: "\f53d";
}

.mdi-redo-variant:before {
	content: "\f53e";
}

.mdi-refresh:before {
	content: "\f53f";
}

.mdi-regex:before {
	content: "\f540";
}

.mdi-relative-scale:before {
	content: "\f541";
}

.mdi-reload:before {
	content: "\f542";
}

.mdi-remote:before {
	content: "\f543";
}

.mdi-rename-box:before {
	content: "\f544";
}

.mdi-repeat:before {
	content: "\f545";
}

.mdi-repeat-off:before {
	content: "\f546";
}

.mdi-repeat-once:before {
	content: "\f547";
}

.mdi-replay:before {
	content: "\f548";
}

.mdi-reply:before {
	content: "\f549";
}

.mdi-reply-all:before {
	content: "\f54a";
}

.mdi-reproduction:before {
	content: "\f54b";
}

.mdi-resize-bottom-right:before {
	content: "\f54c";
}

.mdi-responsive:before {
	content: "\f54d";
}

.mdi-rewind:before {
	content: "\f54e";
}

.mdi-ribbon:before {
	content: "\f54f";
}

.mdi-road:before {
	content: "\f550";
}

.mdi-road-variant:before {
	content: "\f551";
}

.mdi-rocket:before {
	content: "\f552";
}

.mdi-rotate-3d:before {
	content: "\f553";
}

.mdi-rotate-left:before {
	content: "\f554";
}

.mdi-rotate-left-variant:before {
	content: "\f555";
}

.mdi-rotate-right:before {
	content: "\f556";
}

.mdi-rotate-right-variant:before {
	content: "\f557";
}

.mdi-router-wireless:before {
	content: "\f558";
}

.mdi-routes:before {
	content: "\f559";
}

.mdi-rss:before {
	content: "\f55a";
}

.mdi-rss-box:before {
	content: "\f55b";
}

.mdi-ruler:before {
	content: "\f55c";
}

.mdi-run:before {
	content: "\f55d";
}

.mdi-sale:before {
	content: "\f55e";
}

.mdi-satellite:before {
	content: "\f55f";
}

.mdi-satellite-variant:before {
	content: "\f560";
}

.mdi-scale:before {
	content: "\f561";
}

.mdi-scale-bathroom:before {
	content: "\f562";
}

.mdi-school:before {
	content: "\f563";
}

.mdi-screen-rotation:before {
	content: "\f564";
}

.mdi-screen-rotation-lock:before {
	content: "\f565";
}

.mdi-screwdriver:before {
	content: "\f566";
}

.mdi-script:before {
	content: "\f567";
}

.mdi-sd:before {
	content: "\f568";
}

.mdi-seal:before {
	content: "\f569";
}

.mdi-seat-flat:before {
	content: "\f56a";
}

.mdi-seat-flat-angled:before {
	content: "\f56b";
}

.mdi-seat-individual-suite:before {
	content: "\f56c";
}

.mdi-seat-legroom-extra:before {
	content: "\f56d";
}

.mdi-seat-legroom-normal:before {
	content: "\f56e";
}

.mdi-seat-legroom-reduced:before {
	content: "\f56f";
}

.mdi-seat-recline-extra:before {
	content: "\f570";
}

.mdi-seat-recline-normal:before {
	content: "\f571";
}

.mdi-security:before {
	content: "\f572";
}

.mdi-security-network:before {
	content: "\f573";
}

.mdi-select:before {
	content: "\f574";
}

.mdi-select-all:before {
	content: "\f575";
}

.mdi-select-inverse:before {
	content: "\f576";
}

.mdi-select-off:before {
	content: "\f577";
}

.mdi-selection:before {
	content: "\f578";
}

.mdi-send:before {
	content: "\f579";
}

.mdi-server:before {
	content: "\f57a";
}

.mdi-server-minus:before {
	content: "\f57b";
}

.mdi-server-network:before {
	content: "\f57c";
}

.mdi-server-network-off:before {
	content: "\f57d";
}

.mdi-server-off:before {
	content: "\f57e";
}

.mdi-server-plus:before {
	content: "\f57f";
}

.mdi-server-remove:before {
	content: "\f580";
}

.mdi-server-security:before {
	content: "\f581";
}

.mdi-settings:before {
	content: "\f582";
}

.mdi-settings-box:before {
	content: "\f583";
}

.mdi-shape-plus:before {
	content: "\f584";
}

.mdi-share:before {
	content: "\f585";
}

.mdi-share-variant:before {
	content: "\f586";
}

.mdi-shield:before {
	content: "\f587";
}

.mdi-shield-outline:before {
	content: "\f588";
}

.mdi-shopping:before {
	content: "\f589";
}

.mdi-shopping-music:before {
	content: "\f58a";
}

.mdi-shredder:before {
	content: "\f58b";
}

.mdi-shuffle:before {
	content: "\f58c";
}

.mdi-shuffle-disabled:before {
	content: "\f58d";
}

.mdi-shuffle-variant:before {
	content: "\f58e";
}

.mdi-sigma:before {
	content: "\f58f";
}

.mdi-sign-caution:before {
	content: "\f590";
}

.mdi-signal:before {
	content: "\f591";
}

.mdi-silverware:before {
	content: "\f592";
}

.mdi-silverware-fork:before {
	content: "\f593";
}

.mdi-silverware-spoon:before {
	content: "\f594";
}

.mdi-silverware-variant:before {
	content: "\f595";
}

.mdi-sim:before {
	content: "\f596";
}

.mdi-sim-alert:before {
	content: "\f597";
}

.mdi-sim-off:before {
	content: "\f598";
}

.mdi-sitemap:before {
	content: "\f599";
}

.mdi-skip-backward:before {
	content: "\f59a";
}

.mdi-skip-forward:before {
	content: "\f59b";
}

.mdi-skip-next:before {
	content: "\f59c";
}

.mdi-skip-previous:before {
	content: "\f59d";
}

.mdi-skype:before {
	content: "\f59e";
}

.mdi-skype-business:before {
	content: "\f59f";
}

.mdi-slack:before {
	content: "\f5a0";
}

.mdi-sleep:before {
	content: "\f5a1";
}

.mdi-sleep-off:before {
	content: "\f5a2";
}

.mdi-smoking:before {
	content: "\f5a3";
}

.mdi-smoking-off:before {
	content: "\f5a4";
}

.mdi-snapchat:before {
	content: "\f5a5";
}

.mdi-snowman:before {
	content: "\f5a6";
}

.mdi-sofa:before {
	content: "\f5a7";
}

.mdi-sort:before {
	content: "\f5a8";
}

.mdi-sort-alphabetical:before {
	content: "\f5a9";
}

.mdi-sort-ascending:before {
	content: "\f5aa";
}

.mdi-sort-descending:before {
	content: "\f5ab";
}

.mdi-sort-numeric:before {
	content: "\f5ac";
}

.mdi-sort-variant:before {
	content: "\f5ad";
}

.mdi-soundcloud:before {
	content: "\f5ae";
}

.mdi-source-fork:before {
	content: "\f5af";
}

.mdi-source-pull:before {
	content: "\f5b0";
}

.mdi-speaker:before {
	content: "\f5b1";
}

.mdi-speaker-off:before {
	content: "\f5b2";
}

.mdi-speedometer:before {
	content: "\f5b3";
}

.mdi-spellcheck:before {
	content: "\f5b4";
}

.mdi-spotify:before {
	content: "\f5b5";
}

.mdi-spotlight:before {
	content: "\f5b6";
}

.mdi-spotlight-beam:before {
	content: "\f5b7";
}

.mdi-square-inc:before {
	content: "\f5b8";
}

.mdi-square-inc-cash:before {
	content: "\f5b9";
}

.mdi-stackoverflow:before {
	content: "\f5ba";
}

.mdi-stairs:before {
	content: "\f5bb";
}

.mdi-star:before {
	content: "\f5bc";
}

.mdi-star-circle:before {
	content: "\f5bd";
}

.mdi-star-half:before {
	content: "\f5be";
}

.mdi-star-off:before {
	content: "\f5bf";
}

.mdi-star-outline:before {
	content: "\f5c0";
}

.mdi-steam:before {
	content: "\f5c1";
}

.mdi-steering:before {
	content: "\f5c2";
}

.mdi-step-backward:before {
	content: "\f5c3";
}

.mdi-step-backward-2:before {
	content: "\f5c4";
}

.mdi-step-forward:before {
	content: "\f5c5";
}

.mdi-step-forward-2:before {
	content: "\f5c6";
}

.mdi-stethoscope:before {
	content: "\f5c7";
}

.mdi-stocking:before {
	content: "\f5c8";
}

.mdi-stop:before {
	content: "\f5c9";
}

.mdi-store:before {
	content: "\f5ca";
}

.mdi-store-24-hour:before {
	content: "\f5cb";
}

.mdi-stove:before {
	content: "\f5cc";
}

.mdi-subway:before {
	content: "\f5cd";
}

.mdi-sunglasses:before {
	content: "\f5ce";
}

.mdi-swap-horizontal:before {
	content: "\f5cf";
}

.mdi-swap-vertical:before {
	content: "\f5d0";
}

.mdi-swim:before {
	content: "\f5d1";
}

.mdi-switch:before {
	content: "\f5d2";
}

.mdi-sword:before {
	content: "\f5d3";
}

.mdi-sync:before {
	content: "\f5d4";
}

.mdi-sync-alert:before {
	content: "\f5d5";
}

.mdi-sync-off:before {
	content: "\f5d6";
}

.mdi-tab:before {
	content: "\f5d7";
}

.mdi-tab-unselected:before {
	content: "\f5d8";
}

.mdi-table:before {
	content: "\f5d9";
}

.mdi-table-column-plus-after:before {
	content: "\f5da";
}

.mdi-table-column-plus-before:before {
	content: "\f5db";
}

.mdi-table-column-remove:before {
	content: "\f5dc";
}

.mdi-table-column-width:before {
	content: "\f5dd";
}

.mdi-table-edit:before {
	content: "\f5de";
}

.mdi-table-large:before {
	content: "\f5df";
}

.mdi-table-row-height:before {
	content: "\f5e0";
}

.mdi-table-row-plus-after:before {
	content: "\f5e1";
}

.mdi-table-row-plus-before:before {
	content: "\f5e2";
}

.mdi-table-row-remove:before {
	content: "\f5e3";
}

.mdi-tablet:before {
	content: "\f5e4";
}

.mdi-tablet-android:before {
	content: "\f5e5";
}

.mdi-tablet-ipad:before {
	content: "\f5e6";
}

.mdi-tag:before {
	content: "\f5e7";
}

.mdi-tag-faces:before {
	content: "\f5e8";
}

.mdi-tag-multiple:before {
	content: "\f5e9";
}

.mdi-tag-outline:before {
	content: "\f5ea";
}

.mdi-tag-text-outline:before {
	content: "\f5eb";
}

.mdi-target:before {
	content: "\f5ec";
}

.mdi-taxi:before {
	content: "\f5ed";
}

.mdi-teamviewer:before {
	content: "\f5ee";
}

.mdi-telegram:before {
	content: "\f5ef";
}

.mdi-television:before {
	content: "\f5f0";
}

.mdi-television-guide:before {
	content: "\f5f1";
}

.mdi-temperature-celsius:before {
	content: "\f5f2";
}

.mdi-temperature-fahrenheit:before {
	content: "\f5f3";
}

.mdi-temperature-kelvin:before {
	content: "\f5f4";
}

.mdi-tennis:before {
	content: "\f5f5";
}

.mdi-tent:before {
	content: "\f5f6";
}

.mdi-terrain:before {
	content: "\f5f7";
}

.mdi-text-to-speech:before {
	content: "\f5f8";
}

.mdi-text-to-speech-off:before {
	content: "\f5f9";
}

.mdi-texture:before {
	content: "\f5fa";
}

.mdi-theater:before {
	content: "\f5fb";
}

.mdi-theme-light-dark:before {
	content: "\f5fc";
}

.mdi-thermometer:before {
	content: "\f5fd";
}

.mdi-thermometer-lines:before {
	content: "\f5fe";
}

.mdi-thumb-down:before {
	content: "\f5ff";
}

.mdi-thumb-down-outline:before {
	content: "\f600";
}

.mdi-thumb-up:before {
	content: "\f601";
}

.mdi-thumb-up-outline:before {
	content: "\f602";
}

.mdi-thumbs-up-down:before {
	content: "\f603";
}

.mdi-ticket:before {
	content: "\f604";
}

.mdi-ticket-account:before {
	content: "\f605";
}

.mdi-ticket-confirmation:before {
	content: "\f606";
}

.mdi-tie:before {
	content: "\f607";
}

.mdi-timelapse:before {
	content: "\f608";
}

.mdi-timer:before {
	content: "\f609";
}

.mdi-timer-10:before {
	content: "\f60a";
}

.mdi-timer-3:before {
	content: "\f60b";
}

.mdi-timer-off:before {
	content: "\f60c";
}

.mdi-timer-sand:before {
	content: "\f60d";
}

.mdi-timetable:before {
	content: "\f60e";
}

.mdi-toggle-switch:before {
	content: "\f60f";
}

.mdi-toggle-switch-off:before {
	content: "\f610";
}

.mdi-tooltip:before {
	content: "\f611";
}

.mdi-tooltip-edit:before {
	content: "\f612";
}

.mdi-tooltip-image:before {
	content: "\f613";
}

.mdi-tooltip-outline:before {
	content: "\f614";
}

.mdi-tooltip-outline-plus:before {
	content: "\f615";
}

.mdi-tooltip-text:before {
	content: "\f616";
}

.mdi-tor:before {
	content: "\f617";
}

.mdi-traffic-light:before {
	content: "\f618";
}

.mdi-train:before {
	content: "\f619";
}

.mdi-tram:before {
	content: "\f61a";
}

.mdi-transcribe:before {
	content: "\f61b";
}

.mdi-transcribe-close:before {
	content: "\f61c";
}

.mdi-transfer:before {
	content: "\f61d";
}

.mdi-tree:before {
	content: "\f61e";
}

.mdi-trello:before {
	content: "\f61f";
}

.mdi-trending-down:before {
	content: "\f620";
}

.mdi-trending-neutral:before {
	content: "\f621";
}

.mdi-trending-up:before {
	content: "\f622";
}

.mdi-triangle:before {
	content: "\f623";
}

.mdi-triangle-outline:before {
	content: "\f624";
}

.mdi-trophy:before {
	content: "\f625";
}

.mdi-trophy-award:before {
	content: "\f626";
}

.mdi-trophy-outline:before {
	content: "\f627";
}

.mdi-trophy-variant:before {
	content: "\f628";
}

.mdi-trophy-variant-outline:before {
	content: "\f629";
}

.mdi-truck:before {
	content: "\f62a";
}

.mdi-truck-delivery:before {
	content: "\f62b";
}

.mdi-tshirt-crew:before {
	content: "\f62c";
}

.mdi-tshirt-v:before {
	content: "\f62d";
}

.mdi-tumblr:before {
	content: "\f62e";
}

.mdi-tumblr-reblog:before {
	content: "\f62f";
}

.mdi-twitch:before {
	content: "\f630";
}

.mdi-twitter:before {
	content: "\f631";
}

.mdi-twitter-box:before {
	content: "\f632";
}

.mdi-twitter-circle:before {
	content: "\f633";
}

.mdi-twitter-retweet:before {
	content: "\f634";
}

.mdi-ubuntu:before {
	content: "\f635";
}

.mdi-umbraco:before {
	content: "\f636";
}

.mdi-umbrella:before {
	content: "\f637";
}

.mdi-umbrella-outline:before {
	content: "\f638";
}

.mdi-undo:before {
	content: "\f639";
}

.mdi-undo-variant:before {
	content: "\f63a";
}

.mdi-unfold-less:before {
	content: "\f63b";
}

.mdi-unfold-more:before {
	content: "\f63c";
}

.mdi-ungroup:before {
	content: "\f63d";
}

.mdi-untappd:before {
	content: "\f63e";
}

.mdi-upload:before {
	content: "\f63f";
}

.mdi-usb:before {
	content: "\f640";
}

.mdi-vector-arrange-above:before {
	content: "\f641";
}

.mdi-vector-arrange-below:before {
	content: "\f642";
}

.mdi-vector-circle:before {
	content: "\f643";
}

.mdi-vector-circle-variant:before {
	content: "\f644";
}

.mdi-vector-combine:before {
	content: "\f645";
}

.mdi-vector-curve:before {
	content: "\f646";
}

.mdi-vector-difference:before {
	content: "\f647";
}

.mdi-vector-difference-ab:before {
	content: "\f648";
}

.mdi-vector-difference-ba:before {
	content: "\f649";
}

.mdi-vector-intersection:before {
	content: "\f64a";
}

.mdi-vector-line:before {
	content: "\f64b";
}

.mdi-vector-point:before {
	content: "\f64c";
}

.mdi-vector-polygon:before {
	content: "\f64d";
}

.mdi-vector-polyline:before {
	content: "\f64e";
}

.mdi-vector-selection:before {
	content: "\f64f";
}

.mdi-vector-square:before {
	content: "\f650";
}

.mdi-vector-triangle:before {
	content: "\f651";
}

.mdi-vector-union:before {
	content: "\f652";
}

.mdi-verified:before {
	content: "\f653";
}

.mdi-vibrate:before {
	content: "\f654";
}

.mdi-video:before {
	content: "\f655";
}

.mdi-video-off:before {
	content: "\f656";
}

.mdi-video-switch:before {
	content: "\f657";
}

.mdi-view-agenda:before {
	content: "\f658";
}

.mdi-view-array:before {
	content: "\f659";
}

.mdi-view-carousel:before {
	content: "\f65a";
}

.mdi-view-column:before {
	content: "\f65b";
}

.mdi-view-dashboard:before {
	content: "\f65c";
}

.mdi-view-day:before {
	content: "\f65d";
}

.mdi-view-grid:before {
	content: "\f65e";
}

.mdi-view-headline:before {
	content: "\f65f";
}

.mdi-view-list:before {
	content: "\f660";
}

.mdi-view-module:before {
	content: "\f661";
}

.mdi-view-quilt:before {
	content: "\f662";
}

.mdi-view-stream:before {
	content: "\f663";
}

.mdi-view-week:before {
	content: "\f664";
}

.mdi-vimeo:before {
	content: "\f665";
}

.mdi-vine:before {
	content: "\f666";
}

.mdi-vk:before {
	content: "\f667";
}

.mdi-vk-box:before {
	content: "\f668";
}

.mdi-vk-circle:before {
	content: "\f669";
}

.mdi-voicemail:before {
	content: "\f66a";
}

.mdi-volume-high:before {
	content: "\f66b";
}

.mdi-volume-low:before {
	content: "\f66c";
}

.mdi-volume-medium:before {
	content: "\f66d";
}

.mdi-volume-off:before {
	content: "\f66e";
}

.mdi-vpn:before {
	content: "\f66f";
}

.mdi-walk:before {
	content: "\f670";
}

.mdi-wallet:before {
	content: "\f671";
}

.mdi-wallet-giftcard:before {
	content: "\f672";
}

.mdi-wallet-membership:before {
	content: "\f673";
}

.mdi-wallet-travel:before {
	content: "\f674";
}

.mdi-wan:before {
	content: "\f675";
}

.mdi-watch:before {
	content: "\f676";
}

.mdi-watch-export:before {
	content: "\f677";
}

.mdi-watch-import:before {
	content: "\f678";
}

.mdi-water:before {
	content: "\f679";
}

.mdi-water-off:before {
	content: "\f67a";
}

.mdi-water-percent:before {
	content: "\f67b";
}

.mdi-water-pump:before {
	content: "\f67c";
}

.mdi-weather-cloudy:before {
	content: "\f67d";
}

.mdi-weather-fog:before {
	content: "\f67e";
}

.mdi-weather-hail:before {
	content: "\f67f";
}

.mdi-weather-lightning:before {
	content: "\f680";
}

.mdi-weather-night:before {
	content: "\f681";
}

.mdi-weather-partlycloudy:before {
	content: "\f682";
}

.mdi-weather-pouring:before {
	content: "\f683";
}

.mdi-weather-rainy:before {
	content: "\f684";
}

.mdi-weather-snowy:before {
	content: "\f685";
}

.mdi-weather-sunny:before {
	content: "\f686";
}

.mdi-weather-sunset:before {
	content: "\f687";
}

.mdi-weather-sunset-down:before {
	content: "\f688";
}

.mdi-weather-sunset-up:before {
	content: "\f689";
}

.mdi-weather-windy:before {
	content: "\f68a";
}

.mdi-weather-windy-variant:before {
	content: "\f68b";
}

.mdi-web:before {
	content: "\f68c";
}

.mdi-webcam:before {
	content: "\f68d";
}

.mdi-weight:before {
	content: "\f68e";
}

.mdi-weight-kilogram:before {
	content: "\f68f";
}

.mdi-whatsapp:before {
	content: "\f690";
}

.mdi-wheelchair-accessibility:before {
	content: "\f691";
}

.mdi-white-balance-auto:before {
	content: "\f692";
}

.mdi-white-balance-incandescent:before {
	content: "\f693";
}

.mdi-white-balance-irradescent:before {
	content: "\f694";
}

.mdi-white-balance-sunny:before {
	content: "\f695";
}

.mdi-wifi:before {
	content: "\f696";
}

.mdi-wifi-off:before {
	content: "\f697";
}

.mdi-wii:before {
	content: "\f698";
}

.mdi-wikipedia:before {
	content: "\f699";
}

.mdi-window-close:before {
	content: "\f69a";
}

.mdi-window-closed:before {
	content: "\f69b";
}

.mdi-window-maximize:before {
	content: "\f69c";
}

.mdi-window-minimize:before {
	content: "\f69d";
}

.mdi-window-open:before {
	content: "\f69e";
}

.mdi-window-restore:before {
	content: "\f69f";
}

.mdi-windows:before {
	content: "\f6a0";
}

.mdi-wordpress:before {
	content: "\f6a1";
}

.mdi-worker:before {
	content: "\f6a2";
}

.mdi-wrap:before {
	content: "\f6a3";
}

.mdi-wrench:before {
	content: "\f6a4";
}

.mdi-wunderlist:before {
	content: "\f6a5";
}

.mdi-xbox:before {
	content: "\f6a6";
}

.mdi-xbox-controller:before {
	content: "\f6a7";
}

.mdi-xbox-controller-off:before {
	content: "\f6a8";
}

.mdi-xda:before {
	content: "\f6a9";
}

.mdi-xing:before {
	content: "\f6aa";
}

.mdi-xing-box:before {
	content: "\f6ab";
}

.mdi-xing-circle:before {
	content: "\f6ac";
}

.mdi-xml:before {
	content: "\f6ad";
}

.mdi-yeast:before {
	content: "\f6ae";
}

.mdi-yelp:before {
	content: "\f6af";
}

.mdi-youtube-play:before {
	content: "\f6b0";
}

.mdi-zip-box:before {
	content: "\f6b1";
}

.mdi-18px {
	font-size: 18px;
}

.mdi-24px {
	font-size: 24px;
}

.mdi-36px {
	font-size: 36px;
}

.mdi-48px {
	font-size: 48px;
}

.mdi-dark {
	color: rgba(0, 0, 0, 0.54);
}

.mdi-dark.mdi-inactive {
	color: rgba(0, 0, 0, 0.26);
}

.mdi-light {
	color: white;
}

.mdi-light.mdi-inactive {
	color: rgba(255, 255, 255, 0.3);
}

/* @font-face {
	font-family: 'Linearicons';
	font-weight: normal;
	font-style: normal;
	src: url(../Linearicons.eot); */
	/* For IE6-8 */
	/* src: local("Linearicons"), local("Linearicons"), url(../Linearicons.woff) format("woff"), url(../Linearicons.ttf) format("truetype");
} */

.linearicons,
[class^="linearicons-"], [class*=" linearicons-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'Linearicons' !important;
	/* speak: none; */
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.linearicons-home:before {
	content: "\e900";
}

.linearicons-home2:before {
	content: "\e901";
}

.linearicons-home3:before {
	content: "\e902";
}

.linearicons-home4:before {
	content: "\e903";
}

.linearicons-home5:before {
	content: "\e904";
}

.linearicons-home6:before {
	content: "\e905";
}

.linearicons-bathtub:before {
	content: "\e906";
}

.linearicons-toothbrush:before {
	content: "\e907";
}

.linearicons-bed:before {
	content: "\e908";
}

.linearicons-couch:before {
	content: "\e909";
}

.linearicons-chair:before {
	content: "\e90a";
}

.linearicons-city:before {
	content: "\e90b";
}

.linearicons-apartment:before {
	content: "\e90c";
}

.linearicons-pencil:before {
	content: "\e90d";
}

.linearicons-pencil2:before {
	content: "\e90e";
}

.linearicons-pen:before {
	content: "\e90f";
}

.linearicons-pencil3:before {
	content: "\e910";
}

.linearicons-eraser:before {
	content: "\e911";
}

.linearicons-pencil4:before {
	content: "\e912";
}

.linearicons-pencil5:before {
	content: "\e913";
}

.linearicons-feather:before {
	content: "\e914";
}

.linearicons-feather2:before {
	content: "\e915";
}

.linearicons-feather3:before {
	content: "\e916";
}

.linearicons-pen2:before {
	content: "\e917";
}

.linearicons-pen-add:before {
	content: "\e918";
}

.linearicons-pen-remove:before {
	content: "\e919";
}

.linearicons-vector:before {
	content: "\e91a";
}

.linearicons-pen3:before {
	content: "\e91b";
}

.linearicons-blog:before {
	content: "\e91c";
}

.linearicons-brush:before {
	content: "\e91d";
}

.linearicons-brush2:before {
	content: "\e91e";
}

.linearicons-spray:before {
	content: "\e91f";
}

.linearicons-paint-roller:before {
	content: "\e920";
}

.linearicons-stamp:before {
	content: "\e921";
}

.linearicons-tape:before {
	content: "\e922";
}

.linearicons-desk-tape:before {
	content: "\e923";
}

.linearicons-texture:before {
	content: "\e924";
}

.linearicons-eye-dropper:before {
	content: "\e925";
}

.linearicons-palette:before {
	content: "\e926";
}

.linearicons-color-sampler:before {
	content: "\e927";
}

.linearicons-bucket:before {
	content: "\e928";
}

.linearicons-gradient:before {
	content: "\e929";
}

.linearicons-gradient2:before {
	content: "\e92a";
}

.linearicons-magic-wand:before {
	content: "\e92b";
}

.linearicons-magnet:before {
	content: "\e92c";
}

.linearicons-pencil-ruler:before {
	content: "\e92d";
}

.linearicons-pencil-ruler2:before {
	content: "\e92e";
}

.linearicons-compass:before {
	content: "\e92f";
}

.linearicons-aim:before {
	content: "\e930";
}

.linearicons-gun:before {
	content: "\e931";
}

.linearicons-bottle:before {
	content: "\e932";
}

.linearicons-drop:before {
	content: "\e933";
}

.linearicons-drop-crossed:before {
	content: "\e934";
}

.linearicons-drop2:before {
	content: "\e935";
}

.linearicons-snow:before {
	content: "\e936";
}

.linearicons-snow2:before {
	content: "\e937";
}

.linearicons-fire:before {
	content: "\e938";
}

.linearicons-lighter:before {
	content: "\e939";
}

.linearicons-knife:before {
	content: "\e93a";
}

.linearicons-dagger:before {
	content: "\e93b";
}

.linearicons-tissue:before {
	content: "\e93c";
}

.linearicons-toilet-paper:before {
	content: "\e93d";
}

.linearicons-poop:before {
	content: "\e93e";
}

.linearicons-umbrella:before {
	content: "\e93f";
}

.linearicons-umbrella2:before {
	content: "\e940";
}

.linearicons-rain:before {
	content: "\e941";
}

.linearicons-tornado:before {
	content: "\e942";
}

.linearicons-wind:before {
	content: "\e943";
}

.linearicons-fan:before {
	content: "\e944";
}

.linearicons-contrast:before {
	content: "\e945";
}

.linearicons-sun-small:before {
	content: "\e946";
}

.linearicons-sun:before {
	content: "\e947";
}

.linearicons-sun2:before {
	content: "\e948";
}

.linearicons-moon:before {
	content: "\e949";
}

.linearicons-cloud:before {
	content: "\e94a";
}

.linearicons-cloud-upload:before {
	content: "\e94b";
}

.linearicons-cloud-download:before {
	content: "\e94c";
}

.linearicons-cloud-rain:before {
	content: "\e94d";
}

.linearicons-cloud-hailstones:before {
	content: "\e94e";
}

.linearicons-cloud-snow:before {
	content: "\e94f";
}

.linearicons-cloud-windy:before {
	content: "\e950";
}

.linearicons-sun-wind:before {
	content: "\e951";
}

.linearicons-cloud-fog:before {
	content: "\e952";
}

.linearicons-cloud-sun:before {
	content: "\e953";
}

.linearicons-cloud-lightning:before {
	content: "\e954";
}

.linearicons-cloud-sync:before {
	content: "\e955";
}

.linearicons-cloud-lock:before {
	content: "\e956";
}

.linearicons-cloud-gear:before {
	content: "\e957";
}

.linearicons-cloud-alert:before {
	content: "\e958";
}

.linearicons-cloud-check:before {
	content: "\e959";
}

.linearicons-cloud-cross:before {
	content: "\e95a";
}

.linearicons-cloud-crossed:before {
	content: "\e95b";
}

.linearicons-cloud-database:before {
	content: "\e95c";
}

.linearicons-database:before {
	content: "\e95d";
}

.linearicons-database-add:before {
	content: "\e95e";
}

.linearicons-database-remove:before {
	content: "\e95f";
}

.linearicons-database-lock:before {
	content: "\e960";
}

.linearicons-database-refresh:before {
	content: "\e961";
}

.linearicons-database-check:before {
	content: "\e962";
}

.linearicons-database-history:before {
	content: "\e963";
}

.linearicons-database-upload:before {
	content: "\e964";
}

.linearicons-database-download:before {
	content: "\e965";
}

.linearicons-server:before {
	content: "\e966";
}

.linearicons-shield:before {
	content: "\e967";
}

.linearicons-shield-check:before {
	content: "\e968";
}

.linearicons-shield-alert:before {
	content: "\e969";
}

.linearicons-shield-cross:before {
	content: "\e96a";
}

.linearicons-lock:before {
	content: "\e96b";
}

.linearicons-rotation-lock:before {
	content: "\e96c";
}

.linearicons-unlock:before {
	content: "\e96d";
}

.linearicons-key:before {
	content: "\e96e";
}

.linearicons-key-hole:before {
	content: "\e96f";
}

.linearicons-toggle-off:before {
	content: "\e970";
}

.linearicons-toggle-on:before {
	content: "\e971";
}

.linearicons-cog:before {
	content: "\e972";
}

.linearicons-cog2:before {
	content: "\e973";
}

.linearicons-wrench:before {
	content: "\e974";
}

.linearicons-screwdriver:before {
	content: "\e975";
}

.linearicons-hammer-wrench:before {
	content: "\e976";
}

.linearicons-hammer:before {
	content: "\e977";
}

.linearicons-saw:before {
	content: "\e978";
}

.linearicons-axe:before {
	content: "\e979";
}

.linearicons-axe2:before {
	content: "\e97a";
}

.linearicons-shovel:before {
	content: "\e97b";
}

.linearicons-pickaxe:before {
	content: "\e97c";
}

.linearicons-factory:before {
	content: "\e97d";
}

.linearicons-factory2:before {
	content: "\e97e";
}

.linearicons-recycle:before {
	content: "\e97f";
}

.linearicons-trash:before {
	content: "\e980";
}

.linearicons-trash2:before {
	content: "\e981";
}

.linearicons-trash3:before {
	content: "\e982";
}

.linearicons-broom:before {
	content: "\e983";
}

.linearicons-game:before {
	content: "\e984";
}

.linearicons-gamepad:before {
	content: "\e985";
}

.linearicons-joystick:before {
	content: "\e986";
}

.linearicons-dice:before {
	content: "\e987";
}

.linearicons-spades:before {
	content: "\e988";
}

.linearicons-diamonds:before {
	content: "\e989";
}

.linearicons-clubs:before {
	content: "\e98a";
}

.linearicons-hearts:before {
	content: "\e98b";
}

.linearicons-heart:before {
	content: "\e98c";
}

.linearicons-star:before {
	content: "\e98d";
}

.linearicons-star-half:before {
	content: "\e98e";
}

.linearicons-star-empty:before {
	content: "\e98f";
}

.linearicons-flag:before {
	content: "\e990";
}

.linearicons-flag2:before {
	content: "\e991";
}

.linearicons-flag3:before {
	content: "\e992";
}

.linearicons-mailbox-full:before {
	content: "\e993";
}

.linearicons-mailbox-empty:before {
	content: "\e994";
}

.linearicons-at-sign:before {
	content: "\e995";
}

.linearicons-envelope:before {
	content: "\e996";
}

.linearicons-envelope-open:before {
	content: "\e997";
}

.linearicons-paperclip:before {
	content: "\e998";
}

.linearicons-paper-plane:before {
	content: "\e999";
}

.linearicons-reply:before {
	content: "\e99a";
}

.linearicons-reply-all:before {
	content: "\e99b";
}

.linearicons-inbox:before {
	content: "\e99c";
}

.linearicons-inbox2:before {
	content: "\e99d";
}

.linearicons-outbox:before {
	content: "\e99e";
}

.linearicons-box:before {
	content: "\e99f";
}

.linearicons-archive:before {
	content: "\e9a0";
}

.linearicons-archive2:before {
	content: "\e9a1";
}

.linearicons-drawers:before {
	content: "\e9a2";
}

.linearicons-drawers2:before {
	content: "\e9a3";
}

.linearicons-drawers3:before {
	content: "\e9a4";
}

.linearicons-eye:before {
	content: "\e9a5";
}

.linearicons-eye-crossed:before {
	content: "\e9a6";
}

.linearicons-eye-plus:before {
	content: "\e9a7";
}

.linearicons-eye-minus:before {
	content: "\e9a8";
}

.linearicons-binoculars:before {
	content: "\e9a9";
}

.linearicons-binoculars2:before {
	content: "\e9aa";
}

.linearicons-hdd:before {
	content: "\e9ab";
}

.linearicons-hdd-down:before {
	content: "\e9ac";
}

.linearicons-hdd-up:before {
	content: "\e9ad";
}

.linearicons-floppy-disk:before {
	content: "\e9ae";
}

.linearicons-disc:before {
	content: "\e9af";
}

.linearicons-tape2:before {
	content: "\e9b0";
}

.linearicons-printer:before {
	content: "\e9b1";
}

.linearicons-shredder:before {
	content: "\e9b2";
}

.linearicons-file-empty:before {
	content: "\e9b3";
}

.linearicons-file-add:before {
	content: "\e9b4";
}

.linearicons-file-check:before {
	content: "\e9b5";
}

.linearicons-file-lock:before {
	content: "\e9b6";
}

.linearicons-files:before {
	content: "\e9b7";
}

.linearicons-copy:before {
	content: "\e9b8";
}

.linearicons-compare:before {
	content: "\e9b9";
}

.linearicons-folder:before {
	content: "\e9ba";
}

.linearicons-folder-search:before {
	content: "\e9bb";
}

.linearicons-folder-plus:before {
	content: "\e9bc";
}

.linearicons-folder-minus:before {
	content: "\e9bd";
}

.linearicons-folder-download:before {
	content: "\e9be";
}

.linearicons-folder-upload:before {
	content: "\e9bf";
}

.linearicons-folder-star:before {
	content: "\e9c0";
}

.linearicons-folder-heart:before {
	content: "\e9c1";
}

.linearicons-folder-user:before {
	content: "\e9c2";
}

.linearicons-folder-shared:before {
	content: "\e9c3";
}

.linearicons-folder-music:before {
	content: "\e9c4";
}

.linearicons-folder-picture:before {
	content: "\e9c5";
}

.linearicons-folder-film:before {
	content: "\e9c6";
}

.linearicons-scissors:before {
	content: "\e9c7";
}

.linearicons-paste:before {
	content: "\e9c8";
}

.linearicons-clipboard-empty:before {
	content: "\e9c9";
}

.linearicons-clipboard-pencil:before {
	content: "\e9ca";
}

.linearicons-clipboard-text:before {
	content: "\e9cb";
}

.linearicons-clipboard-check:before {
	content: "\e9cc";
}

.linearicons-clipboard-down:before {
	content: "\e9cd";
}

.linearicons-clipboard-left:before {
	content: "\e9ce";
}

.linearicons-clipboard-alert:before {
	content: "\e9cf";
}

.linearicons-clipboard-user:before {
	content: "\e9d0";
}

.linearicons-register:before {
	content: "\e9d1";
}

.linearicons-enter:before {
	content: "\e9d2";
}

.linearicons-exit:before {
	content: "\e9d3";
}

.linearicons-papers:before {
	content: "\e9d4";
}

.linearicons-news:before {
	content: "\e9d5";
}

.linearicons-reading:before {
	content: "\e9d6";
}

.linearicons-typewriter:before {
	content: "\e9d7";
}

.linearicons-document:before {
	content: "\e9d8";
}

.linearicons-document2:before {
	content: "\e9d9";
}

.linearicons-graduation-hat:before {
	content: "\e9da";
}

.linearicons-license:before {
	content: "\e9db";
}

.linearicons-license2:before {
	content: "\e9dc";
}

.linearicons-medal-empty:before {
	content: "\e9dd";
}

.linearicons-medal-first:before {
	content: "\e9de";
}

.linearicons-medal-second:before {
	content: "\e9df";
}

.linearicons-medal-third:before {
	content: "\e9e0";
}

.linearicons-podium:before {
	content: "\e9e1";
}

.linearicons-trophy:before {
	content: "\e9e2";
}

.linearicons-trophy2:before {
	content: "\e9e3";
}

.linearicons-music-note:before {
	content: "\e9e4";
}

.linearicons-music-note2:before {
	content: "\e9e5";
}

.linearicons-music-note3:before {
	content: "\e9e6";
}

.linearicons-playlist:before {
	content: "\e9e7";
}

.linearicons-playlist-add:before {
	content: "\e9e8";
}

.linearicons-guitar:before {
	content: "\e9e9";
}

.linearicons-trumpet:before {
	content: "\e9ea";
}

.linearicons-album:before {
	content: "\e9eb";
}

.linearicons-shuffle:before {
	content: "\e9ec";
}

.linearicons-repeat-one:before {
	content: "\e9ed";
}

.linearicons-repeat:before {
	content: "\e9ee";
}

.linearicons-headphones:before {
	content: "\e9ef";
}

.linearicons-headset:before {
	content: "\e9f0";
}

.linearicons-loudspeaker:before {
	content: "\e9f1";
}

.linearicons-equalizer:before {
	content: "\e9f2";
}

.linearicons-theater:before {
	content: "\e9f3";
}

.linearicons-3d-glasses:before {
	content: "\e9f4";
}

.linearicons-ticket:before {
	content: "\e9f5";
}

.linearicons-presentation:before {
	content: "\e9f6";
}

.linearicons-play:before {
	content: "\e9f7";
}

.linearicons-film-play:before {
	content: "\e9f8";
}

.linearicons-clapboard-play:before {
	content: "\e9f9";
}

.linearicons-media:before {
	content: "\e9fa";
}

.linearicons-film:before {
	content: "\e9fb";
}

.linearicons-film2:before {
	content: "\e9fc";
}

.linearicons-surveillance:before {
	content: "\e9fd";
}

.linearicons-surveillance2:before {
	content: "\e9fe";
}

.linearicons-camera:before {
	content: "\e9ff";
}

.linearicons-camera-crossed:before {
	content: "\ea00";
}

.linearicons-camera-play:before {
	content: "\ea01";
}

.linearicons-time-lapse:before {
	content: "\ea02";
}

.linearicons-record:before {
	content: "\ea03";
}

.linearicons-camera2:before {
	content: "\ea04";
}

.linearicons-camera-flip:before {
	content: "\ea05";
}

.linearicons-panorama:before {
	content: "\ea06";
}

.linearicons-time-lapse2:before {
	content: "\ea07";
}

.linearicons-shutter:before {
	content: "\ea08";
}

.linearicons-shutter2:before {
	content: "\ea09";
}

.linearicons-face-detection:before {
	content: "\ea0a";
}

.linearicons-flare:before {
	content: "\ea0b";
}

.linearicons-convex:before {
	content: "\ea0c";
}

.linearicons-concave:before {
	content: "\ea0d";
}

.linearicons-picture:before {
	content: "\ea0e";
}

.linearicons-picture2:before {
	content: "\ea0f";
}

.linearicons-picture3:before {
	content: "\ea10";
}

.linearicons-pictures:before {
	content: "\ea11";
}

.linearicons-book:before {
	content: "\ea12";
}

.linearicons-audio-book:before {
	content: "\ea13";
}

.linearicons-book2:before {
	content: "\ea14";
}

.linearicons-bookmark:before {
	content: "\ea15";
}

.linearicons-bookmark2:before {
	content: "\ea16";
}

.linearicons-label:before {
	content: "\ea17";
}

.linearicons-library:before {
	content: "\ea18";
}

.linearicons-library2:before {
	content: "\ea19";
}

.linearicons-contacts:before {
	content: "\ea1a";
}

.linearicons-profile:before {
	content: "\ea1b";
}

.linearicons-portrait:before {
	content: "\ea1c";
}

.linearicons-portrait2:before {
	content: "\ea1d";
}

.linearicons-user:before {
	content: "\ea1e";
}

.linearicons-user-plus:before {
	content: "\ea1f";
}

.linearicons-user-minus:before {
	content: "\ea20";
}

.linearicons-user-lock:before {
	content: "\ea21";
}

.linearicons-users:before {
	content: "\ea22";
}

.linearicons-users2:before {
	content: "\ea23";
}

.linearicons-users-plus:before {
	content: "\ea24";
}

.linearicons-users-minus:before {
	content: "\ea25";
}

.linearicons-group-work:before {
	content: "\ea26";
}

.linearicons-woman:before {
	content: "\ea27";
}

.linearicons-man:before {
	content: "\ea28";
}

.linearicons-baby:before {
	content: "\ea29";
}

.linearicons-baby2:before {
	content: "\ea2a";
}

.linearicons-baby3:before {
	content: "\ea2b";
}

.linearicons-baby-bottle:before {
	content: "\ea2c";
}

.linearicons-walk:before {
	content: "\ea2d";
}

.linearicons-hand-waving:before {
	content: "\ea2e";
}

.linearicons-jump:before {
	content: "\ea2f";
}

.linearicons-run:before {
	content: "\ea30";
}

.linearicons-woman2:before {
	content: "\ea31";
}

.linearicons-man2:before {
	content: "\ea32";
}

.linearicons-man-woman:before {
	content: "\ea33";
}

.linearicons-height:before {
	content: "\ea34";
}

.linearicons-weight:before {
	content: "\ea35";
}

.linearicons-scale:before {
	content: "\ea36";
}

.linearicons-button:before {
	content: "\ea37";
}

.linearicons-bow-tie:before {
	content: "\ea38";
}

.linearicons-tie:before {
	content: "\ea39";
}

.linearicons-socks:before {
	content: "\ea3a";
}

.linearicons-shoe:before {
	content: "\ea3b";
}

.linearicons-shoes:before {
	content: "\ea3c";
}

.linearicons-hat:before {
	content: "\ea3d";
}

.linearicons-pants:before {
	content: "\ea3e";
}

.linearicons-shorts:before {
	content: "\ea3f";
}

.linearicons-flip-flops:before {
	content: "\ea40";
}

.linearicons-shirt:before {
	content: "\ea41";
}

.linearicons-hanger:before {
	content: "\ea42";
}

.linearicons-laundry:before {
	content: "\ea43";
}

.linearicons-store:before {
	content: "\ea44";
}

.linearicons-haircut:before {
	content: "\ea45";
}

.linearicons-store-24:before {
	content: "\ea46";
}

.linearicons-barcode:before {
	content: "\ea47";
}

.linearicons-barcode2:before {
	content: "\ea48";
}

.linearicons-barcode3:before {
	content: "\ea49";
}

.linearicons-cashier:before {
	content: "\ea4a";
}

.linearicons-bag:before {
	content: "\ea4b";
}

.linearicons-bag2:before {
	content: "\ea4c";
}

.linearicons-cart:before {
	content: "\ea4d";
}

.linearicons-cart-empty:before {
	content: "\ea4e";
}

.linearicons-cart-full:before {
	content: "\ea4f";
}

.linearicons-cart-plus:before {
	content: "\ea50";
}

.linearicons-cart-plus2:before {
	content: "\ea51";
}

.linearicons-cart-add:before {
	content: "\ea52";
}

.linearicons-cart-remove:before {
	content: "\ea53";
}

.linearicons-cart-exchange:before {
	content: "\ea54";
}

.linearicons-tag:before {
	content: "\ea55";
}

.linearicons-tags:before {
	content: "\ea56";
}

.linearicons-receipt:before {
	content: "\ea57";
}

.linearicons-wallet:before {
	content: "\ea58";
}

.linearicons-credit-card:before {
	content: "\ea59";
}

.linearicons-cash-dollar:before {
	content: "\ea5a";
}

.linearicons-cash-euro:before {
	content: "\ea5b";
}

.linearicons-cash-pound:before {
	content: "\ea5c";
}

.linearicons-cash-yen:before {
	content: "\ea5d";
}

.linearicons-bag-dollar:before {
	content: "\ea5e";
}

.linearicons-bag-euro:before {
	content: "\ea5f";
}

.linearicons-bag-pound:before {
	content: "\ea60";
}

.linearicons-bag-yen:before {
	content: "\ea61";
}

.linearicons-coin-dollar:before {
	content: "\ea62";
}

.linearicons-coin-euro:before {
	content: "\ea63";
}

.linearicons-coin-pound:before {
	content: "\ea64";
}

.linearicons-coin-yen:before {
	content: "\ea65";
}

.linearicons-calculator:before {
	content: "\ea66";
}

.linearicons-calculator2:before {
	content: "\ea67";
}

.linearicons-abacus:before {
	content: "\ea68";
}

.linearicons-vault:before {
	content: "\ea69";
}

.linearicons-telephone:before {
	content: "\ea6a";
}

.linearicons-phone-lock:before {
	content: "\ea6b";
}

.linearicons-phone-wave:before {
	content: "\ea6c";
}

.linearicons-phone-pause:before {
	content: "\ea6d";
}

.linearicons-phone-outgoing:before {
	content: "\ea6e";
}

.linearicons-phone-incoming:before {
	content: "\ea6f";
}

.linearicons-phone-in-out:before {
	content: "\ea70";
}

.linearicons-phone-error:before {
	content: "\ea71";
}

.linearicons-phone-sip:before {
	content: "\ea72";
}

.linearicons-phone-plus:before {
	content: "\ea73";
}

.linearicons-phone-minus:before {
	content: "\ea74";
}

.linearicons-voicemail:before {
	content: "\ea75";
}

.linearicons-dial:before {
	content: "\ea76";
}

.linearicons-telephone2:before {
	content: "\ea77";
}

.linearicons-pushpin:before {
	content: "\ea78";
}

.linearicons-pushpin2:before {
	content: "\ea79";
}

.linearicons-map-marker:before {
	content: "\ea7a";
}

.linearicons-map-marker-user:before {
	content: "\ea7b";
}

.linearicons-map-marker-down:before {
	content: "\ea7c";
}

.linearicons-map-marker-check:before {
	content: "\ea7d";
}

.linearicons-map-marker-crossed:before {
	content: "\ea7e";
}

.linearicons-radar:before {
	content: "\ea7f";
}

.linearicons-compass2:before {
	content: "\ea80";
}

.linearicons-map:before {
	content: "\ea81";
}

.linearicons-map2:before {
	content: "\ea82";
}

.linearicons-location:before {
	content: "\ea83";
}

.linearicons-road-sign:before {
	content: "\ea84";
}

.linearicons-calendar-empty:before {
	content: "\ea85";
}

.linearicons-calendar-check:before {
	content: "\ea86";
}

.linearicons-calendar-cross:before {
	content: "\ea87";
}

.linearicons-calendar-31:before {
	content: "\ea88";
}

.linearicons-calendar-full:before {
	content: "\ea89";
}

.linearicons-calendar-insert:before {
	content: "\ea8a";
}

.linearicons-calendar-text:before {
	content: "\ea8b";
}

.linearicons-calendar-user:before {
	content: "\ea8c";
}

.linearicons-mouse:before {
	content: "\ea8d";
}

.linearicons-mouse-left:before {
	content: "\ea8e";
}

.linearicons-mouse-right:before {
	content: "\ea8f";
}

.linearicons-mouse-both:before {
	content: "\ea90";
}

.linearicons-keyboard:before {
	content: "\ea91";
}

.linearicons-keyboard-up:before {
	content: "\ea92";
}

.linearicons-keyboard-down:before {
	content: "\ea93";
}

.linearicons-delete:before {
	content: "\ea94";
}

.linearicons-spell-check:before {
	content: "\ea95";
}

.linearicons-escape:before {
	content: "\ea96";
}

.linearicons-enter2:before {
	content: "\ea97";
}

.linearicons-screen:before {
	content: "\ea98";
}

.linearicons-aspect-ratio:before {
	content: "\ea99";
}

.linearicons-signal:before {
	content: "\ea9a";
}

.linearicons-signal-lock:before {
	content: "\ea9b";
}

.linearicons-signal-80:before {
	content: "\ea9c";
}

.linearicons-signal-60:before {
	content: "\ea9d";
}

.linearicons-signal-40:before {
	content: "\ea9e";
}

.linearicons-signal-20:before {
	content: "\ea9f";
}

.linearicons-signal-0:before {
	content: "\eaa0";
}

.linearicons-signal-blocked:before {
	content: "\eaa1";
}

.linearicons-sim:before {
	content: "\eaa2";
}

.linearicons-flash-memory:before {
	content: "\eaa3";
}

.linearicons-usb-drive:before {
	content: "\eaa4";
}

.linearicons-phone:before {
	content: "\eaa5";
}

.linearicons-smartphone:before {
	content: "\eaa6";
}

.linearicons-smartphone-notification:before {
	content: "\eaa7";
}

.linearicons-smartphone-vibration:before {
	content: "\eaa8";
}

.linearicons-smartphone-embed:before {
	content: "\eaa9";
}

.linearicons-smartphone-waves:before {
	content: "\eaaa";
}

.linearicons-tablet:before {
	content: "\eaab";
}

.linearicons-tablet2:before {
	content: "\eaac";
}

.linearicons-laptop:before {
	content: "\eaad";
}

.linearicons-laptop-phone:before {
	content: "\eaae";
}

.linearicons-desktop:before {
	content: "\eaaf";
}

.linearicons-launch:before {
	content: "\eab0";
}

.linearicons-new-tab:before {
	content: "\eab1";
}

.linearicons-window:before {
	content: "\eab2";
}

.linearicons-cable:before {
	content: "\eab3";
}

.linearicons-cable2:before {
	content: "\eab4";
}

.linearicons-tv:before {
	content: "\eab5";
}

.linearicons-radio:before {
	content: "\eab6";
}

.linearicons-remote-control:before {
	content: "\eab7";
}

.linearicons-power-switch:before {
	content: "\eab8";
}

.linearicons-power:before {
	content: "\eab9";
}

.linearicons-power-crossed:before {
	content: "\eaba";
}

.linearicons-flash-auto:before {
	content: "\eabb";
}

.linearicons-lamp:before {
	content: "\eabc";
}

.linearicons-flashlight:before {
	content: "\eabd";
}

.linearicons-lampshade:before {
	content: "\eabe";
}

.linearicons-cord:before {
	content: "\eabf";
}

.linearicons-outlet:before {
	content: "\eac0";
}

.linearicons-battery-power:before {
	content: "\eac1";
}

.linearicons-battery-empty:before {
	content: "\eac2";
}

.linearicons-battery-alert:before {
	content: "\eac3";
}

.linearicons-battery-error:before {
	content: "\eac4";
}

.linearicons-battery-low1:before {
	content: "\eac5";
}

.linearicons-battery-low2:before {
	content: "\eac6";
}

.linearicons-battery-low3:before {
	content: "\eac7";
}

.linearicons-battery-mid1:before {
	content: "\eac8";
}

.linearicons-battery-mid2:before {
	content: "\eac9";
}

.linearicons-battery-mid3:before {
	content: "\eaca";
}

.linearicons-battery-full:before {
	content: "\eacb";
}

.linearicons-battery-charging:before {
	content: "\eacc";
}

.linearicons-battery-charging2:before {
	content: "\eacd";
}

.linearicons-battery-charging3:before {
	content: "\eace";
}

.linearicons-battery-charging4:before {
	content: "\eacf";
}

.linearicons-battery-charging5:before {
	content: "\ead0";
}

.linearicons-battery-charging6:before {
	content: "\ead1";
}

.linearicons-battery-charging7:before {
	content: "\ead2";
}

.linearicons-chip:before {
	content: "\ead3";
}

.linearicons-chip-x64:before {
	content: "\ead4";
}

.linearicons-chip-x86:before {
	content: "\ead5";
}

.linearicons-bubble:before {
	content: "\ead6";
}

.linearicons-bubbles:before {
	content: "\ead7";
}

.linearicons-bubble-dots:before {
	content: "\ead8";
}

.linearicons-bubble-alert:before {
	content: "\ead9";
}

.linearicons-bubble-question:before {
	content: "\eada";
}

.linearicons-bubble-text:before {
	content: "\eadb";
}

.linearicons-bubble-pencil:before {
	content: "\eadc";
}

.linearicons-bubble-picture:before {
	content: "\eadd";
}

.linearicons-bubble-video:before {
	content: "\eade";
}

.linearicons-bubble-user:before {
	content: "\eadf";
}

.linearicons-bubble-quote:before {
	content: "\eae0";
}

.linearicons-bubble-heart:before {
	content: "\eae1";
}

.linearicons-bubble-emoticon:before {
	content: "\eae2";
}

.linearicons-bubble-attachment:before {
	content: "\eae3";
}

.linearicons-phone-bubble:before {
	content: "\eae4";
}

.linearicons-quote-open:before {
	content: "\eae5";
}

.linearicons-quote-close:before {
	content: "\eae6";
}

.linearicons-dna:before {
	content: "\eae7";
}

.linearicons-heart-pulse:before {
	content: "\eae8";
}

.linearicons-pulse:before {
	content: "\eae9";
}

.linearicons-syringe:before {
	content: "\eaea";
}

.linearicons-pills:before {
	content: "\eaeb";
}

.linearicons-first-aid:before {
	content: "\eaec";
}

.linearicons-lifebuoy:before {
	content: "\eaed";
}

.linearicons-bandage:before {
	content: "\eaee";
}

.linearicons-bandages:before {
	content: "\eaef";
}

.linearicons-thermometer:before {
	content: "\eaf0";
}

.linearicons-microscope:before {
	content: "\eaf1";
}

.linearicons-brain:before {
	content: "\eaf2";
}

.linearicons-beaker:before {
	content: "\eaf3";
}

.linearicons-skull:before {
	content: "\eaf4";
}

.linearicons-bone:before {
	content: "\eaf5";
}

.linearicons-construction:before {
	content: "\eaf6";
}

.linearicons-construction-cone:before {
	content: "\eaf7";
}

.linearicons-pie-chart:before {
	content: "\eaf8";
}

.linearicons-pie-chart2:before {
	content: "\eaf9";
}

.linearicons-graph:before {
	content: "\eafa";
}

.linearicons-chart-growth:before {
	content: "\eafb";
}

.linearicons-chart-bars:before {
	content: "\eafc";
}

.linearicons-chart-settings:before {
	content: "\eafd";
}

.linearicons-cake:before {
	content: "\eafe";
}

.linearicons-gift:before {
	content: "\eaff";
}

.linearicons-balloon:before {
	content: "\eb00";
}

.linearicons-rank:before {
	content: "\eb01";
}

.linearicons-rank2:before {
	content: "\eb02";
}

.linearicons-rank3:before {
	content: "\eb03";
}

.linearicons-crown:before {
	content: "\eb04";
}

.linearicons-lotus:before {
	content: "\eb05";
}

.linearicons-diamond:before {
	content: "\eb06";
}

.linearicons-diamond2:before {
	content: "\eb07";
}

.linearicons-diamond3:before {
	content: "\eb08";
}

.linearicons-diamond4:before {
	content: "\eb09";
}

.linearicons-linearicons:before {
	content: "\eb0a";
}

.linearicons-teacup:before {
	content: "\eb0b";
}

.linearicons-teapot:before {
	content: "\eb0c";
}

.linearicons-glass:before {
	content: "\eb0d";
}

.linearicons-bottle2:before {
	content: "\eb0e";
}

.linearicons-glass-cocktail:before {
	content: "\eb0f";
}

.linearicons-glass2:before {
	content: "\eb10";
}

.linearicons-dinner:before {
	content: "\eb11";
}

.linearicons-dinner2:before {
	content: "\eb12";
}

.linearicons-chef:before {
	content: "\eb13";
}

.linearicons-scale2:before {
	content: "\eb14";
}

.linearicons-egg:before {
	content: "\eb15";
}

.linearicons-egg2:before {
	content: "\eb16";
}

.linearicons-eggs:before {
	content: "\eb17";
}

.linearicons-platter:before {
	content: "\eb18";
}

.linearicons-steak:before {
	content: "\eb19";
}

.linearicons-hamburger:before {
	content: "\eb1a";
}

.linearicons-hotdog:before {
	content: "\eb1b";
}

.linearicons-pizza:before {
	content: "\eb1c";
}

.linearicons-sausage:before {
	content: "\eb1d";
}

.linearicons-chicken:before {
	content: "\eb1e";
}

.linearicons-fish:before {
	content: "\eb1f";
}

.linearicons-carrot:before {
	content: "\eb20";
}

.linearicons-cheese:before {
	content: "\eb21";
}

.linearicons-bread:before {
	content: "\eb22";
}

.linearicons-ice-cream:before {
	content: "\eb23";
}

.linearicons-ice-cream2:before {
	content: "\eb24";
}

.linearicons-candy:before {
	content: "\eb25";
}

.linearicons-lollipop:before {
	content: "\eb26";
}

.linearicons-coffee-bean:before {
	content: "\eb27";
}

.linearicons-coffee-cup:before {
	content: "\eb28";
}

.linearicons-cherry:before {
	content: "\eb29";
}

.linearicons-grapes:before {
	content: "\eb2a";
}

.linearicons-citrus:before {
	content: "\eb2b";
}

.linearicons-apple:before {
	content: "\eb2c";
}

.linearicons-leaf:before {
	content: "\eb2d";
}

.linearicons-landscape:before {
	content: "\eb2e";
}

.linearicons-pine-tree:before {
	content: "\eb2f";
}

.linearicons-tree:before {
	content: "\eb30";
}

.linearicons-cactus:before {
	content: "\eb31";
}

.linearicons-paw:before {
	content: "\eb32";
}

.linearicons-footprint:before {
	content: "\eb33";
}

.linearicons-speed-slow:before {
	content: "\eb34";
}

.linearicons-speed-medium:before {
	content: "\eb35";
}

.linearicons-speed-fast:before {
	content: "\eb36";
}

.linearicons-rocket:before {
	content: "\eb37";
}

.linearicons-hammer2:before {
	content: "\eb38";
}

.linearicons-balance:before {
	content: "\eb39";
}

.linearicons-briefcase:before {
	content: "\eb3a";
}

.linearicons-luggage-weight:before {
	content: "\eb3b";
}

.linearicons-dolly:before {
	content: "\eb3c";
}

.linearicons-plane:before {
	content: "\eb3d";
}

.linearicons-plane-crossed:before {
	content: "\eb3e";
}

.linearicons-helicopter:before {
	content: "\eb3f";
}

.linearicons-traffic-lights:before {
	content: "\eb40";
}

.linearicons-siren:before {
	content: "\eb41";
}

.linearicons-road:before {
	content: "\eb42";
}

.linearicons-engine:before {
	content: "\eb43";
}

.linearicons-oil-pressure:before {
	content: "\eb44";
}

.linearicons-coolant-temperature:before {
	content: "\eb45";
}

.linearicons-car-battery:before {
	content: "\eb46";
}

.linearicons-gas:before {
	content: "\eb47";
}

.linearicons-gallon:before {
	content: "\eb48";
}

.linearicons-transmission:before {
	content: "\eb49";
}

.linearicons-car:before {
	content: "\eb4a";
}

.linearicons-car-wash:before {
	content: "\eb4b";
}

.linearicons-car-wash2:before {
	content: "\eb4c";
}

.linearicons-bus:before {
	content: "\eb4d";
}

.linearicons-bus2:before {
	content: "\eb4e";
}

.linearicons-car2:before {
	content: "\eb4f";
}

.linearicons-parking:before {
	content: "\eb50";
}

.linearicons-car-lock:before {
	content: "\eb51";
}

.linearicons-taxi:before {
	content: "\eb52";
}

.linearicons-car-siren:before {
	content: "\eb53";
}

.linearicons-car-wash3:before {
	content: "\eb54";
}

.linearicons-car-wash4:before {
	content: "\eb55";
}

.linearicons-ambulance:before {
	content: "\eb56";
}

.linearicons-truck:before {
	content: "\eb57";
}

.linearicons-trailer:before {
	content: "\eb58";
}

.linearicons-scale-truck:before {
	content: "\eb59";
}

.linearicons-train:before {
	content: "\eb5a";
}

.linearicons-ship:before {
	content: "\eb5b";
}

.linearicons-ship2:before {
	content: "\eb5c";
}

.linearicons-anchor:before {
	content: "\eb5d";
}

.linearicons-boat:before {
	content: "\eb5e";
}

.linearicons-bicycle:before {
	content: "\eb5f";
}

.linearicons-bicycle2:before {
	content: "\eb60";
}

.linearicons-dumbbell:before {
	content: "\eb61";
}

.linearicons-bench-press:before {
	content: "\eb62";
}

.linearicons-swim:before {
	content: "\eb63";
}

.linearicons-football:before {
	content: "\eb64";
}

.linearicons-baseball-bat:before {
	content: "\eb65";
}

.linearicons-baseball:before {
	content: "\eb66";
}

.linearicons-tennis:before {
	content: "\eb67";
}

.linearicons-tennis2:before {
	content: "\eb68";
}

.linearicons-ping-pong:before {
	content: "\eb69";
}

.linearicons-hockey:before {
	content: "\eb6a";
}

.linearicons-8ball:before {
	content: "\eb6b";
}

.linearicons-bowling:before {
	content: "\eb6c";
}

.linearicons-bowling-pins:before {
	content: "\eb6d";
}

.linearicons-golf:before {
	content: "\eb6e";
}

.linearicons-golf2:before {
	content: "\eb6f";
}

.linearicons-archery:before {
	content: "\eb70";
}

.linearicons-slingshot:before {
	content: "\eb71";
}

.linearicons-soccer:before {
	content: "\eb72";
}

.linearicons-basketball:before {
	content: "\eb73";
}

.linearicons-cube:before {
	content: "\eb74";
}

.linearicons-3d-rotate:before {
	content: "\eb75";
}

.linearicons-puzzle:before {
	content: "\eb76";
}

.linearicons-glasses:before {
	content: "\eb77";
}

.linearicons-glasses2:before {
	content: "\eb78";
}

.linearicons-accessibility:before {
	content: "\eb79";
}

.linearicons-wheelchair:before {
	content: "\eb7a";
}

.linearicons-wall:before {
	content: "\eb7b";
}

.linearicons-fence:before {
	content: "\eb7c";
}

.linearicons-wall2:before {
	content: "\eb7d";
}

.linearicons-icons:before {
	content: "\eb7e";
}

.linearicons-resize-handle:before {
	content: "\eb7f";
}

.linearicons-icons2:before {
	content: "\eb80";
}

.linearicons-select:before {
	content: "\eb81";
}

.linearicons-select2:before {
	content: "\eb82";
}

.linearicons-site-map:before {
	content: "\eb83";
}

.linearicons-earth:before {
	content: "\eb84";
}

.linearicons-earth-lock:before {
	content: "\eb85";
}

.linearicons-network:before {
	content: "\eb86";
}

.linearicons-network-lock:before {
	content: "\eb87";
}

.linearicons-planet:before {
	content: "\eb88";
}

.linearicons-happy:before {
	content: "\eb89";
}

.linearicons-smile:before {
	content: "\eb8a";
}

.linearicons-grin:before {
	content: "\eb8b";
}

.linearicons-tongue:before {
	content: "\eb8c";
}

.linearicons-sad:before {
	content: "\eb8d";
}

.linearicons-wink:before {
	content: "\eb8e";
}

.linearicons-dream:before {
	content: "\eb8f";
}

.linearicons-shocked:before {
	content: "\eb90";
}

.linearicons-shocked2:before {
	content: "\eb91";
}

.linearicons-tongue2:before {
	content: "\eb92";
}

.linearicons-neutral:before {
	content: "\eb93";
}

.linearicons-happy-grin:before {
	content: "\eb94";
}

.linearicons-cool:before {
	content: "\eb95";
}

.linearicons-mad:before {
	content: "\eb96";
}

.linearicons-grin-evil:before {
	content: "\eb97";
}

.linearicons-evil:before {
	content: "\eb98";
}

.linearicons-wow:before {
	content: "\eb99";
}

.linearicons-annoyed:before {
	content: "\eb9a";
}

.linearicons-wondering:before {
	content: "\eb9b";
}

.linearicons-confused:before {
	content: "\eb9c";
}

.linearicons-zipped:before {
	content: "\eb9d";
}

.linearicons-grumpy:before {
	content: "\eb9e";
}

.linearicons-mustache:before {
	content: "\eb9f";
}

.linearicons-tombstone-hipster:before {
	content: "\eba0";
}

.linearicons-tombstone:before {
	content: "\eba1";
}

.linearicons-ghost:before {
	content: "\eba2";
}

.linearicons-ghost-hipster:before {
	content: "\eba3";
}

.linearicons-halloween:before {
	content: "\eba4";
}

.linearicons-christmas:before {
	content: "\eba5";
}

.linearicons-easter-egg:before {
	content: "\eba6";
}

.linearicons-mustache2:before {
	content: "\eba7";
}

.linearicons-mustache-glasses:before {
	content: "\eba8";
}

.linearicons-pipe:before {
	content: "\eba9";
}

.linearicons-alarm:before {
	content: "\ebaa";
}

.linearicons-alarm-add:before {
	content: "\ebab";
}

.linearicons-alarm-snooze:before {
	content: "\ebac";
}

.linearicons-alarm-ringing:before {
	content: "\ebad";
}

.linearicons-bullhorn:before {
	content: "\ebae";
}

.linearicons-hearing:before {
	content: "\ebaf";
}

.linearicons-volume-high:before {
	content: "\ebb0";
}

.linearicons-volume-medium:before {
	content: "\ebb1";
}

.linearicons-volume-low:before {
	content: "\ebb2";
}

.linearicons-volume:before {
	content: "\ebb3";
}

.linearicons-mute:before {
	content: "\ebb4";
}

.linearicons-lan:before {
	content: "\ebb5";
}

.linearicons-lan2:before {
	content: "\ebb6";
}

.linearicons-wifi:before {
	content: "\ebb7";
}

.linearicons-wifi-lock:before {
	content: "\ebb8";
}

.linearicons-wifi-blocked:before {
	content: "\ebb9";
}

.linearicons-wifi-mid:before {
	content: "\ebba";
}

.linearicons-wifi-low:before {
	content: "\ebbb";
}

.linearicons-wifi-low2:before {
	content: "\ebbc";
}

.linearicons-wifi-alert:before {
	content: "\ebbd";
}

.linearicons-wifi-alert-mid:before {
	content: "\ebbe";
}

.linearicons-wifi-alert-low:before {
	content: "\ebbf";
}

.linearicons-wifi-alert-low2:before {
	content: "\ebc0";
}

.linearicons-stream:before {
	content: "\ebc1";
}

.linearicons-stream-check:before {
	content: "\ebc2";
}

.linearicons-stream-error:before {
	content: "\ebc3";
}

.linearicons-stream-alert:before {
	content: "\ebc4";
}

.linearicons-communication:before {
	content: "\ebc5";
}

.linearicons-communication-crossed:before {
	content: "\ebc6";
}

.linearicons-broadcast:before {
	content: "\ebc7";
}

.linearicons-antenna:before {
	content: "\ebc8";
}

.linearicons-satellite:before {
	content: "\ebc9";
}

.linearicons-satellite2:before {
	content: "\ebca";
}

.linearicons-mic:before {
	content: "\ebcb";
}

.linearicons-mic-mute:before {
	content: "\ebcc";
}

.linearicons-mic2:before {
	content: "\ebcd";
}

.linearicons-spotlights:before {
	content: "\ebce";
}

.linearicons-hourglass:before {
	content: "\ebcf";
}

.linearicons-loading:before {
	content: "\ebd0";
}

.linearicons-loading2:before {
	content: "\ebd1";
}

.linearicons-loading3:before {
	content: "\ebd2";
}

.linearicons-refresh:before {
	content: "\ebd3";
}

.linearicons-refresh2:before {
	content: "\ebd4";
}

.linearicons-undo:before {
	content: "\ebd5";
}

.linearicons-redo:before {
	content: "\ebd6";
}

.linearicons-jump2:before {
	content: "\ebd7";
}

.linearicons-undo2:before {
	content: "\ebd8";
}

.linearicons-redo2:before {
	content: "\ebd9";
}

.linearicons-sync:before {
	content: "\ebda";
}

.linearicons-repeat-one2:before {
	content: "\ebdb";
}

.linearicons-sync-crossed:before {
	content: "\ebdc";
}

.linearicons-sync2:before {
	content: "\ebdd";
}

.linearicons-repeat-one3:before {
	content: "\ebde";
}

.linearicons-sync-crossed2:before {
	content: "\ebdf";
}

.linearicons-return:before {
	content: "\ebe0";
}

.linearicons-return2:before {
	content: "\ebe1";
}

.linearicons-refund:before {
	content: "\ebe2";
}

.linearicons-history:before {
	content: "\ebe3";
}

.linearicons-history2:before {
	content: "\ebe4";
}

.linearicons-self-timer:before {
	content: "\ebe5";
}

.linearicons-clock:before {
	content: "\ebe6";
}

.linearicons-clock2:before {
	content: "\ebe7";
}

.linearicons-clock3:before {
	content: "\ebe8";
}

.linearicons-watch:before {
	content: "\ebe9";
}

.linearicons-alarm2:before {
	content: "\ebea";
}

.linearicons-alarm-add2:before {
	content: "\ebeb";
}

.linearicons-alarm-remove:before {
	content: "\ebec";
}

.linearicons-alarm-check:before {
	content: "\ebed";
}

.linearicons-alarm-error:before {
	content: "\ebee";
}

.linearicons-timer:before {
	content: "\ebef";
}

.linearicons-timer-crossed:before {
	content: "\ebf0";
}

.linearicons-timer2:before {
	content: "\ebf1";
}

.linearicons-timer-crossed2:before {
	content: "\ebf2";
}

.linearicons-download:before {
	content: "\ebf3";
}

.linearicons-upload:before {
	content: "\ebf4";
}

.linearicons-download2:before {
	content: "\ebf5";
}

.linearicons-upload2:before {
	content: "\ebf6";
}

.linearicons-enter-up:before {
	content: "\ebf7";
}

.linearicons-enter-down:before {
	content: "\ebf8";
}

.linearicons-enter-left:before {
	content: "\ebf9";
}

.linearicons-enter-right:before {
	content: "\ebfa";
}

.linearicons-exit-up:before {
	content: "\ebfb";
}

.linearicons-exit-down:before {
	content: "\ebfc";
}

.linearicons-exit-left:before {
	content: "\ebfd";
}

.linearicons-exit-right:before {
	content: "\ebfe";
}

.linearicons-enter-up2:before {
	content: "\ebff";
}

.linearicons-enter-down2:before {
	content: "\ec00";
}

.linearicons-enter-vertical:before {
	content: "\ec01";
}

.linearicons-enter-left2:before {
	content: "\ec02";
}

.linearicons-enter-right2:before {
	content: "\ec03";
}

.linearicons-enter-horizontal:before {
	content: "\ec04";
}

.linearicons-exit-up2:before {
	content: "\ec05";
}

.linearicons-exit-down2:before {
	content: "\ec06";
}

.linearicons-exit-left2:before {
	content: "\ec07";
}

.linearicons-exit-right2:before {
	content: "\ec08";
}

.linearicons-cli:before {
	content: "\ec09";
}

.linearicons-bug:before {
	content: "\ec0a";
}

.linearicons-code:before {
	content: "\ec0b";
}

.linearicons-file-code:before {
	content: "\ec0c";
}

.linearicons-file-image:before {
	content: "\ec0d";
}

.linearicons-file-zip:before {
	content: "\ec0e";
}

.linearicons-file-audio:before {
	content: "\ec0f";
}

.linearicons-file-video:before {
	content: "\ec10";
}

.linearicons-file-preview:before {
	content: "\ec11";
}

.linearicons-file-charts:before {
	content: "\ec12";
}

.linearicons-file-stats:before {
	content: "\ec13";
}

.linearicons-file-spreadsheet:before {
	content: "\ec14";
}

.linearicons-link:before {
	content: "\ec15";
}

.linearicons-unlink:before {
	content: "\ec16";
}

.linearicons-link2:before {
	content: "\ec17";
}

.linearicons-unlink2:before {
	content: "\ec18";
}

.linearicons-thumbs-up:before {
	content: "\ec19";
}

.linearicons-thumbs-down:before {
	content: "\ec1a";
}

.linearicons-thumbs-up2:before {
	content: "\ec1b";
}

.linearicons-thumbs-down2:before {
	content: "\ec1c";
}

.linearicons-thumbs-up3:before {
	content: "\ec1d";
}

.linearicons-thumbs-down3:before {
	content: "\ec1e";
}

.linearicons-share:before {
	content: "\ec1f";
}

.linearicons-share2:before {
	content: "\ec20";
}

.linearicons-share3:before {
	content: "\ec21";
}

.linearicons-magnifier:before {
	content: "\ec22";
}

.linearicons-file-search:before {
	content: "\ec23";
}

.linearicons-find-replace:before {
	content: "\ec24";
}

.linearicons-zoom-in:before {
	content: "\ec25";
}

.linearicons-zoom-out:before {
	content: "\ec26";
}

.linearicons-loupe:before {
	content: "\ec27";
}

.linearicons-loupe-zoom-in:before {
	content: "\ec28";
}

.linearicons-loupe-zoom-out:before {
	content: "\ec29";
}

.linearicons-cross:before {
	content: "\ec2a";
}

.linearicons-menu:before {
	content: "\ec2b";
}

.linearicons-list:before {
	content: "\ec2c";
}

.linearicons-list2:before {
	content: "\ec2d";
}

.linearicons-list3:before {
	content: "\ec2e";
}

.linearicons-menu2:before {
	content: "\ec2f";
}

.linearicons-list4:before {
	content: "\ec30";
}

.linearicons-menu3:before {
	content: "\ec31";
}

.linearicons-exclamation:before {
	content: "\ec32";
}

.linearicons-question:before {
	content: "\ec33";
}

.linearicons-check:before {
	content: "\ec34";
}

.linearicons-cross2:before {
	content: "\ec35";
}

.linearicons-plus:before {
	content: "\ec36";
}

.linearicons-minus:before {
	content: "\ec37";
}

.linearicons-percent:before {
	content: "\ec38";
}

.linearicons-chevron-up:before {
	content: "\ec39";
}

.linearicons-chevron-down:before {
	content: "\ec3a";
}

.linearicons-chevron-left:before {
	content: "\ec3b";
}

.linearicons-chevron-right:before {
	content: "\ec3c";
}

.linearicons-chevrons-expand-vertical:before {
	content: "\ec3d";
}

.linearicons-chevrons-expand-horizontal:before {
	content: "\ec3e";
}

.linearicons-chevrons-contract-vertical:before {
	content: "\ec3f";
}

.linearicons-chevrons-contract-horizontal:before {
	content: "\ec40";
}

.linearicons-arrow-up:before {
	content: "\ec41";
}

.linearicons-arrow-down:before {
	content: "\ec42";
}

.linearicons-arrow-left:before {
	content: "\ec43";
}

.linearicons-arrow-right:before {
	content: "\ec44";
}

.linearicons-arrow-up-right:before {
	content: "\ec45";
}

.linearicons-arrows-merge:before {
	content: "\ec46";
}

.linearicons-arrows-split:before {
	content: "\ec47";
}

.linearicons-arrow-divert:before {
	content: "\ec48";
}

.linearicons-arrow-return:before {
	content: "\ec49";
}

.linearicons-expand:before {
	content: "\ec4a";
}

.linearicons-contract:before {
	content: "\ec4b";
}

.linearicons-expand2:before {
	content: "\ec4c";
}

.linearicons-contract2:before {
	content: "\ec4d";
}

.linearicons-move:before {
	content: "\ec4e";
}

.linearicons-tab:before {
	content: "\ec4f";
}

.linearicons-arrow-wave:before {
	content: "\ec50";
}

.linearicons-expand3:before {
	content: "\ec51";
}

.linearicons-expand4:before {
	content: "\ec52";
}

.linearicons-contract3:before {
	content: "\ec53";
}

.linearicons-notification:before {
	content: "\ec54";
}

.linearicons-warning:before {
	content: "\ec55";
}

.linearicons-notification-circle:before {
	content: "\ec56";
}

.linearicons-question-circle:before {
	content: "\ec57";
}

.linearicons-menu-circle:before {
	content: "\ec58";
}

.linearicons-checkmark-circle:before {
	content: "\ec59";
}

.linearicons-cross-circle:before {
	content: "\ec5a";
}

.linearicons-plus-circle:before {
	content: "\ec5b";
}

.linearicons-circle-minus:before {
	content: "\ec5c";
}

.linearicons-percent-circle:before {
	content: "\ec5d";
}

.linearicons-arrow-up-circle:before {
	content: "\ec5e";
}

.linearicons-arrow-down-circle:before {
	content: "\ec5f";
}

.linearicons-arrow-left-circle:before {
	content: "\ec60";
}

.linearicons-arrow-right-circle:before {
	content: "\ec61";
}

.linearicons-chevron-up-circle:before {
	content: "\ec62";
}

.linearicons-chevron-down-circle:before {
	content: "\ec63";
}

.linearicons-chevron-left-circle:before {
	content: "\ec64";
}

.linearicons-chevron-right-circle:before {
	content: "\ec65";
}

.linearicons-backward-circle:before {
	content: "\ec66";
}

.linearicons-first-circle:before {
	content: "\ec67";
}

.linearicons-previous-circle:before {
	content: "\ec68";
}

.linearicons-stop-circle:before {
	content: "\ec69";
}

.linearicons-play-circle:before {
	content: "\ec6a";
}

.linearicons-pause-circle:before {
	content: "\ec6b";
}

.linearicons-next-circle:before {
	content: "\ec6c";
}

.linearicons-last-circle:before {
	content: "\ec6d";
}

.linearicons-forward-circle:before {
	content: "\ec6e";
}

.linearicons-eject-circle:before {
	content: "\ec6f";
}

.linearicons-crop:before {
	content: "\ec70";
}

.linearicons-frame-expand:before {
	content: "\ec71";
}

.linearicons-frame-contract:before {
	content: "\ec72";
}

.linearicons-focus:before {
	content: "\ec73";
}

.linearicons-transform:before {
	content: "\ec74";
}

.linearicons-grid:before {
	content: "\ec75";
}

.linearicons-grid-crossed:before {
	content: "\ec76";
}

.linearicons-layers:before {
	content: "\ec77";
}

.linearicons-layers-crossed:before {
	content: "\ec78";
}

.linearicons-toggle:before {
	content: "\ec79";
}

.linearicons-rulers:before {
	content: "\ec7a";
}

.linearicons-ruler:before {
	content: "\ec7b";
}

.linearicons-funnel:before {
	content: "\ec7c";
}

.linearicons-flip-horizontal:before {
	content: "\ec7d";
}

.linearicons-flip-vertical:before {
	content: "\ec7e";
}

.linearicons-flip-horizontal2:before {
	content: "\ec7f";
}

.linearicons-flip-vertical2:before {
	content: "\ec80";
}

.linearicons-angle:before {
	content: "\ec81";
}

.linearicons-angle2:before {
	content: "\ec82";
}

.linearicons-subtract:before {
	content: "\ec83";
}

.linearicons-combine:before {
	content: "\ec84";
}

.linearicons-intersect:before {
	content: "\ec85";
}

.linearicons-exclude:before {
	content: "\ec86";
}

.linearicons-align-center-vertical:before {
	content: "\ec87";
}

.linearicons-align-right:before {
	content: "\ec88";
}

.linearicons-align-bottom:before {
	content: "\ec89";
}

.linearicons-align-left:before {
	content: "\ec8a";
}

.linearicons-align-center-horizontal:before {
	content: "\ec8b";
}

.linearicons-align-top:before {
	content: "\ec8c";
}

.linearicons-square:before {
	content: "\ec8d";
}

.linearicons-plus-square:before {
	content: "\ec8e";
}

.linearicons-minus-square:before {
	content: "\ec8f";
}

.linearicons-percent-square:before {
	content: "\ec90";
}

.linearicons-arrow-up-square:before {
	content: "\ec91";
}

.linearicons-arrow-down-square:before {
	content: "\ec92";
}

.linearicons-arrow-left-square:before {
	content: "\ec93";
}

.linearicons-arrow-right-square:before {
	content: "\ec94";
}

.linearicons-chevron-up-square:before {
	content: "\ec95";
}

.linearicons-chevron-down-square:before {
	content: "\ec96";
}

.linearicons-chevron-left-square:before {
	content: "\ec97";
}

.linearicons-chevron-right-square:before {
	content: "\ec98";
}

.linearicons-check-square:before {
	content: "\ec99";
}

.linearicons-cross-square:before {
	content: "\ec9a";
}

.linearicons-menu-square:before {
	content: "\ec9b";
}

.linearicons-prohibited:before {
	content: "\ec9c";
}

.linearicons-circle:before {
	content: "\ec9d";
}

.linearicons-radio-button:before {
	content: "\ec9e";
}

.linearicons-ligature:before {
	content: "\ec9f";
}

.linearicons-text-format:before {
	content: "\eca0";
}

.linearicons-text-format-remove:before {
	content: "\eca1";
}

.linearicons-text-size:before {
	content: "\eca2";
}

.linearicons-bold:before {
	content: "\eca3";
}

.linearicons-italic:before {
	content: "\eca4";
}

.linearicons-underline:before {
	content: "\eca5";
}

.linearicons-strikethrough:before {
	content: "\eca6";
}

.linearicons-highlight:before {
	content: "\eca7";
}

.linearicons-text-align-left:before {
	content: "\eca8";
}

.linearicons-text-align-center:before {
	content: "\eca9";
}

.linearicons-text-align-right:before {
	content: "\ecaa";
}

.linearicons-text-align-justify:before {
	content: "\ecab";
}

.linearicons-line-spacing:before {
	content: "\ecac";
}

.linearicons-indent-increase:before {
	content: "\ecad";
}

.linearicons-indent-decrease:before {
	content: "\ecae";
}

.linearicons-text-wrap:before {
	content: "\ecaf";
}

.linearicons-pilcrow:before {
	content: "\ecb0";
}

.linearicons-direction-ltr:before {
	content: "\ecb1";
}

.linearicons-direction-rtl:before {
	content: "\ecb2";
}

.linearicons-page-break:before {
	content: "\ecb3";
}

.linearicons-page-break2:before {
	content: "\ecb4";
}

.linearicons-sort-alpha-asc:before {
	content: "\ecb5";
}

.linearicons-sort-alpha-desc:before {
	content: "\ecb6";
}

.linearicons-sort-numeric-asc:before {
	content: "\ecb7";
}

.linearicons-sort-numeric-desc:before {
	content: "\ecb8";
}

.linearicons-sort-amount-asc:before {
	content: "\ecb9";
}

.linearicons-sort-amount-desc:before {
	content: "\ecba";
}

.linearicons-sort-time-asc:before {
	content: "\ecbb";
}

.linearicons-sort-time-desc:before {
	content: "\ecbc";
}

.linearicons-sigma:before {
	content: "\ecbd";
}

.linearicons-pencil-line:before {
	content: "\ecbe";
}

.linearicons-hand:before {
	content: "\ecbf";
}

.linearicons-pointer-up:before {
	content: "\ecc0";
}

.linearicons-pointer-right:before {
	content: "\ecc1";
}

.linearicons-pointer-down:before {
	content: "\ecc2";
}

.linearicons-pointer-left:before {
	content: "\ecc3";
}

.linearicons-finger-tap:before {
	content: "\ecc4";
}

.linearicons-fingers-tap:before {
	content: "\ecc5";
}

.linearicons-reminder:before {
	content: "\ecc6";
}

.linearicons-fingers-crossed:before {
	content: "\ecc7";
}

.linearicons-fingers-victory:before {
	content: "\ecc8";
}

.linearicons-gesture-zoom:before {
	content: "\ecc9";
}

.linearicons-gesture-pinch:before {
	content: "\ecca";
}

.linearicons-fingers-scroll-horizontal:before {
	content: "\eccb";
}

.linearicons-fingers-scroll-vertical:before {
	content: "\eccc";
}

.linearicons-fingers-scroll-left:before {
	content: "\eccd";
}

.linearicons-fingers-scroll-right:before {
	content: "\ecce";
}

.linearicons-hand2:before {
	content: "\eccf";
}

.linearicons-pointer-up2:before {
	content: "\ecd0";
}

.linearicons-pointer-right2:before {
	content: "\ecd1";
}

.linearicons-pointer-down2:before {
	content: "\ecd2";
}

.linearicons-pointer-left2:before {
	content: "\ecd3";
}

.linearicons-finger-tap2:before {
	content: "\ecd4";
}

.linearicons-fingers-tap2:before {
	content: "\ecd5";
}

.linearicons-reminder2:before {
	content: "\ecd6";
}

.linearicons-gesture-zoom2:before {
	content: "\ecd7";
}

.linearicons-gesture-pinch2:before {
	content: "\ecd8";
}

.linearicons-fingers-scroll-horizontal2:before {
	content: "\ecd9";
}

.linearicons-fingers-scroll-vertical2:before {
	content: "\ecda";
}

.linearicons-fingers-scroll-left2:before {
	content: "\ecdb";
}

.linearicons-fingers-scroll-right2:before {
	content: "\ecdc";
}

.linearicons-fingers-scroll-vertical3:before {
	content: "\ecdd";
}

.linearicons-border-style:before {
	content: "\ecde";
}

.linearicons-border-all:before {
	content: "\ecdf";
}

.linearicons-border-outer:before {
	content: "\ece0";
}

.linearicons-border-inner:before {
	content: "\ece1";
}

.linearicons-border-top:before {
	content: "\ece2";
}

.linearicons-border-horizontal:before {
	content: "\ece3";
}

.linearicons-border-bottom:before {
	content: "\ece4";
}

.linearicons-border-left:before {
	content: "\ece5";
}

.linearicons-border-vertical:before {
	content: "\ece6";
}

.linearicons-border-right:before {
	content: "\ece7";
}

.linearicons-border-none:before {
	content: "\ece8";
}

.linearicons-ellipsis:before {
	content: "\ece9";
}
