
.tabs {
  max-width: 90%;
  float: none;
  width: 90%;
  list-style: none;
  padding: 0;
  margin: 75px auto;
  border-bottom: 0.1px solid #ccc;
}
.tabs:after {
  content: "";
  display: table;
  clear: both;
}
.tabs input[type="radio"] {
  display: none;
}
.tabs label {
  display: block;
  float: left;
  width: 33.3333%;
  color: #aaa;
  font-size: 25px;
  font-weight: normal;
  text-decoration: none;
  text-align: center;
  line-height: 2;
  cursor: pointer;
  border-top: 0.1px solid #ccc;
  padding-top: 30px;
  margin-bottom: 30px;
  border-bottom: 4px solid #fff;
  -webkit-transition: all 0.5s; /* Safari 3.1 to 6.0 */
  transition: all 0.5s;
}
.tabs label span {
  display: none;
}
.tabs label i {
  padding: 5px;
  margin-right: 0;
}
.tabs label:hover {
  color: #0accbe;
  /*box-shadow: inset 0 0px #0accbe;*/
  border-bottom: 4px solid #0accbe;
}

.tab-content {
  display: none;
  width: 100%;
  float: left;
  padding: 15px;
  box-sizing: border-box;
  background-color: #ffffff;
}
.tab-content * {
  -webkit-animation: scale 0.7s ease-in-out;
  -moz-animation: scale 0.7s ease-in-out;
  animation: scale 0.7s ease-in-out;
}
@keyframes scale {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  50% {
    transform: scale(1.01);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
#tab1:checked ~ #tab-l1,
#tab2:checked ~ #tab-l2,
#tab3:checked ~ #tab-l3 {
  background: #fff;
  border-bottom: 4px solid #0accbe;
  color: #0accbe;
}
#tab1:checked ~ #tab-content1,
#tab2:checked ~ #tab-content2,
#tab3:checked ~ #tab-content3 {
  display: block;
}
@media (min-width: 768px) {
  .tabs i {
    padding: 5px;
    margin-right: 10px;
  }
  .tabs label span {
    display: inline-block;
  }
  .tabs {
    max-width: 950px;
    margin: 50px auto;
  }
}
