.introducing_4s{

position: absolute;
width: 100%;
height: 982px;
left: 0px;
top: 995px;

background: black;
}
.elipse_20
{
    /* box-sizing: border-box; */

position: absolute;
width: 49px;
height: 49px;
left: 194px;
top: 205px;
background: #292929;
border: 3px solid #FFFFFF;
border-radius: 25px;
}
.elipse_24
{
    border-radius: 25px;
    position: absolute;
    width: 49px;
    height: 49px;
    left: 194px;
    top: 271px;
    
    background: #2D1500;
    border: 3px solid #FFFFFF;
}
.elipse_25
{
    border-radius: 25px;
position: absolute;
width: 49px;
height: 49px;
left: 194px;
top: 338px;

background: #C6C6C6;
border: 3px solid #FFFFFF;
}
.elipse_26
{
    border-radius: 25px;
    position: absolute;
width: 49px;
height: 49px;
left: 194px;
top: 405px;

background: #031D35;
border: 3px solid #FFFFFF;
}

.elipse_20:hover
{
    border: 3px solid #3E71DE;
}
.elipse_24:hover
{
    /* position: absolute;
width: 55px;
height: 55px;
left: 191px;
top: 202px;

background: #3E71DE; */
border: 3px solid #3E71DE;
}
.elipse_25:hover{
    /* position: absolute;
    width: 55px;
    height: 55px;
    left: 191px;
    top: 202px;
    
    background: #3E71DE; */
    border: 3px solid #3E71DE;
}
.elipse_26:hover{
    /* position: absolute;
width: 55px;
height: 55px;
left: 191px;
top: 202px;

background: #3E71DE; */
border: 3px solid #3E71DE;
}
