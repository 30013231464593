.card {
    width: 500px;
    max-width: 90%;
    min-height: 300px;
    background: #1d1d1d;
    padding: 30px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.headingWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}
.heading {
    font-size: 22px;
    font-weight: bold;
    margin-left: 10px;
}
