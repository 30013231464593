.check-heading {
    font-weight: 800;
    font-size: 1.1rem;
    color: black;

}

.check-subheading {
    font-weight: 800;
    font-size: 1rem;
    color: black;

}

.checkout-page-1 {
    font-family: Avenir;
}


.checkout-header h5 {
    font-size: 1.2rem;
    font-weight: 800;
    color: black;
    font-family: Avenir;
}

.checkout-header p {
    color: black;
    font-size: 1rem;
    margin-bottom: 0;
    font-weight: 600;
}

.checkout-container {
    color: black;
}

.checkout-btn {
    background-color: #3b82f6;
    color: white;
    margin-top: 0;
    size: 1rem;
    font-size: 1rem;
    font-weight: 800;
    padding: 10px 18px;
    width: 100%;
    border-radius: .5rem;
}


.checkout-notice h5 {
    font-weight: 800;
    color: black;
    size: 1.2rem;
    font-family: Avenir;
}


.checkout-option-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: .6rem 1rem;
    border: 2px solid #e5e7eb;
    border-radius: .8rem;
    cursor: pointer;
}

.checkout-option-item .title {
    font-size: .8rem;
    font-weight: 800;
    margin-bottom: 0;

}



.checkout-option-item .des {
    margin: 0;
    padding: 0;
    size: .8rem;
}

.active-item {
    border: 2px solid #3b82f6;
}

.checkout-container h6 {
    font-weight: 800;
    font-size: 1rem;
    font-family: Avenir;
    color: black;
}


/* Billing */
.displayBilling p {
    padding-bottom: 0;
    font-weight: 800;
}

.review-text p {
    margin: 0 !important;
    padding: 0;
    font-weight: 500;
}

.review-change-btn {
    color: #3b82f6;
    font-weight: 600;
    cursor: pointer;
}